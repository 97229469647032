import React, { useState, useRef } from 'react';
import Styled from './CarouselHome.styled';
import { Button, Carousel, Drawer } from 'antd';
import Colors from 'enums/Colors';
import { MdArrowForward, MdArrowDownward, MdOutlineMenu } from 'react-icons/md';

import img01 from '../../../assets/imagem_01.jpeg';
import img02 from '../../../assets/imagem_02.jpg';
import img03 from '../../../assets/imagem_07.webp';
import img04 from '../../../assets/imagem_08.jpg';
import { isMobileDevice } from 'react-select/src/utils';
import { IsCustomMaxWidth, IsCustomMinWidth, IsMobileMaxWidth } from 'core/util/WindowSize';
import { BiMenuAltRight } from 'react-icons/bi';
import { IoMdClose } from 'react-icons/io';
import { ButtonHomeSolftBlueV2, ButtonHomeV2 } from '../button/ButtonHomeV2.styled';
import { IoArrowForwardOutline } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import LogoHumaneTextWhite from '../../../../../images/logo/logo_text_white.png';

const enum DescriptionNavEnum {
    SOLUCOES = 'SOLUCOES',
    TEMATICAS = 'TEMATICAS',
    CURSOS = 'CURSOS',
    JORNADAS = 'JORNADAS',
    BIBLIOTECA = 'BIBLIOTECA',
}

interface IButtonAccessBanner {
    text: string;
    url: string;
}

interface IItemNav {
    children: any;
    onClick?: () => void;
    styles?: React.CSSProperties;
}

const ItemNav = ({ children, onClick, styles }: IItemNav) => {
    return (
        <Styled.ContainerMenuNav style={{ ...styles }} onClick={onClick}>
            {children}
        </Styled.ContainerMenuNav>
    );
};

interface ICorouselHome {
    listTarget: React.RefObject<HTMLDivElement>[];
    onChangeTarget: (ref: React.RefObject<HTMLDivElement>) => void;
}

const CorouselHome = ({ onChangeTarget, listTarget }: ICorouselHome) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const carouselRef = useRef<any>(null); // Referência para o carrossel
    const totalSlides = 4;

    const isTablet = IsCustomMaxWidth(680);
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onCloseDrawer = () => {
        setOpen(false);
    };

    const handleDotClick = (index: number) => {
        setCurrentSlide(index);
        carouselRef.current.goTo(index); // Usa a referência para mudar o slide
    };

    const handleSlideClick = () => {
        if (isTablet) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return showDrawer();
        }
        if (currentSlide + 1 < totalSlides) {
            setCurrentSlide(currentSlide + 1);
            carouselRef.current.goTo(currentSlide + 1);
        } else {
            setCurrentSlide(0);
            carouselRef.current.goTo(0);
        }
    };

    const ButtonAccessBanner = ({ text, url }: IButtonAccessBanner) => {
        const history = useHistory();

        const goToLink = () => {
            if (url.includes('http')) {
                window.open(url);

                setTimeout(() => {
                    onCloseDrawer();
                }, 2000);
            } else {
                history.push(url);
            }
        };

        return (
            <ButtonHomeSolftBlueV2
                onClick={() => {
                    goToLink();
                }}
                style={{ width: '200px' }}
            >
                {text} <IoArrowForwardOutline size={18} />
            </ButtonHomeSolftBlueV2>
        );
    };

    const getDescriptionNav = (desc: DescriptionNavEnum, notResumid?: boolean): string => {
        const isTablet = IsCustomMaxWidth(1000);

        switch (desc) {
            case DescriptionNavEnum.SOLUCOES:
                return isTablet && !notResumid ? 'Nossas soluções' : 'Embasadas em evidências e na nossa vasta experiência em sala de aula.';
            case DescriptionNavEnum.TEMATICAS:
                return isTablet && !notResumid
                    ? 'Nossas temáticas'
                    : 'Explore os temas como alfabetização, educação integral e competências socioemocionais.';
            case DescriptionNavEnum.CURSOS:
                return isTablet && !notResumid ? 'Nossos cursos' : 'Cursos livres de curta duração para o desenvolvimento de educadores.';
            case DescriptionNavEnum.JORNADAS:
                return isTablet && !notResumid
                    ? 'Nossas jornadas'
                    : 'Percursos formativos, com ferramentas e atividades para potencializar o seu desenvolvimento.';
            case DescriptionNavEnum.BIBLIOTECA:
                return isTablet && !notResumid
                    ? 'Nossa biblioteca'
                    : 'Guias temáticos, e-books e diversos estudos produzidos pelo Instituto Ayrton Senna e parceiros.';
        }
    };

    return (
        <Styled.Container>
            <Drawer
                closable={false}
                style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(0, 0, 0, 0.8)', color: '#FFFFFF' }}
                onClose={onCloseDrawer}
                open={open}
            >
                <IoMdClose size={32} style={{ marginBottom: '32px' }} onClick={onCloseDrawer} />
                <div>
                    <ItemNav
                        styles={{ borderBottom: '1px solid #FFFFFF', marginBottom: '8px', paddingBottom: '8px' }}
                        onClick={() => {
                            onChangeTarget(listTarget[0]);
                            onCloseDrawer();
                        }}
                    >
                        <Styled.TitleNav>Soluções</Styled.TitleNav>
                        <Styled.DescriptionNav>{getDescriptionNav(DescriptionNavEnum.SOLUCOES, true)}</Styled.DescriptionNav>
                    </ItemNav>
                    <ItemNav
                        styles={{ borderBottom: '1px solid #FFFFFF', marginBottom: '8px', paddingBottom: '8px' }}
                        onClick={() => {
                            onChangeTarget(listTarget[1]);
                            onCloseDrawer();
                        }}
                    >
                        <Styled.TitleNav>Temáticas</Styled.TitleNav>
                        <Styled.DescriptionNav>{getDescriptionNav(DescriptionNavEnum.TEMATICAS, true)}</Styled.DescriptionNav>
                    </ItemNav>
                    <ItemNav
                        styles={{ borderBottom: '1px solid #FFFFFF', marginBottom: '8px', paddingBottom: '8px' }}
                        onClick={() => {
                            onChangeTarget(listTarget[2]);
                            onCloseDrawer();
                        }}
                    >
                        <Styled.TitleNav>Cursos</Styled.TitleNav>
                        <Styled.DescriptionNav>{getDescriptionNav(DescriptionNavEnum.CURSOS, true)}</Styled.DescriptionNav>
                    </ItemNav>
                    <ItemNav
                        styles={{ borderBottom: '1px solid #FFFFFF', marginBottom: '8px', paddingBottom: '8px' }}
                        onClick={() => {
                            onChangeTarget(listTarget[3]);
                            onCloseDrawer();
                        }}
                    >
                        <Styled.TitleNav>Jornadas</Styled.TitleNav>
                        <Styled.DescriptionNav>{getDescriptionNav(DescriptionNavEnum.JORNADAS, true)}</Styled.DescriptionNav>
                    </ItemNav>
                    <ItemNav
                        styles={{ borderBottom: '1px solid #FFFFFF', marginBottom: '8px', paddingBottom: '8px' }}
                        onClick={() => {
                            onChangeTarget(listTarget[4]);
                            onCloseDrawer();
                        }}
                    >
                        <Styled.TitleNav>Biblioteca</Styled.TitleNav>
                        <Styled.DescriptionNav>{getDescriptionNav(DescriptionNavEnum.BIBLIOTECA, true)}</Styled.DescriptionNav>
                    </ItemNav>
                </div>
            </Drawer>
            <Carousel
                ref={carouselRef} // Associa a referência ao carrossel
                effect="fade"
                infinite
                autoplay
                autoplaySpeed={8000}
                dots={false}
                beforeChange={(from, to) => setCurrentSlide(to)}
            >
                <Styled.ContentCarousel img={img01} onClick={() => handleSlideClick()}>
                    <Styled.BodyBanner>
                        <img style={{ width: IsMobileMaxWidth(600) ? '350px' : '400px' }} src={LogoHumaneTextWhite} />
                        {/* <Styled.TitleBanner>Nosso propósito</Styled.TitleBanner> */}
                        <Styled.DescriptionBanner
                        style={{
                            marginTop: IsMobileMaxWidth(600) ? '20px' : '1rem'
                        }}
                            dangerouslySetInnerHTML={{
                                __html: IsCustomMinWidth(600)
                                    ? `
                           Somos um ambiente digital que oferece gratuitamente cursos, <br> jornadas de desenvolvimento e formação integral para educadores. <br/>
                           Tudo fundamentado em evidências científicas e no conhecimento <br/> acumulado em três décadas de atuação. 
                            `
                                    : `Somos um ambiente digital que oferece gratuitamente cursos, jornadas de desenvolvimento e formação integral para educadores.
                           Tudo fundamentado em evidências científicas e no conhecimento  acumulado em três décadas de atuação. `,
                            }}
                        />
                    </Styled.BodyBanner>
                </Styled.ContentCarousel>
                <Styled.ContentCarousel img={img02} onClick={() => handleSlideClick()}>
                    <Styled.BodyBanner>
                        <Styled.TitleBanner>Nosso objetivo</Styled.TitleBanner>
                        <Styled.DescriptionBanner
                            dangerouslySetInnerHTML={{
                                __html: `
                              Buscamos <strong>desenvolver competências</strong> tanto no aspecto individual <br/> quanto coletivo, fortalecendo o desenvolvimento integral dos estudantes. 
                              Além disso, buscamos <strong>atualizar conhecimentos para enfrentar os  desafios da educação contemporânea</strong>, complementando a formação 
                              dos educadores e oferecendo suporte prático para sua rotina de trabalho.
                            `,
                            }}
                        />
                    </Styled.BodyBanner>
                </Styled.ContentCarousel>
                <Styled.ContentCarousel rightPorcent={IsCustomMaxWidth(600) ? '70%' : '50%'} img={img03} onClick={() => handleSlideClick()}>
                    <Styled.BodyBanner>
                        <Styled.DestaqueTextBanner>30 anos de legado</Styled.DestaqueTextBanner>
                        <Styled.TitleBanner>
                            TRANSFORMANDO VIDAS POR <br /> MEIO DA EDUCAÇÃO
                        </Styled.TitleBanner>
                        <ButtonAccessBanner text="Saiba mais" url="https://institutoayrtonsenna.org.br/30-anos/" />
                    </Styled.BodyBanner>
                </Styled.ContentCarousel>
                <Styled.ContentCarousel topPorcent="60%" revert img={img04} onClick={() => handleSlideClick()}>
                    <Styled.BodyBanner>
                        <Styled.DestaqueTextBanner>NOVO CURSO</Styled.DestaqueTextBanner>
                        <Styled.TitleBanner>Desempenho Escolar</Styled.TitleBanner>
                        <Styled.DescriptionBanner
                            dangerouslySetInnerHTML={{
                                __html: `
                                Saiba como as competências socioemocionais contribuem para o desempenho escolar dos estudantes e como elas favorecem o uso das estratégias de aprendizagem pelos estudantes, 
                                refletindo na autonomia e motivação para os estudos.
                            `,
                            }}
                        />
                        <ButtonAccessBanner text="Acesse o curso" url="/recursos/cursos/desempenho-escolar" />
                    </Styled.BodyBanner>
                </Styled.ContentCarousel>
            </Carousel>
            <Styled.ContainerDots className="custom-dots">
                {Array.from({ length: totalSlides }).map((_, index) => (
                    <span
                        key={index}
                        onClick={() => handleDotClick(index)}
                        style={{
                            display: 'inline-block',
                            width: '8px',
                            height: '8px',
                            margin: '0 5px',
                            backgroundColor: currentSlide === index ? Colors.Yellow : '#d9d9d9',
                            borderRadius: '50%',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s ease',
                        }}
                    />
                ))}
            </Styled.ContainerDots>
            <Styled.ContainerNav>
                <div style={{ width: '100%', backgroundColor: '#000000', height: '100%', opacity: '60%', position: 'absolute', zIndex: 1 }} />

                <Styled.ContentNav>
                    {IsCustomMaxWidth(1000) && (
                        <ItemNav
                            styles={{
                                width: '320px',
                            }}
                        >
                            <Styled.ContentItemNav
                                onClick={() => {
                                    handleSlideClick();
                                }}
                            >
                                <Styled.TitleNavStatic>Navegue pelas Categorias</Styled.TitleNavStatic>
                                <MdArrowForward size={32} style={{ marginRight: '20px' }} />
                            </Styled.ContentItemNav>
                        </ItemNav>
                    )}

                    <Styled.ContentInfo>
                        {IsCustomMinWidth(1000) && (
                            <ItemNav>
                                <div
                                    onClick={handleSlideClick}
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}
                                >
                                    <Styled.TitleNavStatic dangerouslySetInnerHTML={{ __html: 'Navegue </br> pelas </br> Categorias' }} />
                                    <MdArrowForward size={32} style={{ marginRight: '20px' }} />
                                </div>
                            </ItemNav>
                        )}

                        <ItemNav onClick={() => onChangeTarget(listTarget[0])}>
                            <Styled.TitleNav>Soluções</Styled.TitleNav>
                            <Styled.DescriptionNav>{getDescriptionNav(DescriptionNavEnum.SOLUCOES)}</Styled.DescriptionNav>
                            <Styled.SaibaMais>Saiba mais</Styled.SaibaMais>
                        </ItemNav>
                        <ItemNav onClick={() => onChangeTarget(listTarget[1])}>
                            <Styled.TitleNav>Temáticas</Styled.TitleNav>
                            <Styled.DescriptionNav>{getDescriptionNav(DescriptionNavEnum.TEMATICAS)}</Styled.DescriptionNav>
                            <Styled.SaibaMais>Saiba mais</Styled.SaibaMais>
                        </ItemNav>
                        <ItemNav onClick={() => onChangeTarget(listTarget[2])}>
                            <Styled.TitleNav>Cursos</Styled.TitleNav>
                            <Styled.DescriptionNav>{getDescriptionNav(DescriptionNavEnum.CURSOS)}</Styled.DescriptionNav>
                            <Styled.SaibaMais>Saiba mais</Styled.SaibaMais>
                        </ItemNav>
                        <ItemNav onClick={() => onChangeTarget(listTarget[3])}>
                            <Styled.TitleNav>Jornadas</Styled.TitleNav>
                            <Styled.DescriptionNav>{getDescriptionNav(DescriptionNavEnum.JORNADAS)}</Styled.DescriptionNav>
                            <Styled.SaibaMais>Saiba mais</Styled.SaibaMais>
                        </ItemNav>
                        <ItemNav onClick={() => onChangeTarget(listTarget[4])}>
                            <Styled.TitleNav>Biblioteca</Styled.TitleNav>
                            <Styled.DescriptionNav>{getDescriptionNav(DescriptionNavEnum.BIBLIOTECA)}</Styled.DescriptionNav>
                            <Styled.SaibaMais>Saiba mais</Styled.SaibaMais>
                        </ItemNav>
                    </Styled.ContentInfo>
                </Styled.ContentNav>
            </Styled.ContainerNav>
        </Styled.Container>
    );
};

export default CorouselHome;
