import React, { useContext, useEffect, useState } from 'react';
import { Table, Input, Button, Space, Modal, Badge, DatePicker, Select } from 'antd';
import { MessageOutlined, SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { FadCommentaryContentService, IFilterCommentary } from 'core/http/service/ferramentaAutoria/FadCommentaryContent.service';
import { FadCommentaryContentPrivateModel } from 'models/ferramentaAutoria/FadCommentaryContentPrivateModel';
import { FerramentaAutoriaContext } from '../context/FerramentaAutoriaContext';
import moment from 'moment';
import { MdCheck, MdCheckCircle, MdWarning } from 'react-icons/md';
import Colors from 'enums/Colors';
import { FadCoursesModel } from 'models/ferramentaAutoria/FadCoursesModel';
import { FadModulesModel } from 'models/ferramentaAutoria/FadModulesModel';
import { FadCommentaryContentModel } from 'models/ferramentaAutoria/FadCommentaryContentModel';
import { ToastSuccess } from 'components/Toaster';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import { FadClassesModel } from 'models/ferramentaAutoria/FadClassesModel';
import useFetch from 'hooks/useFetch';
import { FadCoursesService } from 'core/http/service/ferramentaAutoria/FadCourses.service';

const Container = styled.div`
    padding: 20px;
    background: #fff;
`;

const Styled = {
    Commentary: styled.div`
        display: flex;
        flex-direction: column;
    `,

    NameUser: styled.h1`
        font-weight: 700;
        color: ${Colors.Blue};
    `,

    Content: styled.p`
        margin-bottom: 12px;
    `,

    RowBettween: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,

    Metric: {
        label: styled.h1`
            font-weight: 600;
        `,

        value: styled.h1`
         font-weight: 600;
         color: ${Colors.Blue};
        `
    }
};

const { RangePicker } = DatePicker;

const AdminFadCommentarys = () => {
    const { commentarys, setCommentary } = useContext(FerramentaAutoriaContext.Context);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [filters, setFilters] = useState<IFilterCommentary | null>(null);
    const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

    //Filters Selecteds
    const [commentaryFilters, setCommentaryFilters] = useState<FadCommentaryContentPrivateModel[]>([]);
    const [courses, setCourses] = useState<FadCoursesModel[]>([]);
    const [nameCourseSelected, setNameCourseSelected] = useState<string>('');
    const [status, setStatus] = useState<number>(-1);

    const [metricCommentary, setMetricCommentary] = useState<number>(0);

    //Add novo comentário
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [replyComment, setReplyComment] = useState('');
    const [selectedComment, setSelectedComment] = useState<FadCommentaryContentPrivateModel | null>(null);

    const service = new FadCommentaryContentService();
    const serviceCourse = new FadCoursesService();

    const fetchData = async (page: number, pageSize: number, filters: IFilterCommentary | null) => {
        setLoading(true);

        try {
            const response = await service.findAll(filters);

            setCommentary(response.data);
            setPagination(prev => ({ ...prev, total: response.totalElements }));

            const response1 = await service.getCountCommentary(filters);

            setMetricCommentary(response1.data);
        } catch (error) {
            console.error('Erro ao buscar comentários:', error);
        }
        setLoading(false);
    };

    const handleDateChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
        if (dates && dates[0] && dates[1]) {
            setFilters((prev: any) => ({
                ...prev,
                dateInit: dates[0]?.toISOString() || undefined,
                dateMax: dates[1]?.endOf('day').toISOString() || undefined,
            }));
        }
    };

    useFetch(async () => {
        const { data, status } = await serviceCourse.findByIsPublic(true);

        if (status === 200) {
            setCourses(data);
        }
    }, []);

    const handleTableChange = (pagination: any) => {
        setPagination(pagination);
    };

    const handleExpand = (expanded: boolean, record: FadCommentaryContentPrivateModel) => {
        setExpandedRowKeys(expanded ? [record.codigo] : []);
    };

    const handleReply = (record: FadCommentaryContentPrivateModel) => {
        setSelectedComment(record);
        setIsModalOpen(true);
    };

    const resetFilter = () => {
        setFilters(null);
        setReplyComment('');
        setCommentaryFilters(commentarys);
        fetchData(pagination.current, pagination.pageSize, null);
        setStatus(-1);
    };

    const onChangeSelectedCourse = (value: string) => {
        setFilters((prev: any) => ({ ...prev, codigoCourse: parseInt(value) }));
    };

    const onChangeSelectedStatus = (value: string) => {
        let commentarysList = [...commentarys];

        commentarysList = commentarysList.filter(c => {
            if (parseInt(value) === 1 && c.answeredAdmin) {
                return true; // Mantém os comentáros que foram respondidos
            }

            if (parseInt(value) === 0 && !c.answeredAdmin) {
                return true; // Mantém os comentários que não foram respondidos
            }

            return false; // Remove os comentários que não atendem à condição
        });
        setStatus(parseInt(value));

        setCommentaryFilters(commentarysList); // Atualiza o estado com os comentários filtrados
    };

    useEffect(() => {
        const courseSelected = courses.find(c => c.codigo === filters?.codigoCourse);

        setNameCourseSelected(courseSelected?.name ?? '');
    }, [filters?.codigoCourse]);

    const onSearch = (value: string) => {
        console.log('search:', value);
    };

    useEffect(() => {
        setCommentaryFilters(commentarys);
    }, [commentarys]);

    const handPublicCommentary = async () => {
        if (selectedComment?.classe && selectedComment.content) {
            const body = new FadCommentaryContentModel().fromJSON({
                codigo: -1,
                codigoFerramentaAutoriaClasse: selectedComment.classe.codigo,
                codigoFerramentaAutoriaContent: selectedComment.content.codigo,
                codigoRelation: selectedComment.codigo,
                nome: '',
                commentary: replyComment,
                dateCreated: '',
                dateUpdated: '',
                myCommentary: true,
                listRespostas: [],
            });

            const { status } = await service.create(body);

            if (status === 201) {
                fetchData(pagination.current, pagination.pageSize, filters);
                setReplyComment('');
                setIsModalOpen(false);
                ToastSuccess('Comentário realizado com sucesso!');
            }
        }
    };

    useEffect(() => {
        fetchData(pagination.current, pagination.pageSize, null);
    }, []);

    const columns = [
        {
            title: 'Respostas',
            dataIndex: 'respostas',
            key: 'respostas',
            render: (_: any, record: FadCommentaryContentPrivateModel) => (
                <Badge count={record.listRespostas.length} overflowCount={5} size="small">
                    <MessageOutlined />
                </Badge>
            ),
        },
        {
            title: 'Nome',
            dataIndex: 'nome',
            key: 'nome',
        },
        {
            title: 'Curso',
            dataIndex: 'course',
            key: 'course',
            render: (c: FadCoursesModel) => c.name,
        },
        {
            title: 'Módulo',
            dataIndex: 'module',
            key: 'module',
            render: (m: FadModulesModel) => m.title,
        },
        {
            title: 'Turma',
            dataIndex: 'classe',
            key: 'classe',
            render: (c: FadClassesModel) => c.name,
        },
        {
            title: 'Respondido',
            dataIndex: 'answeredAdmin',
            key: 'answeredAdmin',
            render: (v: boolean) => (v ? <MdCheckCircle size={22} color={Colors.LightGreen} /> : <MdWarning size={22} color={Colors.Yellow2} />),
        },
        {
            title: 'Criado em',
            dataIndex: 'dateCreated',
            key: 'dateCreated',
            render: (v: string) => moment(v).format('DD/MM/YYYY HH:mm:ss'),
        },
        {
            title: 'Ações',
            key: 'acoes',
            render: (_: any, record: FadCommentaryContentPrivateModel) => (
                <Button icon={<MessageOutlined />} onClick={() => handleReply(record)} />
            ),
        },
    ];

    return (
        <Container>
            <Styled.RowBettween>
                <Space style={{ marginBottom: 16 }}>
                    <Select
                        showSearch
                        value={nameCourseSelected.length > 0 ? nameCourseSelected : null}
                        placeholder="Selecionar curso"
                        optionFilterProp="label"
                        onChange={onChangeSelectedCourse}
                        onSearch={onSearch}
                        options={courses.map(c => ({ label: c.name, value: c.codigo }))}
                        style={{
                            width: 220,
                        }}
                    />
                    <Select
                        placeholder="Status"
                        optionFilterProp="label"
                        value={status != -1 ? (status ? 'Respondido' : 'Pendente') : null}
                        onChange={v => {
                            onChangeSelectedStatus(v);
                        }}
                        options={[
                            {
                                label: 'Respondido',
                                value: 1,
                            },
                            {
                                label: 'Pendente',
                                value: 0,
                            },
                        ]}
                        style={{
                            width: 220,
                        }}
                    />

                    <RangePicker
                        format="DD-MM-YYYY"
                        placeholder={['Ínicio', 'Fim']}
                        value={filters?.dateInit && filters?.dateMax ? [dayjs(filters.dateInit), dayjs(filters.dateMax)] : null}
                        onChange={handleDateChange}
                    />

                    <Button onClick={() => fetchData(pagination.current, pagination.pageSize, filters)}>Aplicar filtro</Button>
                    <Button
                        onClick={() => {
                            resetFilter();
                        }}
                    >
                        Limpar Filtro
                    </Button>
                </Space>
                <Space>
                        <Styled.Metric.label>Quantidade:</Styled.Metric.label>
                        <Styled.Metric.value>{commentaryFilters.length}</Styled.Metric.value>
                </Space>
            </Styled.RowBettween>
            <Table
                columns={columns}
                dataSource={commentaryFilters}
                loading={loading}
                pagination={pagination}
                onChange={handleTableChange}
                rowKey="codigo"
                expandable={{
                    expandedRowKeys,
                    onExpand: handleExpand,
                    expandedRowRender: record => (
                        <Table
                            columns={[
                                { title: 'Nome', dataIndex: 'nome', key: 'nome' },
                                { title: 'Comentário', dataIndex: 'commentary', key: 'commentary' },
                                {
                                    title: 'Criado em',
                                    dataIndex: 'dateCreated',
                                    key: 'dateCreated',
                                    render: (v: string) => moment(v).format('DD/MM/YYYY HH:mm:ss'),
                                },
                            ]}
                            dataSource={record.listRespostas || []}
                            rowKey="codigo"
                            pagination={false}
                        />
                    ),
                }}
            />
            <Modal
                title="Responder Comentário"
                visible={isModalOpen}
                cancelText={'Fechar'}
                okText={'Responder'}
                okButtonProps={{
                    style: {
                        backgroundColor: Colors.Blue,
                    },
                }}
                onOk={handPublicCommentary}
                onCancel={() => setIsModalOpen(false)}
            >
                <Styled.Commentary>
                    <Styled.NameUser>{selectedComment?.nome}</Styled.NameUser>
                    <Styled.Content>{selectedComment?.commentary}</Styled.Content>
                </Styled.Commentary>

                <Input.TextArea
                    rows={4}
                    value={replyComment}
                    onChange={e => setReplyComment(e.target.value)}
                    placeholder="Escreva sua resposta aqui..."
                />
            </Modal>
        </Container>
    );
};

export default AdminFadCommentarys;
