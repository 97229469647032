import Title from 'components/UI/Title';
import React from 'react';
import eye from './../../assets/images/eye.svg';
import screen from './../../assets/images/screen.svg';
import mouse from './../../assets/images/mouse.svg';
import button from './../../assets/images/button.svg';
import button2 from './../../assets/images/button2.svg';
import { ISliderItemAutoavaliacao } from './SliderItemAutoavaliacao';

const SliderAutoavaliacaoData: ISliderItemAutoavaliacao[] = [
    {
        index: 1,
        title: <Title style={{ color: '#183F73', fontSize: '38px' }}>Atenção</Title>,
        image: screen,
        image2: '',
        image3: '',
        content: (
            <>
                <p>
                    Recomendamos <strong>realizar a jornada utilizando o computador</strong>, para que tenha uma melhor experiência no
                    preenchimento da autoavaliação.
                </p>
            </>
        ),
        content2: <></>,
        content3: '',
    },
    {
        index: 2,
        title: <Title style={{ color: '#183F73', fontSize: '38px' }}>Instruções</Title>,
        image: button,
        image2: button2,
        image3: mouse,
        content: (
            <>
                <p>Para iniciar uma nova autoavaliação clique no botão “Responder Instrumento”.</p>
            </>
        ),
        content2: (
            <>
                <p>Para continuar uma autoavaliação já iniciada, clique no ícone “lápis”, presente no canto direito do cartão.</p>
            </>
        ),
        content3: (
            <>
                <p>
                    Durante o preenchimento da autoavaliação, utilize o rolamento do mouse, as diferentes barras de rolagem da página ou a tecla
                    “Tab” para navegar.
                </p>
            </>
        ),
    },
];

export default SliderAutoavaliacaoData;
