import React from 'react';
import { Input, Modal } from 'antd';
import Colors from 'enums/Colors';
import { FadTemplatesModel } from 'models/ferramentaAutoria/FadTemplatesModel';
import styled from 'styled-components';

const Styled = {
    Container: styled.div`
        padding: 20px;
        margin-bottom: 50px;
        margin-left: 100px;
        display: flex;
        flex-direction: column;
        background-color: ${Colors.White};
    `,

    ColumnForm: styled.div`
        display: flex;
        flex-direction: column;
        gap: 4px;
    `,

    LabelForm: styled.h3`
        //styleName: Base/Legenda 2;
        font-family: 'Barlow';
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        text-align: left;
        text-decoration-skip-ink: none;
        color: ${Colors.Blue};
    `,
    LabelForm2: styled.h3`
        //styleName: Base/Legenda 2;
        //styleName: Base/Corpo;
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        text-decoration-skip-ink: none;
        color: #666666;
    `,
};

interface IModalFormTemplate {
    open: boolean;
    loading: boolean;
    handleEdit: () => void;
    handleHidden: () => void;
    setForm: (value: any) => void;
    form: FadTemplatesModel;
}

const ModalFormTemplate = ({ open, loading, form, handleEdit, handleHidden, setForm }: IModalFormTemplate) => {
    return (
        <Modal
            title={<h1 style={{ fontSize: 26 }}> {'Editar template'}</h1>}
            open={open}
            onOk={handleEdit}
            okText={'Salvar edição'}
            cancelText={'Cancelar'}
            maskClosable={false}
            confirmLoading={loading}
            okButtonProps={{
                style: {
                    backgroundColor: Colors.Blue,
                },
            }}
            onCancel={handleHidden}
        >
            <Styled.ColumnForm style={{ borderTop: '1px solid #F0F0F0', paddingTop: 22 }}>
                <Styled.LabelForm>Título do template</Styled.LabelForm>
                <Input
                    showCount
                    value={form.name}
                    maxLength={100}
                    onChange={e => {
                        setForm((prev: any) => ({ ...prev, name: e.target.value }));
                    }}
                    placeholder="Digite o título do template"
                    size="large"
                />
            </Styled.ColumnForm>
          
        </Modal>
    );
};

export default ModalFormTemplate;
