import { FadLogUserModulesModel } from 'models/ferramentaAutoria/FadLogUserModules';
import { GenericService } from '../Generic.service';

export class FadLogUserModulesService extends GenericService {
    readonly URI: string = '/v1/fadLogUserModules';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    async create(model: FadLogUserModulesModel) {
        return await this.post('', model);
    }

    async update(codigo: number, model: FadLogUserModulesModel) {
        return await this.put(`${codigo}`, model);
    }

    async deleteModel(codigo: number) {
        return await this.delete(codigo);
    }

    async findById(codigo: number) {
        return await this.get(`${codigo}`);
    }

    async findAllFadLogUserModules() {
        return await this.get();
    }

    async findByFadModules(fadModules: any) {
        return await this.get('byFadModules', fadModules);
    }

    async findByCodigoFadModules(codigoFadModules: number) {
        return await this.get(`byCodigoFadModules/${codigoFadModules}`);
    }

    async findByPerfilUsuario(perfilUsuario: any) {
        return await this.get('byPerfilUsuario', perfilUsuario);
    }

    async findByCodigoPerfilUsuario(codigoPerfilUsuario: number) {
        return await this.get(`byCodigoPerfilUsuario/${codigoPerfilUsuario}`);
    }

    async findByBrowserKey(browserKey: string) {
        return await this.get(`byBrowserKey/${browserKey}`);
    }

    async findByDateCreated(dateCreated: string) {
        return await this.get('byDateCreated', { dateCreated });
    }

    async findByDateUpdate(dateUpdate: string) {
        return await this.get('byDateUpdate', { dateUpdate });
    }
}
