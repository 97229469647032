import { jsonProperty, Serializable, SerializationSettings } from "ts-serializable";

interface IFadUserProgressContentsPublicModel {
    codigo: number;
    codigoContent: number;
    codigoClasse: number;
    isCompleted: boolean;
    percentageCompleted: number;
}

export class FadUserProgressContentsPublicModel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number)
    codigoContent: number = -1;

    @jsonProperty(Number)
    codigoClasse: number = -1;

    @jsonProperty(Boolean)
    isCompleted: boolean = false;

    @jsonProperty(Number)
    percentageCompleted: number = 0;

    fromJSON(json: IFadUserProgressContentsPublicModel, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.codigoContent = json.codigoContent;
        this.codigoClasse = json.codigoClasse;
        this.isCompleted = json.isCompleted;
        this.percentageCompleted = json.percentageCompleted;
        return this;
    }
}