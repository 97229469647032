import ComponentReguaAvaliativaModel from 'models/componentInteraction/ComponenteReguaAvaliativa';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextDangerouslySetInnerHtml } from '../../RecursosConteudoPage.styled';
import ComponentReguaAvaliativaQuestions from 'models/componentInteraction/ComponentReguaAvaliativaQuestions';
import ComponentReguaAvaliativaFeedbacks from 'models/componentInteraction/ComponentReguaAvaliativaFeedbacks';
import ComponentReguaAvaliativaOptions from 'models/componentInteraction/ComponentReguaAvaliativaOptions';
import { Slider } from 'antd';
import { OutlineButton } from 'components/UI/Buttons';
import Feedback from 'react-bootstrap/esm/Feedback';
import Colors from 'enums/Colors';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        gap: 8px;
        height: 400px;
        margin: 22px 0px;
        border: 1px solid #f0f0f0;
        padding: 16px;
        border-radius: 8px;
    `,

    Titulo: styled.h1`
        //styleName: Base/H4;
        font-family: 'Barlow';
        font-size: 22px;
        font-weight: 600;
        line-height: 26.4px;
        text-align: left;
        text-decoration-skip-ink: none;
        color: ${Colors.Blue};
    `,

    TextTituloAux: styled.p`
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        text-decoration-skip-ink: none;
    `,

    Regua: {
        Container: styled.div`
            border: 1px solid #d9d9d9;
            height: 100%;
            padding: 16px;
            border-radius: 8px;
            background-color: #fafafa;
            gap: 8px;
        `,

        Titulo: styled.h1`
            //styleName: Base/Subtitulo;
            font-family: 'Barlow';
            font-size: 18px;
            font-weight: 600;
            line-height: 25.2px;
            text-align: left;
            text-decoration-skip-ink: none;
        `,

        Center: styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        `,

        TextAux: styled.p`
            //styleName: Base/Corpo;
            font-family: 'Barlow';
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            text-decoration-skip-ink: none;
            color: #666666;
        `,
    },

    Feedback: {
        Container: styled.div`
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 32px;
            height: 100%;
            gap: 8px;
        `,

        Pontuacao: styled.h1`
            //styleName: Base/H2;
            font-family: 'Barlow';
            font-size: 36px;
            font-weight: 700;
            line-height: 43.2px;
            text-align: left;
            text-decoration-skip-ink: none;
            color: ${Colors.Blue};
        `,

        ContentText: styled.div`
            width: 100%;
            min-height: 120px;
            padding: 16px;
            //styleName: Base/H5;
            font-family: 'Barlow';
            font-size: 18px;
            font-weight: 400;
            line-height: 25.2px;
            text-align: center;
            text-decoration-skip-ink: none;
            color: #242424;
            background-color: #fafafa;
        `,
    },

    RowEnd: styled.div`
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: end;
    `,
};
interface IComponent {
    niveis: ComponentReguaAvaliativaOptions[];
    nivelSelect: (value: number) => void;
}

const Regua = ({ niveis, nivelSelect }: IComponent) => {
    const niveisComponent = niveis.reduce((acc: any, d, index) => {
        acc[index] = d.text ?? ' ';
        return acc;
    }, {});

    return (
        <div style={{ height: '100px', width: '90%' }}>
            <Slider autoFocus marks={niveisComponent} max={niveis.length - 1} onChange={v => nivelSelect(v + 1)} step={null} defaultValue={0} />
        </div>
    );
};

interface IReguaAvaliativaPublic {
    dados: ComponentReguaAvaliativaModel;
}

const ReguaAvaliativaPublic = ({ dados }: IReguaAvaliativaPublic) => {
    const [questions, setQuestions] = useState<ComponentReguaAvaliativaQuestions[]>(dados.questions);
    const [indexQuestion, setIndexQuestion] = useState<number>(0);

    //Pontuações
    const [myPontos, setMyPontos] = useState<number>(0);
    const [nivelSelect, setNivelSelected] = useState<number>(1);

    const [feedback, setFeedback] = useState<ComponentReguaAvaliativaFeedbacks | null>(null);

    const isFinish = (): boolean => {
        return questions.length - 1 === indexQuestion;
    };

    const fetchQuestionNumbers = (): string => {
        return !feedback ? indexQuestion + 1 + ' / ' + questions.length : '';
    };

    function isBetween(value: number, min: number, max: number) {
        return value >= min && value <= max;
    }

    const addFeedback = (pontos: number) => {
        const feedbackSelecionado = dados.feedbacks.filter(f => isBetween(pontos, f.nivelMin, f.nivelMax) && f).find(f => f);

        if (feedbackSelecionado) {
            setFeedback(feedbackSelecionado);
        }
    };

    const resetQuestions = () => {
        setIndexQuestion(0);
        setMyPontos(0);
        setNivelSelected(1);
        setFeedback(null);
    };

    const nextQuestion = () => {
        let myp = myPontos;
        const pontosAtuais = (myp += nivelSelect);

        setMyPontos(pontosAtuais);
        if (!isFinish()) {
            setIndexQuestion(indexQuestion + 1);
        } else {
            addFeedback(pontosAtuais);
        }

        //resetInfo
        setNivelSelected(1);
    };

    useEffect(() => {
        setQuestions(dados.questions);
    }, [dados]);

    return (
        <Styled.Container>
            <Styled.Titulo>{dados.titulo} </Styled.Titulo>
            {!feedback && (
                <>
                    <TextDangerouslySetInnerHtml
                        style={{ margin: 0 }}
                        dangerouslySetInnerHTML={{
                            __html: dados.textTituloAux ?? '',
                        }}
                    />
                    {questions.map((f, i) => {
                        if (i === indexQuestion) {
                            return (
                                <Styled.Regua.Container key={i}>
                                    <Styled.Regua.Titulo>{f.titulo}</Styled.Regua.Titulo>
                                    <Styled.Regua.TextAux>{f.textAux}</Styled.Regua.TextAux>
                                    <Styled.Regua.Center>
                                        <Regua niveis={f.options ?? []} nivelSelect={v => setNivelSelected(v)} />
                                    </Styled.Regua.Center>
                                </Styled.Regua.Container>
                            );
                        }
                    })}
                    <Styled.RowEnd>
                        <h2 style={{fontWeight: 500}}>{fetchQuestionNumbers()}</h2>
                        <OutlineButton
                            onClick={() => {
                                nextQuestion();
                            }}
                        >
                            {isFinish() ? 'Concluir' : 'Avançar'}
                        </OutlineButton>
                    </Styled.RowEnd>
                </>
            )}

            {feedback && (
                <Styled.Feedback.Container>
                    <Styled.Feedback.Pontuacao>
                        {feedback.nivelMin} - {feedback.nivelMax}
                    </Styled.Feedback.Pontuacao>
                    <Styled.Feedback.ContentText>{feedback.text}</Styled.Feedback.ContentText>
                    <OutlineButton
                        onClick={() => {
                            resetQuestions();
                        }}
                    >
                        Tentar novamente
                    </OutlineButton>
                </Styled.Feedback.Container>
            )}
        </Styled.Container>
    );
};

export default ReguaAvaliativaPublic;
