import { jsonProperty, Serializable, SerializationSettings } from 'ts-serializable';

interface IFadUserConcluidModulesPublicModel {
    codigo: number;
    codigoFerramentaAutoriaModule: number;
    codigoFerramentaAutoriaClasse: number;
}

export class FadUserConcluidModulesPublicModel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number)
    codigoFerramentaAutoriaModule: number = -1;

    @jsonProperty(Number)
    codigoFerramentaAutoriaClasse: number = -1;

    fromJSON(json: IFadUserConcluidModulesPublicModel, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.codigoFerramentaAutoriaModule = json.codigoFerramentaAutoriaModule;
        this.codigoFerramentaAutoriaClasse = json.codigoFerramentaAutoriaClasse;

        return this;
    }
}
