import React, { useEffect, useState } from 'react';
import {
    Button,
    Container,
    Content,
    InfoIcon,
    InnerContainer,
    OuterContainer,
    Text,
    TextContainer,
    Title,
} from './RealizandoEscolhasCard.styled';
import infoIcon from '../assets/images/infoIcon.svg';
import ModalDefault from 'pages/realizandoEscolhas/pages/components/ModalDefault';
import TrilhaFormativaModal from './TrilhaFormativaModal';
import {
    buscarCategoriaPorPerfil,
    buscarOcupacaoPorPerfil,
    redirectToInstrumento,
    redirectToTrilhaFormativaLMS,
} from 'pages/realizandoEscolhas/utils/RealizandoEscolhasUtils';
import UsuarioDTO from 'models/UsuarioDTO';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import ModalBlocked from './ModalBlocked';
import { GTM_Page_Realizando_Escolhas } from 'components/GoogleTagManager';
import PerfilUsuario from 'models/perfil/PerfilUsuario';

export interface ITile {
    title: string;
    contentText: string;
    buttonCallback: () => void;
    moreInfo: string;
    srcBackground: any;
}

const RealizandoEscolhasCard = ({ title, contentText, srcBackground, buttonCallback, moreInfo }: ITile) => {
    const [isHovered, setHovered] = useState<Boolean>(false);
    const [showTrilhaFormativaReqModal, setShowTrilhaFormativaReqModal] = useState(false);
    const [showModalBlocked, setShowModalBlocked] = useState(false);

    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);
    const profile = useAppSelector(state => state.profileReducer.userProfile);
    const history = useHistory();

    const buttonAction = async () => {
        if (title == 'Avaliação') {
            const userOcupacao = buscarOcupacaoPorPerfil(profile);

            if (userOcupacao !== 'B') {
                await goToInstrumento();
            } else {
                setShowModalBlocked(true);
            }
        } else {
            buttonCallback();
        }
    };

    const goToTrilhaFormativaLMS = async (role: string, is9ano: boolean) => {
        const urlRedirect = await redirectToTrilhaFormativaLMS(role, is9ano, usuarioLogado, true);
        history.push(urlRedirect);

    };

    const goToInstrumento = async () => {
        redirectToInstrumento(
            (await buscarCategoriaPorPerfil(usuarioLogado)) ?? ('A' as string),
            setShowTrilhaFormativaReqModal,
            usuarioLogado,
            () => {
                history.push('/realizandoEscolhas/avaliacao');
                GTM_Page_Realizando_Escolhas('home_cards_realizando_escolhas', `acessar_page_avaliacao`);
            }
        );
    };

    return (
        <Container imageSrc={srcBackground}>
            <InfoIcon src={infoIcon} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} />

            {!isHovered && (
                <Content>
                    <OuterContainer>
                        <Title style={title == 'Avaliação' || title == 'Trilha formativa' ? { paddingTop: '45px' } : {}} onClick={buttonAction}>
                            {title}
                        </Title>
                        <InnerContainer>
                            <TextContainer>
                                <Text
                                    onClick={() => {
                                        buttonAction();
                                    }}
                                >
                                    {contentText}
                                </Text>
                            </TextContainer>

                            <Button
                                onClick={() => {
                                    buttonAction();
                                }}
                            >
                                {`Acessar`}
                            </Button>
                        </InnerContainer>
                    </OuterContainer>
                </Content>
            )}

            {isHovered && (
                <Content style={{ padding: '25px', background: 'rgba(0, 0, 0, 0.8)' }}>
                    <Title style={{ height: '40px', padding: '0', fontSize: '36px' }}>{`Mais Informações`}</Title>
                    <TextContainer>
                        <Text style={{ fontSize: 'clamp(12px, 1.4vw, 20px)', maxWidth: '100%' }}>{moreInfo}</Text>
                    </TextContainer>
                </Content>
            )}

            <ModalDefault show={showModalBlocked}>
                <ModalBlocked setShow={setShowModalBlocked} goToTrilhaFormativaCallback={() => ''} />
            </ModalDefault>

            <ModalDefault show={showTrilhaFormativaReqModal}>
                <TrilhaFormativaModal
                    isHome={true}
                    setShow={setShowTrilhaFormativaReqModal}
                    goToTrilhaFormativaCallback={async (trilha, is9Ano) => {
                        goToTrilhaFormativaLMS((await buscarCategoriaPorPerfil(usuarioLogado)) ?? ('A' as string), is9Ano);
                        setShowTrilhaFormativaReqModal(false);
                    }}
                />
            </ModalDefault>
        </Container>
    );
};

export default RealizandoEscolhasCard;
