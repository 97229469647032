import { FadUserConcluidModulesModel } from 'models/ferramentaAutoria/FadUserConcluidModulesModel';
import { GenericService } from '../Generic.service';
import { FadUserConcluidModulesPublicModel } from 'models/ferramentaAutoria/public/FadUserConcluidModulesPublicModel';

export class FadUserConcluidModulesService extends GenericService {
    readonly URI: string = '/v1/fadUserConcluidModules';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    async create(model: FadUserConcluidModulesPublicModel) {
        return await this.post('', model);
    }

    async update(codigo: number, model: FadUserConcluidModulesPublicModel) {
        return await this.put(`${codigo}`, model);
    }

    async deleteModel(codigo: number) {
        return await this.delete(codigo);
    }

    async findById(codigo: number) {
        return await this.get(`${codigo}`);
    }

    async findAllFadUserProgressModules() {
        return await this.get();
    }

    async findByFadModules(fadModules: any) {
        return await this.get('byFadModules', fadModules);
    }

    async findByCodigoFadModules(codigoFadModules: number) {
        return await this.get(`byCodigoFadModules/${codigoFadModules}`);
    }

    async findByPerfilUsuario(perfilUsuario: any) {
        return await this.get('byPerfilUsuario', perfilUsuario);
    }

    async findByCodigoPerfilUsuario(codigoPerfilUsuario: number) {
        return await this.get(`byCodigoPerfilUsuario/${codigoPerfilUsuario}`);
    }

    async findByIsCompleted(isCompleted: boolean) {
        return await this.get(`byIsCompleted/${isCompleted}`);
    }

    async findByPercentageCompleted(percentageCompleted: number) {
        return await this.get(`byPercentageCompleted/${percentageCompleted}`);
    }
}