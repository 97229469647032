import { FadCommentaryContentModel } from 'models/ferramentaAutoria/FadCommentaryContentModel';
import { GenericService } from '../Generic.service';

export interface IFilterCommentary {
    page: number;
    size: number;
    codigo?: number;
    codigoFerramentaAutoriaContent?: number;
    codigoFerramentaAutoriaClasse?: number;
    codigoCourse?: number;
    commentary?: string;
    dateInit?: string;
    dateMax?: string;
}

export class FadCommentaryContentService extends GenericService {
    readonly URI: string = '/v1/ferramentaAutoriaCommentaryContent';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    async create(model: FadCommentaryContentModel) {
        return await this.post('', model);
    }

    async update(codigo: number, model: FadCommentaryContentModel) {
        return await this.put(`${codigo}`, model);
    }

    async deleteModel(codigo: number) {
        return await this.delete(codigo);
    }

    async findById(codigo: number) {
        return await this.get(`${codigo}`);
    }

    async findAll(filter: IFilterCommentary | null) {
        const params: any = {
            ...filter,
        };

        // Remove undefined or null parameters
        Object.keys(params).forEach(key => {
            if (params[key] === undefined || params[key] === null) {
                delete params[key];
            }
        });

        return await this.get('', params);
    }
    async getCountCommentary(filter: IFilterCommentary | null) {
        const params: any = {
            ...filter,
        };

        // Remove undefined or null parameters
        Object.keys(params).forEach(key => {
            if (params[key] === undefined || params[key] === null) {
                delete params[key];
            }
        });

        return await this.get('comentarios/count', params);
    }

    async findByCodigo(codigo: number) {
        return await this.get(`codigo/${codigo}`);
    }

    async findByCodigoPerfilUsuario(codigoPerfilUsuario: string) {
        return await this.get(`perfilusuario/${codigoPerfilUsuario}`);
    }

    async findByFerramentaAutoriaContent(codigoFerramentaAutoriaContent: number) {
        return await this.get(`content/${codigoFerramentaAutoriaContent}`);
    }

    async findByFerramentaAutoriaClasse(codigoFerramentaAutoriaClasse: number) {
        return await this.get(`classe/${codigoFerramentaAutoriaClasse}`);
    }

    async findByCommentary(commentary: string) {
        return await this.get(`commentary/${commentary}`);
    }

    async findByDateCreated(dateCreated: string) {
        return await this.get(`datecreated/${dateCreated}`);
    }

    async findByDateUpdated(dateUpdated: string) {
        return await this.get(`dateupdated/${dateUpdated}`);
    }
}
