import React, { useContext, useEffect, useState } from 'react';
import Styled, { FAD_Row1, FAD_RowBettweeen, FAD_Title } from './AdminFerramentaAutoria.styled';
import { EnumTypeButtonFad, FAD_Button_Primary } from './components/FadButtons';
import { FaBook, FaPager } from 'react-icons/fa';
import { Select, TabsProps, Tabs, Modal, Input, Switch, ColorPicker, Badge } from 'antd';
import Search from 'antd/es/input/Search';
import { FadCoursesModel } from 'models/ferramentaAutoria/FadCoursesModel';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import Colors from 'enums/Colors';
import useFetch from 'hooks/useFetch';
import { CertificacoesModel } from 'models/certificacoes/CertificacoesModel';
import { CertificacoesService } from 'core/http/service/Certificacoes.service';
import { FadLogUserCoursesService } from 'core/http/service/ferramentaAutoria/FadLogUserCourses.service';
import { FadCoursesService } from 'core/http/service/ferramentaAutoria/FadCourses.service';
import { ToastError, ToastSuccess } from 'components/Toaster';
import AdminFadAbas from './components/abas/AdminFadAbas';
import TextArea from 'antd/es/input/TextArea';
import { FadPagesModel } from 'models/ferramentaAutoria/FadPagesModel';
import { FadPagesService } from 'core/http/service/ferramentaAutoria/FadPages.service';
import AdminFadAbaPages from './components/abas/AdminFadAbaPages';
import ModalFormCourse from './components/modals/ModalFormCourse';
import { FerramentaAutoriaContext } from './context/FerramentaAutoriaContext';
import { TemplateCourseFadEnum } from './components/courses/TemplateCourse';
import AdminFadTemplates from './pages/AdminFadTemplates';
import { FadTemplatesModel } from 'models/ferramentaAutoria/FadTemplatesModel';
import { FadTemplatesService } from 'core/http/service/ferramentaAutoria/FadTemplates.service';
import { TbMessage } from 'react-icons/tb';
import AdminFadCommentarys from './pages/AdminFadCommentarys';
import { PiCardsFill } from 'react-icons/pi';
import { RiPagesLine } from 'react-icons/ri';
import { ImInsertTemplate } from 'react-icons/im';

const InitialFormCourse = FadCoursesModel.fromJSON({
    codigo: -1,
    codigoCertificado: -1,
    name: '',
    description: '',
    url: '',
    typeRedirect: TipoRedirecionamento.IGNORAR, // Assuming TipoRedirecionamentoEnum is converted to String
    template: TemplateCourseFadEnum.Lateral,
    sequentialContents: false,
    sequentialSteps: false,
    isPublic: false,
    ordem: 1,
});

const InitialFormPage = FadPagesModel.fromJSON({
    codigo: -1,
    url: '',
    cor: Colors.Blue,
});

const AdminFerramentaAutoria = () => {
    //Extras
    const [isModalOpenCreateCourse, setIsModalOpenCreateCourse] = useState<boolean>(false);
    const [isModalOpenEditCourse, setIsModalOpenEditCourse] = useState<boolean>(false);
    const [isModalOpenCreatePage, setIsModalOpenCreatePage] = useState<boolean>(false);
    const { updateCourse } = useContext(FerramentaAutoriaContext.Context);

    //Context
    const { certificados, commentarys } = useContext(FerramentaAutoriaContext.Context);

    //Forms
    const [formCourse, setFormCourse] = useState<FadCoursesModel>(InitialFormCourse);
    const [formPage, setFormPage] = useState<FadPagesModel>(InitialFormPage);

    //Services
    const serviceCertificacao = new CertificacoesService();
    const serviceFadCourses = new FadCoursesService();
    const serviceFadPages = new FadPagesService();
    const serviceFadTemplates = new FadTemplatesService();

    //listas
    const [listCourses, setListCourses] = useState<FadCoursesModel[]>([]);
    const [listPages, setListPages] = useState<FadPagesModel[]>([]);
    const [templates, setTemplates] = useState<FadTemplatesModel[]>([]);

    //_____________________
    const [isValidateFormPage, setIsValidateFormPage] = useState<boolean>(false);

    const handleResetForms = () => {
        setFormCourse(InitialFormCourse);
    };

    const openModalCreatePage = () => setIsModalOpenCreatePage(true);

    const hidenModelCreatePage = () => {
        setIsModalOpenCreatePage(false);
        handleResetForms();
    };

    const openModalCreateCourse = () => setIsModalOpenCreateCourse(true);

    const hidenModelCreateCourse = () => {
        setIsModalOpenCreateCourse(false);
        handleResetForms();
    };

    useFetch(async () => {
        const { data } = await serviceFadCourses.findAllFadCourses();
        setListCourses(data);
    }, []);

    useFetch(async () => {
        const { data, status } = await serviceFadPages.findAllFadPages();

        if (status === 200) {
            setListPages(data);
        }
    }, []);

    useFetch(async () => {
        const { data, status } = await serviceFadTemplates.getAllTemplates();

        if (status === 200) {
            setTemplates(data);
        }
    }, []);

    //laodings
    const [loadingCreate, setLoadingCreate] = useState<boolean>(false);

    const handleCreatePage = async () => {
        setLoadingCreate(true);

        const novoForm = formPage;
        novoForm.url = '/' + novoForm.url;

        try {
            const { data, status } = await serviceFadPages.create(novoForm);

            if (status === 201) {
                ToastSuccess('Página criada!');

                setListPages((prev: any) => [...prev, data]);

                hidenModelCreatePage();
                setFormPage(InitialFormPage);
            }
        } catch (error) {
            ToastError('URL da página já existente!');
            setFormPage((prev: any) => ({ ...prev, url: '' }));
        }
        setLoadingCreate(false);
    };

    const handleCreateCourse = async () => {
        setLoadingCreate(true);

        const novoForm = formCourse;
        novoForm.url = '/' + novoForm.url;
        novoForm.ordem = listCourses.at(-1)?.ordem ?? 0 + 1;

        const { data, status } = await serviceFadCourses.create(novoForm);

        if (status === 201) {
            ToastSuccess('Curso criado com sucesso!');
            setListCourses((prev: any) => {
                return [...prev, data];
            });
            hidenModelCreateCourse();
            handleResetForms();
        } else if (status === 409) {
            ToastError('URL já em uso em outro curso!');
            setFormCourse((prev: any) => {
                return {
                    ...prev,
                    url: '',
                };
            });
        }

        setLoadingCreate(false);
    };

    const handleUpdate = async () => {
        setLoadingCreate(true);

        const novoForm = formCourse;
        novoForm.url = '/' + novoForm.url;

        const data = updateCourse(novoForm);

        setListCourses((prev: any) =>
            prev.map((course: FadCoursesModel) => {
                if (course.codigo === novoForm.codigo) {
                    return data;
                }

                return course;
            })
        );

        setLoadingCreate(false);
    };

    useEffect(() => {
        if (formPage.url.length > 2) {
            setIsValidateFormPage(true);
        } else {
            setIsValidateFormPage(false);
        }
    }, [formPage]);

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                    }}
                >
                    Cursos
                    <PiCardsFill size={18} />
                </div>
            ),
            children: <AdminFadAbas courses={listCourses} />,
        },
        {
            key: '2',
            label: (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                    }}
                >
                    Páginas
                    <RiPagesLine size={18} />
                </div>
            ),
            children: <AdminFadAbaPages pages={listPages} />,
        },
        {
            key: '3',
            label: (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                    }}
                >
                    Templates
                    <ImInsertTemplate size={18} />
                </div>
            ),
            children: <AdminFadTemplates dados={templates} />,
        },
        {
            key: '4',
            label: (
                <div
                    style={{
                        display: 'flex',
                        gap: '4px',
                    }}
                >
                    Comentários
                    <TbMessage size={18} className="iconButtonFad" color={Colors.Blue} />
                </div>
            ),
            children: <AdminFadCommentarys />,
        },
    ];

    return (
        <Styled.Container>
            <FAD_RowBettweeen style={{ borderBottom: '1px solid #D9D9D9', paddingBottom: 20 }}>
                <FAD_Row1>
                    <FAD_Title>Biblioteca de cursos</FAD_Title>
                    <FAD_Button_Primary
                        text="Criar Curso"
                        onClick={openModalCreateCourse}
                        type={EnumTypeButtonFad.HAVE_BORDER_RADIUS}
                        icon={<FaBook />}
                    />
                    <FAD_Button_Primary
                        text="Criar Página"
                        onClick={openModalCreatePage}
                        type={EnumTypeButtonFad.HAVE_BORDER_RADIUS}
                        icon={<FaPager />}
                    />
                </FAD_Row1>
                <FAD_Row1>
                    <Search placeholder="Pesquisar" onSearch={value => ''} style={{ width: 200 }} size="large" />
                    <Select
                        style={{ width: 200 }}
                        placeholder="Ordenar por atualização"
                        onChange={() => ''}
                        options={[
                            { value: 'jack', label: 'Jack' },
                            { value: 'lucy', label: 'Lucy' },
                            { value: 'Yiminghe', label: 'yiminghe' },
                            { value: 'disabled', label: 'Disabled', disabled: true },
                        ]}
                        size="large"
                    />
                    <Select
                        style={{ width: 200 }}
                        placeholder="Ordenar por status"
                        onChange={() => ''}
                        options={[
                            { value: 'jack', label: 'Jack' },
                            { value: 'lucy', label: 'Lucy' },
                            { value: 'Yiminghe', label: 'yiminghe' },
                            { value: 'disabled', label: 'Disabled', disabled: true },
                        ]}
                        size="large"
                    />
                </FAD_Row1>
            </FAD_RowBettweeen>
            <Tabs defaultActiveKey="1" items={items} onChange={() => ''} />

            {/* Modais */}
            <Modal
                title={<h1 style={{ fontSize: 26 }}>Criar página</h1>}
                open={isModalOpenCreatePage}
                onOk={handleCreatePage}
                okText={'Criar página'}
                cancelText={'Cancelar'}
                maskClosable={false}
                confirmLoading={loadingCreate}
                okButtonProps={{
                    style: {
                        backgroundColor: isValidateFormPage ? Colors.Blue : Colors.Grey10,
                    },
                    disabled: !isValidateFormPage,
                }}
                onCancel={hidenModelCreatePage}
            >
                <Styled.ColumnForm style={{ borderTop: '1px solid #F0F0F0', paddingTop: 22 }}>
                    <Styled.LabelForm>URL</Styled.LabelForm>
                    <Input
                        addonBefore="/"
                        value={formPage.url.toLocaleLowerCase().replace(/\//g, '')}
                        showCount
                        maxLength={100}
                        onChange={e => {
                            setFormPage((prev: any) => ({ ...prev, url: e.target.value.replace(/\//g, '').trim() }));
                        }}
                        placeholder="Digite a url da página"
                        size="large"
                    />
                </Styled.ColumnForm>

                <Styled.ColumnForm style={{ paddingTop: 10 }}>
                    <Styled.LabelForm>Cor</Styled.LabelForm>
                    <ColorPicker
                        style={{ width: '120px' }}
                        value={formPage.cor}
                        onChange={v => {
                            setFormPage((prev: any) => ({ ...prev, cor: v.toHexString() }));
                        }}
                        defaultValue="#1677ff"
                        size="large"
                        showText
                    />
                </Styled.ColumnForm>
            </Modal>

            <ModalFormCourse
                open={isModalOpenCreateCourse}
                openEdit={isModalOpenEditCourse}
                handleSave={handleCreateCourse}
                handleEdit={handleUpdate}
                loading={loadingCreate}
                certificados={certificados}
                form={formCourse}
                setForm={setFormCourse}
                handleHidden={hidenModelCreateCourse}
            />
        </Styled.Container>
    );
};

export default AdminFerramentaAutoria;
