import { jsonProperty, Serializable, SerializationSettings } from 'ts-serializable';

interface IFadCommentaryContentModel {
    codigo: number;
    codigoFerramentaAutoriaContent: number;
    codigoFerramentaAutoriaClasse: number;
    codigoRelation: number;
    listRespostas: FadCommentaryContentModel[];
    nome: string;
    commentary: string;
    myCommentary: boolean;
    dateCreated: string; // LocalDateTime será tratado como string
    dateUpdated: string; // LocalDateTime será tratado como string
}

export class FadCommentaryContentModel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number)
    codigoFerramentaAutoriaContent: number = -1;

    @jsonProperty(Number)
    codigoFerramentaAutoriaClasse: number = -1;

    @jsonProperty(Number)
    codigoRelation: number = -1;

    @jsonProperty(String)
    nome: string = '';

    @jsonProperty(String)
    commentary: string = '';

    @jsonProperty(Boolean)
    myCommentary: boolean = false;

    @jsonProperty(String)
    dateCreated: string = '';

    @jsonProperty(String)
    dateUpdated: string = '';

    listRespostas: FadCommentaryContentModel[] = [];

    fromJSON(json: IFadCommentaryContentModel, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.codigoFerramentaAutoriaContent = json.codigoFerramentaAutoriaContent;
        this.codigoFerramentaAutoriaClasse = json.codigoFerramentaAutoriaClasse;
        this.codigoRelation = json.codigoRelation;
        this.nome = json.nome;
        this.commentary = json.commentary;
        this.myCommentary = json.myCommentary;
        this.dateCreated = json.dateCreated;
        this.dateUpdated = json.dateUpdated;
        this.listRespostas = json.listRespostas;
        return this;
    }
}
