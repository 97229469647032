import { bucketS3, caminhoAnexosComponentsInteraction } from 'constants/EnvironmentData';
import { IsCustomMaxWidth, IsCustomMinWidth } from 'core/util/WindowSize';
import Colors from 'enums/Colors';
import ComponentBaralhoModel from 'models/componentInteraction/ComponentBaralho';
import React, { useRef, useState } from 'react';
import { IoPauseOutline, IoPlayOutline } from 'react-icons/io5';
import { TfiReload } from 'react-icons/tfi';
import Slider from 'react-slick';
import styled from 'styled-components';
import { TextDangerouslySetInnerHtml } from '../../RecursosConteudoPage.styled';

interface IArrowButton {
    next: boolean;
}

const Styled = {
    Container: styled.div`
        display: flex;
        position: relative;
        margin: 22px 0px;

        @media screen and (max-width: 600px) {
            padding: 0;
        }
    `,

    SvgIcon: styled.svg.attrs({
        version: '1.1',
        xmlns: 'http://www.w3.org/2000/svg',
        xmlnsXlink: 'http://www.w3.org/1999/xlink',
    })`
        height: 59px;
        width: 56px;
        fill: ${Colors.Yellow};

        &:hover {
            fill: ${Colors.Yellow};
        }

        @media screen and (max-width: 600px) {
            width: 26px;
            height: 29px;
        }
    `,

    ArrowButton: styled.div<IArrowButton>`
        cursor: pointer;
        width: 56px;
        height: 56px;
        outline: none;
        border: none;
        position: absolute;
        z-index: 8;
        top: 45%;
        right: ${props => (props.next ? '0%' : '')};
        left: ${props => (props.next ? '97%' : '-4%')};

        @media screen and (max-width: 1500px) {
            left: ${props => (props.next ? '98%' : '-4%')};
        }
        @media screen and (max-width: 1280px) {
            left: ${props => (props.next ? '100%' : '-5%')};
        }

        @media screen and (max-width: 600px) {
            left: ${props => (props.next ? '100%' : '-5%')};
            height: 29px;
            width: 26px;
        }
    `,

    CarouselCardContainer: styled.div<{ preview?: boolean; centralized?: boolean; quantity: number }>`
        width: ${({ quantity }) => (quantity == 2 ? '50%' : '100%')};

        .slick-slide {
            display: flex;
            justify-content: center;
            align-items: start;
        }

        .slick-dots {
            li {
                button::before {
                    color: ${({ preview }) => (preview ? Colors.Blue : Colors.Yellow)}; /* Define a cor das dots */
                    font-size: 20px;
                }
                &.slick-active {
                    button::before {
                        color: ${({ preview }) => (preview ? Colors.Blue : Colors.Yellow)}; /* Define a cor para a dot selecionada */
                    }
                }
            }
        }

        @media screen and (max-width: 800px) {
            padding: 0;
            .slick-slide {
                justify-content: center;
                align-items: center;
            }
        }
    `,

    Carrousel: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        height: 450px;

        @media screen and (max-width: 1280px) {
            max-width: 620px;
        }

        @media screen and (max-width: 600px) {
            margin: 10px;
        }
    `,

    CardCarousel: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: ${Colors.White};
        width: 620px;
        height: 460px;
        border: 1px solid #f1f1f1;
        border-radius: 20px;

        @media screen and (max-width: 600px) {
            width: 300px;
            height: 300px;
            min-height: auto;
        }
    `,

    ImgPreview: styled.img`
        width: 100%;
        height: 193px;
        object-fit: fill;
    `,

    Card: {
        Container: styled.div`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            min-width: 320px;
            max-width: 320px;
            padding: 16px 16px;
            border: 1px 0px 0px 0px;
            opacity: 0px;
            border: 1px solid #f0f0f0;
            perspective: 1000px;
            transform-style: preserve-3d;
            transition: transform 0.6s;

            &.flipped {
                transform: rotateY(180deg); /* Gira o cartão */
            }

            @media screen and (max-width: 430px) {
                min-width: 400px;
                width: 100%;
            }
        `,

        Between: styled.div`
            display: flex;
            align-items: center;
            justify-content: space-between;
        `,

        Title: styled.h1`
            font-family: 'Barlow';
            font-size: 18px;
            font-weight: 600;
            line-height: 25.2px;
        `,

        PlayAudio: styled.div`
            border: 1px solid ${Colors.Blue};
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            transition: all 0.3s;
            cursor: pointer;

            &:hover {
                box-shadow: 2px 2px 4px #797979;
            }
        `,

        Footer: styled.div`
            display: flex;
            align-items: center;
            justify-content: end;
        `,

        Button: styled.button`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 37px;
            opacity: 0px;
            border: none;
            color: ${Colors.Blue};
            border: none;
        `,

        Side: styled.div`
            position: absolute;
            width: 100%;
            height: 100%;
            backface-visibility: hidden; /* Oculta a parte de trás quando não está virada */
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
        `,

        Front: styled.div`
            display: flex;
            max-width: 390px;
            justify-content: space-between;
            flex-direction: column;
            gap: 10px;
            max-height: 380px;
            overflow: scroll;
            overflow-x: hidden;
            overflow-y: auto;
        `,

        Back: styled.div`
            position: absolute;
            display: flex;
            max-width: 390px;
            height: 100%;
            justify-content: space-between;
            flex-direction: column;
            backface-visibility: hidden;
            gap: 10px;
            backface-visibility: hidden;
            transform: rotateY(180deg); /* Gira o verso para que não apareça inicialmente */
        `,

        ButtonEdit: styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
            top: -10px;
            left: 80%;
            background-color: ${Colors.Yellow2};
            width: 32px;
            height: 32px;
            border-radius: 10px;
            position: absolute;
            cursor: pointer;
        `,

        ButtonDelete: styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
            top: -10px;
            left: 90%;
            background-color: ${Colors.DeleteRed};
            color: ${Colors.White};
            width: 32px;
            height: 32px;
            border-radius: 10px;
            position: absolute;
            cursor: pointer;
        `,

        Controls: styled.div`
            display: flex;
            gap: 10px; // Espaço entre os ícones
            margin-top: 10px; // Margem acima dos controles
            cursor: pointer; // Cursor de ponteiro ao passar o mouse
        `,
    },
    ContentAll: styled.div`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
    `,
};

interface ICardBaralho {
    card: ComponentBaralhoModel;
}

const CardBaralho = ({ card }: ICardBaralho) => {
    const getS3BaseUrl = (): string => {
        return bucketS3() + caminhoAnexosComponentsInteraction();
    };

    const [flipped, setFlipped] = useState<boolean>(false);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);

    const audioRef = useRef<any>(null);
    const audio2Ref = useRef<any>(null);

    const handlePauseAudio = (ref: any) => {
        if (ref.current) {
            ref.current.pause();
            setIsPlaying(false);
        }
    };

    const handleFlip = () => {
        setFlipped(!flipped);
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }

        if (audio2Ref.current) {
            audio2Ref.current.pause();
            audio2Ref.current.currentTime = 0;
        }
    };

    const handlePlayAudio = (ref: any) => {
        if (ref.current) {
            ref.current.play();
            setIsPlaying(true);
        }
    };

    return (
        <Styled.Card.Container className={flipped ? 'flipped' : ''}>
            <Styled.Card.Front style={{ transform: flipped ? 'rotateY(180deg)' : '', cursor: 'pointer' }} onClick={handleFlip}>
                {!flipped ? (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.2rem' }}>
                            <Styled.ImgPreview src={getS3BaseUrl() + card.filepath} alt={`Frente do Cartão`} />
                            {(card.title !== '' || card.urlAudio !== '') && (
                                <Styled.Card.Between>
                                    {card.title !== '' && <Styled.Card.Title>{card.title}</Styled.Card.Title>}
                                    {card.urlAudio !== '' && card.urlAudio !== null && (
                                        <>
                                            {!isPlaying ? (
                                                <Styled.Card.PlayAudio onClick={() => handlePlayAudio(audioRef)}>
                                                    <IoPlayOutline size={22} color={Colors.Blue} />
                                                </Styled.Card.PlayAudio>
                                            ) : (
                                                <Styled.Card.PlayAudio onClick={() => handlePauseAudio(audioRef)}>
                                                    <IoPauseOutline size={22} color={Colors.Blue} />
                                                </Styled.Card.PlayAudio>
                                            )}
                                        </>
                                    )}
                                    <audio ref={audioRef} src={card.urlAudio} />
                                </Styled.Card.Between>
                            )}
                            {card.description !== '' && card.description != '<p><br></p>' && (
                                <TextDangerouslySetInnerHtml dangerouslySetInnerHTML={{ __html: card.description }} />
                            )}
                        </div>

                        <Styled.Card.Footer>
                            <Styled.Card.Button
                                style={{
                                    backgroundColor: Colors.White,
                                }}
                                onClick={handleFlip}
                            >
                                <TfiReload />
                            </Styled.Card.Button>
                        </Styled.Card.Footer>
                    </>
                ) : (
                    <>
                        {card.baralhoBack?.filepath !== null && card.baralhoBack?.filepath !== '' && (
                            <Styled.ImgPreview src={getS3BaseUrl() + card.baralhoBack?.filepath} alt={`Atrás do Cartão`} />
                        )}
                        {card.baralhoBack && (
                            <>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                    {(card.baralhoBack.title || card.baralhoBack.urlAudio) && (
                                        <Styled.Card.Between>
                                            {card.baralhoBack.title && <Styled.Card.Title>{card.baralhoBack.title}</Styled.Card.Title>}
                                            {card.baralhoBack.urlAudio && (
                                                <>
                                                    {!isPlaying ? (
                                                        <Styled.Card.PlayAudio onClick={() => handlePlayAudio(audio2Ref)}>
                                                            <IoPlayOutline size={22} color={Colors.Blue} />
                                                        </Styled.Card.PlayAudio>
                                                    ) : (
                                                        <Styled.Card.PlayAudio onClick={() => handlePauseAudio(audio2Ref)}>
                                                            <IoPauseOutline size={22} color={Colors.Blue} />
                                                        </Styled.Card.PlayAudio>
                                                    )}
                                                </>
                                            )}
                                        </Styled.Card.Between>
                                    )}
                                    <audio ref={audio2Ref} src={card.baralhoBack.urlAudio} />
                                    <TextDangerouslySetInnerHtml dangerouslySetInnerHTML={{ __html: card.baralhoBack?.description }} />
                                </div>
                            </>
                        )}

                        <Styled.Card.Footer>
                            <Styled.Card.Button
                                style={{
                                    backgroundColor: Colors.White,
                                    color: Colors.Blue,
                                }}
                                onClick={handleFlip}
                            >
                                <TfiReload />
                            </Styled.Card.Button>
                        </Styled.Card.Footer>
                    </>
                )}
            </Styled.Card.Front>
        </Styled.Card.Container>
    );
};

interface IComponentBaralhoPublic {
    dados: ComponentBaralhoModel[];
    quantityVisible: number;
    centralized?: boolean;
    isFad?: boolean;
}

const ComponentBaralhoPublic = ({ dados, quantityVisible, centralized, isFad }: IComponentBaralhoPublic) => {
    const NextArrow = ({ ...props }: any) => {
        return (
            <Styled.ArrowButton next={true} onClick={props.onClick}>
                <Styled.SvgIcon>
                    <path
                        d="M0.472656 26.5214L11.1593 15.0974L0.472656 3.6734L3.76266 0.164062L17.7627 15.0974L3.76266 30.0307L0.472656 26.5214Z"
                        transform={IsCustomMinWidth(600) ? 'translate(18, 12)' : ''}
                        fill={Colors.Blue}
                        stroke={Colors.Blue}
                    />
                </Styled.SvgIcon>
            </Styled.ArrowButton>
        );
    };

    const PreviousArrow = ({ ...props }: any) => {
        return (
            <Styled.ArrowButton next={false} onClick={props.onClick}>
                <Styled.SvgIcon>
                    <path
                        d="M17.9931 26.5214L7.30646 15.0974L17.9931 3.6734L14.7031 0.164062L0.703125 15.0974L14.7031 30.0307L17.9931 26.5214Z"
                        transform={IsCustomMinWidth(600) ? 'translate(15, 12)' : ''}
                        fill={Colors.Blue}
                        stroke={Colors.Blue}
                    />
                </Styled.SvgIcon>
            </Styled.ArrowButton>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: IsCustomMaxWidth(1280) ? 1 : quantityVisible,
        slidesToScroll: isFad ? quantityVisible : 1,
        arrows: IsCustomMaxWidth(600) ? false : true,

        nextArrow: <NextArrow />,
        prevArrow: <PreviousArrow />,
    };

    return (
        <Styled.Container>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: centralized ? '100%' : 'auto',
                }}
            >
                {dados.length !== quantityVisible ? (
                    <Styled.Carrousel>
                        <Styled.CarouselCardContainer quantity={quantityVisible} style={{ margin: 0 }}>
                            <Slider {...settings}>
                                {dados.map((card, index) => (
                                    <CardBaralho card={card} key={index} />
                                ))}
                            </Slider>
                        </Styled.CarouselCardContainer>
                    </Styled.Carrousel>
                ) : (
                    <Styled.ContentAll>
                        {dados.map((card, index) => (
                            <CardBaralho card={card} key={index} />
                        ))}
                    </Styled.ContentAll>
                )}
            </div>
        </Styled.Container>
    );
};

export default ComponentBaralhoPublic;
