import { jsonProperty, Serializable, SerializationSettings } from "ts-serializable";

interface IFadRelationsPage {
    codigo: number;
    codigoFerramentaAutoriaPage: number;
    codigoFerramentaAutoriaCourse: number;
}

export class FadRelationsPageModel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number)
    codigoFerramentaAutoriaPage: number = -1;

    @jsonProperty(Number)
    codigoFerramentaAutoriaCourse: number = -1;

    fromJSON(json: IFadRelationsPage, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.codigoFerramentaAutoriaPage = json.codigoFerramentaAutoriaPage;
        this.codigoFerramentaAutoriaCourse = json.codigoFerramentaAutoriaCourse;
        return this;
    }
}