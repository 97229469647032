import { FadPagesModel } from 'models/ferramentaAutoria/FadPagesModel';
import { GenericService } from '../Generic.service';

export class FadPagesService extends GenericService {
    readonly URI: string = '/v1/fadPages';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    async create(model: FadPagesModel) {
        return await this.post('', model);
    }

    async update(codigo: number, model: FadPagesModel) {
        return await this.put(`${codigo}`, model);
    }

    async deleteFadPages(codigo: number) {
        return await this.delete(codigo);
    }

    async findById(codigo: number) {
        return await this.get(`${codigo}`);
    }

    async findAllFadPages() {
        return await this.get();
    }

    async findByUrl(url: string) {
        return await this.get(`byUrl/${url}`);
    }

    async findByCor(cor: string) {
        return await this.get(`byCor/${cor}`);
    }

    async findByUrlPublic(url: string) {
        return await this.get(`public/${url}`);
    }

}
