import { GTM_GA4_Ecommerce_Purchase, GTM_GA4_Ecommerce_Select_Item, GTM_Page_Curso_Livre } from 'components/GoogleTagManager';
import { MobileMaxWidth } from 'constants/Dimensions';
import { bucketS3, caminhoAnexosCursosLivres } from 'constants/EnvironmentData';
import keycloak from 'core/config/keycloak';
import { mediaOnlyScreenMaxWidthStr } from 'core/util/cssHelp';
import { DateHelp } from 'core/util/DateHelp';
import FullCss, { MediaQuery } from 'core/util/FullCss';
import { IsCustomMaxWidth, IsMobileMaxWidth } from 'core/util/WindowSize';
import Colors from 'enums/Colors';
import useLogged from 'hooks/useLogged';
import { KeycloakLoginOptions } from 'keycloak-js';
import AdminCursoLivre from 'models/admin/AdminCursoLivre';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import ClockSvg from '../assets/Clock.svg';
import LampSvg from '../assets/Lamp.svg';
import { CardTag } from './components/CursoLivreCardTag';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import UsuarioDTO from 'models/UsuarioDTO';
import { IntegracaoLmsService } from 'core/http/service/IntegracaoLMS.service';
import { MatriculaLMSEnum } from 'enums/MatriculaLMSEnum';
import { LoadingIndicator } from 'react-select/src/components/indicators';
import Loading from 'components/Loading';
import GenericComponentsUtils from 'pages/generic-components/generic-components.utils';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';

const CursoLivreCardContainer = styled.div(() => {
    return new FullCss(
        {
            width: '345px',
            minHeight: '575px',
            borderRadius: '5px 5px 5px 5px',
            display: 'flex',
            flexDirection: 'column',
            filter: 'drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25))',
        },

        [
            new MediaQuery(mediaOnlyScreenMaxWidthStr(912), {
                width: '325px',
                marginBottom: '20px',
                marginTop: '10px',
            }),

            new MediaQuery(mediaOnlyScreenMaxWidthStr(470), {
                width: '325px',
            }),
            new MediaQuery(mediaOnlyScreenMaxWidthStr(350), {
                width: '273px',
            }),
        ]
    ).getCss();
});

const ImageContainer = styled.div(() => {
    return new FullCss(
        {
            width: '345px',
            height: '190px',
            display: 'flex',
            borderRadius: '5px 0px 0px 5px',
        },
        [
            new MediaQuery('&:hover', { cursor: 'pointer' }),
            new MediaQuery(mediaOnlyScreenMaxWidthStr(912), { width: '325px' }),
            new MediaQuery(mediaOnlyScreenMaxWidthStr(350), { width: '273px' }),
        ]
    ).getCss();
});

const Img = styled.img({
    position: 'relative',
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    borderRadius: '5px 5px 0px 0px',
});

const ContentContainer = styled.div`
    padding: 0px 16px;
    gap: 20px;
    width: 100%;
    background-color: ${Colors.UncleanWhite};
    word-break: break-word;
    border-radius: 0px 0px 5px 5px;
    overflow-x: hidden;
    flex-grow: 1;
`;

const CardTitle = styled.p({
    color: Colors.DarkBlue,
    fontWeight: '700',
    fontSize: 'clamp(14px, 5vw, 24px)',
    lineHeight: '29px',
    letterSpacing: '0.1px',
});

const CardDescription = styled.p({
    color: Colors.DarkBlue,
    fontWeight: '400',
    fontSize: 'clamp(11px, 1.6vw, 16px)',
    lineHeight: '22px',
    letterSpacing: '0.1px;',
    marginBottom: '130px'
});

interface IAccessButton {
    disabled?: boolean;
    isMatriculado?: boolean;
}

const Center = styled.div`
    text-align: center;
    width: 100%;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        padding: 0;
        padding-bottom: 10px;
    }
`;

export const AccessButton = styled.button<IAccessButton>(({ disabled = false, isMatriculado = false }: IAccessButton) => {
    return new FullCss(
        {
            fontWeight: 'bold',
            borderRadius: '7px',
            boxSizing: 'border-box',
            border: '1px solid white',
            backgroundColor: disabled ? Colors.Grey13 : isMatriculado ? Colors.Yellow : Colors.Blue,
            color: Colors.White,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '40px',
            padding: '16px 24px',
            borderColor: disabled ? Colors.Grey13 : isMatriculado ? Colors.Yellow : Colors.Blue,
            margin: '0 auto',
        },
        [
            !disabled
                ? new MediaQuery('&:hover', {
                      color: Colors.White,
                      backgroundColor: isMatriculado ? Colors.Yellow2 : Colors.DarkBlue3,
                      border: `1px solid ${isMatriculado ? Colors.Yellow2 : Colors.DarkBlue3} `,
                  })
                : new MediaQuery('', {}),
        ]
    ).getCss();
});

const InfoSectionItemStyle = styled.div({
    color: Colors.DarkBlue,
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '16px',
    letterSpacing: '0.1px;',
    display: 'flex',
    marginTop: '7px',
});

const InfoSectionContainer = styled.div(() => {
    return new FullCss({
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    }).getCss();
});

const BodyContainer = styled.div`
    display: flex;
`;

const BottomContainer = styled.div`
    position: absolute;
    bottom: 0px;
    width: 310px;
    margin-bottom: 15px;

    @media only screen and (max-width: 912px) {
        width: 290px;
        padding-bottom: 7px;
    }

    @media only screen and (max-width: 350px) {
        width: 243px;
    }
`;

interface ICursoLivreCard {
    curso: AdminCursoLivre;
}

const CursoLivreCard: FunctionComponent<ICursoLivreCard> = ({ ...props }: ICursoLivreCard) => {
    const { authenticated, login } = useLogged();
    const location = useLocation();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);
    const [matriculado, setMatriculado] = useState(false);
    const [accessNegated, setAccessNegated] = useState(false);

    const integracaoLmsService = new IntegracaoLmsService();

    const getS3BaseUrl = (): string => {
        return bucketS3() + caminhoAnexosCursosLivres();
    };

    interface IInfoSectionItem {
        descricao: string;
        icon: any;
    }
    const InfoSectionItem = ({ ...props }: IInfoSectionItem) => {
        const IsCustomRez = IsCustomMaxWidth(350);
        return (
            <InfoSectionItemStyle
                style={{ marginRight: '0px', marginTop: '0', gap: '10px', alignItems: 'center', width: !IsCustomRez ? '40%' : '50%' }}
            >
                <div style={{ display: 'flex', alignContent: 'center' }}>
                    <img style={{ marginRight: '0', height: '26px', width: '20px' }} src={props.icon} />
                </div>
                <p>{props.descricao}</p>
            </InfoSectionItemStyle>
        );
    };

    const replaces: string[][] = [
        ['HORA', 'hora'],
        ['MINUTO', 'minuto'],
    ];

    const getDescricaoDuracaCurso = (curso: AdminCursoLivre) => {
        const index = replaces.findIndex(tipo => {
            return tipo[0] === curso.tipoDuracao;
        });
        let tipoDuracao: string = replaces[index][1];

        if (curso.duracao > 1) {
            tipoDuracao = tipoDuracao + 's';
        }

        return curso.duracao + ' ' + tipoDuracao + ' de dedicação';
    };

    const options: KeycloakLoginOptions = {
        redirectUri: window.location.origin + '/login?' + '/cursos-livres',
    };

    const openUrl = (url: string) => {
        if (!props.curso.naoAplicavel) {
            if (exibirCursoEncerrado(props.curso.dataExpiracao) || (exibirInscricoesEncerradas(props.curso.prazoInscricao) && !matriculado)) {
                return;
            }
        }

        GenericComponentsUtils.gotoLink(
            url,
            history,
            keycloak.authenticated ?? false,
            { tipoRedirecionamento: TipoRedirecionamento.CADASTRO },
            { tipoRedirecionamento: TipoRedirecionamento.IGNORAR }
        );
    };

    const exibirInscricoesEncerradas = (dataControle: Date | null): Boolean => {
        return dataControle !== null && DateHelp.afterToday(dataControle) && !DateHelp.isLessEqualsToday(dataControle);
    };

    const exibirCursoEncerrado = (dataControle: Date | null): Boolean => {
        return dataControle !== null && DateHelp.afterToday(dataControle) && !DateHelp.isLessEqualsToday(dataControle);
    };

    const exibirTagAbaixo = (prazoInscricao: Date | null, dataExpiracao: Date | null): Boolean => {
        return exibirInscricoesEncerradas(prazoInscricao) || exibirCursoEncerrado(dataExpiracao) || DateHelp.isLessEqualsToday(prazoInscricao);
    };

    const verifyLMS = (url: string): boolean => {
        return url.includes('brightspace') || url.includes('cursoslivres.institutoayrtonsenna');
    };

    const getLabelButton = (prazoInscricao: Date | null, dataExpiracao: Date | null, emProducao: boolean, url: string): string => {
        const isLMS = verifyLMS(url);

        if (matriculado) {
            if (!exibirCursoEncerrado(dataExpiracao)) {
                return 'Acessar meu curso';
            }
        } else if (isLMS) {
            return 'Inscreva-se';
        } else {
            return 'Acessar';
        }

        return 'Acessar';
    };

    const verifyCurso = async () => {
        setLoading(true);
        try {
            const username = usuarioLogado.username;
            const result = await integracaoLmsService.statusMatricula(username ?? '', parseInt(props.curso.idCurso));
            const data: MatriculaLMSEnum = result.data;

            if (data === MatriculaLMSEnum.Matriculado) {
                setMatriculado(true);
            }
        } catch (error) {
            console.log(error);
        }

        setLoading(false);
    };

    useEffect(() => {
        if (authenticated) {
            verifyCurso();
        }
    }, [authenticated]);

    useEffect(() => {
        if (!props.curso.naoAplicavel) {
            if (
                exibirCursoEncerrado(props.curso.dataExpiracao) ||
                (exibirInscricoesEncerradas(props.curso.prazoInscricao) && !matriculado) ||
                props.curso.emProducao
            ) {
                setAccessNegated(true);
            }
        } else {
            if (props.curso.emProducao) {
                setAccessNegated(true);
            }
        }
    }, [matriculado, props]);

    return (
        <CursoLivreCardContainer>
            <ImageContainer
                onClick={() => {
                    openUrl(props.curso.url);
                }}
            >
                <Img src={getS3BaseUrl() + props.curso.filePath} />

                {exibirInscricoesEncerradas(props.curso.prazoInscricao) && !props.curso.naoAplicavel && (
                    <CardTag bgColor={Colors.Yellow} textColor={Colors.Blue}>
                        Inscrições Encerradas
                    </CardTag>
                )}

                {exibirCursoEncerrado(props.curso.dataExpiracao) && !props.curso.naoAplicavel && (
                    <CardTag bgColor={Colors.Yellow} textColor={Colors.Blue}>
                        Curso Encerrado
                    </CardTag>
                )}

                {exibirInscricoesEncerradas(props.curso.prazoInscricao) && props.curso.emProducao === true ? (
                    <CardTag
                        exibirNormal={exibirTagAbaixo(props.curso.prazoInscricao ?? null, props.curso.dataExpiracao ?? null) ? true : false}
                        exibirAbaixo={exibirTagAbaixo(props.curso.prazoInscricao, props.curso.dataExpiracao) ? true : false}
                    >
                        Em breve nova turma
                    </CardTag>
                ) : (
                    props.curso.prazoInscricao &&
                    DateHelp.isLessEqualsToday(props.curso.prazoInscricao) && (
                        <CardTag bgColor={Colors.Yellow} textColor={Colors.Blue}>
                            Inscrições até {DateHelp.formatDateWithoutYear(props.curso.prazoInscricao)}
                        </CardTag>
                    )
                )}
            </ImageContainer>

            <ContentContainer>
                <br />
                <CardTitle>{props.curso.titulo}</CardTitle>
                <br />
                <BodyContainer>
                    <CardDescription>{props.curso.descricao}</CardDescription>
                </BodyContainer>

                <BottomContainer>
                    <InfoSectionContainer style={{ marginBottom: '16px', gap: '5px', justifyContent: 'space-between' }}>
                        <InfoSectionItem descricao={getDescricaoDuracaCurso(props.curso)} icon={ClockSvg} />
                        <InfoSectionItem descricao={`Com certificado`} icon={LampSvg} />
                    </InfoSectionContainer>

                    <Center>
                        {loading ? (
                            <Loading />
                        ) : (
                            <>
                                {matriculado ? (
                                    <>
                                        <AccessButton
                                            isMatriculado
                                            disabled={
                                                (exibirCursoEncerrado(props.curso.dataExpiracao)
                                                    ? true
                                                    : false || props.curso.emProducao == true) && !props.curso.naoAplicavel
                                            }
                                            onClick={() => {
                                                GTM_Page_Curso_Livre(props.curso.codigo, props.curso.titulo, props.curso.descricao);
                                                GTM_GA4_Ecommerce_Select_Item(props.curso.codigo.toString(), props.curso.titulo);
                                                GTM_GA4_Ecommerce_Purchase(
                                                    keycloak.subject ?? '',
                                                    props.curso.codigo.toString(),
                                                    props.curso.titulo
                                                );
                                                openUrl(props.curso.urlAcessarCurso);
                                            }}
                                        >
                                            {getLabelButton(
                                                props.curso.prazoInscricao,
                                                props.curso.dataExpiracao,
                                                props.curso.emProducao,
                                                props.curso.url
                                            )}
                                        </AccessButton>
                                    </>
                                ) : (
                                    <>
                                        <AccessButton
                                            disabled={accessNegated}
                                            onClick={() => {
                                                GTM_Page_Curso_Livre(props.curso.codigo, props.curso.titulo, props.curso.descricao);
                                                GTM_GA4_Ecommerce_Select_Item(props.curso.codigo.toString(), props.curso.titulo);
                                                GTM_GA4_Ecommerce_Purchase(
                                                    keycloak.subject ?? '',
                                                    props.curso.codigo.toString(),
                                                    props.curso.titulo
                                                );
                                                openUrl(props.curso.url);
                                            }}
                                        >
                                            {getLabelButton(
                                                props.curso.prazoInscricao,
                                                props.curso.dataExpiracao,
                                                props.curso.emProducao,
                                                props.curso.url
                                            )}
                                        </AccessButton>
                                    </>
                                )}
                            </>
                        )}
                    </Center>
                </BottomContainer>
            </ContentContainer>
        </CursoLivreCardContainer>
    );
};

export default CursoLivreCard;
