import React, { useEffect, useState } from 'react';
import { Image } from 'antd';

interface IAmpliarImg {
    show: boolean;
    onClose: () => void;
    imgSrc: string;
}

const AmpliarImgV2: React.FC<IAmpliarImg> = ({ show, onClose, imgSrc }) => {
    const [visible, setVisible] = useState(show);

    useEffect(() => {
        setVisible(show);
    }, [show]);
    return (
        <>
            <Image
                src={imgSrc}
                alt="Imagem Ampliada"
                style={{ display: 'none' }} // Oculta a imagem na tela
                preview={{
                    visible: visible,
                    onVisibleChange: vis => {
                        setVisible(vis);
                        if (!vis) onClose(); // Fecha quando o preview for fechado
                    },
                }}
            />
        </>
    );
};

export default AmpliarImgV2;
