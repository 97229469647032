

export enum ComponentInteractionEnum {
    AUDIO = 'AUDIO',
    TEXT = 'TEXT',
    TWO_TEXT = 'TWO_TEXT',
    TEXT_IMG_LEFT = "TEXT_IMG_LEFT",
    TEXT_IMG_RIGHT = "TEXT_IMG_RIGHT",
    TEXT_HIGHLIGHTED = "TEXT_HIGHLIGHTED",
    SANFONA = 'SANFONA',
    QUIZ = 'QUIZ',
    CORRESPONDENT = 'CORRESPONDENT',
    CARROSSEL = 'CARROSSEL',
    ABAS = 'ABAS',
    CARD_BARALHO = 'CARD_BARALHO',
    REGUA_AVALIATIVA = 'REGUA_AVALIATIVA',
    TIMELINE = 'TIMELINE',
    QUIZ_PONTUACAO = 'QUIZ_PONTUACAO',
    CENARIO = 'CENARIO',
    NEXT_INFORMATION = 'NEXT_INFORMATION',
    DIVIDER_LINE = 'DIVIDER_LINE',
    DIVIDER_LINE_WITH_NUMBER = 'DIVIDER_LINE_WITH_NUMBER',
    NENHUM = ''
}