import Colors from 'enums/Colors';
import styled from 'styled-components';

export const FAD_Title = styled.h1`
    font-family: 'Barlow';
    font-size: 36px;
    font-weight: 700;
    line-height: 43.2px;
    text-align: left;
    text-decoration-skip-ink: none;
`;

export const FAD_Row1 = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;
export const FAD_RowBettweeen = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
`;

const StyledFerramentaAutoria = {
    Container: styled.div`
        padding: 20px;
        margin-bottom: 50px;
        margin-left: 100px;
        display: flex;
        flex-direction: column;
        background-color: ${Colors.White};
    `,

    ColumnForm: styled.div`
        display: flex;
        flex-direction: column;
        gap: 4px;
    `,

    LabelForm: styled.h3`
        //styleName: Base/Legenda 2;
        font-family: 'Barlow';
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        text-align: left;
        text-decoration-skip-ink: none;
        color: ${Colors.Blue};
    `,
    LabelForm2: styled.h3`
        //styleName: Base/Legenda 2;
        //styleName: Base/Corpo;
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        text-decoration-skip-ink: none;
        color: #666666;
    `,
};

export default StyledFerramentaAutoria;
