import { TemplateCourseFadEnum } from "pages/ferramentaAutoria/components/courses/TemplateCourse";
import { jsonProperty, Serializable, SerializationSettings } from "ts-serializable";

interface IFadCourses {
    codigo: number;
    codigoCertificado: number;
    name: string;
    description: string;
    url: string;
    filepath?: string;
    typeRedirect: string; // Assuming TipoRedirecionamentoEnum is converted to String
    template: TemplateCourseFadEnum;
    sequentialContents: boolean;
    sequentialSteps: boolean;
    isPublic: boolean;
    ordem: number;
}

export class FadCoursesModel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number)
    codigoCertificado: number = -1;

    @jsonProperty(String)
    name: string = '';

    @jsonProperty(String)
    description: string = '';

    @jsonProperty(String)
    url: string = '';

    @jsonProperty(String)
    filepath?: string = '';

    @jsonProperty(String)
    typeRedirect: string = '';

    @jsonProperty(String)
    template: TemplateCourseFadEnum = TemplateCourseFadEnum.Lateral;

    @jsonProperty(Boolean)
    sequentialContents: boolean = false;

    @jsonProperty(Boolean)
    sequentialSteps: boolean = false;

    @jsonProperty(Boolean)
    isPublic: boolean = false;

    @jsonProperty(Number)
    ordem: number = -1;

    fromJSON(json: IFadCourses, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.codigoCertificado = json.codigoCertificado;
        this.name = json.name;
        this.description = json.description;
        this.url = json.url;
        this.filepath = json.filepath,
        this.typeRedirect = json.typeRedirect;
        this.template = json.template;
        this.sequentialContents = json.sequentialContents;
        this.sequentialSteps = json.sequentialSteps;
        this.isPublic = json.isPublic;
        this.ordem = json.ordem;
        return this;
    }
}