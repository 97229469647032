import { FadPagesService } from 'core/http/service/ferramentaAutoria/FadPages.service';
import Colors from 'enums/Colors';
import useFetch from 'hooks/useFetch';
import { FadCoursesPublicModel } from 'models/ferramentaAutoria/public/FadCoursesPublicModel';
import CardCoursePublic from 'pages/ferramentaAutoria/components/courses/CardCoursePublic';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        padding: 160px 60px;
    `,

    Title: styled.h1`
        font-size: 46px;
        font-weight: bold;
        color: ${Colors.SoftBlue};
        text-decoration: underline;
        text-underline-offset: calc(1rem);
    `,

    Row: styled.div`
        display: flex;
        gap: 16px;

        @media screen and (max-width: 720px) {
            flex-direction: column;
        }
    `,
};

const FadPage = () => {
    const [courses, setCourses] = useState<FadCoursesPublicModel[]>([]);
    const { page }: any = useParams();

    //services
    const servicePage = new FadPagesService();

    useFetch(async () => {
        if (page) {
            const { data, status } = await servicePage.findByUrlPublic(page);

            if (status === 200) {
                setCourses(data);
            }
        }
    }, [window.location.pathname]);

    return (
        <Styled.Container>
            <Styled.Title>Cursos</Styled.Title>

            <Styled.Row>
                {courses.map((c, i) => (
                    <CardCoursePublic key={i} dados={c} />
                ))}
            </Styled.Row>
        </Styled.Container>
    );
};

export default FadPage;
