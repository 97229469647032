import { jsonProperty, Serializable, SerializationSettings } from "ts-serializable";

interface IFadContents {
    codigo: number;
    codigoFerramentaAutoriaModule: number;
    title: string;
    isPublic: boolean;
    activeAvaliations: boolean;
    activeCommentary: boolean;
    ordem: number;
}

export class FadContentsModel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number)
    codigoFerramentaAutoriaModule: number = -1;

    @jsonProperty(String)
    title: string = '';

    @jsonProperty(Boolean)
    isPublic: boolean = false;

    @jsonProperty(Boolean)
    activeAvaliations: boolean = false;

    @jsonProperty(Boolean)
    activeCommentary: boolean = false;

    @jsonProperty(Number)
    ordem: number = -1;

    fromJSON(json: IFadContents, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.codigoFerramentaAutoriaModule = json.codigoFerramentaAutoriaModule;
        this.title = json.title;
        this.isPublic = json.isPublic;
        this.activeAvaliations = json.activeAvaliations;
        this.activeCommentary = json.activeCommentary;
        this.ordem = json.ordem;
        return this;
    }
}