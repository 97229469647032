import React from 'react';
import { useState, useEffect } from 'react';
import {
    Container,
    Content,
    Text,
    Title,
    ButtonContainer,
    DropIcon,
    InnerButton,
    InnerCircleText,
    InnerContent,
    LateralContent,
    Separator,
    SideContent,
    YellowCircle,
    DescriptionText,
    ContainerCenter,
} from './RealizandoEscolhasList.styled';
import DropPlus from '../../assets/images/dropdown-plus.svg';
import DropMinus from '../../assets/images/dropdown-minus.svg';
import ModalDefault, { ModalDefaultMobile } from 'pages/realizandoEscolhas/pages/components/ModalDefault';
import TrilhaFormativaModal from '../TrilhaFormativaModal';
import UsuarioDTO from 'models/UsuarioDTO';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import {
    buscarCategoriaPorPerfil,
    buscarOcupacaoPorPerfil,
    redirectToInstrumento,
    redirectToTrilhaFormativaLMS,
} from 'pages/realizandoEscolhas/utils/RealizandoEscolhasUtils';
import { useHistory } from 'react-router-dom';
import ModalBlocked from '../ModalBlocked';
import { GTM_Page_Realizando_Escolhas } from 'components/GoogleTagManager';

export interface ITile {
    title: string;
    contentText: string;
    buttonCallback: () => void;
    moreInfo: string;
    position: number;
}

const RealizandoEscolhasListMobile = ({ title, contentText, buttonCallback, moreInfo, position }: ITile) => {
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);
    const profile = useAppSelector(state => state.profileReducer.userProfile);
    const [showTrilhaFormativaReqModal, setShowTrilhaFormativaReqModal] = useState(false);
    const [showModalBlocked, setShowModalBlocked] = useState(false);
    const history = useHistory();

    const buttonAction = async () => {
        if (title == 'Avaliação') {
            const userOcupacao = buscarOcupacaoPorPerfil(profile);

            if (userOcupacao !== 'B') {
                await goToInstrumento();
            } else {
                setShowModalBlocked(true);
            }
        } else {
            buttonCallback();
        }
    };

    const goToInstrumento = async () => {
        redirectToInstrumento(
            (await buscarCategoriaPorPerfil(usuarioLogado)) ?? ('A' as string),
            setShowTrilhaFormativaReqModal,
            usuarioLogado,
            () => {
                history.push('/realizandoEscolhas/avaliacao');
                GTM_Page_Realizando_Escolhas('home_cards_realizando_escolhas', `acessar_page_avaliacao`);
            }
        );
    };

    const goToTrilhaFormativaLMS = async (role: string) => {
        const urlRedirect = await redirectToTrilhaFormativaLMS(role, false, usuarioLogado, true);
        history.push(urlRedirect);
    };

    return (
        <ContainerCenter>
            <Container>
                <Content>
                    <InnerContent>
                        <SideContent>
                            <YellowCircle>
                                <InnerCircleText>{position}</InnerCircleText>
                            </YellowCircle>
                        </SideContent>
                        <LateralContent>
                            <Title>{title}</Title>
                            <Text>{contentText}</Text>
                        </LateralContent>
                    </InnerContent>
                    <ButtonContainer>
                        <DescriptionText>{moreInfo}</DescriptionText>
                        <InnerButton
                            onClick={() => {
                                buttonAction();
                            }}
                        >
                            {'Acessar'}
                        </InnerButton>
                    </ButtonContainer>
                </Content>

                {/* ========= MODAIS ========== */}
                <ModalDefault show={showModalBlocked}>
                    <ModalBlocked setShow={setShowModalBlocked} goToTrilhaFormativaCallback={() => ''} />
                </ModalDefault>

                <ModalDefaultMobile show={showTrilhaFormativaReqModal}>
                    <TrilhaFormativaModal
                        isHome={true}
                        setShow={setShowTrilhaFormativaReqModal}
                        goToTrilhaFormativaCallback={async () => {
                            goToTrilhaFormativaLMS((await buscarCategoriaPorPerfil(usuarioLogado)) ?? ('A' as string));
                            setShowTrilhaFormativaReqModal(false);
                        }}
                    />
                </ModalDefaultMobile>
            </Container>
            <Separator />
        </ContainerCenter>
    );
};

export default RealizandoEscolhasListMobile;
