
import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import AcessoExlusivoProvider from '../context/AcessoExclusivoProvider';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import DialogosSocioemocionaisPrivate from './dialogosSocioemocionais/DialogosSocioemocionaisPrivate';
import CircuitoPrivate from './circuito360/CircuitoPrivate';

export interface IAcessoExclusivoRoute {}

const AcessoExclusivoRoute = ({}: IAcessoExclusivoRoute) => {
    const location = useLocation();

    return (
        <AcessoExlusivoProvider>
            <DynamicHeader />
            <Route exact path={"/exclusivo/dialogos-socioemocionais"} component={DialogosSocioemocionaisPrivate} />
            <Route exact path={"/exclusivo/circuito-360"} component={CircuitoPrivate} />
        </AcessoExlusivoProvider>
    );
};

export default AcessoExclusivoRoute;
