import { Skeleton } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { MdAdd } from 'react-icons/md';

interface IImageUpload {
    base64: string;
    handleUpload: (value: string) => void;
    componentStyled?: React.CSSProperties;
    componentImgStyled?: React.CSSProperties;
    placeholder?: string;
    isVisibleButton?: boolean;
}

const ImageUpload = ({ base64, handleUpload, componentStyled, componentImgStyled, placeholder, isVisibleButton }: IImageUpload) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [lastBase64, setLastBase64] = useState<string>(base64);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setLoading(true);
            const reader = new FileReader();
            reader.onloadend = () => {
                handleUpload(reader.result as string); // Armazena o Base64 no estado
            };
            reader.readAsDataURL(file); // Converte o arquivo para Base64
        }
    };

    useEffect(() => {
        if (lastBase64 != base64) {
            setLoading(false);
            setLastBase64(base64);
        }
    }, [base64]);

    return (
        <div
            style={{
                width: '520px', // Tamanho do container
                height: '240px',
                border: '2px dashed #d9d9d9', // Borda tracejada
                borderRadius: '8px', // Cantos arredondados
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#fafafa',
                position: 'relative',
                ...componentStyled,
            }}
        >
            {base64 ? (
                <>
                    <label
                        style={{
                            textAlign: 'center',
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'center',
                        }}
                    >
                        {loading ? (
                            <>
                                <Skeleton.Image
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '240px',
                                        borderRadius: '8px',
                                        ...componentImgStyled,
                                    }}
                                    active
                                />
                            </>
                        ) : (
                            <>
                                <img
                                    src={base64} // Exibe a imagem em Base64
                                    alt="Preview"
                                    style={{
                                        width: '100%',
                                        height: '240px',
                                        objectFit: 'cover',
                                        borderRadius: '8px',
                                        ...componentImgStyled,
                                    }}
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }} // Esconde o input de upload
                                    onChange={handleImageChange}
                                />
                            </>
                        )}
                    </label>
                </>
            ) : (
                <label
                    style={{
                        textAlign: 'center',
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div
                        style={{
                            width: '60px',
                            height: '60px',
                            borderRadius: '50%',
                            backgroundColor: '#003366', // Cor azul
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '8px',
                        }}
                    >
                        <span style={{ fontSize: '24px', color: '#fff' }}>
                            <MdAdd />
                        </span>
                    </div>
                    <span style={{ color: '#8c8c8c', fontSize: '16px' }}>{placeholder ?? 'Adicione uma imagem de destaque'}</span>
                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }} // Esconde o input de upload
                        onChange={handleImageChange}
                    />
                </label>
            )}

            {isVisibleButton && (
                <>
                    <input
                        type="file"
                        accept="image/*"
                        style={{ position: 'absolute', top: '100%', margin: 4 }} // Esconde o input de upload
                        onChange={handleImageChange}
                    />
                </>
            )}
        </div>
    );
};

export default ImageUpload;
