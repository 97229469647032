import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import App from './App';
import EducationalSolutionsDetail from './pages/intermediarias/educationalSolutionsDetail/EducationalSolutionsDetail';
import Profile from './pages/profile/Profile';
import PainelSocioemocional from './pages/painelSocioemocional/PainelSocioemocional';
import PrivateRoute from 'PrivateRoute';
import { useKeycloak } from '@react-keycloak/web';
import Loading from 'components/Loading';
import Login from 'pages/Login';
import Biblioteca from 'pages/biblioteca_v2/Biblioteca';
import Admin from 'pages/admin/Admin';
import { RoleEnum } from 'enums/RoleEnum';
import UnauthorizedPage from 'pages/painelSocioemocional/UnauthorizedPage';
import Logout from 'Logout';
import EditProfile from 'pages/editProfile/EditProfile';
import CursoLivreDetail from 'pages/intermediarias/cursoLivre/CursoLivreDetail';
import CriatividadeDetail from 'pages/intermediarias/criatividadeDetail/CriatividadeDetail';
import Alfabetizacao360Detail from 'pages/intermediarias/alfabetizacao360Detail/Alfabetizacao360Detail';
import RealizandoEscolhas from 'pages/realizandoEscolhas/RealizandoEscolhas';
import RealizandoEscolhasDetail from 'pages/intermediarias/realizandoEscolhas/RealizandoEscolhasDetail';

import * as profileActions from './store/actions/Profile';
import * as pdpActions from './store/actions/Pdp';
import * as diaryActions from './store/actions/Diary';
import * as authenticationActions from './store/actions/Authentication';
import { useDispatch } from 'react-redux';
import Painel from 'pages/intermediarias/painel/Painel';
import Suporte from 'pages/intermediarias/suporte/Suporte';
import ABTest, { keyLocalStorage } from 'components/ab_tests/ABTest';
import { cacheShowSliderDialogKey } from 'pages/painelSocioemocional/home/context/PainelSocioProvider';
import PesquisaHome from 'pages/pesquisa/resultadoPesquisa/ResultadoPesquisa';
import Pesquisa from 'pages/pesquisa/Pesquisa';
import Exemples from 'pages/generic-components/exemples/Exemples';
import { isDevelopment } from 'constants/EnvironmentData';
import PaginaBase from 'pages/paginaBase-gestaoPaginas/PaginaBase';
import NotFound from 'pages/not_found/NotFound';
import RecursosRoute from 'pages/recursos/RecursosRoute';
import CompetenciasSocioemocionais from 'pages/intermediarias/competenciasSocioemocionais/CompetenciasSocioemocionais';
import Solucoes from 'pages/solucoes/Solucoes';
import Circuito360 from 'pages/solucoes/subpages/circuito360/Circuito360';
import GestaoNota10 from 'pages/solucoes/subpages/gestaoNota10/GestaoNota10';
import SeLiga from 'pages/solucoes/subpages/seliga/SeLiga';
import AceleraBrasil from 'pages/solucoes/subpages/aceleraBrasil/AceleraBrasil';
import FormulaVitoria from 'pages/solucoes/subpages/formulaVitoria/FormulaVitoria';
import DialogoSocioemocional from 'pages/solucoes/subpages/dialogoSocioemocional/DialogoSocioemocional';
import RealizandoEscolhaSolucao from 'pages/solucoes/subpages/realizandoEscolhas/RealizandoEscolhasSolucao';
import EbooksRoute from 'pages/ebooks/EbooksRoute';
import AcessoExclusivoRoute from 'pages/acessoExclusivo/pages/AcessoExclusivoRoute';
import ValidationAccount from 'pages/profile/components/informacoesPessoais/forms/ValidationAccount';
import FadCourseRouter from 'pages/ferramentaAutoria/pages/public/FadCourseRouter';
import { PesquisaContext } from 'pages/home/context/PesquisaContext';
import BarraPesquisa from 'pages/pesquisa/barraPesquisa/BarraPesquisa';

const Routes = () => {
    const { keycloak, initialized } = useKeycloak();
    const dispatch = useDispatch();

    const { isSearchView } = useContext(PesquisaContext.Context);

    if (!initialized) {
        return <Loading />;
    }

    useEffect(() => {
        if (keycloak && initialized) {
            keycloak.onTokenExpired = () => keycloak.updateToken(1);

            if (!keycloak.authenticated) {
                dispatch(profileActions.clearStore());
                dispatch(pdpActions.clearStore());
                dispatch(diaryActions.clearStore());
                dispatch(authenticationActions.logout());
                sessionStorage.clear();

                //não excluir versao do test A/B
                // const versionTest = ABTest.getCurrentVersion();
                localStorage.clear();

                // localStorage.setItem(keyLocalStorage, versionTest);
            }
        }

        return () => {
            if (keycloak) keycloak.onTokenExpired = () => {};
        };
    }, [initialized, keycloak]);

    return (
        <>
            <Switch>
                <Route exact path={['/', '/home']} component={App} />

                <Route path="/socioemocional-professores" component={EducationalSolutionsDetail} />
                <Route path="/solucoes" component={Solucoes} />
                <Route path="/circuito-360" component={Circuito360} />
                <Route path="/gestao-nota-10" component={GestaoNota10} />
                <Route path="/acelera-brasil" component={AceleraBrasil} />
                <Route path="/dialogos-socioemocionais" component={DialogoSocioemocional} />
                <Route path="/formula-da-vitoria" component={FormulaVitoria} />
                <Route path="/solucao-realizando-escolhas" component={RealizandoEscolhaSolucao} />
                <Route path="/se-liga" component={SeLiga} />
                <Route path="/criatividade-pensamento-critico" component={CriatividadeDetail} />
                <Route path={['/alfabetizacao-360']} component={Alfabetizacao360Detail} />
                <Route path={['/realizando-escolhas']} component={RealizandoEscolhasDetail} />
                <Route path={['/competencias-socioemocionais']} component={CompetenciasSocioemocionais} />
                <Route path="/suporte" component={Suporte} />
                <PrivateRoute path="/painel" component={Painel} />

                <Route path="/naoAutorizado" component={UnauthorizedPage} />

                <PrivateRoute path="/login" component={Login} />
                <PrivateRoute path="/profile" component={Profile} />
                <PrivateRoute exact path="/editarPerfil" component={EditProfile} />

                <PrivateRoute path="/painelSocioemocional" component={PainelSocioemocional} />
                <Route path="/biblioteca" component={Biblioteca} />
                <Route path={['/cursos-livres']} component={CursoLivreDetail} />

                <Route path={['/cursos/:page', '/cursos/:page/:curso', '/cursos/:page/:curso/conteudos']} component={FadCourseRouter} />

                <PrivateRoute path="/realizandoEscolhas" component={RealizandoEscolhas} />

                <PrivateRoute path="/admin" roles={[RoleEnum.Administrador]} component={Admin} />
                <Route path="/logout" component={Logout} />
                <Route path="/pesquisa" component={Pesquisa} />
                <Route path="/recursos" component={RecursosRoute} />
                <Route path="/exclusivo" component={AcessoExclusivoRoute} />

                <Route path="/validar" component={ValidationAccount} />

                <Route path="/ebooks" component={EbooksRoute} />
                <Route path="/pagina" component={PaginaBase} />
                <Route path="/not-found" component={NotFound} />

                {isDevelopment() && <Route exact path="/componentes-genericos" component={Exemples} />}

                <Route path="/educationalSolutionsDetail">
                    <Redirect to={{ pathname: '/socioemocional-professores', state: { statu: 301 } }} />
                </Route>
                <Route path="/criatividadeDetail">
                    <Redirect to={{ pathname: '/criatividade-pensamento-critico', state: { statu: 301 } }} />
                </Route>
                <Route path="/alfabetizacao360Detail">
                    <Redirect to={{ pathname: '/alfabetizacao-360', state: { statu: 301 } }} />
                </Route>
                <Route path="/realizandoEscolhasDetail">
                    <Redirect to={{ pathname: '/realizando-escolhas', state: { statu: 301 } }} />
                </Route>
                <Route path="/cursosLivres">
                    <Redirect to={{ pathname: '/cursos-livres', state: { statu: 301 } }} />
                </Route>
            </Switch>
            {isSearchView && <BarraPesquisa />}
        </>
    );
};

export default Routes;
