import { render } from '@testing-library/react';
import { ColorPicker, Input, Popconfirm, Popover, Table } from 'antd';
import { ToastError, ToastSuccess } from 'components/Toaster';
import { FadPagesService } from 'core/http/service/ferramentaAutoria/FadPages.service';
import Colors from 'enums/Colors';
import { FadPagesModel } from 'models/ferramentaAutoria/FadPagesModel';
import React, { useEffect, useState } from 'react';
import { MdDeleteForever, MdEdit, MdSave, MdSaveAs } from 'react-icons/md';

interface IAdminFadAbaPages {
    pages: FadPagesModel[];
}

const columns = [
    {
        title: 'URL',
        dataIndex: 'url',
        key: 'url',
    },

    {
        title: 'Cor',
        dataIndex: 'cor',
        key: 'cor',
        render: (v: string) => <ColorPicker value={v} />,
    },
    {
        title: 'Ações',
        dataIndex: 'acoes',
        key: 'acoes',
        render: () => (
            <div style={{ display: 'flex', gap: '16px' }}>
                <Popconfirm
                    title="Deletar página!"
                    description="Você tem certeza que deseja excluir essa página?"
                    onConfirm={() => ''}
                    onCancel={() => ''}
                    okText="Sim"
                    cancelText="Não"
                >
                    <MdDeleteForever size={22} color={Colors.DeleteRed} cursor={'pointer'} />
                </Popconfirm>
            </div>
        ),
    },
];

const AdminFadAbaPages = ({ pages }: IAdminFadAbaPages) => {
    const [listPages, setListPages] = useState<FadPagesModel[]>(pages);

    //service
    const serviceFadPages = new FadPagesService();

    const handleEdit = (index: number, val: string, isUrl: boolean) => {
        setListPages((prev: any) =>
            prev.map((p: FadPagesModel, i: number) => {
                if (i === index) {
                    return {
                        ...p,
                        url: isUrl ? val : p.url.replace(/\s+/g, ''),
                        cor: !isUrl ? val : p.cor,
                    };
                }

                return p;
            })
        );
    };

    const handleDeletePage = async (id: number) => {
        const { status } = await serviceFadPages.delete(id);

        if (status === 204) {
            setListPages(listPages.filter((p: FadPagesModel) => p.codigo !== id));
            ToastSuccess("Página excluída com sucesso!")
        }
    };

    const handleSaveEdit = async (index: number) => {
        let datePage = listPages[index];

        datePage.url = datePage.url.replace(/\s+/g, '');

        const { data, status } = await serviceFadPages.update(datePage.codigo, datePage);

        if (status === 200) {
            ToastSuccess('Página atualizada!');

            setListPages((prev: any) =>
                prev.map((p: FadPagesModel, i: number) => {
                    if (i === index) {
                        return data;
                    }

                    return p;
                })
            );
        }else {
            ToastError("Já possuí uma página com essa URL")
        }
    };

    useEffect(() => {
        setListPages(pages);
    }, [pages]);

    const columns = [
        {
            title: 'URL',
            dataIndex: 'url',
            key: 'url',
        },

        {
            title: 'Cor',
            dataIndex: 'cor',
            key: 'cor',
        },
        {
            title: 'Ações',
            dataIndex: 'acoes',
            key: 'acoes',
        },
    ];

    return (
        <Table
            dataSource={listPages.map((l, index) => ({
                url: (
                    <p style={{ backgroundColor: l.cor ?? '#000', padding: '20px' }}>
                        <Input
                            type="text"
                            value={l.url}
                            onChange={e => handleEdit(index, e.target.value, true)}
                            style={{ backgroundColor: 'transparent', border: 'none', padding: 10, color: '#FFF', fontSize: 18 }}
                        />{' '}
                    </p>
                ),
                cor: <ColorPicker value={l.cor} onChange={v => handleEdit(index, v.toHexString(), false)} />,
                acoes: (
                    <div style={{ display: 'flex', gap: '16px' }}>
                        <MdSaveAs size={22} color={Colors.Blue} cursor={'pointer'} onClick={() => handleSaveEdit(index)} />
                        <Popconfirm
                            title="Deletar página!"
                            description="Você tem certeza que deseja excluir essa página?"
                            onConfirm={() => handleDeletePage(l.codigo)}
                            onCancel={() => ''}
                            okText="Sim"
                            cancelText="Não"
                        >
                            <MdDeleteForever size={22} color={Colors.DeleteRed} cursor={'pointer'} />
                        </Popconfirm>
                    </div>
                ),
            }))}
            columns={columns}
        />
    );
};

export default AdminFadAbaPages;
