import { Serializable } from 'ts-serializable';
import { GenericService } from '../Generic.service';
import { ComponentInteractionAnexo } from 'models/componentInteraction/ComponentInteractionAnexo';

export class ComponentInteractionAnexoService extends GenericService {
    readonly URI: string = '/v1/componentInteractionAnexo';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public insertAnexo(anexo: ComponentInteractionAnexo, idComponent: number) {
        return this.post('' + idComponent, anexo);
    }

    public fetchAnexoByCodigoComponent(idComponent: number, pub: boolean) {
        return this.get('' + idComponent + '/' + pub);
    }
}
