import { InputNumber } from 'antd';
import Colors from 'enums/Colors';
import { ComponentInteractionEnum } from 'enums/ComponentInteractionEnum';
import ComponentInteractionModel from 'models/componentInteraction/ComponentInteraction';
import React from 'react';
import styled from 'styled-components';
import type { InputNumberProps } from 'antd';

const Styled = {
    Divider: styled.div`
        width: 100%;
        height: 3px;
        background-color: #f0f0f0;
        margin: 22px 0px;
    `,

    DividerNumber: {
        Container: styled.div`
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
        `,

        Metric: styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            border-radius: 100%;
            position: absolute;
            top: -10px;
            font-size: 22px;
            gap: 10px;
            background-color: ${Colors.Blue};
            color: ${Colors.White};
        `,
    },
};

interface IComponentDividersAdmin {
    dados: ComponentInteractionModel;
    onChange: (value: number | null) => void;
}

const ComponentDividersAdmin = ({ dados, onChange }: IComponentDividersAdmin) => {
    if (dados.tipo === ComponentInteractionEnum.DIVIDER_LINE) {
        return <Styled.Divider />;
    }

    if (dados.tipo === ComponentInteractionEnum.DIVIDER_LINE_WITH_NUMBER) {
        return (
            <>
                <Styled.DividerNumber.Container>
                    <Styled.DividerNumber.Metric>{parseInt(dados.conteudo) > 0 ? dados.conteudo : 1}</Styled.DividerNumber.Metric>
                </Styled.DividerNumber.Container>
                <Styled.Divider />
                <InputNumber min={1} max={10} value={parseInt(dados.conteudo) ?? 1} defaultValue={1} onChange={onChange} />
            </>
        );
    }

    return <></>;
};

export default ComponentDividersAdmin;
