import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FAD_Button_Outline, FAD_Button_Primary } from '../components/FadButtons';
import { FadTemplatesService } from 'core/http/service/ferramentaAutoria/FadTemplates.service';
import { FadTemplatesModel } from 'models/ferramentaAutoria/FadTemplatesModel';
import { MdDeleteForever, MdEdit, MdSettings } from 'react-icons/md';
import { Checkbox, Collapse, CollapseProps, InputNumber } from 'antd';
import { FadRelationsTemplateModel } from 'models/ferramentaAutoria/FadRelationsTemplateModel';
import useFetch from 'hooks/useFetch';
import { FadRelationsTemplateService } from 'core/http/service/ferramentaAutoria/FadRelationsTemplate.service';
import Colors from 'enums/Colors';
import { ToastSuccess } from 'components/Toaster';
import ModalFormTemplate from '../components/modals/ModalFormTemplate';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
    `,

    Row: styled.div`
        display: flex;
        gap: 16px;
    `,

    RowBetween: styled.div`
        display: flex;
        justify-content: space-between;
    `,

    RowEnd: styled.div`
        display: flex;
        justify-content: end;
    `,

    Column: styled.div`
        display: flex;
        flex-direction: column;
        gap: 8px;
    `,

    Card: {
        Container: styled.div`
            display: flex;
            flex-direction: column;
            width: 360px;
            padding: 10px;
            gap: 16px;
            border: 1px solid #00000088;
            -webkit-box-shadow: 10px 10px 5px -7px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 10px 10px 5px -7px rgba(0, 0, 0, 0.75);
            box-shadow: 10px 10px 5px -7px rgba(0, 0, 0, 0.75);
        `,

        Title: styled.h2`
            font-size: 22px;
        `,
    },
};

interface ICardTemplate {
    dados: FadTemplatesModel;
    handleDeleteTemplate: (codigo: number) => void;
    handleUpdate: () => void;
}

const CardTemplate = ({ dados, handleDeleteTemplate, handleUpdate }: ICardTemplate) => {
    const [relations, setRelations] = useState<FadRelationsTemplateModel[]>([]);

    //service
    const serviceFadRelations = new FadRelationsTemplateService();

    // Atualiza a relação ao marcar/desmarcar um checkbox
    const handleChange = async (relation: FadRelationsTemplateModel, checked: boolean) => {
        if (checked) {
            const { data, status } = await serviceFadRelations.saveRelationTemplate(relation);
            if (status === 201) {
                setRelations(prev => [...prev, data]);
            }
        } else {
            const { status } = await serviceFadRelations.deleteRelationTemplate(relation.codigo);
            if (status === 204) {
                setRelations(prev => prev.filter(r => r.codigo !== relation.codigo));
            }
        }
    };

    // Atualiza a ordem de um item
    const updateOrdem = async (codigo: number, newOrdem: number) => {
        if (newOrdem === null) {
            return;
        }

        const relationToUpdate = relations.find(r => r.codigo === codigo);
        if (!relationToUpdate) return;

        const updatedRelation = new FadRelationsTemplateModel().fromJSON({
            ...relationToUpdate,
            ordem: newOrdem,
        });

        const { status } = await serviceFadRelations.updateRelationTemplate(codigo, updatedRelation);
        if (status === 200) {
            setRelations((prev: any) => prev.map((r: FadRelationsTemplateModel) => (r.codigo === codigo ? { ...r, ordem: newOrdem } : r)));
        }
    };
    useFetch(async () => {
        const { data, status } = await serviceFadRelations.getRelationTemplateByCodigo(dados.codigo);
        if (status === 200) {
            if (Array.isArray(data)) {
                setRelations(data);
            } else {
                setRelations([data]);
            }
        } else {
            setRelations([]); // Garante que relations é um array
        }
    }, [dados]);
    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Blocos de textos',
            children: (
                <Styled.Column>
                    <Styled.RowBetween style={{ alignItems: 'center' }}>
                        <Checkbox
                            onChange={e =>
                                handleChange(
                                    {
                                        codigo: relations.find(f => f.type === 'TEXT')?.codigo ?? -1,
                                        type: 'TEXT',
                                        codigoTemplate: dados.codigo,
                                        ordem: 1,
                                    } as FadRelationsTemplateModel,
                                    e.target.checked
                                )
                            }
                            checked={Array.isArray(relations) && relations.some(r => r.type === 'TEXT')}
                        >
                            Texto
                        </Checkbox>
                        <InputNumber
                            min={1}
                            max={100}
                            value={relations.find(f => f.type === 'TEXT')?.ordem}
                            onChange={value => updateOrdem(relations.find(f => f.type === 'TEXT')?.codigo!, value as number)}
                            placeholder="Ordem"
                        />
                    </Styled.RowBetween>
                    <Styled.RowBetween style={{ alignItems: 'center' }}>
                        <Checkbox
                            onChange={e =>
                                handleChange(
                                    {
                                        codigo: relations.find(f => f.type === 'TWO_TEXT')?.codigo ?? -1,
                                        type: 'TWO_TEXT',
                                        codigoTemplate: dados.codigo,
                                        ordem: 1,
                                    } as FadRelationsTemplateModel,
                                    e.target.checked
                                )
                            }
                            checked={Array.isArray(relations) && relations.some(r => r.type === 'TWO_TEXT')}
                        >
                            Texto em duas colunas
                        </Checkbox>
                        <InputNumber
                            min={1}
                            max={100}
                            value={relations.find(f => f.type === 'TWO_TEXT')?.ordem}
                            onChange={value => updateOrdem(relations.find(f => f.type === 'TWO_TEXT')?.codigo!, value as number)}
                            placeholder="Ordem"
                        />
                    </Styled.RowBetween>
                    <Styled.RowBetween style={{ alignItems: 'center' }}>
                        <Checkbox
                            onChange={e =>
                                handleChange(
                                    {
                                        codigo: relations.find(f => f.type === 'TEXT_IMG_LEFT')?.codigo ?? -1,
                                        type: 'TEXT_IMG_LEFT',
                                        codigoTemplate: dados.codigo,
                                        ordem: 1,
                                    } as FadRelationsTemplateModel,
                                    e.target.checked
                                )
                            }
                            checked={Array.isArray(relations) && relations.some(r => r.type === 'TEXT_IMG_LEFT')}
                        >
                            Texto com imagem a esquerda
                        </Checkbox>
                        <InputNumber
                            min={1}
                            max={100}
                            value={relations.find(f => f.type === 'TEXT_IMG_LEFT')?.ordem}
                            onChange={value => updateOrdem(relations.find(f => f.type === 'TEXT_IMG_LEFT')?.codigo!, value as number)}
                            placeholder="Ordem"
                        />
                    </Styled.RowBetween>
                    <Styled.RowBetween style={{ alignItems: 'center' }}>
                        <Checkbox
                            onChange={e =>
                                handleChange(
                                    {
                                        codigo: relations.find(f => f.type === 'TEXT_IMG_RIGHT')?.codigo ?? -1,
                                        type: 'TEXT_IMG_RIGHT',
                                        codigoTemplate: dados.codigo,
                                        ordem: 1,
                                    } as FadRelationsTemplateModel,
                                    e.target.checked
                                )
                            }
                            checked={Array.isArray(relations) && relations.some(r => r.type === 'TEXT_IMG_RIGHT')}
                        >
                            Texto com imagem a direita
                        </Checkbox>
                        <InputNumber
                            min={1}
                            max={100}
                            value={relations.find(f => f.type === 'TEXT_IMG_RIGHT')?.ordem}
                            onChange={value => updateOrdem(relations.find(f => f.type === 'TEXT_IMG_RIGHT')?.codigo!, value as number)}
                            placeholder="Ordem"
                        />
                    </Styled.RowBetween>
                </Styled.Column>
            ),
        },
        {
            key: '2',
            label: 'Avaliação',
            children: (
                <Styled.Column>
                    <Styled.RowBetween style={{ alignItems: 'center' }}>
                        <Checkbox
                            onChange={e =>
                                handleChange(
                                    {
                                        codigo: relations.find(f => f.type === 'QUIZ')?.codigo ?? -1,
                                        type: 'QUIZ',
                                        codigoTemplate: dados.codigo,
                                        ordem: 1,
                                    } as FadRelationsTemplateModel,
                                    e.target.checked
                                )
                            }
                            checked={Array.isArray(relations) && relations.some(r => r.type === 'QUIZ')}
                        >
                            Quiz
                        </Checkbox>
                        <InputNumber
                            min={1}
                            max={100}
                            value={relations.find(f => f.type === 'QUIZ')?.ordem}
                            onChange={value => updateOrdem(relations.find(f => f.type === 'QUIZ')?.codigo!, value as number)}
                            placeholder="Ordem"
                        />
                    </Styled.RowBetween>
                    <Styled.RowBetween style={{ alignItems: 'center' }}>
                        <Checkbox
                            onChange={e =>
                                handleChange(
                                    {
                                        codigo: relations.find(f => f.type === 'REGUA_AVALIATIVA')?.codigo ?? -1,
                                        type: 'REGUA_AVALIATIVA',
                                        codigoTemplate: dados.codigo,
                                        ordem: 1,
                                    } as FadRelationsTemplateModel,
                                    e.target.checked
                                )
                            }
                            checked={Array.isArray(relations) && relations.some(r => r.type === 'REGUA_AVALIATIVA')}
                        >
                            Régua Avaliativa
                        </Checkbox>
                        <InputNumber
                            min={1}
                            max={100}
                            value={relations.find(f => f.type === 'REGUA_AVALIATIVA')?.ordem}
                            onChange={value => updateOrdem(relations.find(f => f.type === 'REGUA_AVALIATIVA')?.codigo!, value as number)}
                            placeholder="Ordem"
                        />
                    </Styled.RowBetween>
                    <Styled.RowBetween style={{ alignItems: 'center' }}>
                        <Checkbox
                            onChange={e =>
                                handleChange(
                                    {
                                        codigo: relations.find(f => f.type === 'QUIZ_PONTUACAO')?.codigo ?? -1,
                                        type: 'QUIZ_PONTUACAO',
                                        codigoTemplate: dados.codigo,
                                        ordem: 1,
                                    } as FadRelationsTemplateModel,
                                    e.target.checked
                                )
                            }
                            checked={Array.isArray(relations) && relations.some(r => r.type === 'QUIZ_PONTUACAO')}
                        >
                            Quiz com pontuação
                        </Checkbox>
                        <InputNumber
                            min={1}
                            max={100}
                            value={relations.find(f => f.type === 'QUIZ_PONTUACAO')?.ordem}
                            onChange={value => updateOrdem(relations.find(f => f.type === 'QUIZ_PONTUACAO')?.codigo!, value as number)}
                            placeholder="Ordem"
                        />
                    </Styled.RowBetween>
                </Styled.Column>
            ),
        },
        {
            key: '3',
            label: 'Interação',
            children: (
                <Styled.Column>
                    <Styled.RowBetween style={{ alignItems: 'center' }}>
                        <Checkbox
                            onChange={e =>
                                handleChange(
                                    {
                                        codigo: relations.find(f => f.type === 'SANFONA')?.codigo ?? -1,
                                        type: 'SANFONA',
                                        codigoTemplate: dados.codigo,
                                        ordem: 1,
                                    } as FadRelationsTemplateModel,
                                    e.target.checked
                                )
                            }
                            checked={Array.isArray(relations) && relations.some(r => r.type === 'SANFONA')}
                        >
                            Sanfona
                        </Checkbox>
                        <InputNumber
                            min={1}
                            max={100}
                            value={relations.find(f => f.type === 'SANFONA')?.ordem}
                            onChange={value => updateOrdem(relations.find(f => f.type === 'SANFONA')?.codigo!, value as number)}
                            placeholder="Ordem"
                        />
                    </Styled.RowBetween>

                    <Styled.RowBetween style={{ alignItems: 'center' }}>
                        <Checkbox
                            onChange={e =>
                                handleChange(
                                    {
                                        codigo: relations.find(f => f.type === 'CORRESPONDENT')?.codigo ?? -1,
                                        type: 'CORRESPONDENT',
                                        codigoTemplate: dados.codigo,
                                        ordem: 1,
                                    } as FadRelationsTemplateModel,
                                    e.target.checked
                                )
                            }
                            checked={Array.isArray(relations) && relations.some(r => r.type === 'CORRESPONDENT')}
                        >
                            Correspondência
                        </Checkbox>
                        <InputNumber
                            min={1}
                            max={100}
                            value={relations.find(f => f.type === 'CORRESPONDENT')?.ordem}
                            onChange={value => updateOrdem(relations.find(f => f.type === 'CORRESPONDENT')?.codigo!, value as number)}
                            placeholder="Ordem"
                        />
                    </Styled.RowBetween>
                    <Styled.RowBetween style={{ alignItems: 'center' }}>
                        <Checkbox
                            onChange={e =>
                                handleChange(
                                    {
                                        codigo: relations.find(f => f.type === 'CARROSSEL')?.codigo ?? -1,
                                        type: 'CARROSSEL',
                                        codigoTemplate: dados.codigo,
                                        ordem: 1,
                                    } as FadRelationsTemplateModel,
                                    e.target.checked
                                )
                            }
                            checked={Array.isArray(relations) && relations.some(r => r.type === 'CARROSSEL')}
                        >
                            Carrossel
                        </Checkbox>
                        <InputNumber
                            min={1}
                            max={100}
                            value={relations.find(f => f.type === 'CARROSSEL')?.ordem}
                            onChange={value => updateOrdem(relations.find(f => f.type === 'CARROSSEL')?.codigo!, value as number)}
                            placeholder="Ordem"
                        />
                    </Styled.RowBetween>
                    <Styled.RowBetween style={{ alignItems: 'center' }}>
                        <Checkbox
                            onChange={e =>
                                handleChange(
                                    {
                                        codigo: relations.find(f => f.type === 'ABAS')?.codigo ?? -1,
                                        type: 'ABAS',
                                        codigoTemplate: dados.codigo,
                                        ordem: 1,
                                    } as FadRelationsTemplateModel,
                                    e.target.checked
                                )
                            }
                            checked={Array.isArray(relations) && relations.some(r => r.type === 'ABAS')}
                        >
                            Abas
                        </Checkbox>
                        <InputNumber
                            min={1}
                            max={100}
                            value={relations.find(f => f.type === 'ABAS')?.ordem}
                            onChange={value => updateOrdem(relations.find(f => f.type === 'ABAS')?.codigo!, value as number)}
                            placeholder="Ordem"
                        />
                    </Styled.RowBetween>
                    <Styled.RowBetween style={{ alignItems: 'center' }}>
                        <Checkbox
                            onChange={e =>
                                handleChange(
                                    {
                                        codigo: relations.find(f => f.type === 'CARD_BARALHO')?.codigo ?? -1,
                                        type: 'CARD_BARALHO',
                                        codigoTemplate: dados.codigo,
                                        ordem: 1,
                                    } as FadRelationsTemplateModel,
                                    e.target.checked
                                )
                            }
                            checked={Array.isArray(relations) && relations.some(r => r.type === 'CARD_BARALHO')}
                        >
                            Baralho
                        </Checkbox>
                        <InputNumber
                            min={1}
                            max={100}
                            value={relations.find(f => f.type === 'CARD_BARALHO')?.ordem}
                            onChange={value => updateOrdem(relations.find(f => f.type === 'CARD_BARALHO')?.codigo!, value as number)}
                            placeholder="Ordem"
                        />
                    </Styled.RowBetween>
                    <Styled.RowBetween style={{ alignItems: 'center' }}>
                        <Checkbox
                            onChange={e =>
                                handleChange(
                                    {
                                        codigo: relations.find(f => f.type === 'TIMELINE')?.codigo ?? -1,
                                        type: 'TIMELINE',
                                        codigoTemplate: dados.codigo,
                                        ordem: 1,
                                    } as FadRelationsTemplateModel,
                                    e.target.checked
                                )
                            }
                            checked={Array.isArray(relations) && relations.some(r => r.type === 'TIMELINE')}
                        >
                            Linha do tempo
                        </Checkbox>
                        <InputNumber
                            min={1}
                            max={100}
                            value={relations.find(f => f.type === 'TIMELINE')?.ordem}
                            onChange={value => updateOrdem(relations.find(f => f.type === 'TIMELINE')?.codigo!, value as number)}
                            placeholder="Ordem"
                        />
                    </Styled.RowBetween>
                    <Styled.RowBetween style={{ alignItems: 'center' }}>
                        <Checkbox
                            onChange={e =>
                                handleChange(
                                    {
                                        codigo: relations.find(f => f.type === 'CENARIO')?.codigo ?? -1,
                                        type: 'CENARIO',
                                        codigoTemplate: dados.codigo,
                                        ordem: 1,
                                    } as FadRelationsTemplateModel,
                                    e.target.checked
                                )
                            }
                            checked={Array.isArray(relations) && relations.some(r => r.type === 'CENARIO')}
                        >
                            Cenário
                        </Checkbox>
                        <InputNumber
                            min={1}
                            max={100}
                            value={relations.find(f => f.type === 'CENARIO')?.ordem}
                            onChange={value => updateOrdem(relations.find(f => f.type === 'CENARIO')?.codigo!, value as number)}
                            placeholder="Ordem"
                        />
                    </Styled.RowBetween>
                </Styled.Column>
            ),
        },
        {
            key: '4',
            label: 'Extras',
            children: (
                <Styled.Column>
                    <Styled.RowBetween style={{ alignItems: 'center' }}>
                        <Checkbox
                            onChange={e =>
                                handleChange(
                                    {
                                        codigo: relations.find(f => f.type === 'AUDIO')?.codigo ?? -1,
                                        type: 'AUDIO',
                                        codigoTemplate: dados.codigo,
                                        ordem: 1,
                                    } as FadRelationsTemplateModel,
                                    e.target.checked
                                )
                            }
                            checked={Array.isArray(relations) && relations.some(r => r.type === 'AUDIO')}
                        >
                            Áudio
                        </Checkbox>
                        <InputNumber
                            min={1}
                            max={100}
                            value={relations.find(f => f.type === 'AUDIO')?.ordem}
                            onChange={value => updateOrdem(relations.find(f => f.type === 'AUDIO')?.codigo!, value as number)}
                            placeholder="Ordem"
                        />
                    </Styled.RowBetween>
                </Styled.Column>
            ),
        },
    ];

    return (
        <Styled.Card.Container>
            <Styled.RowBetween style={{ alignItems: 'center' }}>
                <Styled.Row style={{ alignItems: 'center' }}>
                    <Styled.Card.Title>{dados.name}</Styled.Card.Title>
                    <MdEdit size={18} cursor={'pointer'} color={Colors.Yellow} onClick={handleUpdate} />
                </Styled.Row>
                <MdDeleteForever size={22} cursor={'pointer'} color={Colors.DeleteRed} onClick={() => handleDeleteTemplate(dados.codigo)} />
            </Styled.RowBetween>

            <Collapse items={items} style={{ backgroundColor: '#FFF' }} bordered={false} />
        </Styled.Card.Container>
    );
};

interface IAdminFadTemplates {
    dados: FadTemplatesModel[];
}

const AdminFadTemplates = ({ dados }: IAdminFadTemplates) => {
    const [templates, setTemplates] = useState<FadTemplatesModel[]>(dados);
    const [formTemplate, setFormTemplate] = useState<FadTemplatesModel | null>(null);

    const [openEditTemplate, setEditTemplate] = useState<boolean>(false);

    //service
    const serviceTemplate = new FadTemplatesService();

    const handleNewTemplate = async () => {
        const date = new FadTemplatesModel().fromJSON({
            codigo: -1,
            name: 'Template',
        });

        const { data, status } = await serviceTemplate.saveTemplate(date);

        if (status === 201) {
            setTemplates((prev: any) => [...prev, data]);
        }
    };

    const deleteTemplate = async (codigo: number) => {
        const { status } = await serviceTemplate.delete(codigo);

        if (status === 204) {
            ToastSuccess('Template removido com sucesso!');
            setTemplates((prev: any) => prev.filter((t: FadTemplatesModel) => t.codigo !== codigo));
        }
    };

    const updateTemplate = async () => {
        if (formTemplate) {
            const { data, status } = await serviceTemplate.updateTemplate(formTemplate?.codigo, formTemplate);

            if (status === 200) {
                ToastSuccess('Template atualizado com sucesso!');

                setTemplates((prev: any) =>
                    prev.map((t: FadTemplatesModel) => {
                        if (t.codigo === formTemplate.codigo) {
                            return data;
                        }

                        return t;
                    })
                );

                setEditTemplate(false);
            }
        }
    };

    useEffect(() => {
        setTemplates(dados);
    }, [dados]);

    return (
        <Styled.Container>
            <Styled.RowEnd>
                <FAD_Button_Outline text="Criar um novo template" onClick={handleNewTemplate} />
            </Styled.RowEnd>
            <Styled.Row>
                {templates.map((template, i) => (
                    <CardTemplate
                        key={i}
                        dados={template}
                        handleDeleteTemplate={deleteTemplate}
                        handleUpdate={() => {
                            setFormTemplate(template);
                            setEditTemplate(true);
                        }}
                    />
                ))}
            </Styled.Row>
            <ModalFormTemplate
                open={openEditTemplate}
                setForm={setFormTemplate}
                form={formTemplate ?? new FadTemplatesModel()}
                handleEdit={updateTemplate}
                handleHidden={() => setEditTemplate(false)}
                loading={false}
            />
        </Styled.Container>
    );
};

export default AdminFadTemplates;
