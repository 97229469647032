import { FadCoursesModel } from 'models/ferramentaAutoria/FadCoursesModel';
import { GenericService } from '../Generic.service';

export class FadCoursesService extends GenericService {
    readonly URI: string = '/v1/fadCourses';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    async create(model: FadCoursesModel) {
        return await this.post('', model);
    }

    async update(codigo: number, model: FadCoursesModel) {
        return await this.put(`${codigo}`, model);
    }

    async deleteModel(codigo: number) {
        return await this.delete(codigo);
    }

    async findById(codigo: number) {
        return await this.get(`${codigo}`);
    }

    async findAllFadCourses() {
        return await this.get();
    }

    async findByCertificacao(certificacao: any) {
        return await this.get('byCertificacao', certificacao);
    }

    async findByCertificacaoCodigo(codigo: number) {
        return await this.get(`byCertificacaoCodigo/${codigo}`);
    }

    async findByName(name: string) {
        return await this.get(`byName/${name}`);
    }

    async findByUrl(url: string) {
        return await this.get(`byUrl/${url}`);
    }

    async findByTypeRedirect(typeRedirect: string) {
        return await this.get('byTypeRedirect', { typeRedirect });
    }

    async findBySequentialContents(sequentialContents: boolean) {
        return await this.get('bySequentialContents', { sequentialContents });
    }

    async findBySequentialSteps(sequentialSteps: boolean) {
        return await this.get('bySequentialSteps', { sequentialSteps });
    }

    async findByIsPublic(isPublic: boolean) {
        return await this.get('byIsPublic', { isPublic });
    }

    async findByOrdem(ordem: number) {
        return await this.get(`byOrdem/${ordem}`);
    }

    async findByUrlPublic(url: string) {
        return await this.get(`public/${url}`);
    }
}
