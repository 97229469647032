import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ComponentAbasModel from 'models/componentInteraction/ComponentAbas';
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md';
import Colors from 'enums/Colors';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import { TextDangerouslySetInnerHtml } from '../../RecursosConteudoPage.styled';

type TabContent = {
    id: number;
    title: string;
    description: string;
};

const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 22px 0px;
`;

const TabsWrapper = styled.div`
    display: flex;
    background-color: #f7f7f7;
    border-bottom: 1px solid #e0e0e0;
    align-items: center;
`;

const ArrowButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    font-size: 24px;
    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;

const Tabs = styled.div`
    display: flex;
    overflow: hidden;
    flex: 1;
`;

const Tab = styled.div<{ active?: boolean }>`
    flex: 1;
    padding: 16px;
    display: flex;
    justify-content: center;
    line-height: 22px;
    background-color: ${props => (props.active ? '#e0e0e0' : '#f7f7f7')};
    cursor: pointer;
    border-bottom: ${props => (props.active ? `3px solid ${Colors.Blue}` : 'none')};
    font-weight: ${props => (props.active ? 'bold' : 'normal')};
    font-size: 16px;
    color: ${Colors.Blue};
`;

const Content = styled.div`
    padding: 16px;
`;

interface IComponentAbasPublic {
    dados: ComponentAbasModel[] | null;
    openModal: (src: string) => void;
}

const ComponentAbasPublic = ({ dados, openModal }: IComponentAbasPublic) => {
    const [tabs, setTabs] = useState<TabContent[]>([]);
    const [activeTab, setActiveTab] = useState<number>(-1);
    const [startIndex, setStartIndex] = useState<number>(0);

    const isMobile = IsMobileMaxWidth();

    const handleTabChange = (id: number) => {
        setActiveTab(id);
    };

    const initializeTabs = (dados: ComponentAbasModel[]) => {
        const uniqueTabs = dados.reduce((acc: TabContent[], dado) => {
            if (!acc.some(tab => tab.id === dado.id)) {
                acc.push({
                    id: dado.id,
                    title: dado.title,
                    description: dado.description,
                });
            }
            return acc;
        }, []);
        setTabs(uniqueTabs);
    };

    const nextGroup = () => {
        setStartIndex(startIndex + (isMobile ? 1 : 3));
        setActiveTab(tabs[startIndex + (isMobile ? 1 : 3)].id);
    };

    const prevGroup = () => {
        setStartIndex(startIndex - (isMobile ? 1 : 3));
        setActiveTab(tabs[startIndex - (isMobile ? 1 : 3)].id);
    };

    useEffect(() => {
        if (dados && dados.length > 0) {
            initializeTabs(dados);
            setActiveTab(dados[0].id); // Definir a primeira aba como ativa
        }
    }, [dados]);

    const visibleTabs = tabs.slice(startIndex, startIndex + (isMobile ? 1 : 3));

    return (
        <Container>
            <TabsWrapper>
                <ArrowButton onClick={prevGroup} disabled={startIndex === 0}>
                    <MdArrowBackIos />
                </ArrowButton>
                <Tabs>
                    {visibleTabs.map(tab => (
                        <Tab
                            style={{ border: '1px solid rgb(240, 240, 240)' }}
                            key={tab.id}
                            active={tab.id === activeTab}
                            onClick={() => handleTabChange(tab.id)}
                        >
                            {tab.title}
                        </Tab>
                    ))}
                </Tabs>
                <ArrowButton onClick={nextGroup} disabled={startIndex + (isMobile ? 1 : 3) >= tabs.length}>
                    <MdArrowForwardIos />
                </ArrowButton>
            </TabsWrapper>
            <Content>
                {tabs.map(
                    tab =>
                        tab.id === activeTab && (
                            <div key={tab.id}>
                                <TextDangerouslySetInnerHtml
                                    style={{ margin: 0 }}
                                    onClick={e => {
                                        const target = e.target as HTMLImageElement;
                                        if (target.tagName === 'IMG') {
                                            openModal(target.src);
                                        }
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: tab.description ?? '',
                                    }}
                                />
                            </div>
                        )
                )}
            </Content>
        </Container>
    );
};

export default ComponentAbasPublic;
