import { Collapse, CollapseProps, ConfigProvider, Drawer } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ListItensMenuLateral from './ListItensMenuLateral';
import Colors from 'enums/Colors';
import ComponentInteractionSimpleModel from 'models/componentInteraction/ComponentInterationSimple';
import { ComponentInteractionEnum } from 'enums/ComponentInteractionEnum';
import { template } from 'lodash';
import { FadTemplatesModel } from 'models/ferramentaAutoria/FadTemplatesModel';
import useFetch from 'hooks/useFetch';
import { FadTemplatesService } from 'core/http/service/ferramentaAutoria/FadTemplates.service';
import { ImInsertTemplate } from 'react-icons/im';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        gap: 8px;
    `,

    Item: styled.div`
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;
        height: 48px;
        padding: 8px;
        border-radius: 8px;
        border: 0.5px 0px 0px 0px;
        opacity: 0px;
        cursor: pointer;
        border: 0.5px solid #d9d9d9;
        //styleName: Base/Legenda 2;
        font-family: 'Barlow';
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        transition: all 0.3s ease-out;

        &:hover {
            background-color: ${Colors.Blue};
            color: ${Colors.White};

            .iconItem {
                border: 0.2px solid #fff;
            }
        }
    `,

    IconItem: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 8px;
        border: 0.2px solid #000000;
    `,
};

interface IAdminFadMenuLateral {
    open: boolean;
    setHidden: () => void;
    handleCreateComponent: (tipo: ComponentInteractionEnum) => void;
    handleAddTemplate: (codigoTemplate: number) => void;
}

interface IItem {
    catergory: number;
    handleClick: (tipo: ComponentInteractionEnum) => void;
    handleClickNewTemplate?: (codigoTemplate: number) => void;
    isTemplate?: boolean;
}

const Item = ({ catergory, handleClick, isTemplate, handleClickNewTemplate }: IItem) => {
    const [templates, setTemplates] = useState<FadTemplatesModel[]>([]);

    //service
    const serviceFadTemplates = new FadTemplatesService();

    useFetch(async () => {
        if (isTemplate) {
            const { data, status } = await serviceFadTemplates.getAllTemplates();

            if (status === 200) {
                setTemplates(data);
            }
        }
    }, [isTemplate]);

    return (
        <Styled.Container>
            {!isTemplate &&
                ListItensMenuLateral.filter(f => f.category == catergory).map((l, i) => (
                    <Styled.Item key={i} onClick={() => handleClick(l.tipo)}>
                        <Styled.IconItem className="iconItem">{l.icon}</Styled.IconItem>
                        {l.title}
                    </Styled.Item>
                ))}

            {isTemplate &&
                templates.map((t, i) => (
                    <Styled.Item key={t.codigo} onClick={() => handleClickNewTemplate && handleClickNewTemplate(t.codigo)}>
                        <Styled.IconItem className="iconItem">{<ImInsertTemplate />}</Styled.IconItem>
                        {t.name}
                    </Styled.Item>
                ))}
        </Styled.Container>
    );
};

const AdminFadMenuLateral = ({ open, setHidden, handleCreateComponent, handleAddTemplate }: IAdminFadMenuLateral) => {
    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Blocos de textos',
            children: <Item catergory={1} handleClick={handleCreateComponent} />,
        },
        {
            key: '2',
            label: 'Avaliações',
            children: <Item catergory={2} handleClick={handleCreateComponent} />,
        },
        {
            key: '3',
            label: 'Interações',
            children: <Item catergory={3} handleClick={handleCreateComponent} />,
        },
        {
            key: '4',
            label: 'Expansíveis',
            children: <Item catergory={4} handleClick={handleCreateComponent} />,
        },
        {
            key: '5',
            label: 'Divisores',
            children: <Item catergory={5} handleClick={handleCreateComponent} />,
        },
        {
            key: '6',
            label: 'Extras',
            children: <Item catergory={0} handleClick={handleCreateComponent} />,
        },
        {
            key: '7',
            label: 'Templates',
            children: <Item catergory={99} handleClick={() => ''} handleClickNewTemplate={handleAddTemplate} isTemplate />,
        },
    ];

    return (
        <Drawer title="Menu de Criação" onClose={setHidden} open={open}>
            <Collapse items={items} style={{ backgroundColor: '#FFF' }} bordered={false} defaultActiveKey={['1', '2', '3', '4', '5', '6']} />
        </Drawer>
    );
};

export default AdminFadMenuLateral;
