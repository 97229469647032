import React, { useEffect, useState, useContext } from 'react';
import LogoRealizandoEscolhas from '../assets/images/logoRealizandoEscolhasDesktop.svg';
import {
    DefaultText,
    HomePageButton,
    IconHome,
    ImgSvgLogo,
    MenuItem,
    MenuItemText,
    RealizandoEscolhasHeaderStyle,
} from './RealizandoEscolhasHeader.styled';
import { useHistory, useLocation } from 'react-router-dom';
import { CSSProperties } from 'styled-components';
import Colors from 'enums/Colors';
import ModalDefault from 'pages/realizandoEscolhas/pages/components/ModalDefault';
import TrilhaFormativaModal from './TrilhaFormativaModal';
import {
    buscarCategoriaPorPerfil,
    buscarOcupacaoPorPerfil,
    redirectToInstrumento,
    redirectToTrilhaFormativaLMS,
} from 'pages/realizandoEscolhas/utils/RealizandoEscolhasUtils';
import UsuarioDTO from 'models/UsuarioDTO';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { GTM_Page_Realizando_Escolhas } from 'components/GoogleTagManager';
import HomeWhite from '../../../../images/home-white.svg';
import RealizandoEscolhasProvider from '../context/RealizandoEscolhasProvider';
import ModalBlocked from './ModalBlocked';
import ModalCheckGoToPage from 'components/modal/ModalCheckGoToPage';
import { RealizandoEscolhasContext } from '../context/RealizandoEscolhasContext';

const EscolhasRoute: string = '/realizandoEscolhas/escolhas';
const FundamentosRoute: string = '/realizandoEscolhas/fundamentos';
const TrilhaFormativaRoute: string = '/realizandoEscolhas/trilhaFormativa';
const InstrumentoRoute: string = '/realizandoEscolhas/avaliacao';

interface IRealizandoEscolhasHeader {}

const RealizandoEscolhasHeader = ({}: IRealizandoEscolhasHeader) => {
    const history = useHistory();
    const location = useLocation();
    const actualRoute = location.pathname;
    const [showTrilhaFormativaReqModal, setShowTrilhaFormativaReqModal] = useState(false);
    const [showModalBlocked, setShowModalBlocked] = useState(false);
    const profile = useAppSelector(state => state.profileReducer.userProfile);
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);
    const [openTrilha, setOpenTrilha] = useState(-1);
    const [ocupacao, setOcupacao] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const { isVerifyGoToPage, goToPage, showModalNavigate, setShowModalNavigate } = useContext(RealizandoEscolhasContext.Context);

    const handleAccessAvaliacao = async () => {
        const userOcupacao = buscarOcupacaoPorPerfil(profile);

        if (userOcupacao !== 'B') {
            await goToInstrumento();
        } else {
            setShowModalBlocked(true);
        }
    };

    const goToInstrumento = async () => {
        await redirectToInstrumento(
            (await buscarCategoriaPorPerfil(usuarioLogado)) ?? ('A' as string),
            setShowTrilhaFormativaReqModal,
            usuarioLogado,
            () => {
                GTM_Page_Realizando_Escolhas(
                    'header_desktop',
                    `acessar_page_${InstrumentoRoute.replace('/realizandoEscolhas/', '').toLowerCase()}`
                );
                history.push(InstrumentoRoute);
            }
        );
    };

    const goToTrilhaFormativaLMS = async (role: string, is9ano: boolean) => {
        const urlRedirect = await redirectToTrilhaFormativaLMS(role, false, usuarioLogado, true);
        GTM_Page_Realizando_Escolhas('header_desktop', 'acessar_page_trilha_formativa');
        setShowTrilhaFormativaReqModal(false);
        setOpenTrilha(-1);
        history.push(urlRedirect);
    };

    const goToLandingpage = () => {
        GTM_Page_Realizando_Escolhas('header_desktop', 'acessar_home');
        isVerifyGoToPage('/');
    };

    const goToHomePage = () => {
        GTM_Page_Realizando_Escolhas('header_desktop', 'acessar_page_home');
        isVerifyGoToPage('/realizandoEscolhas/home');
    };

    function selectedStyle(route: string | undefined): CSSProperties {
        let style: CSSProperties;

        if (actualRoute === route) {
            style = {
                color: Colors.White,
                cursor: 'pointer',
                fontWeight: '700',
                textDecoration: 'underline',
                textDecorationThickness: '6px',
                textUnderlineOffset: '21px',
            };
        } else {
            style = {
                color: Colors.White,
            };
        }
        return style;
    }

    const goToTrilhaFormativa = async () => {
        goToTrilhaFormativaLMS((await buscarCategoriaPorPerfil(usuarioLogado)) ?? ('A' as string), openTrilha === 1 ? true : false);
    };

    useEffect(() => {
        if (openTrilha !== -1) goToTrilhaFormativa();
    }, [openTrilha]);

    useEffect(() => {
        setOcupacao(buscarOcupacaoPorPerfil(profile) === 'A' || buscarOcupacaoPorPerfil(profile) === 'B' ? true : false);
    }, []);

    return (
        <RealizandoEscolhasHeaderStyle>
            <ModalCheckGoToPage
                showModal={showModalNavigate}
                setShowModal={setShowModalNavigate}
                goToPage={goToPage}
                isRealizandoEscolhas={true}
            />

            <ImgSvgLogo src={LogoRealizandoEscolhas} onClick={goToHomePage} />
            <MenuItem>
                <MenuItemText style={selectedStyle(EscolhasRoute)} onClick={goToHomePage}>
                    <IconHome src={HomeWhite} alt="" />
                </MenuItemText>
                <MenuItemText style={selectedStyle(EscolhasRoute)} onClick={() => isVerifyGoToPage(EscolhasRoute)}>
                    {'Por que falar de escolhas?'}
                </MenuItemText>
                <MenuItemText style={selectedStyle(FundamentosRoute)} onClick={() => isVerifyGoToPage(FundamentosRoute)}>
                    {'Fundamentos da ciência'}
                </MenuItemText>
                {ocupacao && (
                    <>
                        <MenuItemText style={selectedStyle(TrilhaFormativaRoute)} onClick={() => isVerifyGoToPage(TrilhaFormativaRoute)}>
                            {'Trilha formativa'}
                        </MenuItemText>
                        <MenuItemText style={selectedStyle(InstrumentoRoute)} onClick={handleAccessAvaliacao}>
                            {'Avaliação'}
                        </MenuItemText>
                    </>
                )}

                <HomePageButton onClick={goToLandingpage}>
                    <DefaultText>{`Pagina inicial humane`}</DefaultText>
                </HomePageButton>
            </MenuItem>

            <ModalDefault show={showTrilhaFormativaReqModal}>
                <TrilhaFormativaModal setShow={setShowTrilhaFormativaReqModal} goToTrilhaFormativaCallback={setOpenTrilha} />
            </ModalDefault>
            <ModalDefault show={showModalBlocked}>
                <ModalBlocked setShow={setShowModalBlocked} goToTrilhaFormativaCallback={() => ''} />
            </ModalDefault>
        </RealizandoEscolhasHeaderStyle>
    );
};
export default RealizandoEscolhasHeader;
