import { jsonProperty, Serializable, SerializationSettings } from "ts-serializable";
import { FadUserConcluidModulesModel } from "../FadUserConcluidModulesModel";
import { FadContentsPublicModel } from "./FadContentsPublicModel";
import { FadModulesAnexoModel } from "../FadModulesAnexoModel";

interface IFadModulesPublicModel{
    codigo: number;
    codigoFerramentaAutoriaModule: number;
    title: string;
    isPublic: boolean;
    ordem: number;
    progress: FadUserConcluidModulesModel,
    conteudos: FadContentsPublicModel[],
    anexo: FadModulesAnexoModel

}

export class FadModulesPublicModel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number)
    codigoFerramentaAutoriaModule: number = -1;

    @jsonProperty(String)
    title: string = '';

    @jsonProperty(String)
    description: string = '';

    @jsonProperty(Boolean)
    isPublic: boolean = false;

    @jsonProperty(Number)
    ordem: number = -1;

    progress: FadUserConcluidModulesModel = new FadUserConcluidModulesModel();

    conteudos: FadContentsPublicModel[] = [];

    anexo: FadModulesAnexoModel = new FadModulesAnexoModel();

    fromJSON(json: IFadModulesPublicModel, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.codigoFerramentaAutoriaModule = json.codigoFerramentaAutoriaModule;
        this.title = json.title;
        this.isPublic = json.isPublic;
        this.ordem = json.ordem;
        this.progress = json.progress;
        this.conteudos = json.conteudos;
        this.anexo = json.anexo;
        return this;
    }
}