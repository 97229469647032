import { jsonProperty, Serializable, SerializationSettings } from 'ts-serializable';

interface IFadUserAvaliationsContentsModel {
    codigo: number;
    codigoFerramentaAutoriaContent: number;
    codigoFerramentaAutoriaClasse: number;
    rate: number;
    codigoPerfilUsuario: string;
    dateCreated: string; // LocalDateTime será tratado como string no TypeScript
}

export class FadUserAvaliationsContentsModel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number)
    codigoFerramentaAutoriaContent: number = -1;

    @jsonProperty(Number)
    codigoFerramentaAutoriaClasse: number = -1;

    @jsonProperty(Number)
    rate: number = -1;

    @jsonProperty(String)
    codigoPerfilUsuario: string = '';

    @jsonProperty(String)
    dateCreated: string = '';

    fromJSON(json: IFadUserAvaliationsContentsModel, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.codigoFerramentaAutoriaContent = json.codigoFerramentaAutoriaContent;
        this.codigoFerramentaAutoriaClasse = json.codigoFerramentaAutoriaClasse;
        this.rate = json.rate;
        this.codigoPerfilUsuario = json.codigoPerfilUsuario;
        this.dateCreated = json.dateCreated;
        return this;
    }
}
