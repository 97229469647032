import { Button, Divider, Input, Modal, Popconfirm, Upload } from 'antd';
import { ToastSuccess } from 'components/Toaster';
import { ComponentCenarioService } from 'core/http/service/componetInteraction/ComponentCenario.service';
import Colors from 'enums/Colors';
import { ComponentCenario } from 'models/componentInteraction/ComponentCenario';
import { ComponentCenarioAlternativa } from 'models/componentInteraction/ComponentCenarioAlternativa';
import { ComponentCenarioGlobal } from 'models/componentInteraction/ComponentCenarioGlobal';
import { ComponentInteractionAnexo } from 'models/componentInteraction/ComponentInteractionAnexo';
import editorConfig from 'pages/diarioBordo/components/textEditor/editorConfig';
import React, { useEffect, useState } from 'react';
import { FiCheckCircle } from 'react-icons/fi';
import { MdDeleteForever, MdSaveAs } from 'react-icons/md';
import ReactQuill from 'react-quill';
import styled from 'styled-components';
import { UploadOutlined } from '@ant-design/icons';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 27px 12px;
    `,

    Title: styled.h2`
        //styleName: Base/Legenda 2;
        font-family: 'Barlow';
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: ${Colors.Blue};
    `,

    Alternativa: {
        Content: styled.div`
            display: flex;
            align-items: center;
            gap: 8px;
        `,

        Column: styled.div`
            display: flex;
            flex-direction: column;
            width: 50%;
            gap: 8px;
            margin-bottom: 42px;
        `,
    },

    RowBetteween: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    `,

    RowEnd: styled.div`
        display: flex;
        align-items: center;
        justify-content: end;
        width: 100%;
        gap: 1rem;
    `,

    StyledUpload: styled(Upload)`
        display: flex;
        flex-direction: column;
        align-items: center;
    `,

    ImagePreview: styled.img`
        width: 720px;
        max-height: 400px;
        margin-top: 16px;
        object-fit: cover;
    `,

    Navigation: {
        Arrow: styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            margin-right: 10px;
            width: 62px;
            border-radius: 5px;
            border: 1px solid #e6e6e6;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                border: 1px solid ${Colors.Blue};
            }
        `,

        ItemFooter: styled.div<{ isActive: boolean }>`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 4px;
            align-items: center;
            justify-content: center;
            border: 1px solid ${Colors.Blue};
            color: ${({ isActive }) => (isActive ? Colors.White : Colors.Blue)};
            background-color: ${({ isActive }) => (isActive ? Colors.Blue : Colors.White)};
            cursor: pointer;
        `,
    },
};

interface IComponentCenarioAdmin {
    dados: ComponentCenarioGlobal | null;
    codigoInteraction: number;
}

const beforeUpload = (file: File) => {
    const isImage = file.type.startsWith('image/');
    const isValidSize = file.size / 1024 / 1024 < 2; // Max 2MB

    if (!isImage) {
        alert('Você só pode fazer upload de imagens!');
    }

    if (!isValidSize) {
        alert('A imagem deve ser menor que 2MB!');
    }

    return isImage && isValidSize;
};

const ComponentCenarioAdmin = ({ dados, codigoInteraction }: IComponentCenarioAdmin) => {
    const [cenarios, setCenarios] = useState<ComponentCenario[]>(dados?.cenarios ?? []);
    const [anexo, setAnexo] = useState<ComponentInteractionAnexo>(dados?.anexo ?? new ComponentInteractionAnexo());
    const [indexCenario, setIndexCenario] = useState<number>(0);

    const [fileList, setFileList] = useState<any[]>([]);
    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const [base64Image, setBase64Image] = useState<string | null>(null);

    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const service = new ComponentCenarioService();

    const handleSave = async () => {
        setLoadingSave(true);

        const global = new ComponentCenarioGlobal().fromJSON({
            cenarios: cenarios,
            anexo:
                anexo !== null
                    ? anexo
                    : new ComponentInteractionAnexo().fromJSON({
                          id: -1,
                          filepath: '',
                          base64: base64Image ?? '',
                      }),
        });

        const { data, status } = await service.insert(codigoInteraction, global);

        if (status === 201) {
            ToastSuccess('Cenário salvo com sucesso!');

            setCenarios(data.cenarios);
            setAnexo(data.anexo);
        }

        setLoadingSave(false);
    };

    const handleChangeImg = (info: any) => {
        if (info.fileList.length > 0) {
            setFileList(info.fileList);

            // Realiza o preview da imagem e converte para Base64
            const file = info.fileList[info.fileList.length - 1].originFileObj;

            const reader = new FileReader();
            reader.onload = e => {
                const base64String = e.target?.result as string;
                setBase64Image(base64String); // Armazena a imagem em Base64
                setPreviewImage(base64String);
                setAnexo((prev: any) => ({ ...prev, base64: base64String }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleNewAlt = () => {
        setCenarios((prev: any) =>
            prev.map((c: ComponentCenario, i: number) => {
                if (i === indexCenario) {
                    const alternativas = [...c.alternativas, new ComponentCenarioAlternativa()];

                    return {
                        ...c,
                        alternativas: alternativas,
                    };
                }

                return c;
            })
        );
    };

    const handleNewCenario = () => {
        setCenarios([
            ...cenarios,
            new ComponentCenario().fromJSON({
                id: -1,
                title: '',
                alternativas: [
                    new ComponentCenarioAlternativa().fromJSON({
                        id: -1,
                        text: '',
                        feedback: '',
                    }),
                ],
            }),
        ]);
        setIndexCenario(indexCenario + 1);
    };

    const handleTitle = (value: string) => {
        setCenarios((prev: any) =>
            prev.map((c: ComponentCenario, i: number) => {
                if (i === indexCenario) {
                    return {
                        ...c,
                        title: value,
                    };
                }

                return c;
            })
        );
    };
    const handleTextsAltenativa = (indexAlt: number, value: string, isFeedback: boolean) => {
        setCenarios((prev: any) =>
            prev.map((c: ComponentCenario, i: number) => {
                if (i === indexCenario) {
                    // Mapeia as alternativas para criar um novo array
                    const novasAlternativas = c.alternativas.map((alt, index: number) => {
                        if (index === indexAlt) {
                            return {
                                ...alt,
                                text: !isFeedback ? value : alt.text,
                                feedback: isFeedback ? value : alt.feedback,
                            };
                        }
                        return alt;
                    });

                    // Retorna o componente de cenário com as novas alternativas
                    return {
                        ...c,
                        alternativas: novasAlternativas,
                    };
                }

                return c; // Retorna o cenário não modificado
            })
        );
    };

    const handleRemoveAlternative = async (id: number, index: number) => {
        if (id !== -1) {
            const { status } = await service.deleteAlternativa(id);

            if (status === 204) {
                ToastSuccess('Alternativa removida com sucesso!');
            }
        }

        setCenarios((prev: any) =>
            prev.map((c: ComponentCenario, i: number) => {
                if (i === indexCenario) {
                    // Filtra as alternativas, mantendo apenas as que não têm o id correspondente

                    const novasAlternativas =
                        id !== -1
                            ? c.alternativas.filter((alt: ComponentCenarioAlternativa) => alt.id !== id)
                            : c.alternativas.filter((_, i: number) => i !== index);

                    // Retorna o componente de cenário com as novas alternativas
                    return {
                        ...c,
                        alternativas: novasAlternativas,
                    };
                }

                return c; // Retorna o cenário não modificado
            })
        );
    };

    const handleRemoveCenarioById = async () => {
        const id = cenarios[indexCenario].id;

        if (id != -1) {
            const { status } = await service.deleteCenario(id);

            if (status === 204) {
                ToastSuccess('Cenário removido com sucesso!');
            }
        }

        setCenarios((prev: any) =>
            prev.filter((c: ComponentCenario, i: number) =>
                cenarios[indexCenario].id !== -1 ? c.id !== cenarios[indexCenario].id : i !== indexCenario
            )
        );

        setIndexCenario(indexCenario - 1);
    };

    useEffect(() => {
        if (dados && dados.cenarios.length > 0 && dados.anexo != null) {
            setCenarios(dados.cenarios);
            setAnexo(dados.anexo);
            setPreviewImage(dados.anexo.base64 ?? '');
        } else {
            const newComponent = new ComponentCenarioGlobal().fromJSON({
                cenarios: [
                    new ComponentCenario().fromJSON({
                        id: -1,
                        title: '',
                        alternativas: [
                            new ComponentCenarioAlternativa().fromJSON({
                                id: -1,
                                text: '',
                                feedback: '',
                            }),
                        ],
                    }),
                ],
                anexo: new ComponentInteractionAnexo(),
            });

            setCenarios(newComponent.cenarios);
            setAnexo(newComponent.anexo);
        }
    }, [dados]);

    return (
        <Styled.Container>
            <Styled.Title>Cenário</Styled.Title>
            {previewImage && (
                <div>
                    <Styled.ImagePreview src={previewImage} alt="Preview" />
                </div>
            )}
            <Styled.StyledUpload
                listType="picture-card"
                fileList={fileList}
                onChange={handleChangeImg}
                beforeUpload={beforeUpload}
                showUploadList={false}
            >
                <UploadOutlined />
                <div>Carregar Imagem</div>
            </Styled.StyledUpload>
            {cenarios.map((c, i) => {
                if (i === indexCenario) {
                    return (
                        <>
                            <ReactQuill
                                style={{ marginBottom: '20px' }}
                                theme="snow"
                                placeholder="Título do Cenário."
                                modules={editorConfig.modules2}
                                formats={editorConfig.formats2}
                                value={c.title}
                                onChange={val => {
                                    handleTitle(val);
                                }}
                            />

                            <Styled.Title>Alternativas do cenário</Styled.Title>
                            <Divider />

                            {c.alternativas.map((alt, indexAlt) => (
                                <>
                                    <Styled.Alternativa.Content key={indexAlt}>
                                        <Styled.Alternativa.Column>
                                            <Styled.Title>Alternativa {indexAlt + 1}</Styled.Title>
                                            <Input
                                                showCount
                                                maxLength={200}
                                                style={{ height: 60, fontSize: 22 }}
                                                value={alt.text}
                                                onChange={e => handleTextsAltenativa(indexAlt, e.target.value, false)}
                                            />
                                            <Styled.Title>Feedback</Styled.Title>
                                            <ReactQuill
                                                theme="snow"
                                                placeholder={`Feedback da altenativa ${indexAlt + 1}`}
                                                modules={editorConfig.modules2}
                                                formats={editorConfig.formats2}
                                                value={alt.feedback}
                                                onChange={val => {
                                                    handleTextsAltenativa(indexAlt, val, true);
                                                }}
                                            />
                                        </Styled.Alternativa.Column>

                                        {indexAlt !== 0 && (
                                            <Popconfirm
                                                title="Remover alternativa."
                                                description="Você tem certeza que deseja remover a alternativa?"
                                                onConfirm={() => handleRemoveAlternative(alt.id, indexAlt)}
                                                onCancel={() => ''}
                                                okText="Sim"
                                                cancelText="Não"
                                            >
                                                <MdDeleteForever size={32} color={Colors.DeleteRed} cursor={'pointer'} />
                                            </Popconfirm>
                                        )}
                                    </Styled.Alternativa.Content>
                                </>
                            ))}
                        </>
                    );
                }
            })}

            <Styled.RowBetteween>
                <Button size="large" style={{ width: 200 }} onClick={handleNewAlt}>
                    Adicionar + 1
                </Button>
                <Button size="large" style={{ width: 200 }} onClick={handleNewCenario}>
                    Adicionar novo cenário
                </Button>
                {indexCenario !== 0 && (
                    <Button size="large" style={{ width: 200, color: Colors.DeleteRed }} onClick={() => handleRemoveCenarioById()}>
                        <MdDeleteForever /> Remover cenário
                    </Button>
                )}
                <Button size="large" style={{ width: 200 }} onClick={handleSave} loading={loadingSave}>
                    <MdSaveAs /> Salvar
                </Button>
                <Styled.Alternativa.Column>
                    <p>Navegue entre os cenários:</p>
                    <div style={{ display: 'flex', gap: '8px' }}>
                        {Array.from({ length: cenarios.length === 0 ? 1 : cenarios.length }).map((_, index) => (
                            <Styled.Navigation.ItemFooter onClick={() => setIndexCenario(index)} isActive={indexCenario == index} key={index}>
                                {index + 1}
                            </Styled.Navigation.ItemFooter>
                        ))}
                    </div>
                </Styled.Alternativa.Column>
            </Styled.RowBetteween>
        </Styled.Container>
    );
};

export default ComponentCenarioAdmin;
