import { jsonProperty, Serializable, SerializationSettings } from "ts-serializable";

interface IFadModulesAnexoModel {
    codigo: number;
    codigoFerramentaAutoriaModule: number;
    filepath: string;
    base64: string;
}

export class FadModulesAnexoModel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number)
    codigoFerramentaAutoriaModule: number = -1;

    @jsonProperty(String)
    filepath: string = "";

    @jsonProperty(String)
    base64: string = "";

    fromJSON(json: IFadModulesAnexoModel, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.codigoFerramentaAutoriaModule = json.codigoFerramentaAutoriaModule;
        this.filepath = json.filepath;
        this.base64 = json.base64;
        return this;
    }
}