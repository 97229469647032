import { jsonProperty, Serializable, SerializationSettings } from 'ts-serializable';
import { FadCoursesAnexoModel } from '../FadCourseAnexoModel';
import { FadCoursesModel } from '../FadCoursesModel';
import { FadModulesPublicModel } from './FadModulesPublicModel';
import { CertificacoesModel } from 'models/certificacoes/CertificacoesModel';

interface IFadCoursesPublicModel {
    course: FadCoursesModel;
    anexo: FadCoursesAnexoModel;
    haveAccess: boolean;
}

export class FadCoursesPublicModel extends Serializable {
    course: FadCoursesModel = new FadCoursesModel();

    anexo: FadCoursesAnexoModel = new FadCoursesAnexoModel();

    modulos: FadModulesPublicModel[] = [];

    certificacao: CertificacoesModel = new CertificacoesModel();

    haveAccess: boolean = false;

    fromJSON(json: IFadCoursesPublicModel, settings?: Partial<SerializationSettings>): this {
        this.course = json.course;
        this.anexo = json.anexo;
        this.haveAccess = json.haveAccess;
        return this;
    }
}
