import React, { useEffect, useState } from 'react';
import Styled from './CardTrilha.styled';
import { CardDeck, TrilhaFormativaButton as ButtonYellow } from '../trilhaFormativa/TrilhaFormativa.styled';
import Card from './Card';
import IconFile from '../components/assets/images/icon-file.svg';
import { GTM_Page_Realizando_Escolhas } from 'components/GoogleTagManager';
import {
    buscarCategoriaPorPerfil,
    redirectToInstrumento,
    redirectToTrilhaFormativaLMS,
} from 'pages/realizandoEscolhas/utils/RealizandoEscolhasUtils';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import UsuarioDTO from 'models/UsuarioDTO';
import ModalDefault from './ModalDefault';
import TrilhaFormativaModal from 'pages/realizandoEscolhas/home/components/TrilhaFormativaModal';
import { useHistory } from 'react-router-dom';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import { base64PDFToFile } from 'pages/admin/utils/base64ToFile';
import { RealizandoEscolhasService } from 'core/http/service/RealizandoEscolhas.service';
import { isMobileDevice } from 'react-select/src/utils';
import ModalBlocked from 'pages/realizandoEscolhas/home/components/ModalBlocked';
import { findUserProfileByUsername } from 'store/actions/Profile';
import { ToastError } from 'components/Toaster';
import { cursoRealizandoEscolhasGestor } from 'constants/EnvironmentData';
export interface ICardsContainer {
    perfil: String;
    tabs: String[];
    modulos: any[];
    download: any[];
}

enum TipoModelo {
    modeloPedagogico = 'ModeloPedagogico',
    orietacoesPedagogicas = 'OrientacoesPedagogicas',
}

interface IMobile {
    isMobile: boolean;
}

const CardTrilha = ({ perfil, tabs, modulos, download }: ICardsContainer) => {
    const history = useHistory();
    const [tabsActive, setTabsActive] = useState(perfil === 'A' ? 1 : 0);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
    const [userRole, setUserRole] = useState('');
    const [showTrilhaFormativaReqModal, setShowTrilhaFormativaReqModal] = useState(false);
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);
    const profile = useAppSelector(state => state.profileReducer.userProfile);
    const [showModalBlocked, setShowModalBlocked] = useState(false);
    const [idDownload, setIdDownload] = useState<number>(-1);
    const [idTrilha, setIdTrilha] = useState<number>(-1);

    const goToTrilhaFormativaLMS = async (role: string, is9Ano: boolean) => {
        const urlRedirect = await redirectToTrilhaFormativaLMS(role, is9Ano, usuarioLogado, true);
        GTM_Page_Realizando_Escolhas('trilha_formativa_realizando_escolhas', 'acessar_trilha_formativa_lms');
        history.push(urlRedirect);
    };

    const goToInstrumento = async () => {
        if (perfil === 'A') {
            redirectToInstrumento(userRole, setShowTrilhaFormativaReqModal, usuarioLogado, () => {
                GTM_Page_Realizando_Escolhas('trilha_formativa_realizando_escolhas', 'acessar_instrumento');
                history.push('/realizandoEscolhas/avaliacao');
            });
        } else {
            setShowModalBlocked(true);
        }
    };

    const goToTrilhaGestor = () => {
        window.open(cursoRealizandoEscolhasGestor());
        setShowModalBlocked(false);
    };

    const RenderButtons = ({ isMobile }: IMobile): JSX.Element => {
        const trilhas =
            perfil === 'A' && tabsActive === 1
                ? ['Acessar trilha Ensino Médio']
                : perfil === 'A' && tabsActive === 0
                ? ['Acessar trilha 9º Ano']
                : perfil === 'A' && tabsActive === 2
                ? ['Acessar trilha 9º Ano', 'Acessar trilha Ensino Médio']
                : ['Acessar trilha Gestor Escolar'];

        return (
            <Styled.DivRow isMobile={isMobile}>
                {trilhas.map((trilha, index) => (
                    <Styled.ButtonYellow
                        isMobile={IsMobileMaxWidth()}
                        key={index}
                        style={{
                            margin: '10px',
                            height: '60px',
                        }}
                        disabled={loading && idTrilha === index}
                        onClick={() => {
                            setIdTrilha(index);
                            goToTrilhaFormativaLMS(userRole, trilha === 'Acessar trilha 9º Ano');
                        }}
                    >
                        {loading && idTrilha === index ? 'Carregando...' : trilha}
                    </Styled.ButtonYellow>
                ))}
                <Styled.ButtonNext isMobile={IsMobileMaxWidth()} onClick={() => goToInstrumento()}>
                    {'Acessar avaliação'}
                </Styled.ButtonNext>
            </Styled.DivRow>
        );
    };

    const getCategoryByRole = async () => {
        if (usuarioLogado) {
            setUserRole((await buscarCategoriaPorPerfil(usuarioLogado)) ?? ('A' as string));
        }
    };

    const handleDownloadPDF = async (modelo: TipoModelo) => {
        setLoadingDownload(true);
        const service = new RealizandoEscolhasService();
        try {
            let response;

            if (modelo === TipoModelo.modeloPedagogico) {
                response = await service.buscarModeloPedagogicoBase64Encoded();
            } else {
                response = await service.buscarCadernoGestao64Encoded();
            }
            if (response.status === 200) {
                base64PDFToFile(response.data, modelo === TipoModelo.modeloPedagogico ? download[0].titlePDF : download[1].titlePDF);
            } else {
                ToastError('Aconteceu algum erro ao tentar baixar o material');
            }
        } catch (ex: any) {
            ToastError('Aconteceu algum erro ao tentar baixar o material');
        }
        setLoadingDownload(false);
    };

    useEffect(() => {
        getCategoryByRole();
    }, []);

    return (
        <Styled.Container>
            <Styled.Header isMobile={IsMobileMaxWidth()} isProf={perfil === 'A' ? true : false}>
                {tabs.map((title, index) => (
                    <Styled.Tabs key={index} onClick={() => setTabsActive(index)}>
                        <Styled.TabsLabel isActive={index === tabsActive}>{title}</Styled.TabsLabel>
                        <Styled.Marcador isMobile={IsMobileMaxWidth()} isActive={index === tabsActive} />
                    </Styled.Tabs>
                ))}
            </Styled.Header>

            <Styled.ContainerModulos tabActive={tabsActive} isProf={perfil === 'A' ? true : false} isMobile={IsMobileMaxWidth()}>
                <CardDeck>
                    {perfil === 'A' ? (
                        <>
                            {modulos[tabsActive]?.map((d: any, index: number) => (
                                <Card key={index} title={`${d.title} ${d.subTitle}`} description={d.description} isFundamentos={false} />
                            ))}

                            {tabsActive === tabs.length - 1 &&
                                download?.map((d: any, index: number) => (
                                    <Styled.CardDownload key={index}>
                                        <Styled.IconCard src={IconFile} />
                                        <Styled.TitleCardDownload>{d.title}</Styled.TitleCardDownload>
                                        <Styled.ButtonDownload
                                            disabled={loadingDownload}
                                            onClick={() => {
                                                handleDownloadPDF(index === 0 ? TipoModelo.modeloPedagogico : TipoModelo.orietacoesPedagogicas);
                                            }}
                                        >
                                            {loadingDownload ? 'Carregando...' : 'Baixar material'}
                                        </Styled.ButtonDownload>
                                    </Styled.CardDownload>
                                ))}
                        </>
                    ) : (
                        <>
                            {tabsActive === 0 &&
                                modulos?.map((d: any, index: number) => (
                                    <Card key={index} title={`${d.title} ${d.subTitle}`} description={d.description} isFundamentos={false} />
                                ))}
                            {tabsActive === 1 &&
                                download?.map((d: any, index: number) => (
                                    <Styled.CardDownload key={index}>
                                        <Styled.IconCard src={IconFile} />
                                        <Styled.TitleCardDownload>{d.title}</Styled.TitleCardDownload>
                                        <Styled.ButtonDownload
                                            disabled={loadingDownload && idDownload === index}
                                            onClick={() => {
                                                setIdDownload(index);
                                                handleDownloadPDF(index === 0 ? TipoModelo.modeloPedagogico : TipoModelo.orietacoesPedagogicas);
                                            }}
                                        >
                                            {loadingDownload && idDownload === index ? 'Carregando...' : 'Baixar material'}
                                        </Styled.ButtonDownload>
                                    </Styled.CardDownload>
                                ))}
                        </>
                    )}
                </CardDeck>
            </Styled.ContainerModulos>
            <br />
            <RenderButtons isMobile={IsMobileMaxWidth()} />

            <ModalDefault show={showModalBlocked}>
                <ModalBlocked setShow={setShowModalBlocked} goToTrilhaFormativaCallback={() => goToTrilhaGestor()} />
            </ModalDefault>

            <ModalDefault show={showTrilhaFormativaReqModal}>
                <TrilhaFormativaModal
                    setShow={setShowTrilhaFormativaReqModal}
                    goToTrilhaFormativaCallback={(trilha, is9ano) => {
                        goToTrilhaFormativaLMS(userRole, is9ano);
                        setShowTrilhaFormativaReqModal(false);
                    }}
                />
            </ModalDefault>
        </Styled.Container>
    );
};

export default CardTrilha;
