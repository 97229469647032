import { jsonProperty, Serializable, SerializationSettings } from 'ts-serializable';

interface IComponentInteractionAnexo {
    id: number;
    filepath: string;
    base64: string;
    codigoComponent?: number;
}

export class ComponentInteractionAnexo extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    @jsonProperty(Number, null)
    codigoComponent: number | null = -1;

    @jsonProperty(String)
    filepath: string = '';

    @jsonProperty(String)
    base64: string = '';

    fromJSON(json: IComponentInteractionAnexo, settings?: Partial<SerializationSettings>): this {
        this.id = json.id;
        this.filepath = json.filepath;
        this.base64 = json.base64;
        this.codigoComponent = json.codigoComponent ?? null;

        return this;
    }
}
