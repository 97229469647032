import FerramentaAutoriaProvider from 'pages/ferramentaAutoria/context/FerramentaAutoriaProvider';
import { Route } from 'react-router-dom';
import React from 'react';
import FadPage from './FadPage';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import FadCoursePage from './FadCoursePage';
import FadConentsPage from './FadContentsPage';

const FadCourseRouter = () => {
    return (
        <FerramentaAutoriaProvider>
            <DynamicHeader />
            <Route path={'/cursos/:page'} exact component={() => <FadPage />} />
            <Route path={'/cursos/:page/:curso'} exact component={() => <FadCoursePage />} />
            <Route path={'/cursos/:page/:curso/conteudos'} exact component={() => <FadConentsPage />} />
        </FerramentaAutoriaProvider>
    );
};

export default FadCourseRouter;
