import { Collapse, Input } from 'antd';
import { ComponentAbasService } from 'core/http/service/componetInteraction/ComponentAbas.service';
import Colors from 'enums/Colors';
import ComponentAbasModel from 'models/componentInteraction/ComponentAbas';
import editorConfig from 'pages/diarioBordo/components/textEditor/editorConfig';
import React, { useEffect, useState } from 'react';
import { RiDeleteBack2Fill } from 'react-icons/ri';
import ReactQuill from 'react-quill';
import styled from 'styled-components';

type TabContent = {
    id: number;
    title: string;
    description: string;
};

const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Tabs = styled.div`
    display: flex;
    background-color: #f7f7f7;
    border-bottom: 1px solid #e0e0e0;
`;

const Tab = styled.div<{ active?: boolean }>`
    flex: 1;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${props => (props.active ? '#e0e0e0' : '#f7f7f7')};
    cursor: pointer;
    border-bottom: ${props => (props.active ? '3px solid #333' : 'none')};

    input {
        border: none;
        background: transparent;
        font-weight: ${props => (props.active ? 'bold' : 'normal')};
        width: 80%;
        font-size: 16px;
        color: #333;
    }
`;

const DeleteButton = styled.button`
    background: none;
    border: none;
    color: red;
    cursor: pointer;
    font-weight: bold;
`;

const Content = styled.div`
    padding: 16px;
`;

const AddTabForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    border-top: 1px solid #e0e0e0;
    background-color: #f5f5f5;

    input,
    textarea {
        width: 100%;
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
    }
`;

const AddButton = styled.button`
    padding: 8px 16px;
    height: 40px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;

    &:hover {
        background-color: #555;
    }
`;

interface IComponentAbasAdmin {
    dados: ComponentAbasModel[] | null;
    handleAtualizationAbas: (dados: ComponentAbasModel[]) => void;
}

const ComponentAbasAdmin = ({ dados, handleAtualizationAbas }: IComponentAbasAdmin) => {
    const [tabs, setTabs] = useState<TabContent[]>([]);
    const [activeTab, setActiveTab] = useState<number>(1);
    const [newTabTitle, setNewTabTitle] = useState<string>('');
    const [newTabContent, setNewTabContent] = useState<string>('');
    const service = new ComponentAbasService();

    const handleTabChange = (id: number) => {
        setActiveTab(id);
    };

    const handleAddTab = () => {
        const newTab = new ComponentAbasModel();
        newTab.id = Date.now() % 1000000;
        newTab.title = newTabTitle;
        newTab.description = newTabContent;

        setTabs([...tabs, { id: newTab.id, title: newTab.title, description: newTab.description }]);
        setNewTabTitle('');
        setNewTabContent('');
        handleTabChange(newTab.id);

        if (dados) {
            handleAtualizationAbas([...dados, newTab]);
        }
    };

    const handleDeleteTab = async (id: number) => {
        setTabs(tabs.filter(t => t.id != id));

        await service.deleteById(id);

        const updatedDados = dados?.filter(item => item.id !== id) ?? [];
        handleAtualizationAbas(updatedDados);
    };

    const handleEditTab = (id: number, field: 'title' | 'description', value: string) => {
        setTabs(tabs.map(tab => (tab.id === id ? { ...tab, [field]: value } : tab)));

        const updatedDados =
            dados?.map(item => {
                if (item.id === id) {
                    const updatedItem = new ComponentAbasModel();
                    updatedItem.id = item.id;
                    updatedItem.title = field === 'title' ? value : item.title;
                    updatedItem.description = field === 'description' ? value : item.description;
                    return updatedItem;
                }
                return item;
            }) ?? [];

        handleAtualizationAbas(updatedDados);
    };

    const InitialLogic = (dados: ComponentAbasModel[]) => {
        // Filtrar duplicatas com base no ID
        const uniqueTabs = dados.reduce((acc: TabContent[], dado) => {
            if (!acc.some(tab => tab.id === dado.id)) {
                acc.push({
                    id: dado.id,
                    title: dado.title,
                    description: dado.description,
                });
            }
            return acc;
        }, []);

        setTabs(uniqueTabs);
    };

    useEffect(() => {
        if (dados && dados.length > 0) {
            InitialLogic(dados);
        }
    }, [dados]);
    useEffect(() => {}, [tabs, dados, activeTab]);

    useEffect(() => {
        if (dados && dados.length > 0) {
            setActiveTab(dados[0].id);
        }
    }, []);

    return (
        <Container>
            <Tabs>
                {tabs.map((tab: TabContent, index: number) => (
                    <Tab key={tab.id} active={tab.id === activeTab} onClick={() => handleTabChange(tab.id)}>
                        <Input
                            type="text"
                            value={tab.title}
                            onChange={e => handleEditTab(tab.id, 'title', e.target.value)}
                            style={{ fontWeight: tab.id === activeTab ? 'bold' : 'normal' }}
                        />
                        {tabs.length > 1 && (
                            <DeleteButton onClick={() => handleDeleteTab(tab.id)}>
                                <RiDeleteBack2Fill />
                            </DeleteButton>
                        )}
                    </Tab>
                ))}
            </Tabs>
            <Content>
                {tabs.map(
                    tab =>
                        tab.id === activeTab && (
                            <div key={tab.id}>
                                <ReactQuill
                                    style={{ marginTop: '20px' }}
                                    theme="snow"
                                    modules={editorConfig.modules}
                                    formats={editorConfig.formats}
                                    value={tab.description}
                                    onChange={val => handleEditTab(tab.id, 'description', val)}
                                />
                            </div>
                        )
                )}
            </Content>
            <Collapse
                items={[
                    {
                        key: '1',
                        label: 'Adicionar uma nova aba',
                        children: (
                            <AddTabForm>
                                <Input
                                    type="text"
                                    placeholder="Título da nova aba"
                                    value={newTabTitle}
                                    onChange={e => setNewTabTitle(e.target.value)}
                                />
                                <ReactQuill
                                    style={{ marginTop: '20px', backgroundColor: '#FFF' }}
                                    theme="snow"
                                    modules={editorConfig.modules}
                                    formats={editorConfig.formats}
                                    value={newTabContent}
                                    onChange={setNewTabContent}
                                />
                                <AddButton onClick={handleAddTab}>Adicionar nova Aba</AddButton>
                            </AddTabForm>
                        ),
                    },
                ]}
            />
        </Container>
    );
};

export default ComponentAbasAdmin;
