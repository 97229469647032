import { jsonProperty, Serializable, SerializationSettings } from "ts-serializable";

interface IFadLogUserCourses {
    codigo: number;
    codigoFerramentaAutoriaCourse: number;
    codigoPerfilUsuario: string;
    dateCreated: string; // Using string to represent LocalDateTime
    dateUpdate: string; // Using string to represent LocalDateTime
}

export class FadLogUserCoursesModel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number)
    codigoFerramentaAutoriaCourse: number = -1;

    @jsonProperty(String)
    codigoPerfilUsuario: string = '';

    @jsonProperty(String)
    dateCreated: string = '';

    @jsonProperty(String)
    dateUpdate: string = '';

    fromJSON(json: IFadLogUserCourses, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.codigoFerramentaAutoriaCourse = json.codigoFerramentaAutoriaCourse;
        this.codigoPerfilUsuario = json.codigoPerfilUsuario;
        this.dateCreated = json.dateCreated;
        this.dateUpdate = json.dateUpdate;
        return this;
    }
}