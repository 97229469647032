import ComponentInteractionModel from 'models/componentInteraction/ComponentInteraction';
import { GenericService } from '../Generic.service';
import ComponentInteractionSimpleModel from 'models/componentInteraction/ComponentInterationSimple';

export class ComponentInteractionService extends GenericService {
    readonly URI: string = '/v1/componentInteraction';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public atualizarAndSave(idConteudo: number, data: ComponentInteractionModel) {
        return this.put('save/' + idConteudo, data);
    }

    public atualizarAllOrdens(idConteudo: number, data: ComponentInteractionModel[]) {
        return this.put('atualizarOrdens/' + idConteudo, data);
    }

    public atualizarOrdem(data: ComponentInteractionModel) {
        return this.put('atualizarOrdem', data);
    }

    public deleteById(idComponente: number) {
        return this.delete(idComponente);
    }

    public atualizarQuantity(idComponent: number, quantity: Number) {
        return this.put('atualizarQuantity/' + idComponent, { quantity });
    }

    public atualizarColor(idComponent: number, color: string) {
        return this.put('color/' + idComponent, { color });
    }

    public insertComponentByFAD(idContent: number, model: ComponentInteractionSimpleModel) {
        return this.post('ferramenta-autoria/' + idContent, model);
    }

    public fetchComponentsByFAD(idContent: number, isPublic: boolean) {
        return this.get('ferramenta-autoria/content/' + idContent + '/' + isPublic);
    }

    public saveComponentByFAD(data: ComponentInteractionModel) {
        return this.put('ferramenta-autoria/component', data);
    }
}
