import React from 'react';
import styled from 'styled-components';
import FadAdds from '../adds/FadAdds';
import Colors from 'enums/Colors';
import { MdAdd } from 'react-icons/md';

const Styled = {
    Container: styled.div<{ visible?: boolean }>`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        transition: all 0.5s ease-out;
        cursor: pointer;
        gap: 16px;
        opacity: 0.5;
        margin: 12px 0px;

        &:hover {
            background-color: ${Colors.SoftBlue};
            color: ${Colors.White};
            opacity: 0.5;
        }
    `,
};

interface IAddNewComponent {
    onClick: () => void;
    visible?: boolean;
}

const AddNewComponent = ({ onClick, visible }: IAddNewComponent) => {
    return (
        <Styled.Container visible={visible} onClick={onClick}>
            <MdAdd />
            Adicionar novo componente
        </Styled.Container>
    );
};

export default AddNewComponent;
