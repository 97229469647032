import { FadModulesModel } from 'models/ferramentaAutoria/FadModulesModel';
import { GenericService } from '../Generic.service';

export class FadModulesService extends GenericService {
    readonly URI: string = '/v1/fadModules';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    async create(model: FadModulesModel) {
        return await this.post('', model);
    }

    async update(codigo: number, model: FadModulesModel) {
        return await this.put(`${codigo}`, model);
    }

    async deleteModel(codigo: number) {
        return await this.delete(codigo);
    }

    async findById(codigo: number) {
        return await this.get(`${codigo}`);
    }

    async findAllFadModules() {
        return await this.get();
    }

    async findByFadCourses(fadCourses: any) {
        return await this.get('byFadCourses', fadCourses);
    }

    async findByFadCoursesCodigo(codigo: number) {
        return await this.get(`byFadCoursesCodigo/${codigo}`);
    }

    async findByTitle(title: string) {
        return await this.get(`byTitle/${title}`);
    }

    async findByDescription(description: string) {
        return await this.get(`byDescription/${description}`);
    }

    async findByIsPublic(isPublic: boolean) {
        return await this.get('byIsPublic', { isPublic });
    }

    async findByOrdem(ordem: number) {
        return await this.get(`byOrdem/${ordem}`);
    }
}