type IArquivos = {
    arquivo: any;
    nome: string;
};

type IMaterial = {
    isPaste?: boolean;
    titlePaste?: string;
    arquivos: IArquivos[];
};
type TItem = {
    title: string;
    materiais: IMaterial[];
};

export const ListMateriaisCircuitoPrivate: TItem[] = [
    {
        title: 'Atividades integradoras',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Atividades integradoras',
                arquivos: [
                    {
                        nome: 'Atividades_Integradoras_1_Lingua_Portuguesa_PF',
                        arquivo: 'anexo_storage_files_10710441999468055840.pdf_2024-11-27-1732718562392.pdf',
                    },
                    {
                        nome: 'Atividades_Integradoras_2_Matematica_PF',
                        arquivo: 'anexo_storage_files_3087725304624648186.pdf_2024-11-27-1732718598378.pdf',
                    },
                    {
                        nome: 'Atividades_Integradoras_3_Ciencias_PF',
                        arquivo: 'anexo_storage_files_3210258731084295334.pdf_2024-11-27-1732718621733.pdf',
                    },
                    {
                        nome: 'Atividades_Integradoras_4_Arte_PF',
                        arquivo: 'anexo_storage_files_12449918697507032970.pdf_2024-11-27-1732718651570.pdf',
                    },
                    {
                        nome: 'Atividades_Integradoras_5_LinguagemCorporal_PF',
                        arquivo: 'anexo_storage_files_8535598960527155898.pdf_2024-11-27-1732718674043.pdf',
                    },
                    {
                        nome: 'Atividades_Integradoras_6_Etica_e_CidadaniaDigital_PF',
                        arquivo: 'anexo_storage_files_2091074585240679931.pdf_2024-11-27-1732718699271.pdf',
                    },
                    {
                        nome: 'Atividades_Integradoras_7_Historia_Geografia_PF',
                        arquivo: 'anexo_storage_files_5353104099713754705.pdf_2024-11-27-1732719277491.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Cadernos',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Alfabetização inicial',
                arquivos: [
                    {
                        nome: 'CadForm_AlfabetizacaoInicial_agenda1_PF',
                        arquivo: 'anexo_storage_files_12596608150469745254.pdf_2024-11-27-1732720033105.pdf',
                    },
                    {
                        nome: 'CadForm_AlfabetizacaoInicial_agenda2_PF',
                        arquivo: 'anexo_storage_files_1821024320194862244.pdf_2024-11-27-1732720097774.pdf',
                    },
                    {
                        nome: 'CadForm_AlfabetizacaoInicial_agenda3_PF',
                        arquivo: 'anexo_storage_files_10080388772957520154.pdf_2024-11-27-1732720126879.pdf',
                    },
                    {
                        nome: 'CadForm_AlfabetizacaoInicial_agenda4_PF',
                        arquivo: 'anexo_storage_files_18428542697308021431.pdf_2024-11-27-1732720149314.pdf',
                    },
                ],
            },
            {
                isPaste: true,
                titlePaste: 'Letramento científico',
                arquivos: [
                    {
                        nome: 'CadForm_LetramentoCientifico_agenda1_PF',
                        arquivo: 'anexo_storage_files_15630696014106205639.pdf_2024-11-27-1732720286391.pdf',
                    },
                    {
                        nome: 'CadForm_LetramentoCientifico_agenda2_PF',
                        arquivo: 'anexo_storage_files_11047084698721810441.pdf_2024-11-27-1732720306750.pdf',
                    },
                ],
            },
            {
                isPaste: true,
                titlePaste: 'Linguagem artística',
                arquivos: [
                    {
                        nome: 'CadForm_LinguagemArtistica_agenda1_PF',
                        arquivo: 'anexo_storage_files_1356878958743987121.pdf_2024-11-27-1732720411402.pdf',
                    },
                ],
            },
            {
                isPaste: true,
                titlePaste: 'Linguagem corporal',
                arquivos: [
                    {
                        nome: 'CadForm_LinguagemCorporal_agenda1_PF',
                        arquivo: 'anexo_storage_files_6942092474366046548.pdf_2024-11-27-1732720634449.pdf',
                    },
                    {
                        nome: 'CadForm_LinguagemCorporal_agenda2_PF',
                        arquivo: 'anexo_storage_files_10152599881415266245.pdf_2024-11-27-1732720675724.pdf',
                    },
                ],
            },
            {
                isPaste: true,
                titlePaste: 'Linguagem digital',
                arquivos: [
                    {
                        nome: 'CadForm_LinguagemDigital_agenda_PF',
                        arquivo: 'anexo_storage_files_10064961096977709416.pdf_2024-11-27-1732720729374.pdf',
                    },
                ],
            },
            {
                isPaste: true,
                titlePaste: 'Linguagem portuguesa',
                arquivos: [
                    {
                        nome: 'CadForm_LinguagemDigital_agenda_PF',
                        arquivo: 'anexo_storage_files_11892752086125975764.pdf_2024-11-27-1732720771923.pdf',
                    },
                    {
                        nome: 'CadForm_LinguaPortuguesa_agenda2_PF',
                        arquivo: 'anexo_storage_files_3163283882699004234.pdf_2024-11-27-1732720820483.pdf',
                    },
                    {
                        nome: 'CadForm_LinguaPortuguesa_agenda3_PF',
                        arquivo: 'anexo_storage_files_4294083559133815221.pdf_2024-11-27-1732720834589.pdf',
                    },
                    {
                        nome: 'CadForm_LinguaPortuguesa_agenda4_PF',
                        arquivo: 'anexo_storage_files_12442758632347387986.pdf_2024-11-27-1732720851037.pdf',
                    },
                ],
            },
            {
                isPaste: true,
                titlePaste: 'Matemática',
                arquivos: [
                    {
                        nome: 'CadForm_Matematica_agenda1_PF',
                        arquivo: 'anexo_storage_files_7011238960427356795.pdf_2024-11-27-1732720943257.pdf',
                    },
                    {
                        nome: 'CadForm_Matematica_agenda2_PF',
                        arquivo: 'anexo_storage_files_13847795492037447802.pdf_2024-11-27-1732720956017.pdf',
                    },
                    {
                        nome: 'CadForm_Matematica_agenda3_PF',
                        arquivo: 'anexo_storage_files_14680984804542385367.pdf_2024-11-27-1732720969027.pdf',
                    },
                    {
                        nome: 'CadForm_Matematica_agenda4_PF',
                        arquivo: 'anexo_storage_files_5311694483730578429.pdf_2024-11-27-1732720981520.pdf',
                    },
                ],
            },
        ],
    },
    // {
    //     title: 'Ciclos formativos',
    //     materiais: [
    //         {
    //             isPaste: true,
    //             titlePaste: 'Ciclos Formativos 1',
    //             arquivos: [
    //                 {
    //                     nome: 'PPT_Circuito_360_Ciclo_1_DIA_1',
    //                     arquivo: 'anexo_storage_files_4831682387660923485.pptx_2024-11-27-1732721224477.pptx',
    //                 },
    //                 {
    //                     nome: 'PPT_Circuito_360_Ciclo_1_DIA_2',
    //                     arquivo: 'anexo_storage_files_11257678603226989720.pptx_2024-11-27-1732721255845.pptx',
    //                 },
    //                 {
    //                     nome: 'Ciclo_1_agenda_formativa_circuito_360_digital',
    //                     arquivo: 'anexo_storage_files_16372706645257353906.pdf_2024-11-27-1732721287581.pdf',
    //                 },
    //             ],
    //         },
    //         {
    //             isPaste: true,
    //             titlePaste: 'Ciclos Formativos 2',
    //             arquivos: [
    //                 {
    //                     nome: 'PPT_Circuito_360_Ciclo_2_DIA_1',
    //                     arquivo: 'anexo_storage_files_1938481394880062923.pptx_2024-11-27-1732721460385.pptx',
    //                 },
    //                 {
    //                     nome: 'PPT_Circuito_360_Ciclo_2_DIA_2',
    //                     arquivo: 'anexo_storage_files_2195025552982625865.pptx_2024-11-27-1732721527382.pptx',
    //                 },
    //                 {
    //                     nome: 'Ciclo_2_agenda_formativa_circuito360_digital',
    //                     arquivo: 'anexo_storage_files_11461983862976932688.pdf_2024-11-27-1732721551844.pdf',
    //                 },
    //             ],
    //         },
    //         {
    //             isPaste: true,
    //             titlePaste: 'Ciclos Formativos 3',
    //             arquivos: [
    //                 {
    //                     nome: 'PPT_Circuito_360_Ciclo_3_DIA_1',
    //                     arquivo: 'anexo_storage_files_7027454530366232394.pptx_2024-11-27-1732721651511.pptx',
    //                 },
    //                 {
    //                     nome: 'PPT_Circuito_360_Ciclo_3_DIA_2',
    //                     arquivo: 'anexo_storage_files_8463835791375759196.pptx_2024-11-27-1732721666655.pptx',
    //                 },
    //                 {
    //                     nome: 'Ciclo_3_agenda_formativa_circuito_360_digital',
    //                     arquivo: 'anexo_storage_files_10187726773316944558.pdf_2024-11-27-1732721680771.pdf',
    //                 },
    //             ],
    //         },
    //         {
    //             isPaste: true,
    //             titlePaste: 'Ciclos Formativos 4',
    //             arquivos: [
    //                 {
    //                     nome: 'PPT_Circuito_360_Ciclo_4_DIA_1',
    //                     arquivo: 'anexo_storage_files_4542199976681105000.pptx_2024-11-27-1732721762632.pptx',
    //                 },
    //                 {
    //                     nome: 'PPT_Circuito_360_Ciclo_4_DIA_2',
    //                     arquivo: 'anexo_storage_files_6573494482151778702.pptx_2024-11-27-1732721781567.pptx',
    //                 },
    //                 {
    //                     nome: 'Ciclo_4_agenda_formativa_circuito_360_digital',
    //                     arquivo: 'anexo_storage_files_5901859607763243965.pdf_2024-11-27-1732721795346.pdf',
    //                 },
    //             ],
    //         },
    //     ],
    // },
    {
        title: 'Experimento científico',
        materiais: [
            {
                arquivos: [
                    {
                        nome: 'Guia_de_Experimento_Circuito_360_Digital_PF',
                        arquivo: 'anexo_storage_files_2898531675309869023.pdf_2024-11-27-1732721924696.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Fascículo',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Fascículo',
                arquivos: [
                    {
                        nome: 'Fasciculo_1_Circuito360_Apresentacao_Geral_PF',
                        arquivo: 'anexo_storage_files_8941562152195177206.pdf_2024-11-27-1732722128837.pdf',
                    },
                    {
                        nome: 'Fasciculo_2_Circuito360_PF',
                        arquivo: 'anexo_storage_files_8297185235310730159.pdf_2024-11-27-1732722168980.pdf',
                    },
                    {
                        nome: 'Fascículo_3_Circuito360_PF',
                        arquivo: 'anexo_storage_files_6076412496065180188.pdf_2024-11-27-1732722332320.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Sequências didáticas',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Brincadeiras',
                arquivos: [
                    {
                        nome: 'SD_Brincadeiras_1ano_PF',
                        arquivo: 'anexo_storage_files_17184911271007349902.pdf_2024-11-27-1732722469512.pdf',
                    },

                    {
                        nome: 'SD_Brincadeiras_2ano_PF',
                        arquivo: 'anexo_storage_files_17240824961750427431.pdf_2024-11-27-1732722486928.pdf',
                    },

                    {
                        nome: 'SD_Brincadeiras_3ano_PF',
                        arquivo: 'anexo_storage_files_8671542675959842834.pdf_2024-11-27-1732722499777.pdf',
                    },
                ],
            },
            {
                isPaste: true,
                titlePaste: 'Ciências humanas',
                arquivos: [
                    {
                        nome: 'SD_CienciasHumanas_1ano_PF',
                        arquivo: 'anexo_storage_files_529070919887349585.pdf_2024-11-27-1732722598794.pdf',
                    },
                    {
                        nome: 'SD_CienciasHumanas_2ano_PF',
                        arquivo: 'anexo_storage_files_8848835263266450078.pdf_2024-11-27-1732722614030.pdf',
                    },
                    {
                        nome: 'SD_CienciasHumanas_3ano_PF',
                        arquivo: 'anexo_storage_files_18068123258198521364.pdf_2024-11-27-1732722627077.pdf',
                    },
                ],
            },
            {
                isPaste: true,
                titlePaste: 'Ciências da natureza',
                arquivos: [
                    {
                        nome: 'SD_CienciasNatureza_1ano_PF',
                        arquivo: 'anexo_storage_files_6116621902272374662.pdf_2024-11-27-1732722701801.pdf',
                    },
                    {
                        nome: 'SD_CienciasNatureza_2ano_PF',
                        arquivo: 'anexo_storage_files_4483940677020346706.pdf_2024-11-27-1732722717323.pdf',
                    },
                    {
                        nome: 'SD_CienciasNatureza_3ano_PF',
                        arquivo: 'anexo_storage_files_15438943531033210385.pdf_2024-11-27-1732722739944.pdf',
                    },
                ],
            },
            {
                isPaste: true,
                titlePaste: 'Matemática',
                arquivos: [
                    {
                        nome: 'SD_Matematica_1ano_PF',
                        arquivo: 'anexo_storage_files_17506634485340149473.pdf_2024-11-27-1732722796718.pdf',
                    },
                    {
                        nome: 'SD_Matematica_2ano_PF',
                        arquivo: 'anexo_storage_files_12990475959402712036.pdf_2024-11-27-1732722809194.pdf',
                    },
                    {
                        nome: 'SD_Matematica_3ano_PF',
                        arquivo: 'anexo_storage_files_2853095307183833722.pdf_2024-11-27-1732722820725.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Sistemática de monitoramento',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Sistemática de acompanhamento',
                arquivos: [
                    {
                        nome: 'Sistematica_de_acompanhamento_circuito_360',
                        arquivo: 'anexo_storage_files_6840255233401836343.pdf_2024-11-27-1732723033645.pdf',
                    },
                ],
            },
            {
                isPaste: true,
                titlePaste: 'Tabelas e cartazes',
                arquivos: [
                    {
                        nome: 'Cartazes_Circuito_360_A3_PF ',
                        arquivo: 'anexo_storage_files_14158322519250374192.pdf_2024-11-27-1732723115179.pdf',
                    },
                    {
                        nome: 'Tabelas_Circuito_360_A4_PF',
                        arquivo: 'anexo_storage_files_12481851337425887483.pdf_2024-11-27-1732723127692.pdf',
                    },
                ],
            },
        ],
    },
    // {
    //     title: 'Diagnósticos e avaliações',
    //     materiais: [
    //         {
    //             isPaste: true,
    //             titlePaste: 'Diagnóticos',
    //             arquivos: [
    //                 {
    //                     nome: 'TesteDiagnostico_LP_aluno_PF',
    //                     arquivo: 'anexo_storage_files_12862108764807445737.pdf_2024-11-27-1732723369683.pdf',
    //                 },
    //                 {
    //                     nome: 'TesteDiagnostico_LP_orientacoes_PF',
    //                     arquivo: 'anexo_storage_files_9314911989799689053.pdf_2024-11-27-1732723388008.pdf',
    //                 },
    //                 {
    //                     nome: 'TesteDiagnostico_MAT_aluno_PF',
    //                     arquivo: 'anexo_storage_files_2383007701337639807.pdf_2024-11-27-1732723403038.pdf',
    //                 },
    //                 {
    //                     nome: 'TesteDiagnostico_MAT_orientacoes_PF',
    //                     arquivo: 'anexo_storage_files_6683971141493543256.pdf_2024-11-27-1732723415277.pdf',
    //                 },
    //             ],
    //         },
           
    //     ],
    // },
];
