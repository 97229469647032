import { FadLogUserCoursesModel } from 'models/ferramentaAutoria/FadLogUserCoursesModel';
import { GenericService } from '../Generic.service';

export class FadLogUserCoursesService extends GenericService {
    readonly URI: string = '/v1/fadLogUserCourses';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    async create(model: FadLogUserCoursesModel) {
        return await this.post('', model);
    }

    async update(codigo: number, model: FadLogUserCoursesModel) {
        return await this.put(`${codigo}`, model);
    }

    async deleteModel(codigo: number) {
        return await this.delete(codigo);
    }

    async findById(codigo: number) {
        return await this.get(`${codigo}`);
    }

    async findAllFadLogUserCourses() {
        return await this.get();
    }

    async findByFadCourses(fadCourses: any) {
        return await this.get('byFadCourses', fadCourses);
    }

    async findByFadCoursesCodigo(codigo: number) {
        return await this.get(`byFadCoursesCodigo/${codigo}`);
    }

    async findByPerfilUsuario(perfilUsuario: any) {
        return await this.get('byPerfilUsuario', perfilUsuario);
    }

    async findByPerfilUsuarioCodigo(codigo: number) {
        return await this.get(`byPerfilUsuarioCodigo/${codigo}`);
    }

    async findByDateCreated(dateCreated: string) {
        return await this.get('byDateCreated', { dateCreated });
    }

    async findByDateUpdate(dateUpdate: string) {
        return await this.get('byDateUpdate', { dateUpdate });
    }
}