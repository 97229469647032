import { FadUserProgressContentsPublicModel } from 'models/ferramentaAutoria/public/FadUserProgressContentsPublicModel';
import { GenericService } from '../Generic.service';

export class FadUserProgressContentsService extends GenericService {
    readonly URI: string = '/v1/fadUserProgressContents';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    async inserirProgress(body: FadUserProgressContentsPublicModel) {
        return await this.post('', body);
    }

    async updateProgress(codigo: number, body: FadUserProgressContentsPublicModel) {
        return await this.put('' + codigo, body);
    }
}
