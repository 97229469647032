import { ComponentInteractionEnum } from 'enums/ComponentInteractionEnum';
import { jsonProperty, Serializable, SerializationSettings } from 'ts-serializable';

interface ComponentInteractionJson {
    id: number;
    tipo: ComponentInteractionEnum;
    conteudo: string;
    conteudoTwo: string;
    cor: string;
    quantityVisible: number;
    ordem: number;
    obrigatory: boolean;
    codigoContent: number;
}

class ComponentInteractionSimpleModel extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    tipo: ComponentInteractionEnum = ComponentInteractionEnum.NENHUM;

    @jsonProperty(String)
    conteudo: string = '';

    @jsonProperty(String)
    conteudoTwo: string = '';

    @jsonProperty(Number)
    ordem: number = -1;

    @jsonProperty(Number)
    quantityVisible: number = -1;

    @jsonProperty(Number)
    codigoContent: number = -1;

    @jsonProperty(String, null)
    cor: string | null = null;

    @jsonProperty(Boolean, null)
    obrigatory: boolean | null = null;

    fromJSON(json: ComponentInteractionJson, settings?: Partial<SerializationSettings>): this {
        this.id = json.id;
        this.tipo = json.tipo;
        this.conteudo = json.conteudo;
        this.conteudoTwo = json.conteudoTwo;
        this.cor = json.cor ?? null;
        this.quantityVisible = json.quantityVisible;
        this.ordem = json.ordem;
        this.obrigatory = json.obrigatory ?? null;
        this.codigoContent = json.codigoContent

        return this;
    }
}

export default ComponentInteractionSimpleModel;
