import { jsonProperty, Serializable, SerializationSettings } from 'ts-serializable';

interface IFadClassesModel {
    codigo: number;
    fadCoursesCodigo: number;
    categoriaPaginaExclusivaCodigo: number | null;
    name: string;
    active: boolean;
    maxRegistrationDate: string | null;
    closingDate: string | null;
}

export class FadClassesModel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number)
    fadCoursesCodigo: number = -1;

    @jsonProperty(Number, null)
    categoriaPaginaExclusivaCodigo: number | null = null;

    @jsonProperty(String)
    name: string = '';

    @jsonProperty(Boolean)
    active: boolean = true;

    @jsonProperty(String, null)
    maxRegistrationDate: String | null = null;

    @jsonProperty(String, null)
    closingDate: String | null = null;

    fromJSON(json: IFadClassesModel, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.fadCoursesCodigo = json.fadCoursesCodigo;
        this.categoriaPaginaExclusivaCodigo = json.categoriaPaginaExclusivaCodigo;
        this.name = json.name;
        this.active = json.active;
        this.maxRegistrationDate = json.maxRegistrationDate;
        this.closingDate = json.closingDate;

        return this;
    }
}
