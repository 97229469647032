import { Collapse, Progress } from 'antd';
import keycloak from 'core/config/keycloak';
import { FadCoursesService } from 'core/http/service/ferramentaAutoria/FadCourses.service';
import { FadUserConcluidModulesService } from 'core/http/service/ferramentaAutoria/FadUserConcluidModules.service';
import { FadUserProgressContentsService } from 'core/http/service/ferramentaAutoria/FadUserProgressContents.service';
import Colors from 'enums/Colors';
import useFetch from 'hooks/useFetch';
import { FadUserConcluidModulesModel } from 'models/ferramentaAutoria/FadUserConcluidModulesModel';
import { FadContentsPublicModel } from 'models/ferramentaAutoria/public/FadContentsPublicModel';
import { FadCoursesPublicModel } from 'models/ferramentaAutoria/public/FadCoursesPublicModel';
import { FadModulesPublicModel } from 'models/ferramentaAutoria/public/FadModulesPublicModel';
import { FadUserConcluidModulesPublicModel } from 'models/ferramentaAutoria/public/FadUserConcluidModulesPublicModel';
import { FadUserProgressContentsPublicModel } from 'models/ferramentaAutoria/public/FadUserProgressContentsPublicModel';
import { FAD_Button_Outline } from 'pages/ferramentaAutoria/components/FadButtons';
import { FerramentaAutoriaContext } from 'pages/ferramentaAutoria/context/FerramentaAutoriaContext';
import React, { useContext, useEffect, useState } from 'react';
import { CgLock, CgLockUnlock } from 'react-icons/cg';
import { IoMdArrowDropleft } from 'react-icons/io';
import { MdCheck, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdMenu } from 'react-icons/md';
import { TbArrowLeft } from 'react-icons/tb';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

const Styled = {
    Container: styled.div<{ open: boolean }>`
        position: relative;
        display: flex;
        flex-direction: column;
        border-right: 1px solid #f0f0f0;
        height: 90vh;
        width: 292px;
        transition: all 0.5s;
        position: fixed;
        background-color: ${Colors.White};
        z-index: 1;

        transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-300px)')};
    `,

    Header: styled.div`
        display: flex;
        flex-direction: column;
        gap: 16px;
        background-color: #fafafa;
        padding: 16px;
        padding-bottom: 22px;
        margin-bottom: 22px;
    `,

    ContentModulos: styled.div`
        width: 100%;
        height: 100%;
        overflow: scroll;
        overflow-x: hidden;
        overflow-y: auto;
    `,

    Title: styled.h1`
        //styleName: Base/H4;
        font-family: 'Barlow';
        font-size: 22px;
        font-weight: 600;
        line-height: 26.4px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: ${Colors.Blue};
    `,

    HeaderModule: {
        Container: styled.div`
            display: flex;
            align-items: center;
            border-bottom: 1px solid ${Colors.SoftBlue};
            padding-bottom: 6px;
            min-height: 26px;
        `,

        Title: styled.h1`
            //styleName: Base/Micro Overline;
            font-family: 'Barlow';
            font-size: 14px;
            font-weight: 700;
            line-height: 16.8px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #555;
        `,
    },

    Content: {
        Container: styled.div<{ selected: boolean }>`
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 58px;
            color: ${({ selected }) => (selected ? Colors.Blue : '')};

            margin-bottom: 8px;
            padding-left: 8px;
            cursor: pointer;
            transition: all 0.5s;

            background-color: ${({ selected }) => (selected ? Colors.Grey8 : '#fafafa')};
        `,

        Title: styled.h1<{ selected: boolean }>`
            font-family: 'Barlow';
            font-size: 14px;
            font-weight: 600;
            line-height: 25.2px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            max-width: 70%;
            color: ${({ selected }) => (selected ? Colors.Blue : '#555')};
        `,

        Info: styled.div`
            display: flex;
            align-items: center;
            gap: 8px;
            margin-right: 4px;
        `,
    },

    Menu: styled.div<{ menuOpen: boolean }>`
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${Colors.Yellow};
        width: 45px !important;
        height: 45px !important;
        border-radius: 100%;
        border: 1px solid #f8f8f8;
        position: relative;
        left: ${({ menuOpen }) => (menuOpen ? '250px' : '300px')};
    `,
};

interface IContent {
    content: FadContentsPublicModel;
    lastContentFinish: boolean;
    selected: boolean;
    porcentContentAtt: number;
    module: FadModulesPublicModel;
}

const Content = ({ content, lastContentFinish, selected, porcentContentAtt, module }: IContent) => {
    const { setContentView, coursePublic, moduleView, contentView, classSelected, setModuleView, setCoursePublic, setScrollViewContent } =
        useContext(FerramentaAutoriaContext.Context);
    const [porcent, setPorcent] = useState<number>(content?.progress?.percentageCompleted ?? 0);

    const serviceContent = new FadUserProgressContentsService();
    const serviceUserConcluidModules = new FadUserConcluidModulesService();

    const goToViewConent = () => {
        setScrollViewContent(true);

        if (lastContentFinish || (content.progress && content.progress.isCompleted)) {
            setContentView(content);
            setModuleView(module);
        }
    };

    const getPorcent = () => {
        let newPorcent = content.progress?.percentageCompleted || porcent; // Valor padrão de 0

        if (selected && porcentContentAtt > 0) {
            if (content.progress && content.progress.percentageCompleted) {
                if (porcentContentAtt > content.progress.percentageCompleted && porcent < porcentContentAtt) {
                    newPorcent = porcentContentAtt;
                }
            } else {
                if (porcent < porcentContentAtt) {
                    newPorcent = porcentContentAtt;
                }
            }
        }

        setPorcent(parseInt(newPorcent.toString())); // Atualiza o estado apenas uma vez
        return parseInt(newPorcent.toString()); // Sempre retorna um número
    };

    const atualizarProgressConentObjectCourse = (progress: FadUserProgressContentsPublicModel) => {
        if (!coursePublic) return; // Verifica se o estado atual é válido

        setCoursePublic((prev: any) => {
            if (!prev) return prev; // Retorna o estado anterior se ele for null

            // Criação de uma cópia imutável do estado anterior
            const updatedCoursePublic = { ...prev };

            // Localiza o módulo pelo código
            const module = updatedCoursePublic.modulos.find((m: FadModulesPublicModel) => m.codigo === moduleView?.codigo);

            if (module) {
                // Localiza o conteúdo pelo código
                const contentItem = module.conteudos.find((c: FadContentsPublicModel) => c.codigo === content.codigo);

                if (contentItem) {
                    // Atualiza o progresso do conteúdo
                    contentItem.progress = progress;
                }
            }

            return updatedCoursePublic; // Retorna o novo estado atualizado
        });
    };

    const verifyContentsAllConcluidsModule = async () => {
        if (moduleView) {
            const isConcluid = moduleView.conteudos.every(c => {
                const completed = c?.progress?.isCompleted;
                return completed === true; // Verifica se é exatamente true
            });

            if (moduleView.progress) {
                return;
            }

            if (isConcluid && moduleView) {
                if (!moduleView.progress && classSelected) {
                    const body = new FadUserConcluidModulesPublicModel().fromJSON({
                        codigo: -1,
                        codigoFerramentaAutoriaModule: moduleView.codigo,
                        codigoFerramentaAutoriaClasse: classSelected.codigo,
                    });

                    const { data, status } = await serviceUserConcluidModules.create(body);

                    if (status === 201) {
                        if (data) {
                            const module = new FadModulesPublicModel().fromJSON({
                                ...moduleView,
                                progress: data as FadUserConcluidModulesModel,
                            });

                            setCoursePublic((prev: any) => {
                                const modulos = prev.modulos.map((m: FadModulesPublicModel) => {
                                    if (m.codigo === moduleView.codigo) {
                                        return module;
                                    }

                                    return m;
                                });

                                return {
                                    ...prev,
                                    modulos: modulos,
                                };
                            });

                            setModuleView(module);
                        }
                    }
                }
            }
        }
    };

    useFetch(async () => {
        const codigoClasse = classSelected?.codigo ?? -1;

        const body = new FadUserProgressContentsPublicModel().fromJSON({
            codigo: content?.progress?.codigo ?? -1,
            codigoContent: content.codigo,
            codigoClasse: codigoClasse,
            isCompleted: porcent === 100,
            percentageCompleted: porcent ?? 0,
        });

        if (content.progress && !content.progress.isCompleted) {
            await serviceContent.inserirProgress(body);
        }

        atualizarProgressConentObjectCourse(body); // Atualiza o objeto do curso
    }, [porcent]);

    useEffect(() => {
        //Validar se todos os conteudos estão concluidos do mesmo modulo, caso sim faça a inserção automaticamente da conclusão do modulo.
        verifyContentsAllConcluidsModule();
    }, [coursePublic, contentView]);

    useEffect(() => {
        const newPorcent = getPorcent(); // Chame a função aqui

        setPorcent(newPorcent); // Atualize o estado aqui
    }, [selected, porcentContentAtt, content, coursePublic]);

    return (
        <Styled.Content.Container selected={selected} onClick={goToViewConent}>
            <Styled.Content.Title selected={selected}>{content.title}</Styled.Content.Title>
            <Styled.Content.Info>
                {content.progress && content.progress.isCompleted ? (
                    <Progress
                        className="custom-progress"
                        strokeWidth={10}
                        percent={100}
                        type="circle"
                        format={percent => (
                            <span style={{ fontSize: '10px', color: '#555', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <MdCheck size={16} color="#52c41a" />
                            </span>
                        )}
                    />
                ) : (
                    lastContentFinish && (
                        <Progress
                            className="custom-progress"
                            strokeWidth={10}
                            percent={porcent}
                            showInfo={false}
                            type="circle"
                            format={percent => (
                                <span
                                    className="text-custom-progress"
                                    style={{ fontSize: '10px', color: '#555', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    {percent === 100 ? <MdCheck size={16} color="#52c41a" /> : percent + '%'}
                                </span>
                            )}
                        />
                    )
                )}
                {lastContentFinish ? <CgLockUnlock size={22} color={Colors.SoftBlue} /> : <CgLock size={22} color={Colors.SoftBlue} />}
            </Styled.Content.Info>
        </Styled.Content.Container>
    );
};

interface IFadConentMenuLateralPublic {
    open: boolean;
    setOpen: (value: boolean) => void;
    porcentContentAtt: number;
}

const FadConentMenuLateralPublic = ({ open = true, setOpen, porcentContentAtt }: IFadConentMenuLateralPublic) => {
    const { page, curso }: any = useParams();
    const { coursePublic, setCoursePublic, contentView, moduleView } = useContext(FerramentaAutoriaContext.Context);
    const history = useHistory();

    const [activeKey, setActiveKey] = useState<string | null>(null);

    const onChangeCollapse = (key: string | string[]) => {
        // Se o painel clicado já está ativo, você pode fechá-lo
        setActiveKey(activeKey === key ? null : (key.at(-1) as string));
    };

    //service
    const serviceCourse = new FadCoursesService();

    const getPorcentGlobal = (): number => {
        let maxContents: number = 0;
        let maxConentConcluid: number = 0;

        coursePublic.modulos.forEach(m => {
            if (m.conteudos && m.conteudos.length > 0) {
                m.conteudos.forEach(c => {
                    maxContents++;

                    if (c.progress && c.progress.isCompleted) {
                        maxConentConcluid++;
                    }
                });
            }
        });

        const result = (maxConentConcluid / maxContents) * 100;

        return parseInt(result.toString());
    };

    const getLastContentCompleted = (indexCurrent: number, conteudo: FadContentsPublicModel): boolean => {
        // Buscar o módulo atual do conteúdo
        const codigoModulo = conteudo.codigoFerramentaAutoriaModule;
        const module = coursePublic.modulos.find(m => m.codigo === codigoModulo);

        if (!module) {
            return false;
        }

        // Verificar se existe algum conteúdo anterior dentro do mesmo módulo
        const lastContentExist = module.conteudos[indexCurrent - 1];

        if (lastContentExist?.progress?.isCompleted) {
            return true;
        }

        // Procurar o último módulo anterior que seja válido e acessível
        const accessibleModules = coursePublic.modulos;
        const moduleIndex = accessibleModules.findIndex(m => m.codigo === codigoModulo);

        if (moduleIndex > 0) {
            const lastModule = accessibleModules[moduleIndex - 1];
            const lastContent = lastModule.conteudos.at(-1);

            //Adicionar a logica caso for conteudo sequencias não liberar todos os conteudos do modulo caso não for sequencial.
            if (coursePublic.course.sequentialSteps) {
                const allContentsCompleted = lastModule.conteudos.every(content => content?.progress?.isCompleted);

                if (allContentsCompleted) {
                    if (indexCurrent === 0) {
                        return true;
                    }
                } else {
                    return false;
                }
            } else {
                if (!coursePublic.course.sequentialContents) {
                    return true;
                }

                if (indexCurrent === 0) {
                    return true;
                }
            }
        }

        // Caso seja o primeiro conteúdo do primeiro módulo acessível
        if (moduleIndex === 0 && indexCurrent === 0) {
            return true;
        }

        if (!coursePublic.course.sequentialContents) {
            return true;
        }

        return false;
    };

    const handleVisibleMenu = () => {
        setOpen(!open);
    };

    useFetch(async () => {
        if (keycloak.authenticated) {
            if (coursePublic.course.url == '/' + curso) {
                return;
            }

            const { data, status } = await serviceCourse.findByUrlPublic(curso);

            if (status === 200) {
                setCoursePublic(data);
            }
        }
    }, [window.location.pathname]);

    const goToBack = () => {
        history.replace('/cursos/' + page + '/' + curso);
    };

    useEffect(() => {
        const index = coursePublic.modulos.findIndex(m => m.codigo === moduleView?.codigo);

        setActiveKey(index.toString());
    }, [moduleView]);

    useEffect(() => {
        if (!keycloak.authenticated) {
            history.replace('/cursos/' + page + '/' + curso);
        }
    }, []);

    return (
        <Styled.Container open={open}>
            <Styled.Header>
                <FAD_Button_Outline text="Sair do curso" onClick={() => goToBack()} icon={<TbArrowLeft />} />
                <Styled.Title>{coursePublic.course.name}</Styled.Title>

                <Progress
                    percent={getPorcentGlobal()}
                    percentPosition={{ type: 'outer' }}
                    size="default"
                    format={percent => (
                        <span
                            style={{
                                position: 'absolute',
                                fontWeight: 700,
                                fontSize: '14px',
                                color: '#555',
                                display: 'flex',
                                left: 0,
                                top: 20,
                                fontFamily: 'Barlow',
                            }}
                        >
                            {percent === 100 ? <MdCheck size={16} color="#52c41a" /> : percent + '% completo'}
                        </span>
                    )}
                />
                <Styled.Menu menuOpen={open}>
                    {open ? (
                        <MdKeyboardArrowLeft size={32} cursor={'pointer'} onClick={handleVisibleMenu} />
                    ) : (
                        <MdKeyboardArrowRight size={32} cursor={'pointer'} onClick={handleVisibleMenu} />
                    )}
                </Styled.Menu>
            </Styled.Header>
            <Styled.ContentModulos>
                <Collapse
                    ghost
                    activeKey={activeKey ? [activeKey] : []} // Mantém a chave ativa
                    onChange={onChangeCollapse}
                    items={coursePublic.modulos
                        .sort((a, b) => a.ordem - b.ordem)
                        .map((m, i) => ({
                            key: i,
                            label: (
                                <Styled.HeaderModule.Container>
                                    <Styled.HeaderModule.Title>{m.title}</Styled.HeaderModule.Title>
                                </Styled.HeaderModule.Container>
                            ),
                            children: (
                                <>
                                    {m.conteudos &&
                                        m.conteudos.length > 0 &&
                                        m.conteudos
                                            .sort((a, b) => a.ordem - b.ordem)
                                            .map((c, indexContent) => (
                                                <Content
                                                    key={c.codigo}
                                                    content={c}
                                                    lastContentFinish={getLastContentCompleted(indexContent, c)}
                                                    selected={contentView?.codigo === c.codigo}
                                                    porcentContentAtt={porcentContentAtt}
                                                    module={m}
                                                />
                                            ))}
                                </>
                            ),
                        }))}
                />
            </Styled.ContentModulos>
        </Styled.Container>
    );
};

export default FadConentMenuLateralPublic;
