import ComponentInteractionModel from 'models/componentInteraction/ComponentInteraction';
import { jsonProperty, Serializable, SerializationSettings } from 'ts-serializable';
import { FadUserProgressContentsPublicModel } from './FadUserProgressContentsPublicModel';

interface IFadContentsPublicModel {
    codigo: number;
    codigoFerramentaAutoriaModule: number;
    title: string;
    isPublic: boolean;
    ordem: number;
    componentes: ComponentInteractionModel[];
    progress: FadUserProgressContentsPublicModel;
}

export class FadContentsPublicModel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number)
    codigoFerramentaAutoriaModule: number = -1;

    @jsonProperty(String)
    title: string = '';

    @jsonProperty(Boolean)
    isPublic: boolean = false;

    @jsonProperty(Boolean)
    activeAvaliations: boolean = false;

    @jsonProperty(Boolean)
    activeCommentary: boolean = false;

    @jsonProperty(Number)
    ordem: number = -1;

    componentes: ComponentInteractionModel[] = [];

    progress: FadUserProgressContentsPublicModel = new FadUserProgressContentsPublicModel();

    fromJSON(json: IFadContentsPublicModel, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.codigoFerramentaAutoriaModule = json.codigoFerramentaAutoriaModule;
        this.title = json.title;
        this.isPublic = json.isPublic;
        this.ordem = json.ordem;
        this.componentes = json.componentes;
        this.progress = json.progress;
        return this;
    }
}
