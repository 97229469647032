import { jsonProperty, Serializable, SerializationSettings } from 'ts-serializable';

interface IFadUserConcluidModulesModel {
    codigo: number;
    codigoFerramentaAutoriaModule: number;
}

export class FadUserConcluidModulesModel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number)
    codigoFerramentaAutoriaModule: number = -1;

    fromJSON(json: IFadUserConcluidModulesModel, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.codigoFerramentaAutoriaModule = json.codigoFerramentaAutoriaModule;

        return this;
    }
}
