import { jsonProperty, Serializable, SerializationSettings } from "ts-serializable";

interface IFadModules {
    codigo: number;
    codigoFerramentaAutoriaCourse: number;
    title: string;
    description: string;
    isPublic: boolean;
    ordem: number;
}

export class FadModulesModel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number)
    codigoFerramentaAutoriaCourse: number = -1;

    @jsonProperty(String)
    title: string = '';

    @jsonProperty(String)
    description: string = '';

    @jsonProperty(Boolean)
    isPublic: boolean = false;

    @jsonProperty(Number)
    ordem: number = -1;

    fromJSON(json: IFadModules, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.codigoFerramentaAutoriaCourse = json.codigoFerramentaAutoriaCourse;
        this.title = json.title;
        this.description = json.description;
        this.isPublic = json.isPublic;
        this.ordem = json.ordem;
        return this;
    }
}