import { FerramentaAutoriaContext } from 'pages/ferramentaAutoria/context/FerramentaAutoriaContext';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import FadConentMenuLateralPublic from './components/FadConentMenuLateralPublic';
import { ComponentInteractionEnum } from 'enums/ComponentInteractionEnum';
import RecursoMediaSwitch from 'pages/recursos/recursos-conteudo/components/media-switch/RecursoMediaSwitch';
import { Collapse, ConfigProvider, Image } from 'antd';
import Colors from 'enums/Colors';
import QuizInteractionPublic from 'pages/recursos/recursos-conteudo/components/quiz/QuizInteractionPublic';
import CarrosselInteractionPublic from 'pages/recursos/recursos-conteudo/components/carrossel/CarrosselInteractionPublic';
import ComponentAbasPublic from 'pages/recursos/recursos-conteudo/components/abas/ComponentAbasPublic';
import ComponentBaralhoPublic from 'pages/recursos/recursos-conteudo/components/baralho/ComponentBaralhoPublic';
import ReguaAvaliativaPublic from 'pages/recursos/recursos-conteudo/components/reguaAvaliativa/ReguaAvaliativaPublic';
import ComponentTimeLinesPublic from 'pages/recursos/recursos-conteudo/components/timelines/ComponentTimeLinesPublic';
import ComponentQuizPontucaoPublic from 'pages/recursos/recursos-conteudo/components/quiz/QuizPontuacaoPublic';
import ComponentCenarioPublic from 'pages/recursos/recursos-conteudo/components/cenario/ComponentCenarioPublic';
import CorrespondentPublic from 'pages/recursos/recursos-conteudo/components/correspondent/CorrespondentPublic';
import { CaretRightOutlined } from '@ant-design/icons';
import { fetchS3ComponentInteraction, fetchStorageFile, fetchStorageFileAuthoringTools } from 'hooks/StoreFiles';
import { FAD_Button_Outline, FAD_Button_Primary } from 'pages/ferramentaAutoria/components/FadButtons';
import { debounce } from 'lodash';
import { FadContentsPublicModel } from 'models/ferramentaAutoria/public/FadContentsPublicModel';
import { FadUserProgressContentsService } from 'core/http/service/ferramentaAutoria/FadUserProgressContents.service';
import { FadUserProgressContentsPublicModel } from 'models/ferramentaAutoria/public/FadUserProgressContentsPublicModel';
import { FadModulesPublicModel } from 'models/ferramentaAutoria/public/FadModulesPublicModel';
import FadRaterCommentary from 'pages/ferramentaAutoria/pages/public/components/FadRaterCommentary';
import { MdArrowDownward, MdArrowRight, MdArrowUpward } from 'react-icons/md';
import { FadUserConcluidModulesService } from 'core/http/service/ferramentaAutoria/FadUserConcluidModules.service';
import { FadUserConcluidModulesPublicModel } from 'models/ferramentaAutoria/public/FadUserConcluidModulesPublicModel';
import { useHistory, useParams } from 'react-router-dom';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import './assets/Content.css';
import ComponentInteractionModel from 'models/componentInteraction/ComponentInteraction';
import AmpliarImg from 'components/ampliarImg/AmpliarImg';
import AmpliarImgV2 from 'components/ampliarImg/AmpliarImgV2';
import ComponentDividerPublic from 'pages/recursos/recursos-conteudo/components/dividers/ComponentDividerPublic';

const StyledCaretRightOutlined = styled(CaretRightOutlined)``;

const Styled = {
    Container: styled.div`
        display: flex;
        width: 100%;
        padding-top: 90px;
        overflow-x: hidden;
    `,

    Contents: styled.div<{ menuOpen?: boolean }>`
        display: flex;
        flex-direction: column;

        gap: 16px;
        width: ${({ menuOpen }) => (menuOpen ? ' 80%' : ' 90%')};
        transition: all 0.5s ease-out;
        padding-left: ${({ menuOpen }) => (menuOpen ? '  90px' : '  132px')};
        transform: ${({ menuOpen }) => (menuOpen ? 'translateX(300px)' : 'translateX(40px)')};
        z-index: 0;

        @media screen and (max-width: 1600px) {
            padding-right: ${({ menuOpen }) => (menuOpen ? '  90px' : '  40px')};
        }

        @media screen and (max-width: 720px) {
            transform: ${({ menuOpen }) => (menuOpen ? 'translateX(300px)' : 'translateX(0px)')};
            width: 100%;
            padding: 16px;
        }
    `,

    RowBettween: styled.div`
        margin: 22px 0px;
        display: flex;
        justify-content: space-between;
        gap: 32px;

        @media screen and (max-width: 720px) {
            flex-direction: column;
            justify-content: start;
            gap: 16px;
            
        }
    `,

    TextContent: styled.p`
        max-width: 100%;
        line-height: 1.4; /* Melhor espaçamento padrão */
        font-family: inherit; /* Fonte padrão do sistema */

        /* Ajustes para parágrafos */
        p {
            margin: 0 0 1em; /* Espaço entre parágrafos */
        }

        /* Listas */
        ol,
        ul {
            padding-left: 20px;
        }

        ol {
            list-style: inside decimal;
        }

        ul {
            list-style: inside disc;
        }

        /* Títulos */
        h1,
        h2 {
            font-weight: 700;
            margin: 0.5em 0;
        }

        h1 {
            font-size: 2em;
        }
        h2 {
            font-size: 1.5em;
        }
        h3 {
            font-size: 1.17em;
        }
        h4 {
            font-size: 1em;
        }
        h5 {
            font-size: 0.8em;
        }
        h6 {
            font-size: 0.6em;
        }

        /* Estilos do Quill */
        .ql-align-center {
            text-align: center;
        }
        .ql-align-right {
            text-align: right;
        }
        .ql-align-justify {
            text-align: justify;
        }

        .ql-bold {
            font-weight: bold;
        }
        .ql-italic {
            font-style: italic;
        }
        .ql-underline {
            text-decoration: underline;
        }
        .ql-strike {
            text-decoration: line-through;
        }

        .ql-blockquote {
            border-left: 4px solid #ccc;
            padding-left: 16px;
            margin-left: 0;
            font-style: italic;
        }

        .ql-code-block {
            background-color: #f4f4f4;
            padding: 8px;
            border-radius: 4px;
            font-family: monospace;
        }

        /* 🎨 Adicionando suporte para fontes do Quill */
        .ql-font-serif {
            font-family: serif;
        }
        .ql-font-monospace {
            font-family: monospace;
        }
        .ql-font-sans-serif {
            font-family: sans-serif;
        }

        /* Espaçamentos */
        .ql-size-small {
            font-size: 0.75em;
        }
        .ql-size-large {
            font-size: 1.5em;
        }
        .ql-size-huge {
            font-size: 2.5em;
        }

        /* Ajustes finos de espaçamento */
        .ql-indent-1 {
            margin-left: 20px;
        }
        .ql-indent-2 {
            margin-left: 40px;
        }
        .ql-indent-3 {
            margin-left: 60px;
        }
        .ql-indent-4 {
            margin-left: 80px;
        }

        iframe {
            width: 100%;
            min-height: 300px;
        }
        img {
            display: flex;
            align-self: center;
            justify-self: center;
            max-width: 50%;
            cursor: pointer;
        }

        @media screen and (max-width: 720px) {
            img {
                max-width: 100% !important;
            }
        }

        @media screen and (max-width: 720px) {
            img {
                max-width: 100% !important;
            }
        }
    `,

    ImgHeader: styled.div<{ img: string }>`
        background-image: url(${props => props.img});
        background-size: 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: 240px;
        position: relative; /* Permite que elementos filhos com absolute fiquem dentro */

        @media screen and (max-width: 720px) {
            background-size: contain;
            background-size: auto 180px;
        }
    `,

    HeaderTitle: styled.h1`
        position: absolute; /* Agora ele está dentro do ImgHeader */
        top: 60px;
        left: 200px;
        font-family: 'Barlow';
        font-size: 36px;
        font-weight: 700;
        line-height: 43.2px;
        text-align: left;
        color: ${Colors.Blue};
        max-width: calc(100% - 40px); /* Para evitar que saia da tela */

        @media screen and (max-width: 720px) {
            top: 30px;
            font-size: 22px;
            left: 20px;
            max-width: calc(100% - 20px);
        }
    `,
};

const FadConentsPage = () => {
    const {
        contentView,
        coursePublic,
        moduleView,
        setModuleView,
        setContentView,
        setCoursePublic,
        scrollViewContent,
        classSelected,
        setScrollViewContent,
        inscrito,
        isCourseCloseDate,
        avaliationContent,
        handleAvaliationContent,
    } = useContext(FerramentaAutoriaContext.Context);
    const { page, curso }: any = useParams();
    const [open, setOpen] = useState<boolean>(false);
    const history = useHistory();

    const [modalOpen, setModalOpen] = useState(false);
    const [currentImgSrc, setCurrentImgSrc] = useState('');

    const [visibleSections, setVisibleSections] = useState<number[]>([0]);
    const [firstNextInformationIndex, setFirstNextInformationIndex] = useState<number | null>(null); // Índice do primeiro NEXT_INFORMATION
    const [nextInformationIndexHidden, setNextInformationIndexHidden] = useState<number[]>([]);

    const [porcent, setPorcent] = useState<number>(80);

    const [completionPercentage, setCompletionPercentage] = useState(0);

    const refs = useRef<{ [key: number]: HTMLDivElement | null }>({});
    const componentRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});

    const serviceContent = new FadUserProgressContentsService();
    const serviceUserConcluidModules = new FadUserConcluidModulesService();

    const viewContent = (ref: any) => {
        const contentCode = ref.getAttribute('data-code');

        // Garantir que contentCode seja tratado como número
        const contentCodeNumber = contentCode ? parseInt(contentCode, 10) : null;

        // Verificar se o código do conteúdo foi encontrado
        const content = coursePublic.modulos.find(m => m.codigo === moduleView?.codigo)?.conteudos.find(c => c.codigo === contentCodeNumber);

        if (content) {
            setContentView(content);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            Object.values(refs.current).forEach(targetElement => {
                if (targetElement) {
                    const rect = targetElement.getBoundingClientRect();

                    // Verifica se o elemento está visível na viewport
                    const isVisible = rect.top < window.innerHeight && rect.bottom > 0;

                    if (isVisible) {
                        viewContent(targetElement);
                    }
                }
            });
        };

        // Adiciona o evento de rolagem
        window.addEventListener('scroll', handleScroll);

        // Chama a função uma vez para capturar o estado inicial
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const concluirContent = async (content: FadContentsPublicModel, currentIndex: number) => {
        setScrollViewContent(true);

        const classe = classSelected?.codigo ?? -1;

        const body = new FadUserProgressContentsPublicModel().fromJSON({
            codigo: content?.progress?.codigo ?? -1,
            codigoContent: content.codigo,
            codigoClasse: classe,
            isCompleted: true,
            percentageCompleted: 100,
        });

        content.progress = body;

        const nextContent = coursePublic.modulos
            .find(m => m.codigo === moduleView?.codigo)
            ?.conteudos.find((c, index) => index === currentIndex + 1);

        if (contentView && contentView.progress && contentView.progress.isCompleted && contentView.progress.codigo !== -1) {
            if (nextContent) {
                return setContentView(nextContent);
            } else {
                return concluirModule();
            }
        }

        const { data, status } = await serviceContent.inserirProgress(body);

        if (status == 201) {
            if (nextContent) {
                setContentView(nextContent);
            }

            setCoursePublic((prev: any) => {
                // Faz uma cópia do estado atual
                const updatedModules = prev.modulos.map((module: FadModulesPublicModel) => {
                    // Verifica se é o módulo que queremos atualizar
                    if (module.codigo === moduleView?.codigo) {
                        return {
                            ...module,
                            conteudos: module.conteudos.map((conteudo: FadContentsPublicModel) => {
                                // Atualiza o conteúdo específico
                                if (conteudo.codigo === content.codigo) {
                                    return { ...content, progress: body }; // Substitui o conteúdo
                                }
                                return conteudo; // Mantém os outros conteúdos
                            }),
                        };
                    }
                    return module; // Mantém os outros módulos
                });

                // Retorna o novo estado
                return {
                    ...prev,
                    modules: updatedModules,
                };
            });

            if (!nextContent) {
                const content = contentView;

                if (content) {
                    const newContent = new FadContentsPublicModel().fromJSON({
                        ...content,
                        progress: body,
                    });

                    setContentView(newContent);
                }

                concluirModule();
            }
        }
        setScrollViewContent(false);
    };

    const openModal = (imgSrc: string) => {
        setCurrentImgSrc(imgSrc);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setCurrentImgSrc('');
    };

    const getTotalContents = () => {
        return moduleView?.conteudos.length;
    };

    const getModulePost = () => {
        const moduloAtualIndex = coursePublic.modulos.findIndex(m => m.codigo === moduleView?.codigo);

        const moduloPosterior = coursePublic.modulos[moduloAtualIndex + 1];

        const temModuloPosterior = moduloPosterior !== undefined;

        if (temModuloPosterior) {
            return moduloPosterior;
        }

        return null;
    };

    const getPostModuleExistent = () => {
        if (getModulePost() != null) {
            return true;
        }

        return false;
    };

    const getModuleLast = () => {
        const moduloAtualIndex = coursePublic.modulos.findIndex(m => m.codigo === moduleView?.codigo);

        const moduloAnterior = coursePublic.modulos[moduloAtualIndex - 1];

        const temModuloAnterior = moduloAnterior !== undefined;

        if (temModuloAnterior) {
            return moduloAnterior;
        }

        return null;
    };

    const getLastModuleExistent = () => {
        if (getModuleLast() != null) {
            return true;
        }

        return false;
    };

    const getModuleComplete = () => {
        const module = coursePublic.modulos.find(m => m.codigo === moduleView?.codigo);

        if (module) {
            if (module.progress) {
                return true;
            }
        }

        return false;
    };
    const getTitleModulePost = () => {
        const moduleIndex = coursePublic.modulos.findIndex(m => m.codigo === moduleView?.codigo);

        const moduleNext = coursePublic.modulos[moduleIndex + 1];

        if (moduleView && moduleNext) {
            return moduleNext.title;
        }

        return null;
    };

    const concluirModule = async () => {
        if (moduleView && classSelected) {
            if (moduleView.progress) {
                if (getPostModuleExistent()) {
                    const modulePost = getModulePost();

                    if (modulePost) {
                        setModuleView(modulePost);
                        setContentView(modulePost.conteudos[0]);
                        return;
                    }
                }
            }

            const body = new FadUserConcluidModulesPublicModel().fromJSON({
                codigo: -1,
                codigoFerramentaAutoriaModule: moduleView.codigo,
                codigoFerramentaAutoriaClasse: classSelected.codigo,
            });

            const { status } = await serviceUserConcluidModules.create(body);

            if (status === 201) {
                if (getModulePost()) {
                    const moduloAtualIndex = coursePublic.modulos.findIndex(m => m.codigo === moduleView?.codigo);

                    const moduloPosterior = coursePublic.modulos[moduloAtualIndex + 1];

                    const temModuloPosterior = moduloPosterior !== undefined;

                    if (temModuloPosterior) {
                        setModuleView(moduloPosterior);
                        setContentView(moduloPosterior.conteudos[0]);
                    }
                }
            }
        }
    };

    const validateAccess = () => {
        if (!inscrito) {
            return history.replace('/cursos/' + page + '/' + curso);
        }

        if (inscrito && classSelected && classSelected.closingDate) {
            const close = isCourseCloseDate(classSelected.closingDate.toString());

            if (close) {
                return history.replace('/cursos/' + page + '/' + curso);
            }
        }
    };

    const handleNextInformationHidden = (currentIndex: number) => {
        setNextInformationIndexHidden((prev: number[]) => [...prev, currentIndex]);
    };

    const handleNextInformationClick = (currentIndex: number) => {
        // Identifica os índices das seções que devem ser desbloqueadas
        if (contentView) {
            const totalComponents = contentView.componentes.length;

            // Filtrar os componentes visíveis (com base no índice presente em visibleSections)
            const visibleComponents = visibleSections.filter(
                index => contentView.componentes[index] // Certifica-se de que o índice está dentro do contentView.componentes
            ).length;

            // Calcula a porcentagem de conclusão
            const newCompletionPercentage = Math.min((visibleComponents / totalComponents) * 100, 100);

            // Condição para marcar como 100% apenas se todos os componentes forem visíveis
            const isComplete = visibleComponents === totalComponents;

            setCompletionPercentage(isComplete ? 100 : newCompletionPercentage);

            const nextVisibleSections: number[] = [];
            let nextComponentId: number | null = null;

            for (let i = currentIndex + 1; i < contentView.componentes.length; i++) {
                const component = contentView.componentes[i];
                nextVisibleSections.push(i);

                // Guarda o ID do próximo componente para rolar até ele
                if (!nextComponentId) {
                    nextComponentId = component.id;
                }

                if (component.tipo === ComponentInteractionEnum.NEXT_INFORMATION) {
                    break;
                }
            }

            setVisibleSections(prev => [...prev, ...nextVisibleSections]);

            setTimeout(() => {
                if (nextComponentId) {
                    const element = componentRefs.current[nextComponentId];

                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

                        // Ajusta um pouco para cima se necessário (exemplo: evitar que o conteúdo fique muito colado no topo)
                        setTimeout(() => {
                            window.scrollBy({ top: 0, left: 0, behavior: 'smooth' });
                        }, 300);
                    }
                }
            }, 500);
        }
    };

    const areAllComponentsVisible = (): boolean => {
        if (!contentView) return false;

        const firstNextIndex = contentView.componentes.findIndex(component => component.tipo === ComponentInteractionEnum.NEXT_INFORMATION);

        const totalComponents = contentView.componentes.length;

        if (totalComponents === 0 || firstNextIndex === -1) {
            return true;
        }

        return visibleSections.length === totalComponents;
    };

    const contentConcluid = (): boolean => {
        if (contentView && contentView.progress && contentView.progress.isCompleted) {
            return true;
        }

        return false;
    };

    function isScreenSmallerThan(width: number) {
        return window.innerWidth < width;
    }

    useEffect(() => {
        if (!isScreenSmallerThan(720)) {
            setOpen(true);
        }
    }, []);

    useEffect(() => {
        if (contentView) {
            const firstNextIndex = contentView.componentes.findIndex(component => component.tipo === ComponentInteractionEnum.NEXT_INFORMATION);

            if (firstNextIndex !== -1) {
                setFirstNextInformationIndex(firstNextIndex);

                // Torna visíveis todos os componentes antes do primeiro NEXT_INFORMATION e o próprio NEXT_INFORMATION
                const initialVisibleSections = Array.from({ length: firstNextIndex + 1 }, (_, i) => i);
                setVisibleSections(initialVisibleSections);
            } else {
                setFirstNextInformationIndex(null);
            }
        }
    }, [contentView]);

    useEffect(() => {
        validateAccess();
    }, [moduleView, contentView]);

    useEffect(() => {
        setNextInformationIndexHidden([]);

        window.scrollTo(0, 0);
    }, [, contentView]);

    useEffect(() => {
        validateAccess();
    }, []);

    return (
        <Styled.Container>
            <FadConentMenuLateralPublic open={open} setOpen={setOpen} porcentContentAtt={completionPercentage} />
            <Styled.Contents menuOpen={open}>
                {coursePublic &&
                    coursePublic.modulos.length > 0 &&
                    coursePublic.modulos
                        .find(m => m.codigo === moduleView?.codigo)
                        ?.conteudos.sort((a, b) => a.ordem - b.ordem)
                        .map((contents, index) => {
                            // Verifica se o módulo atual tem um próximo módulo

                            // Comentário: Aqui verificamos se existe um conteúdo anterior
                            const conteudoAnterior = coursePublic.modulos
                                .find(m => m.codigo === moduleView?.codigo)
                                ?.conteudos.slice(0, index) // Pega todos os conteúdos anteriores
                                .reverse() // Inverte para buscar do mais recente ao mais antigo
                                .find(c => c.codigo !== contents.codigo); // Garante que não é o mesmo código

                            // Verifica se o próximo conteúdo existe
                            const proximoConteudo =
                                coursePublic.modulos
                                    .find(m => m.codigo === moduleView?.codigo) // Encontra o módulo atual
                                    ?.conteudos.slice(index + 1) // Pega os conteúdos posteriores ao índice atual
                                    .find(c => c.codigo !== contents.codigo) || null; // Garante que não pega o mesmo conteúdo

                            // Comentário: Verificamos se o conteúdo anterior está concluído
                            const isConteudoAnteriorConcluido = conteudoAnterior
                                ? conteudoAnterior?.progress?.isCompleted || !coursePublic.course.sequentialContents
                                : true; // Se não existir conteúdo anterior, assume-se que é concluído.

                            if ((isConteudoAnteriorConcluido || index === 0) && contents.codigo === contentView?.codigo) {
                                return (
                                    <div key={index} ref={el => (refs.current[contents.codigo] = el)} data-code={contents.codigo}>
                                        {index !== 0 && conteudoAnterior && (
                                            <>
                                                <FAD_Button_Outline
                                                    styles={{ marginBottom: 22, width: '100%' }}
                                                    icon={<MdArrowUpward />}
                                                    text={`${conteudoAnterior.title} / ( ${contents.ordem + 1} - ${getTotalContents()} )`}
                                                    onClick={() => {
                                                        const indexCurrent = moduleView?.conteudos.findIndex(c => c.codigo === contents.codigo);
                                                        if (moduleView && indexCurrent) {
                                                            setContentView(moduleView?.conteudos[indexCurrent - 1]);
                                                        }
                                                    }}
                                                />
                                            </>
                                        )}

                                        {index === 0 && getLastModuleExistent() && (
                                            <>
                                                <FAD_Button_Outline
                                                    styles={{ marginBottom: 22, width: '100%' }}
                                                    icon={<MdArrowUpward />}
                                                    text={`${getModuleLast()?.title}`}
                                                    onClick={() => {
                                                        const modulo = getModuleLast();
                                                        if (modulo) {
                                                            setModuleView(modulo);

                                                            // Pegar o ultimo conteúdo do módulo.
                                                            const lastContent = modulo.conteudos.at(-1);
                                                            if (lastContent) {
                                                                setContentView(lastContent);
                                                            }
                                                        }
                                                    }}
                                                />
                                            </>
                                        )}

                                        <div style={{ position: 'relative' }} ref={el => (refs.current[contents.codigo] = el)}>
                                            <Styled.ImgHeader img={fetchStorageFileAuthoringTools(moduleView?.anexo.filepath ?? '')}>
                                                <Styled.HeaderTitle>{contents.title}</Styled.HeaderTitle>
                                            </Styled.ImgHeader>
                                        </div>

                                        {contents.componentes
                                            .sort((a, b) => a.ordem - b.ordem)
                                            .map((components, indexComponent) => {
                                                const componentRef = (el: HTMLDivElement | null) => {
                                                    componentRefs.current[components.id] = el;
                                                };

                                                const existNextInformation = contentView.componentes.some(
                                                    (c: ComponentInteractionModel) => c.tipo === ComponentInteractionEnum.NEXT_INFORMATION
                                                );

                                                // Verifica se a seção atual está visível
                                                const isVisible = visibleSections.includes(indexComponent);

                                                // Não renderiza o componente se ele não estiver visível

                                                //!contentConcluid() && existNextInformation (Caso for voltar a regra de se caso estiver concluido o conteudo não apresentar o botão continuar e exibir todos os componentes)
                                                if (!isVisible && existNextInformation) {
                                                    return null;
                                                }

                                                if (components.tipo === ComponentInteractionEnum.AUDIO) {
                                                    return (
                                                        <div
                                                            style={{
                                                                marginTop: 22,
                                                                marginBottom: 22,
                                                            }}
                                                            key={components.id}
                                                            className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                            ref={componentRef}
                                                        >
                                                            <RecursoMediaSwitch anexoUrl={components.conteudo} />
                                                        </div>
                                                    );
                                                }

                                                if (components.tipo === ComponentInteractionEnum.TEXT) {
                                                    return (
                                                        <div
                                                            key={components.id}
                                                            style={{
                                                                backgroundColor: components.cor ?? '',
                                                                padding: 8,
                                                                marginTop: 22,
                                                                marginBottom: 22,
                                                            }}
                                                        >
                                                            <Styled.TextContent
                                                                className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                                ref={componentRef}
                                                                onClick={e => {
                                                                    const target = e.target as HTMLImageElement;
                                                                    if (target.tagName === 'IMG') {
                                                                        openModal(target.src);
                                                                    }
                                                                }}
                                                                onMouseOver={e => {
                                                                    const target = e.target as HTMLImageElement;
                                                                    if (target.tagName === 'IMG') {
                                                                    }
                                                                }}
                                                                dangerouslySetInnerHTML={{ __html: components.conteudo ?? '' }}
                                                            />
                                                        </div>
                                                    );
                                                }
                                                if (components.tipo === ComponentInteractionEnum.NEXT_INFORMATION) {
                                                    return (
                                                        <div
                                                            key={components.id}
                                                            ref={componentRef}
                                                            className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                            style={{
                                                                display: nextInformationIndexHidden.includes(indexComponent) ? 'none' : 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                margin: 22,
                                                            }}
                                                        >
                                                            <FAD_Button_Primary
                                                                text="Continuar"
                                                                onClick={() => {
                                                                    handleNextInformationClick(indexComponent);
                                                                    handleNextInformationHidden(indexComponent);
                                                                }}
                                                                styles={{ width: 600 }}
                                                            />
                                                        </div>
                                                    );
                                                }
                                                if (components.tipo === ComponentInteractionEnum.TWO_TEXT) {
                                                    return (
                                                        <Styled.RowBettween
                                                            key={components.id}
                                                            className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                        >
                                                            <Styled.TextContent
                                                                ref={componentRef}
                                                                onClick={e => {
                                                                    const target = e.target as HTMLImageElement;
                                                                    if (target.tagName === 'IMG') {
                                                                        openModal(target.src);
                                                                    }
                                                                }}
                                                                onMouseOver={e => {
                                                                    const target = e.target as HTMLImageElement;
                                                                    if (target.tagName === 'IMG') {
                                                                    }
                                                                }}
                                                                dangerouslySetInnerHTML={{ __html: components.conteudo ?? '' }}
                                                            />
                                                            <Styled.TextContent
                                                                ref={componentRef}
                                                                onClick={e => {
                                                                    const target = e.target as HTMLImageElement;
                                                                    if (target.tagName === 'IMG') {
                                                                        openModal(target.src);
                                                                    }
                                                                }}
                                                                onMouseOver={e => {
                                                                    const target = e.target as HTMLImageElement;
                                                                    if (target.tagName === 'IMG') {
                                                                    }
                                                                }}
                                                                dangerouslySetInnerHTML={{ __html: components.conteudoTwo ?? '' }}
                                                            />
                                                        </Styled.RowBettween>
                                                    );
                                                }
                                                if (components.tipo === ComponentInteractionEnum.TEXT_IMG_LEFT) {
                                                    return (
                                                        <Styled.RowBettween
                                                            key={components.id}
                                                            className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                        >
                                                            {components.anexo && (
                                                                <Image
                                                                    style={{
                                                                        maxWidth: '1200px',
                                                                    }}
                                                                    src={fetchS3ComponentInteraction(components.anexo.filepath)}
                                                                />
                                                            )}

                                                            <Styled.TextContent
                                                                style={{ margin: 0, width: '90%' }}
                                                                ref={componentRef}
                                                                onClick={e => {
                                                                    const target = e.target as HTMLImageElement;
                                                                    if (target.tagName === 'IMG') {
                                                                        openModal(target.src);
                                                                    }
                                                                }}
                                                                onMouseOver={e => {
                                                                    const target = e.target as HTMLImageElement;
                                                                    if (target.tagName === 'IMG') {
                                                                    }
                                                                }}
                                                                dangerouslySetInnerHTML={{ __html: components.conteudo ?? '' }}
                                                            />
                                                        </Styled.RowBettween>
                                                    );
                                                }
                                                if (components.tipo === ComponentInteractionEnum.TEXT_IMG_RIGHT) {
                                                    return (
                                                        <Styled.RowBettween
                                                            key={components.id}
                                                            className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                        >
                                                            <Styled.TextContent
                                                                style={{ margin: 0, width: !open ? '89%' : '100%' }}
                                                                ref={componentRef}
                                                                onClick={e => {
                                                                    const target = e.target as HTMLImageElement;
                                                                    if (target.tagName === 'IMG') {
                                                                        openModal(target.src);
                                                                    }
                                                                }}
                                                                onMouseOver={e => {
                                                                    const target = e.target as HTMLImageElement;
                                                                    if (target.tagName === 'IMG') {
                                                                    }
                                                                }}
                                                                dangerouslySetInnerHTML={{ __html: components.conteudo ?? '' }}
                                                            />
                                                            {components.anexo && (
                                                                <Image
                                                                    style={{
                                                                        maxWidth: '1200px',
                                                                    }}
                                                                    src={fetchS3ComponentInteraction(components.anexo.filepath)}
                                                                />
                                                            )}
                                                        </Styled.RowBettween>
                                                    );
                                                }

                                                if (components.tipo === ComponentInteractionEnum.TEXT_HIGHLIGHTED) {
                                                    return (
                                                        <div
                                                            key={components.id}
                                                            style={{
                                                                position: 'relative',
                                                                width: '100%',
                                                                minHeight: '300px', // Mantém a altura mínima de 300px
                                                                display: 'flex',
                                                                padding: '54px 12px',
                                                                margin: '22px 0px',
                                                                justifyContent: 'center',
                                                                overflow: 'hidden', // Garante que a imagem não ultrapasse os limites do contêiner
                                                            }}
                                                            className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                        >
                                                            {/* Imagem de fundo */}
                                                            {components.anexo && (
                                                                <div
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        left: 0,
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        backgroundImage: `url(${fetchS3ComponentInteraction(
                                                                            components.anexo.filepath
                                                                        )})`,
                                                                        backgroundSize: 'cover', // Mantém a proporção e cobre todo o contêiner
                                                                        backgroundPosition: 'center', // Centraliza a imagem
                                                                        backgroundRepeat: 'no-repeat', // Evita repetições
                                                                        zIndex: 0, // Mantém a imagem no fundo
                                                                    }}
                                                                />
                                                            )}

                                                            {/* Texto sobre a imagem */}
                                                            <Styled.TextContent
                                                                style={{
                                                                    margin: 0,
                                                                    width: '100%',
                                                                    position: 'relative', // Mantém o texto acima da imagem
                                                                    zIndex: 1, // Coloca o texto sobre a imagem
                                                                    color: 'white', // Ajusta a cor do texto para contraste
                                                                    borderRadius: '5px',
                                                                }}
                                                                ref={componentRef}
                                                                onClick={e => {
                                                                    const target = e.target as HTMLImageElement;
                                                                    if (target.tagName === 'IMG') {
                                                                        openModal(target.src);
                                                                    }
                                                                }}
                                                                dangerouslySetInnerHTML={{ __html: components.conteudo ?? '' }}
                                                            />
                                                        </div>
                                                    );
                                                }

                                                if (components.tipo === ComponentInteractionEnum.SANFONA) {
                                                    return (
                                                        <ConfigProvider
                                                            key={components.id}
                                                            collapse={{
                                                                style: {
                                                                    backgroundColor: components.cor ?? '',
                                                                    marginBottom: '22px',
                                                                },
                                                            }}
                                                        >
                                                            <Collapse
                                                                size="large"
                                                                className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                                ref={componentRef}
                                                                items={components.sanfona?.map((sanfona, idxSanfona) => ({
                                                                    key: idxSanfona,
                                                                    label: (
                                                                        <h1
                                                                            style={{
                                                                                color:
                                                                                    components.cor === Colors.Blue ||
                                                                                    components.cor === Colors.SoftBlue
                                                                                        ? Colors.White
                                                                                        : Colors.Blue,
                                                                                fontWeight: 700,
                                                                            }}
                                                                        >
                                                                            {sanfona.titulo}
                                                                        </h1>
                                                                    ),
                                                                    children: (
                                                                        <>
                                                                            {sanfona.componentSanfonaConteudos?.map((conteudo, idxConteudo) => {
                                                                                if (conteudo.tipoConteudo === ComponentInteractionEnum.TEXT) {
                                                                                    return (
                                                                                        <Styled.TextContent
                                                                                            style={{
                                                                                                margin: 0,
                                                                                                padding: 10,
                                                                                            }}
                                                                                            key={idxConteudo}
                                                                                            onClick={e => {
                                                                                                const target = e.target as HTMLImageElement;
                                                                                                if (target.tagName === 'IMG') {
                                                                                                    openModal(target.src);
                                                                                                }
                                                                                            }}
                                                                                            onMouseOver={e => {
                                                                                                const target = e.target as HTMLImageElement;
                                                                                                if (target.tagName === 'IMG') {
                                                                                                }
                                                                                            }}
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: conteudo.conteudo ?? '',
                                                                                            }}
                                                                                        />
                                                                                    );
                                                                                }

                                                                                if (conteudo.tipoConteudo === ComponentInteractionEnum.AUDIO) {
                                                                                    return (
                                                                                        <RecursoMediaSwitch
                                                                                            key={idxConteudo}
                                                                                            anexoUrl={conteudo.conteudo}
                                                                                        />
                                                                                    );
                                                                                }
                                                                            })}
                                                                        </>
                                                                    ),
                                                                }))}
                                                                expandIcon={({ isActive }) => (
                                                                    <StyledCaretRightOutlined
                                                                        style={{
                                                                            color:
                                                                                components.cor === Colors.Blue ||
                                                                                components.cor === Colors.SoftBlue
                                                                                    ? Colors.White
                                                                                    : Colors.Blue,
                                                                        }}
                                                                        size={22}
                                                                        rotate={isActive ? 90 : 0}
                                                                    />
                                                                )}
                                                            />
                                                        </ConfigProvider>
                                                    );
                                                }

                                                if (components.tipo === ComponentInteractionEnum.QUIZ) {
                                                    return (
                                                        <div
                                                            key={components.id}
                                                            ref={componentRef}
                                                            className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                        >
                                                            <QuizInteractionPublic
                                                                quizzes={components.quiz ?? []}
                                                                componente={components}
                                                                openModal={openModal}
                                                                setBlockConcluirConteudo={b => {}}
                                                            />
                                                        </div>
                                                    );
                                                }

                                                if (components.tipo === ComponentInteractionEnum.CORRESPONDENT) {
                                                    return (
                                                        <div
                                                            key={components.id}
                                                            ref={componentRef}
                                                            className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                        >
                                                            <CorrespondentPublic dados={components.correspondent} />;
                                                        </div>
                                                    );
                                                }

                                                if (components.tipo === ComponentInteractionEnum.CARROSSEL) {
                                                    return (
                                                        <div
                                                            key={components.id}
                                                            ref={componentRef}
                                                            className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                        >
                                                            <CarrosselInteractionPublic dados={components.carrossel} />
                                                        </div>
                                                    );
                                                }

                                                if (components.tipo === ComponentInteractionEnum.ABAS) {
                                                    return (
                                                        <div
                                                            key={components.id}
                                                            ref={componentRef}
                                                            className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                        >
                                                            <ComponentAbasPublic dados={components.abas} openModal={openModal} />
                                                        </div>
                                                    );
                                                }

                                                if (components.tipo === ComponentInteractionEnum.CARD_BARALHO) {
                                                    return (
                                                        <div
                                                            key={components.id}
                                                            ref={componentRef}
                                                            className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                        >
                                                            <ComponentBaralhoPublic
                                                                dados={components.baralho ?? []}
                                                                quantityVisible={components.quantityVisible}
                                                                centralized
                                                                isFad
                                                            />
                                                        </div>
                                                    );
                                                }

                                                if (
                                                    components.tipo === ComponentInteractionEnum.REGUA_AVALIATIVA &&
                                                    components.reguaAvaliativa
                                                ) {
                                                    return (
                                                        <div
                                                            key={components.id}
                                                            ref={componentRef}
                                                            className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                        >
                                                            <ReguaAvaliativaPublic dados={components.reguaAvaliativa} />
                                                        </div>
                                                    );
                                                }
                                                if (components.tipo === ComponentInteractionEnum.TIMELINE && components.timelines) {
                                                    return (
                                                        <div
                                                            key={components.id}
                                                            ref={componentRef}
                                                            className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                        >
                                                            <ComponentTimeLinesPublic
                                                                dados={components.timelines}
                                                                color={components.cor ?? ''}
                                                            />
                                                        </div>
                                                    );
                                                }
                                                if (components.tipo === ComponentInteractionEnum.QUIZ_PONTUACAO && components.quizPontuacao) {
                                                    return (
                                                        <div
                                                            key={components.id}
                                                            ref={componentRef}
                                                            className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                        >
                                                            <ComponentQuizPontucaoPublic dados={components.quizPontuacao} />
                                                        </div>
                                                    );
                                                }
                                                if (components.tipo === ComponentInteractionEnum.CENARIO && components.cenarios) {
                                                    return (
                                                        <div
                                                            key={components.id}
                                                            ref={componentRef}
                                                            className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                        >
                                                            <ComponentCenarioPublic dados={components.cenarios} />
                                                        </div>
                                                    );
                                                }
                                                if (
                                                    components.tipo === ComponentInteractionEnum.DIVIDER_LINE ||
                                                    components.tipo === ComponentInteractionEnum.DIVIDER_LINE_WITH_NUMBER
                                                ) {
                                                    return (
                                                        <div
                                                            key={components.id}
                                                            ref={componentRef}
                                                            className={`component-container ${isVisible ? 'fade-in-up' : ''}`}
                                                        >
                                                            <ComponentDividerPublic dados={components} />
                                                        </div>
                                                    );
                                                }
                                            })}
                                        {areAllComponentsVisible() && (
                                            <>
                                                <FadRaterCommentary
                                                    key={`rater-${contents.codigo}`}
                                                    rate={avaliationContent ? avaliationContent.rate : 0}
                                                    setRate={v => handleAvaliationContent(v)}
                                                    titleRate="Avalie este conteúdo"
                                                />

                                                {/* Colocar a logica caso não tenha proximo conteudo verificar se possui um proximo modulo */}
                                                {proximoConteudo && (
                                                    <>
                                                        <FAD_Button_Outline
                                                            styles={{ marginBottom: 22, width: '100%', zIndex: 2 }}
                                                            icon={<MdArrowDownward />}
                                                            text={`${proximoConteudo.title} / ( ${contents.ordem + 1} - ${getTotalContents()} )`}
                                                            onClick={() => concluirContent(contents, index)}
                                                        />
                                                    </>
                                                )}

                                                {!proximoConteudo && getPostModuleExistent() && (
                                                    <>
                                                        <FAD_Button_Outline
                                                            icon={<MdArrowDownward />}
                                                            styles={{ marginBottom: 22, width: '100%', zIndex: 2 }}
                                                            text={`${getTitleModulePost()}`}
                                                            onClick={() => concluirContent(contents, index)}
                                                        />
                                                    </>
                                                )}

                                                {!proximoConteudo && !getPostModuleExistent() && !getModuleComplete() && (
                                                    <>
                                                        <FAD_Button_Outline
                                                            styles={{ marginBottom: 22, width: '100%', zIndex: 2 }}
                                                            text={`Concluir o curso`}
                                                            onClick={() => concluirContent(contents, index)}
                                                        />
                                                    </>
                                                )}
                                            </>
                                        )}
                                        <AmpliarImgV2 show={modalOpen} onClose={closeModal} imgSrc={currentImgSrc} />
                                    </div>
                                );
                            }
                        })}
            </Styled.Contents>
        </Styled.Container>
    );
};

export default FadConentsPage;
