import { jsonProperty, Serializable, SerializationSettings } from 'ts-serializable';

interface IFadLogUserModules {
    codigo: number;
    codigoFerramentaAutoriaModule: number;
    codigoPerfilUsuario: string;
    dateCreated: string; // Using string to represent LocalDateTime
    dateUpdate: string; // Using string to represent LocalDateTime
}

export class FadLogUserModulesModel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number)
    codigoFerramentaAutoriaModule: number = -1;

    @jsonProperty(String)
    codigoPerfilUsuario: string = '';

    @jsonProperty(String)
    dateCreated: string = '';

    @jsonProperty(String)
    dateUpdate: string = '';

    fromJSON(json: IFadLogUserModules, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.codigoFerramentaAutoriaModule = json.codigoFerramentaAutoriaModule;
        this.codigoPerfilUsuario = json.codigoPerfilUsuario;
        this.dateCreated = json.dateCreated;
        this.dateUpdate = json.dateUpdate;
        return this;
    }
}
