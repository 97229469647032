import { Divider, Progress } from 'antd';
import Colors from 'enums/Colors';
import React, { useContext, useEffect, useState } from 'react';
import { GiCogLock } from 'react-icons/gi';
import { MdCheck } from 'react-icons/md';
import styled from 'styled-components';
import { CgLock, CgLockUnlock } from 'react-icons/cg';
import { FadModulesPublicModel } from 'models/ferramentaAutoria/public/FadModulesPublicModel';
import { FadContentsPublicModel } from 'models/ferramentaAutoria/public/FadContentsPublicModel';
import { FerramentaAutoriaContext } from 'pages/ferramentaAutoria/context/FerramentaAutoriaContext';
import { useHistory, useParams } from 'react-router-dom';
import keycloak from 'core/config/keycloak';
import { KeycloakLoginOptions } from 'keycloak-js';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
    `,

    Header: styled.div`
        display: flex;
        flex-direction: column;
        margin-bottom: 22px;
    `,

    HeaderDestaque: styled.div`
        width: fit-content;
        padding: 10px;
        color: ${Colors.Blue};
        background-color: ${Colors.Yellow};
        font-family: 'Barlow';
        font-size: 18px;
        font-weight: 400;
        line-height: 25.2px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        border-radius: 10px 10px 0px 0px;
    `,

    Divider: styled.div`
        width: 100%;
        height: 1px;
        background-color: ${Colors.Yellow};
        margin-bottom: 12px;
    `,

    Description: styled.p`
        //styleName: Base/H5;
        font-family: 'Barlow';
        font-size: 18px;
        font-weight: 400;
        line-height: 25.2px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        max-width: 1280px;
    `,

    Content: {
        Container: styled.div<{ desbloquead: boolean }>`
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            background-color: #fafafa;
            min-height: 56px;
            margin-bottom: 12px;
            //styleName: Base/Subtitulo;
            font-family: 'Barlow';
            font-size: 18px;
            font-weight: 600;
            line-height: 25.2px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            transition: all 0.5s ease-in;
            cursor: ${({ desbloquead }) => (desbloquead ? 'pointer' : 'not-allowed')};

            &:hover {
                background-color: ${({ desbloquead }) => (desbloquead ? Colors.Blue : '')};
                color: ${({ desbloquead }) => (desbloquead ? Colors.White : '')};

                .text-custom-progress {
                    color: ${({ desbloquead }) => (desbloquead ? Colors.White : '')} !important;
                }
            }
        `,
    },
};

interface IContent {
    content: FadContentsPublicModel;
    module: FadModulesPublicModel;
    isFirstConent: boolean;
    lasContentCompleted: boolean;
}

const Content = ({ content, isFirstConent, lasContentCompleted, module }: IContent) => {
    const { page, curso }: any = useParams();
    const [porcent, setPorcent] = useState<number>(0);
    const { setContentView, contentView, coursePublic, setModuleView, setScrollViewContent, courseClose } = useContext(
        FerramentaAutoriaContext.Context
    );
    const desbloqueado: boolean = isFirstConent || (content.progress && content.progress.isCompleted) || lasContentCompleted;
    const history = useHistory();

    const goToAccess = () => {
        setScrollViewContent(true);
        if (!keycloak.authenticated) {
            const options: KeycloakLoginOptions = {
                redirectUri: window.location.origin + '/login?' + '/cursos' + '/' + page + '/' + curso,
            };

            const _url =
                coursePublic.course.typeRedirect === TipoRedirecionamento.CADASTRO
                    ? keycloak.createRegisterUrl(options)
                    : keycloak.createLoginUrl(options);

            window.location.assign(_url);
            return;
        }

        history.push(`/cursos/${page}/${curso}/conteudos`);
        //Mandar para o conteudo que não está completado.
    };

    const goToContent = () => {
        if (desbloqueado && !courseClose) {
            setContentView(content);
            setModuleView(module);
            goToAccess();
        }
    };

    useEffect(() => {
        if (content.progress) {
            setPorcent(content.progress.percentageCompleted);
        }
    }, [content]);

    return (
        <Styled.Content.Container desbloquead={desbloqueado && !courseClose} onClick={goToContent}>
            {content.title}
            <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                {content.progress && content.progress.isCompleted ? (
                    <Progress
                        className="custom-progress"
                        strokeWidth={10}
                        percent={100}
                        type="circle"
                        format={percent => (
                            <span style={{ fontSize: '10px', color: '#555', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <MdCheck size={16} color="#52c41a" />
                            </span>
                        )}
                    />
                ) : (
                    desbloqueado && (
                        <Progress
                            className="custom-progress"
                            strokeWidth={10}
                            percent={porcent}
                            showInfo={false}
                            type="circle"
                            format={percent => (
                                <span
                                    className="text-custom-progress"
                                    style={{ fontSize: '10px', color: '#555', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    {percent === 100 ? <MdCheck size={16} color="#52c41a" /> : percent + '%'}
                                </span>
                            )}
                        />
                    )
                )}
                {desbloqueado && !courseClose ? (
                    <CgLockUnlock size={22} color={Colors.SoftBlue} />
                ) : (
                    <CgLock size={22} color={Colors.SoftBlue} />
                )}
            </div>
        </Styled.Content.Container>
    );
};

interface IFadListModulesPublic {
    modulo: FadModulesPublicModel;
    isFirstModule: boolean;
    lastModuloCompleted: boolean;
    modulesSequences: boolean;
    contentsSequences: boolean;
}

const FadListModulesPublic = ({ modulo, isFirstModule, lastModuloCompleted, modulesSequences, contentsSequences }: IFadListModulesPublic) => {
    const getLastContentCompleted = (indexCurrent: number) => {
        const conteudoLast = modulo.conteudos[indexCurrent - 1];

        if (!conteudoLast && !isFirstModule) {
            if (lastModuloCompleted || !modulesSequences) {
                return true;
            } else {
                return false;
            }
        }

        if (!contentsSequences) {
            return true;
        }

        if (conteudoLast && conteudoLast.progress && conteudoLast.progress.isCompleted) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <Styled.Container>
            <Styled.Header>
                <Styled.HeaderDestaque>{modulo.title}</Styled.HeaderDestaque>
                <Styled.Divider />
                <Styled.Description>{modulo.description}</Styled.Description>
            </Styled.Header>
            {modulo?.conteudos?.length > 0 &&
                modulo.conteudos
                    .sort((a, b) => a.ordem - b.ordem)
                    .map((c, i) => (
                        <Content
                            key={i}
                            content={c}
                            isFirstConent={isFirstModule && i === 0}
                            lasContentCompleted={getLastContentCompleted(i)}
                            module={modulo}
                        />
                    ))}
        </Styled.Container>
    );
};

export default FadListModulesPublic;
