import { Image, notification, Skeleton } from 'antd';
import keycloak from 'core/config/keycloak';
import { FadUserClassesService } from 'core/http/service/ferramentaAutoria/FadUserClasses.service';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import Colors from 'enums/Colors';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { fetchStorageFile, fetchStorageFileAuthoringTools } from 'hooks/StoreFiles';
import useFetch from 'hooks/useFetch';
import { KeycloakLoginOptions } from 'keycloak-js';
import { FadCoursesAnexoModel } from 'models/ferramentaAutoria/FadCourseAnexoModel';
import { FadCoursesModel } from 'models/ferramentaAutoria/FadCoursesModel';
import moment from 'moment';
import { TemplateCourseFadEnum } from 'pages/ferramentaAutoria/components/courses/TemplateCourse';
import { FAD_Button_Outline, FAD_Button_Primary } from 'pages/ferramentaAutoria/components/FadButtons';
import { FerramentaAutoriaContext } from 'pages/ferramentaAutoria/context/FerramentaAutoriaContext';
import { TextDangerouslySetInnerHtml } from 'pages/recursos/recursos-conteudo/RecursosConteudoPage.styled';
import React, { useContext, useState } from 'react';
import { GrCertificate } from 'react-icons/gr';
import { useHistory, useParams } from 'react-router-dom';
import { isMobileDevice } from 'react-select/src/utils';
import styled, { css } from 'styled-components';

const ImageWrapper = styled.div<{ $templateBanner: TemplateCourseFadEnum }>`
    width: ${({ $templateBanner }) => ($templateBanner === TemplateCourseFadEnum.Banner ? '100%' : '720px')};
    height: 240px;
    border-radius: 8px;
    overflow: hidden; // Garante que a imagem respeite o border-radius
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 720px) {
        width: 100%;
        height: auto;
    }
`;


const Styled = {
    Container: styled.div<{ typeTemplateL: boolean }>`
        display: flex;
        flex-direction: row;
        justify-content: ${({ typeTemplateL }) => (typeTemplateL ? 'space-between' : 'start')};
        flex-direction: ${({ typeTemplateL }) => (typeTemplateL ? 'row' : 'column-reverse')};
        margin-top: 22px;
        gap: 16px;

        @media screen and (max-width: 720px) {
            flex-direction: column-reverse;
        }
    `,

    ContentTemplate: styled.div`
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;
    `,

    Title: styled.h1<{ typeTemplateL: boolean }>`
        //styleName: Base/H3;
        font-family: 'Barlow';
        font-size: 28px;
        font-weight: 700;
        line-height: 33.6px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: ${Colors.Blue};
        max-width: ${({ typeTemplateL }) => (typeTemplateL ? '620px' : '90%')};
    `,

    Description: styled.p<{ typeTemplateL: boolean }>`
        //styleName: Base/Corpo;
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        max-width: ${({ typeTemplateL }) => (typeTemplateL ? '720px' : '90%')};
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    `,
    Tag: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333333;
        background-color: #99e3ff;
        height: Hug 28px;
        padding: 4px 8px 4px 8px;
        gap: 16px;
        border-radius: 8px;
        opacity: 0px;
        width: fit-content;
    `,

    Row: styled.div`
        display: flex;
        gap: 16px;
    `,

    SkeletonImg: styled.div``,

    Imagem: styled(Image)<{ $templateBanner: TemplateCourseFadEnum }>`
        width: ${({ $templateBanner }) => ($templateBanner == TemplateCourseFadEnum.Banner ? '100%' : '520px')};
        height: 240px;
        object-fit: cover;
        border-radius: 8px;
    `,
};

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface IFadCourseTemplatePublic {
    course: FadCoursesModel;
    anexo: FadCoursesAnexoModel;
    courseInitiated: boolean;
}

const FadCourseTemplatePublic = ({ course, anexo, courseInitiated }: IFadCourseTemplatePublic) => {
    const { page, curso }: any = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const history = useHistory();
    const {
        setContentView,
        inscrito,
        coursePublic,
        setClassSelected,
        setInscricao,
        classSelected,
        isCourseCloseDate,
        courseClose,
        setModuleView,
        emissaoRealizada,
        handleClickCertificado,
    } = useContext(FerramentaAutoriaContext.Context);
    const isLoggedin: Boolean = useAppSelector(state => state.profileReducer.isLoggedin);
    const [api, contextHolder] = notification.useNotification();
    const [finishCourse, setFinishCourse] = useState<boolean>(false);

    //services
    const serviceFadUserClasse = new FadUserClassesService();

    const openNotificationWithIcon = (type: NotificationType, title: string, description: string) => {
        api[type]({
            message: title,
            description: <p dangerouslySetInnerHTML={{ __html: description }} />,
            duration: 7,
        });
    };

    useFetch(() => {
        if (course.codigo != -1) {
            if (course.template === TemplateCourseFadEnum.Lateral) {
                return setLoading(false);
            }
            const timer = setTimeout(() => {
                setLoading(false);
            }, 1000);

            // Limpa o temporizador se o componente for desmontado ou o efeito reexecutado
            return () => clearTimeout(timer);
        }
    }, [course]);

    const inscreverSe = async () => {
        if (coursePublic) {
            const { data, status } = await serviceFadUserClasse.create(coursePublic.course.codigo);

            if (status === 201) {
                setClassSelected(data);
                setInscricao(true);
                openNotificationWithIcon('success', 'Inscrição realizada!', ' Sua inscrição foi realizada com suscesso.');
            }

            if (status === 423) {
                openNotificationWithIcon(
                    'error',
                    'Prazo Encerrado!',
                    `O prazo para a inscrição terminou! <strong> ${moment(data.error).format('DD/MM/YYYY')}</strong>`
                );
            }
            if (status === 403) {
                openNotificationWithIcon('error', 'Inscrição negada!', `Você não consegue se inscrever neste curso!`);
            }
        }
    };

    const goToAccess = () => {
        if (!keycloak.authenticated) {
            const options: KeycloakLoginOptions = {
                redirectUri: window.location.origin + '/login?' + '/cursos' + '/' + page + '/' + curso,
            };

            const _url =
                course.typeRedirect === TipoRedirecionamento.CADASTRO ? keycloak.createRegisterUrl(options) : keycloak.createLoginUrl(options);

            window.location.assign(_url);
            return;
        }
    };

    const handleClick = () => {
        if (!isLoggedin) {
            return goToAccess();
        }

        if (!inscrito) {
            // Chamar a funcionalidade de inscrever-se o usuário no curso atual;
            return inscreverSe();
        }

        if (inscrito && !courseInitiated) {
            const firstModulo = coursePublic.modulos[0];

            if (firstModulo) {
                setModuleView(firstModulo);

                const content = firstModulo.conteudos[0];

                if (content) {
                    setContentView(content);
                }
            }

            history.push(`/cursos/${page}/${curso}/conteudos`);
        }

        if (courseInitiated) {
            // chamar a funcionalidade para encontrar a onde o usuário parou, ou seja o ultimo conteúdo que não esteja como concluído.

            const moduleNotFinish = coursePublic.modulos.find(m => !m.progress);

            if (moduleNotFinish) {
                setModuleView(moduleNotFinish);

                const reversedIndex = moduleNotFinish?.conteudos
                    .slice()
                    .reverse()
                    .findIndex(c => c.progress && c.progress.isCompleted);

                const contentIndexFinish = reversedIndex !== -1 ? moduleNotFinish.conteudos.length - 1 - reversedIndex : -1;

                const nextContent = moduleNotFinish?.conteudos[contentIndexFinish + 1] ?? null;

                if (nextContent) {
                    setContentView(moduleNotFinish.conteudos[contentIndexFinish + 1]);
                } else {
                    setContentView(moduleNotFinish.conteudos[contentIndexFinish]);
                }
            } else {
                const moduleLastFinish = coursePublic.modulos.at(-1);

                if (moduleLastFinish) {
                    setModuleView(moduleLastFinish);

                    const contentFinish = moduleLastFinish.conteudos.at(-1);

                    if (contentFinish) {
                        setContentView(contentFinish);
                    }
                }
            }

            return history.push(`/cursos/${page}/${curso}/conteudos`);
        }
    };

    useFetch(() => {
        if (coursePublic) {
            if (coursePublic.modulos.length > 0) {
                setFinishCourse(coursePublic.modulos.every(m => m.progress));
            }
        }
    }, [coursePublic]);

    return (
        <Styled.Container typeTemplateL={course.template == TemplateCourseFadEnum.Lateral}>
            {contextHolder}
            {loading ? (
                <>
                    <Styled.ContentTemplate>
                        <Skeleton active style={{ maxWidth: course.template == TemplateCourseFadEnum.Lateral ? '620px' : '100%' }} />
                        <Skeleton.Button active style={{ width: '220px', height: '56px' }} />
                    </Styled.ContentTemplate>
                    <Skeleton.Image
                        active
                        style={{
                            width: course.template === TemplateCourseFadEnum.Banner ? '100%' : '520px',
                            height: '240px',
                        }}
                    />
                </>
            ) : (
                <>
                    <Styled.ContentTemplate>
                        <Styled.Title typeTemplateL={course.template === TemplateCourseFadEnum.Lateral}>{course.name}</Styled.Title>
                        <TextDangerouslySetInnerHtml
                            dangerouslySetInnerHTML={{ __html: course.description }}
                            style={{ margin: 0, maxWidth: course.template === TemplateCourseFadEnum.Lateral ? '720px' : '100%' }}
                        />
                        {/**Color a logica de se estiver logado buscar para ver se o usuário tem algum progresso, caso tenha apresentar o texto
                         *
                         * Continuar
                         * caso não tenha e esteja logado: Iniciar o curso
                         * Caso esteja deslogado: Realizar meu acesso
                         */}
                        {classSelected && (
                            <>
                                {classSelected.closingDate != null && (
                                    <Styled.Tag>
                                        {isCourseCloseDate(classSelected.closingDate.toString()) ? (
                                            <>
                                                Curso encerrado. A data limite foi:
                                                <strong>
                                                    {' '}
                                                    {moment(classSelected.closingDate.toString()).subtract(1, 'days').format('DD/MM/YYYY')}
                                                </strong>
                                            </>
                                        ) : (
                                            <>
                                                Curso disponível até:{' '}
                                                <strong>
                                                    {' '}
                                                    {moment(classSelected.closingDate.toString()).subtract(1, 'days').format('DD/MM/YYYY')} -
                                                    23:59
                                                </strong>
                                            </>
                                        )}
                                    </Styled.Tag>
                                )}
                            </>
                        )}
                        <Styled.Row>
                            <FAD_Button_Primary
                                disabled={courseClose}
                                text={
                                    courseClose
                                        ? 'Curso encerrado'
                                        : isLoggedin
                                        ? courseInitiated
                                            ? 'Continuar'
                                            : inscrito
                                            ? 'Iniciar o curso'
                                            : 'Inscrever-se'
                                        : 'Autenticar-se para continuar'
                                }
                                onClick={() => handleClick()}
                                styles={{ width: '220px' }}
                            />

                            {finishCourse && (
                                <FAD_Button_Outline
                                    text={
                                        emissaoRealizada && !coursePublic.certificacao.precisaSolicitar
                                            ? 'Emitir 2ª via'
                                            : emissaoRealizada && coursePublic.certificacao.precisaSolicitar
                                            ? 'Você já solicitou este certificado'
                                            : coursePublic.certificacao.precisaSolicitar
                                            ? 'Soliciar certificado'
                                            : 'Emitir certificado'
                                    }
                                    onClick={() => handleClickCertificado()}
                                    styles={{ backgroundColor: Colors.SoftBlue, fontWeight: 'bold', color: Colors.White, minWidth: 200 }}
                                    icon={<GrCertificate />}
                                />
                            )}
                        </Styled.Row>
                    </Styled.ContentTemplate>
                    <ImageWrapper $templateBanner={course.template}>
                        <Image
                            src={fetchStorageFileAuthoringTools(anexo.filepath)}
                            fallback={fetchStorageFile('anexo_storage_files_12734497904244904460.jpg_2024-12-23-1734954777648.jpg')}

                        />
                    </ImageWrapper>
                </>
            )}
        </Styled.Container>
    );
};

export default FadCourseTemplatePublic;
