import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FAD_Button_Outline, FAD_Button_Primary } from '../components/FadButtons';
import { IoIosArrowBack, IoMdArrowDown, IoMdArrowUp } from 'react-icons/io';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Collapse, ColorPicker, ConfigProvider, Divider, Select, Skeleton, Switch } from 'antd';
import { FaPlay } from 'react-icons/fa';
import { BsFillSendFill } from 'react-icons/bs';
import Colors from 'enums/Colors';
import { MdDeleteForever, MdEditSquare, MdOutlineSettings, MdPublic, MdSaveAs, MdSettings } from 'react-icons/md';
import FadAdds from '../components/adds/FadAdds';
import useFetch from 'hooks/useFetch';
import { FadModulesService } from 'core/http/service/ferramentaAutoria/FadModules.service';
import { FadModulesModel } from 'models/ferramentaAutoria/FadModulesModel';
import ModalFormModule from '../components/modals/ModalFormModule';
import { FadContentsModel } from 'models/ferramentaAutoria/FadContentsModel';
import { FadContentsService } from 'core/http/service/ferramentaAutoria/FadContents.service';
import AdminFadMenuLateral from '../components/menuLateral/AdminFadMenuLateral';
import { ComponentInteractionService } from 'core/http/service/componetInteraction/ComponentInteraction.service';
import ComponentInteractionSimpleModel from 'models/componentInteraction/ComponentInterationSimple';
import { ToastSuccess } from 'components/Toaster';
import ComponentInteractionModel from 'models/componentInteraction/ComponentInteraction';
import { ComponentInteractionEnum } from 'enums/ComponentInteractionEnum';
import ReactQuill, { Quill } from 'react-quill';
import editorConfig from 'pages/diarioBordo/components/textEditor/editorConfig';
import '../components/essentials/reactQuill.css';
import ImageUpload from 'components/uploader/ImageUpload';
import AddNewComponent from '../components/essentials/AddNewComponent';
import FunctionsFAD from '../helpers/FunctionsFAD';
import { ComponentInteractionAnexo } from 'models/componentInteraction/ComponentInteractionAnexo';
import QuizInteractionAdmin from 'pages/gestao_paginas/components/interactions/QuizInteractionAdmin';
import ComponentQuizModel from 'models/componentInteraction/ComponentQuiz';
import ComponentQuizAlternativasModel from 'models/componentInteraction/ComponentQuizAlternativas';
import { ComponentQuizService } from 'core/http/service/componetInteraction/ComponentQuiz.service';
import ComponentReguaAvaliativaAdmin from 'pages/gestao_paginas/components/interactions/ComponentReguaAvaliativaAdmin';
import ComponentQuizPontucaoAdmin from 'pages/gestao_paginas/components/interactions/ComponentQuizPontucaoAdmin';
import SanfonaInteractionAdmin from 'pages/gestao_paginas/components/interactions/SanfonaInteractionAdmin';
import { Option } from 'antd/es/mentions';
import ComponentSanfonaConteudoModel from 'models/componentInteraction/ComponentSanfonaConteudo';
import { CaretRightOutlined } from '@ant-design/icons';
import ComponentSanfonaModel from 'models/componentInteraction/ComponentSanfona';
import CorrespondenciaInteractionAdmin from 'pages/gestao_paginas/components/interactions/CorrespondenciaInteractionAdmin';
import CorrespondentItemModel from 'models/componentInteraction/CorrespondentItemModel';
import CarrosselInteractionAdmin from 'pages/gestao_paginas/components/interactions/CarrosselInteractionAdmin';
import ComponentItemCarrosselInteraction from 'models/componentInteraction/ComponentItemCarrosselInteraction';
import ComponentBaralhoAdmin from 'pages/gestao_paginas/components/interactions/ComponentBaralhoAdmin';
import ComponentTimeLineAdmin from 'pages/gestao_paginas/components/interactions/ComponentTimeLineAdmin';
import ComponentCenarioAdmin from 'pages/gestao_paginas/components/interactions/ComponentCenarioAdmin';
import ComponentAbasAdmin from 'pages/gestao_paginas/components/interactions/ComponentAbasAdmin';
import ComponentAbasModel from 'models/componentInteraction/ComponentAbas';
import { Form } from 'react-bootstrap';
import ModalFormContent from '../components/modals/ModalFormContent';
import { FadRelationsTemplateService } from 'core/http/service/ferramentaAutoria/FadRelationsTemplate.service';
import { FadRelationsTemplateModel } from 'models/ferramentaAutoria/FadRelationsTemplateModel';
import { HiLockClosed } from 'react-icons/hi';
import { FadModulesAnexoModel } from 'models/ferramentaAutoria/FadModulesAnexoModel';
import { FadModulesAnexoService } from 'core/http/service/ferramentaAutoria/FadModulesAnexo.service';
import { colors } from 'react-select/src/theme';
import ComponentDividersAdmin from 'pages/gestao_paginas/components/interactions/ComponentDividersAdmin';

const StyledCaretRightOutlined = styled(CaretRightOutlined)``;

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;
        overflow: hidden;
        background-color: ${Colors.White};
        padding: 22px;
    `,

    Header: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 80px;
        border-bottom: 1px solid #999999;
    `,

    Buttons: styled.div`
        display: flex;
        gap: 16px;
    `,

    ContentInfoModule: {
        Container: styled.div`
            display: flex;
            flex-direction: column;
            gap: 12px;
            border-bottom: 1px solid #999999;
            margin: 20px 0px;
            padding-bottom: 20px;
        `,
    },

    LabelInput: styled.p`
        //styleName: Base/Legenda;
        font-family: 'Barlow';
        font-size: 14px;
        font-weight: 400;
        line-height: 15.6px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    `,

    RowEnd: styled.div`
        display: flex;
        justify-content: end;
        align-items: center;
    `,

    RowBettween: styled.div`
        display: flex;
        justify-content: space-between;
    `,

    TitleLarge: styled.h1`
        //styleName: Base/H2;
        font-family: 'Barlow';
        font-size: 36px;
        font-weight: 700;
        line-height: 43.2px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    `,

    Content: {
        Cotainer: styled.div`
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            margin-bottom: 20px;
        `,

        Body: styled.div`
            display: flex;
            flex-direction: column;
            border-top: 2px dashed;
            border-bottom: 2px dashed;
            padding: 12px 4px;
        `,
    },

    ContentComponentInteractions: styled.div`
        display: flex;
        flex-direction: column;
        gap: 1rem;
    `,

    Row: styled.div`
        display: flex;
        gap: 1rem;
    `,

    InputSanfona: styled.input<{ colorSelected: string }>`
        border: none;
        border-bottom: 1px solid #d9d9d9;
        width: 80%;
        height: 42px;
        padding-left: 10px;
        font-size: 18px;
        font-weight: 700;
        color: ${({ colorSelected }) => (colorSelected === Colors.Blue || colorSelected === Colors.SoftBlue ? Colors.White : Colors.Blue)};
        background-color: ${({ colorSelected }) => colorSelected};

        &:focus {
            outline: none;
        }
    `,

    Divisor: styled.div<{ width?: string }>`
        display: flex;
        flex-direction: column;
        width: ${props => (props.width ? props.width : 'auto')};
        margin: 10px;
    `,

    Label: styled.div`
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: #00a8e9;
        margin-bottom: 10px;
    `,
};

interface IContents {
    dados: FadContentsModel;
    addNewComponente: (ordem: number) => void;
    handleSettings: () => void;
    components: ComponentInteractionModel[];
    attComponents: (value: any) => void;
}

const Contents = ({ dados, addNewComponente, components, handleSettings, attComponents }: IContents) => {
    const [allComponents, setAllComponents] = useState<ComponentInteractionModel[]>(components);
    const [anexosComponents, setAnexoComponent] = useState<ComponentInteractionAnexo[]>([]);
    const [quizIndex, setQuizIndex] = useState<number>(0);

    //loaders
    const [loaderComponents, setLoaderComponents] = useState<boolean>(true);
    const [loadingButton, setLoadingButton] = useState<number>(-1);

    const [textIndexImage, setTextIndexImage] = useState<number[]>([]);

    const serviceComponentInteraction = new ComponentInteractionService();
    const serviceComponentQuiz = new ComponentQuizService();

    const handleSaveComponentInteraction = async (component: ComponentInteractionModel) => {
        const data = await FunctionsFAD.handleSaveComponent(component);

        attComponents((prev: any) =>
            prev.map((c: ComponentInteractionModel) => {
                if (c.id === data.id) {
                    return data;
                }

                return c;
            })
        );
        setLoadingButton(-1);
    };

    const handleRemoveComponent = async (index: number, model: ComponentInteractionModel) => {
        if (model.id != null) {
            const response = await serviceComponentInteraction.deleteById(model.id);

            if (response.status === 204) {
                ToastSuccess('Componente deletado com sucesso!');
            }
        }

        attComponents((prevInteractions: any) => prevInteractions.filter((m: ComponentInteractionModel, idx: number) => m.id !== model.id));
        setLoadingButton(-1);
    };

    const atualizarComponentInteraction = (indexComponent: number, component: ComponentInteractionModel) => {
        setAllComponents((prevInteractions: any) =>
            prevInteractions.map((item: any, idx: number) => (idx === indexComponent ? component : item))
        );
    };

    const handleSetCor = (value: string, index: number) => {
        setAllComponents((prevInteractions: any) =>
            prevInteractions.map((item: ComponentInteractionModel, idx: number) => (idx === index ? { ...item, cor: value } : item))
        );
    };

    const handleAtualizationTitleSanfona = (value: string, idxComponent: number, idxSanfona: number) => {
        setAllComponents((prevInteractions: any) =>
            prevInteractions.map((item: ComponentInteractionModel, idx: number) => {
                if (idx === idxComponent && item.sanfona) {
                    return {
                        ...item,
                        sanfona: item.sanfona.map((sanfona, sIdx) => (sIdx === idxSanfona ? { ...sanfona, titulo: value } : sanfona)),
                    };
                }
                return item;
            })
        );
    };

    const handleAtualizationConteudoSanfona = (
        conteudo: string,
        tipoConteudo: ComponentInteractionEnum,
        idxComponent: number,
        idxSanfona: number,
        idxConteudo: number
    ) => {
        setAllComponents((prevInteractions: any[]) =>
            prevInteractions.map((item, idx) => {
                if (idx === idxComponent && item.sanfona) {
                    return {
                        ...item,
                        sanfona: item.sanfona.map((sanfona: ComponentSanfonaModel, sIdx: number) =>
                            sIdx === idxSanfona && sanfona.componentSanfonaConteudos
                                ? {
                                      ...sanfona,
                                      componentSanfonaConteudos: sanfona.componentSanfonaConteudos.map((conteudoItem, cIdx) =>
                                          cIdx === idxConteudo
                                              ? {
                                                    ...conteudoItem,
                                                    conteudo,
                                                    tipoConteudo,
                                                }
                                              : conteudoItem
                                      ),
                                  }
                                : sanfona
                        ),
                    };
                }
                return item;
            })
        );
    };

    const handleAddNewAbaSanfona = (idxComponent: number) => {
        setAllComponents((prevInteractions: any) =>
            prevInteractions.map((item: ComponentInteractionModel, idx: number) => {
                if (idx === idxComponent) {
                    return {
                        ...item,
                        sanfona: [
                            ...(item.sanfona || []),
                            new ComponentSanfonaModel(), // Criação de uma nova aba
                        ],
                    };
                }
                return item;
            })
        );
    };

    const handleAddConteudoSanfona = (conteudo: string, tipoConteudo: ComponentInteractionEnum, idxComponent: number, idxSanfona: number) => {
        setAllComponents((prevInteractions: any) =>
            prevInteractions.map((item: ComponentInteractionModel, idx: number) => {
                if (idx === idxComponent && item.sanfona) {
                    return {
                        ...item,
                        sanfona: item.sanfona.map((sanfona, sIdx) => {
                            if (sIdx === idxSanfona) {
                                const currentConteudos = Array.isArray(sanfona.componentSanfonaConteudos)
                                    ? sanfona.componentSanfonaConteudos
                                    : []; // Garante que seja uma lista

                                return {
                                    ...sanfona,
                                    componentSanfonaConteudos: [
                                        ...currentConteudos,
                                        {
                                            id: -1,
                                            conteudo,
                                            tipoConteudo,
                                        },
                                    ],
                                };
                            }
                            return sanfona;
                        }),
                    };
                }
                return item;
            })
        );
    };

    const handleNewQuiz = (indexComponent: number) => {
        const quiz = new ComponentQuizModel();

        let component = allComponents[indexComponent];

        component.quiz?.push(quiz);

        atualizarComponentInteraction(indexComponent, component);

        setQuizIndex((component.quiz?.length ?? 1) - 1);
    };

    const handleNavigateQuiz = (next: boolean, length: number) => {
        if (!next) {
            if (quizIndex > 0) {
                setQuizIndex(quizIndex - 1);
            }
        } else {
            if (quizIndex + 1 < length) {
                setQuizIndex(quizIndex + 1);
            }
        }
    };

    const atualizarPerguntaQuiz = (indexComponent: number, indexQuiz: number, pergunta: string) => {
        let component = allComponents[indexComponent];

        if (component.quiz) component.quiz[indexQuiz].pergunta = pergunta;

        atualizarComponentInteraction(indexComponent, component);
    };

    const atualizarFeedbackQuiz = (indexComponent: number, indexQuiz: number, feedback: string) => {
        let component = allComponents[indexComponent];

        if (component.quiz) component.quiz[indexQuiz].feedback = feedback;

        atualizarComponentInteraction(indexComponent, component);
    };

    const adicionarNovaAlternativaQuiz = (indexComponent: number, indexQuiz: number) => {
        let component = allComponents[indexComponent];

        if (component.quiz) component.quiz[indexQuiz].alternativas?.push(new ComponentQuizAlternativasModel());

        atualizarComponentInteraction(indexComponent, component);
    };

    const atualizarAlternativaQuiz = (indexComponent: number, indexQuiz: number, indexAlternativa: number, alternativa: string) => {
        let component = allComponents[indexComponent];

        if (component && component.quiz) {
            const quizItem = component.quiz[indexQuiz];

            if (quizItem && quizItem.alternativas && Array.isArray(quizItem.alternativas)) {
                if (indexAlternativa >= 0 && indexAlternativa < quizItem.alternativas.length) {
                    quizItem.alternativas[indexAlternativa].alternativa = alternativa;
                }
            }
        }

        atualizarComponentInteraction(indexComponent, component);
    };

    const removerAlternativaQuiz = async (indexComponent: number, indexQuiz: number, indexAlternativa: number) => {
        let component = allComponents[indexComponent];

        if (component && component.quiz) {
            let quizItem = component.quiz[indexQuiz];

            if (quizItem && quizItem.alternativas && Array.isArray(quizItem.alternativas)) {
                if (indexAlternativa >= 0 && indexAlternativa < quizItem.alternativas.length) {
                    let alternativa: ComponentQuizAlternativasModel = quizItem.alternativas[indexAlternativa];

                    const response = await serviceComponentQuiz.deletarAlternativaQuiz(alternativa.id);

                    if (response.status === 204) {
                        ToastSuccess('Alternativa excluída com sucesso!');

                        quizItem.alternativas.splice(indexAlternativa, 1);
                    }
                }
            }
        }

        atualizarComponentInteraction(indexComponent, component);
    };

    const atualizarCheckAlternativa = (indexComponent: number, indexQuiz: number, indexAlternativa: number, checked: boolean) => {
        let component = allComponents[indexComponent];

        if (component && component.quiz) {
            const quizItem = component.quiz[indexQuiz];

            if (quizItem && quizItem.alternativas && Array.isArray(quizItem.alternativas)) {
                if (indexAlternativa >= 0 && indexAlternativa < quizItem.alternativas.length) {
                    if (quizItem.multiplos) {
                        quizItem.alternativas[indexAlternativa].correct = checked;
                    } else {
                        quizItem.alternativas.map((alt, index) => {
                            if (alt.correct) {
                                return (alt.correct = false);
                            }
                        });

                        quizItem.alternativas[indexAlternativa].correct = checked;
                    }
                }
            }
        }

        atualizarComponentInteraction(indexComponent, component);
    };

    const atualizarObrigatoryQuiz = (indexComponent: number, obrigatory: boolean) => {
        let component = allComponents[indexComponent];

        component.obrigatory = obrigatory;

        atualizarComponentInteraction(indexComponent, component);
    };

    const atualizarMultiplos = (indexComponent: number, indexQuiz: number, multiplos: boolean) => {
        let component = allComponents[indexComponent];

        if (component.quiz) {
            component.quiz[indexQuiz].multiplos = multiplos;

            if (!multiplos) {
                const quizItem = component.quiz[indexQuiz];

                if (quizItem && quizItem.alternativas && Array.isArray(quizItem.alternativas)) {
                    quizItem.alternativas.map((alt, index) => {
                        if (alt.correct) {
                            return (alt.correct = false);
                        }
                    });
                }
            }
        }

        atualizarComponentInteraction(indexComponent, component);
    };

    const deletarQuiz = async (indexComponent: number, indexQuiz: number) => {
        let component = allComponents[indexComponent];

        if (component.quiz) {
            let quizItem = component.quiz[indexQuiz];

            const response = await serviceComponentQuiz.deletarQuiz(quizItem.id);

            if (response.status === 204) {
                ToastSuccess('Quiz excluído com sucesso!');

                component.quiz.splice(indexQuiz, 1);

                if (component.quiz.length === 0) return handleRemoveComponent(indexComponent, component);
            }
        }

        atualizarComponentInteraction(indexComponent, component);

        setQuizIndex(indexQuiz > 0 ? (indexQuiz ?? 1) - 1 : 0);
    };

    const handleSaveImg = async (id: number, img: string) => {
        // Buscar o anexo correspondente
        const existingAnexo = anexosComponents.find(a => a.codigoComponent === id);

        // Criar o objeto anexo
        const anexo = new ComponentInteractionAnexo().fromJSON({
            id: existingAnexo?.id ?? -1,
            filepath: existingAnexo?.filepath ?? '',
            base64: img,
        });

        // Chamada à função para inserir/atualizar o anexo
        const data = await FunctionsFAD.insertAndUpdateAnexoComponentInteraction(anexo, id);

        // Atualizar a lista de anexos, removendo o antigo e adicionando o novo
        setAnexoComponent((prev: ComponentInteractionAnexo[]) => {
            // Verificar se o anexo já existe na lista
            const filtered = prev.filter(a => a.id !== data.id);
            return [...filtered, data];
        });
    };

    const handleItemCarrossel = (indexComponent: number, itensCarrossel: ComponentItemCarrosselInteraction[]) => {
        let component = components[indexComponent];

        if (component && component.carrossel) {
            let carrosselItem = component.carrossel;

            if (carrosselItem) {
                carrosselItem.itens = itensCarrossel;
            }
        }

        atualizarComponentInteraction(indexComponent, component);
    };

    const handleAtualizarCorrespondent = (indexComponent: number, value: string, isPerguta?: boolean) => {
        let component = components[indexComponent];

        if (component && component.correspondent) {
            const correspondent = component.correspondent;

            if (isPerguta) {
                correspondent.pergunta = value;
            }

            if (!isPerguta) {
                correspondent.feedback = value;
            }
        }

        atualizarComponentInteraction(indexComponent, component);
    };

    const handleItemCorrespondent = (indexComponent: number, itens: CorrespondentItemModel[]) => {
        let component = components[indexComponent];

        if (component && component.correspondent) {
            const correspondent = component.correspondent;

            correspondent.itens = itens;
        }

        atualizarComponentInteraction(indexComponent, component);
    };

    const atualizarQuantityVisible = (indexComponent: number, quantity: number) => {
        let component = components[indexComponent];

        component.quantityVisible = quantity;

        atualizarComponentInteraction(indexComponent, component);
    };

    const handleAbas = (indexComponent: number, abas: ComponentAbasModel[]) => {
        let component = components[indexComponent];

        if (component && component.abas) {
            component.abas = abas;
        }

        atualizarComponentInteraction(indexComponent, component);
    };

    const moveComponent = async (index: number, direction: 'up' | 'down') => {
        const newComponents = [...allComponents];

        // Obter o componente atual que queremos mover
        const currentComponent = newComponents[index];

        // Encontrar o índice do componente que deve ser trocado
        const targetIndex = direction === 'up' ? index - 1 : index + 1;

        // Verificar se o índice de destino é válido
        if (targetIndex < 0 || targetIndex >= newComponents.length) {
            return; // Não faz nada se o índice for inválido
        }

        const targetComponent = newComponents[targetIndex];

        // Verificar se os componentes pertencem ao mesmo grupo (codigoContent)
        if (currentComponent.codigoContent === targetComponent.codigoContent) {
            // Trocar as ordens
            const tempOrder = currentComponent.ordem;
            currentComponent.ordem = targetComponent.ordem;
            targetComponent.ordem = tempOrder;

            //Atualizar a ordem.

            const response1 = await serviceComponentInteraction.atualizarOrdem(currentComponent);
            const response2 = await serviceComponentInteraction.atualizarOrdem(targetComponent);

            if (response1.status === 204 && response2.status === 204) {
                // Atualizar o estado com a nova lista de componentes

                setAllComponents(newComponents);
            }
        }
    };

    const handleColorComponentInteraction = (codigo: number, value: string) => {
        setAllComponents((prev: any) =>
            prev.map((c: ComponentInteractionModel) => {
                if (c.id === codigo) {
                    return {
                        ...c,
                        cor: value,
                    };
                }

                return c;
            })
        );
    };

    const FooterOptions = (index: number, component: ComponentInteractionModel, numberLoading: number, justTheOptionToDelete?: boolean) => {
        return (
            <>
                <Styled.Row style={{ alignItems: 'center', marginTop: 22 }}>
                    {!justTheOptionToDelete && (
                        <FAD_Button_Outline
                            onClick={() => {
                                setLoadingButton(numberLoading);
                                handleSaveComponentInteraction(component);
                            }}
                            text=""
                            icon={<MdSaveAs size={26} />}
                            styles={{ width: 200 }}
                            loading={loadingButton === numberLoading}
                        />
                    )}
                    <FAD_Button_Outline
                        onClick={() => {
                            setLoadingButton(10 + numberLoading);
                            handleRemoveComponent(index, component);
                        }}
                        text="Remover componente"
                        styles={{ width: 240 }}
                        icon={<MdDeleteForever size={26} />}
                        remove
                        loading={loadingButton === 10 + numberLoading}
                    />
                    <IoMdArrowUp size={26} cursor={'pointer'} onClick={() => moveComponent(index, 'up')} />
                    <IoMdArrowDown size={26} cursor={'pointer'} onClick={() => moveComponent(index, 'down')} />
                </Styled.Row>
                <Divider />
                <AddNewComponent onClick={() => addNewComponente(component.ordem + 1)} />
            </>
        );
    };

    useFetch(async () => {
        if (anexosComponents.length > 0) {
            return;
        }

        if (components.length > 0) {
            setAnexoComponent([]);

            components.forEach(async c => {
                const data = await FunctionsFAD.fetchAnexoByComponent(c.id, false);

                if (data) {
                    setAnexoComponent((prev: any) => [...prev, data]);
                }
            });
        }
    }, [components]);

    useEffect(() => {
        if (components.length > 0) {
            setAllComponents(components);
        }
    }, [components]);

    useEffect(() => {
        const interval = setInterval(() => {
            setLoaderComponents(false);
        }, 7000);

        return () => clearInterval(interval);
    }, []); // Executa apenas uma vez

    return (
        <Styled.Content.Cotainer>
            <Styled.RowBettween>
                <div style={{ display: 'flex', gap: 16, alignItems: 'center', backgroundColor: Colors.Blue, padding: 22 }}>
                    <Styled.LabelInput style={{ fontSize: 18, color: Colors.White, fontWeight: 600 }}>{dados.title}</Styled.LabelInput>
                    <MdOutlineSettings size={18} cursor={'pointer'} color={Colors.White} onClick={handleSettings} />
                </div>
                <div
                    style={{
                        display: 'flex',
                        gap: 16,
                        alignItems: 'center',
                        padding: 22,
                    }}
                >
                    {dados.isPublic ? <MdPublic size={22} color={Colors.CloneGreen} /> : <HiLockClosed size={22} color={Colors.Grey7} />}
                    <Styled.LabelInput style={{ fontSize: 18, color: dados.isPublic ? Colors.CloneGreen : Colors.Grey7, fontWeight: 600 }}>
                        {dados.isPublic ? 'Conteúdo publicado' : 'Conteúdo privado'}
                    </Styled.LabelInput>
                </div>
            </Styled.RowBettween>
            <Styled.Content.Body>
                {allComponents.length == 0 && loaderComponents && <Skeleton active />}
                {allComponents.length > 0 &&
                    allComponents
                        .sort((a, b) => a.ordem - b.ordem)
                        .map((c: ComponentInteractionModel, i: number) => {
                            let triggerFileUpload: () => void;

                            if (c.tipo === ComponentInteractionEnum.TEXT) {
                                return (
                                    <div key={i}>
                                        <div style={{ backgroundColor: c.cor ?? '' }}>
                                            <ReactQuill
                                                style={{ marginTop: '20px' }}
                                                placeholder="Digite o texto aqui."
                                                theme="snow"
                                                modules={editorConfig.modules}
                                                formats={editorConfig.formats}
                                                value={c.conteudo}
                                                onChange={val =>
                                                    setAllComponents((prev: any) =>
                                                        prev.map((c1: ComponentInteractionModel) => {
                                                            if (c1.id === c.id) {
                                                                return { ...c1, conteudo: val };
                                                            }

                                                            return c1;
                                                        })
                                                    )
                                                }
                                            />
                                        </div>
                                        <ColorPicker
                                            value={c.cor ?? ''}
                                            onChange={v => handleColorComponentInteraction(c.id, v.toHexString())}
                                            style={{ width: 220 }}
                                            showText={color => <span>Cor de fundo ({color.toHexString()})</span>}
                                        />

                                        {FooterOptions(i, c, 1)}
                                    </div>
                                );
                            }

                            if (c.tipo === ComponentInteractionEnum.AUDIO) {
                                return (
                                    <>
                                        <Styled.Divisor width="100%">
                                            <Styled.Label>URL do áudio</Styled.Label>
                                            <Form.Control
                                                value={c.conteudo}
                                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                                    const newContent = evt.target.value;

                                                    // Atualiza o estado sem modificar diretamente o array anterior
                                                    setAllComponents((prevInteractions: any) =>
                                                        prevInteractions.map((item: any, idx: number) =>
                                                            idx === i ? { ...item, conteudo: newContent } : item
                                                        )
                                                    );
                                                }}
                                            />
                                        </Styled.Divisor>
                                        {FooterOptions(i, c, 2)}
                                    </>
                                );
                            }

                            if (c.tipo === ComponentInteractionEnum.NEXT_INFORMATION) {
                                return (
                                    <>
                                        <FAD_Button_Outline text="Continuar" onClick={() => ''} />
                                        {FooterOptions(i, c, 2)}
                                    </>
                                );
                            }

                            if (c.tipo === ComponentInteractionEnum.TWO_TEXT) {
                                return (
                                    <>
                                        <Styled.RowBettween>
                                            <ReactQuill
                                                key={i}
                                                style={{ marginTop: '20px' }}
                                                placeholder="Digite o texto aqui."
                                                theme="snow"
                                                modules={editorConfig.modules}
                                                formats={editorConfig.formats}
                                                value={c.conteudo}
                                                onChange={val =>
                                                    setAllComponents((prev: any) =>
                                                        prev.map((c1: ComponentInteractionModel) => {
                                                            if (c1.id === c.id) {
                                                                return { ...c1, conteudo: val };
                                                            }

                                                            return c1;
                                                        })
                                                    )
                                                }
                                            />
                                            <ReactQuill
                                                key={i}
                                                style={{ marginTop: '20px' }}
                                                placeholder="Digite o texto aqui."
                                                theme="snow"
                                                modules={editorConfig.modules2}
                                                formats={editorConfig.formats2}
                                                value={c.conteudoTwo}
                                                onChange={val =>
                                                    setAllComponents((prev: any) =>
                                                        prev.map((c1: ComponentInteractionModel) => {
                                                            if (c1.id === c.id) {
                                                                return { ...c1, conteudoTwo: val };
                                                            }

                                                            return c1;
                                                        })
                                                    )
                                                }
                                            />
                                        </Styled.RowBettween>
                                        {FooterOptions(i, c, 3)}
                                    </>
                                );
                            }

                            if (c.tipo === ComponentInteractionEnum.TEXT_IMG_LEFT) {
                                return (
                                    <>
                                        <Styled.RowBettween>
                                            <ImageUpload
                                                base64={anexosComponents.find(a => a.codigoComponent === c.id)?.base64 ?? ''}
                                                handleUpload={a => handleSaveImg(c.id, a)}
                                                componentStyled={{ width: '920px', height: '620px' }}
                                                componentImgStyled={{ height: '620px' }}
                                                placeholder="Adicione uma imagem"
                                            />
                                            <ReactQuill
                                                key={i}
                                                style={{ marginTop: '20px', width: '60%' }}
                                                placeholder="Digite o texto aqui."
                                                theme="snow"
                                                modules={editorConfig.modules2}
                                                formats={editorConfig.formats2}
                                                value={c.conteudo}
                                                onChange={val =>
                                                    setAllComponents((prev: any) =>
                                                        prev.map((c1: ComponentInteractionModel) => {
                                                            if (c1.id === c.id) {
                                                                return { ...c1, conteudo: val };
                                                            }

                                                            return c1;
                                                        })
                                                    )
                                                }
                                            />
                                        </Styled.RowBettween>
                                        {FooterOptions(i, c, 4)}
                                    </>
                                );
                            }

                            if (c.tipo === ComponentInteractionEnum.TEXT_IMG_RIGHT) {
                                return (
                                    <>
                                        <Styled.RowBettween>
                                            <ReactQuill
                                                key={i}
                                                style={{ marginTop: '20px', width: '60%' }}
                                                placeholder="Digite o texto aqui."
                                                theme="snow"
                                                modules={editorConfig.modules2}
                                                formats={editorConfig.formats2}
                                                value={c.conteudo}
                                                onChange={val =>
                                                    setAllComponents((prev: any) =>
                                                        prev.map((c1: ComponentInteractionModel) => {
                                                            if (c1.id === c.id) {
                                                                return { ...c1, conteudo: val };
                                                            }

                                                            return c1;
                                                        })
                                                    )
                                                }
                                            />
                                            <ImageUpload
                                                base64={anexosComponents.find(a => a.codigoComponent === c.id)?.base64 ?? ''}
                                                handleUpload={a => handleSaveImg(c.id, a)}
                                                componentStyled={{ width: '920px', height: '620px', flexDirection: 'column' }}
                                                componentImgStyled={{ height: '620px' }}
                                                placeholder="Adicione uma imagem"
                                            />
                                        </Styled.RowBettween>
                                        {FooterOptions(i, c, 5)}
                                    </>
                                );
                            }

                            if (c.tipo === ComponentInteractionEnum.TEXT_HIGHLIGHTED) {
                                return (
                                    <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }} key={i}>
                                        <ImageUpload
                                            base64={anexosComponents.find(a => a.codigoComponent === c.id)?.base64 ?? ''}
                                            handleUpload={a => handleSaveImg(c.id, a)}
                                            componentStyled={{ width: '100%', minHeight: '380px' }}
                                            componentImgStyled={{ minHeight: '380px', position: 'absolute' }}
                                            placeholder="Adicione uma imagem"
                                            isVisibleButton
                                        />

                                        <ReactQuill
                                            key={i}
                                            style={{ marginTop: '20px', width: '100%', position: 'absolute', height: '320px' }}
                                            placeholder="Digite o texto aqui."
                                            theme="snow"
                                            modules={editorConfig.modules2}
                                            formats={editorConfig.formats2}
                                            value={c.conteudo}
                                            onChange={val =>
                                                setAllComponents((prev: any) =>
                                                    prev.map((c1: ComponentInteractionModel) => {
                                                        if (c1.id === c.id) {
                                                            return { ...c1, conteudo: val };
                                                        }

                                                        return c1;
                                                    })
                                                )
                                            }
                                        />

                                        {FooterOptions(i, c, 5)}
                                    </div>
                                );
                            }

                            if (c.tipo === ComponentInteractionEnum.QUIZ) {
                                return (
                                    <>
                                        {c.quiz &&
                                            c.quiz.map((quiz, index) => {
                                                if (quizIndex === index) {
                                                    return (
                                                        <QuizInteractionAdmin
                                                            key={index}
                                                            dados={quiz}
                                                            maxListQuiz={c.quiz?.length ?? 1}
                                                            myIndex={index + 1}
                                                            component={c}
                                                            handleNewQuiz={() => handleNewQuiz(i)}
                                                            handleNavigateQuiz={v => handleNavigateQuiz(v, c.quiz?.length ?? 1)}
                                                            atualizarPerguntaQuiz={v => atualizarPerguntaQuiz(i, index, v)}
                                                            atualizarFeedbackQuiz={v => atualizarFeedbackQuiz(i, index, v)}
                                                            adicionarNovaAlternativaQuiz={() => adicionarNovaAlternativaQuiz(i, index)}
                                                            atualizarAlternativaQuiz={(indexAlternativa, value) =>
                                                                atualizarAlternativaQuiz(i, index, indexAlternativa, value)
                                                            }
                                                            atualizarCheckAlternativa={(indexAlternativa, checked) =>
                                                                atualizarCheckAlternativa(i, index, indexAlternativa, checked)
                                                            }
                                                            atualizarObrigatory={v => atualizarObrigatoryQuiz(i, v)}
                                                            atualizarMultiplos={v => atualizarMultiplos(i, index, v)}
                                                            removerAlternativaQuiz={v => removerAlternativaQuiz(i, index, v)}
                                                            deletarQuiz={() => deletarQuiz(i, index)}
                                                        />
                                                    );
                                                }
                                            })}
                                        {FooterOptions(i, c, 6)}
                                    </>
                                );
                            }

                            if (c.tipo === ComponentInteractionEnum.REGUA_AVALIATIVA) {
                                return (
                                    <>
                                        <ComponentReguaAvaliativaAdmin dados={c.reguaAvaliativa} codigoInteraction={c.id} />
                                        {FooterOptions(i, c, 7, true)}
                                    </>
                                );
                            }

                            if (c.tipo === ComponentInteractionEnum.QUIZ_PONTUACAO) {
                                return (
                                    <>
                                        <ComponentQuizPontucaoAdmin dados={c.quizPontuacao} codigoInteraction={c.id} />
                                        {FooterOptions(i, c, 8, true)}
                                    </>
                                );
                            }
                            if (c.tipo === ComponentInteractionEnum.SANFONA) {
                                return (
                                    <>
                                        <Styled.ContentComponentInteractions key={i}>
                                            <Divider />
                                            <Styled.Row>
                                                <Select
                                                    defaultValue={c.cor}
                                                    value={c.cor}
                                                    style={{ width: 200, height: 46 }}
                                                    onChange={v => handleSetCor(v, i)}
                                                >
                                                    <Option value={Colors.Blue}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <ColorPicker value={Colors.Blue} disabledAlpha disabled />
                                                            <p style={{ marginLeft: 10 }}>{Colors.Blue}</p>
                                                        </div>
                                                    </Option>
                                                    <Option value={Colors.SoftBlue}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <ColorPicker value={Colors.SoftBlue} disabledAlpha disabled />
                                                            <p style={{ marginLeft: 10 }}>{Colors.SoftBlue}</p>
                                                        </div>
                                                    </Option>
                                                    <Option value={Colors.Yellow}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <ColorPicker value={Colors.Yellow} disabledAlpha disabled />
                                                            <p style={{ marginLeft: 10 }}>{Colors.Yellow}</p>
                                                        </div>
                                                    </Option>
                                                </Select>
                                            </Styled.Row>

                                            <ConfigProvider
                                                collapse={{
                                                    style: {
                                                        backgroundColor: c.cor ?? '',
                                                    },
                                                }}
                                            >
                                                <Collapse
                                                    size="large"
                                                    items={c.sanfona?.map((sanfona, idxSanfona) => ({
                                                        key: idxSanfona.toString(),
                                                        label: (
                                                            <Styled.InputSanfona
                                                                key={idxSanfona}
                                                                placeholder="Título da Sanfona"
                                                                value={sanfona.titulo}
                                                                colorSelected={c.cor ?? ''}
                                                                onChange={e => handleAtualizationTitleSanfona(e.target.value, i, idxSanfona)}
                                                            />
                                                        ),
                                                        children: (
                                                            <SanfonaInteractionAdmin
                                                                key={idxSanfona}
                                                                onChangeValue={(v, tipo, idxConteudo) =>
                                                                    handleAtualizationConteudoSanfona(v, tipo, i, idxSanfona, idxConteudo)
                                                                }
                                                                handleNewConteud={(val: string, tipoConteudo: ComponentInteractionEnum) =>
                                                                    handleAddConteudoSanfona(val, tipoConteudo, i, idxSanfona)
                                                                }
                                                                conteudos={sanfona.componentSanfonaConteudos ?? []}
                                                            />
                                                        ),
                                                    }))}
                                                    expandIcon={({ isActive }) => (
                                                        <StyledCaretRightOutlined
                                                            style={{
                                                                color:
                                                                    c.cor === Colors.Blue || c.cor === Colors.SoftBlue
                                                                        ? Colors.White
                                                                        : Colors.Blue,
                                                            }}
                                                            size={22}
                                                            rotate={isActive ? 90 : 0}
                                                        />
                                                    )}
                                                />
                                            </ConfigProvider>

                                            <Button
                                                onClick={() => handleAddNewAbaSanfona(i)}
                                                style={{ width: 200, backgroundColor: Colors.Yellow2 }}
                                                type="dashed"
                                            >
                                                Adicionar nova aba
                                            </Button>
                                        </Styled.ContentComponentInteractions>
                                        {FooterOptions(i, c, 9)}
                                    </>
                                );
                            }

                            if (c.tipo === ComponentInteractionEnum.CORRESPONDENT) {
                                return (
                                    <>
                                        <CorrespondenciaInteractionAdmin
                                            dadosCorrespondent={c.correspondent}
                                            handleAtualizarInfos={(value, isPerunta) => {
                                                handleAtualizarCorrespondent(i, value, isPerunta);
                                            }}
                                            handleItens={(itens: CorrespondentItemModel[]) => handleItemCorrespondent(i, itens)}
                                        />
                                        {FooterOptions(i, c, 10)}
                                    </>
                                );
                            }

                            if (c.tipo === ComponentInteractionEnum.CARROSSEL) {
                                return (
                                    <>
                                        <CarrosselInteractionAdmin
                                            items={c.carrossel?.itens ?? []}
                                            handleInfosCarrosel={itens => handleItemCarrossel(i, itens)}
                                        />
                                        {FooterOptions(i, c, 11)}
                                    </>
                                );
                            }

                            if (c.tipo === ComponentInteractionEnum.CARD_BARALHO) {
                                return (
                                    <>
                                        <ComponentBaralhoAdmin
                                            componentInteractionCodigo={c.id}
                                            cardsModels={c.baralho ?? []}
                                            quantityVisible={c.quantityVisible}
                                            atualizarQuantityVisible={(n: number) => atualizarQuantityVisible(i, n)}
                                            isFad
                                        />
                                        {FooterOptions(i, c, 12, true)}
                                    </>
                                );
                            }

                            if (c.tipo === ComponentInteractionEnum.TIMELINE) {
                                return (
                                    <>
                                        <ComponentTimeLineAdmin dados={c.timelines} codigoInteraction={c.id} color={c.cor ?? ''} />
                                        {FooterOptions(i, c, 13, true)}
                                    </>
                                );
                            }

                            if (c.tipo === ComponentInteractionEnum.CENARIO) {
                                return (
                                    <>
                                        <ComponentCenarioAdmin dados={c.cenarios} codigoInteraction={c.id} />
                                        {FooterOptions(i, c, 14, true)}
                                    </>
                                );
                            }

                            if (c.tipo === ComponentInteractionEnum.ABAS) {
                                return (
                                    <>
                                        <ComponentAbasAdmin dados={c.abas} handleAtualizationAbas={abas => handleAbas(i, abas)} />
                                        {FooterOptions(i, c, 15)}
                                    </>
                                );
                            }

                            if (
                                c.tipo === ComponentInteractionEnum.DIVIDER_LINE ||
                                c.tipo === ComponentInteractionEnum.DIVIDER_LINE_WITH_NUMBER
                            ) {
                                return (
                                    <>
                                        <ComponentDividersAdmin
                                            dados={c}
                                            onChange={v =>
                                                setAllComponents((prev: any) =>
                                                    prev.map((c1: ComponentInteractionModel) => {
                                                        if (c1.id === c.id) {
                                                            return { ...c1, conteudo: v };
                                                        }

                                                        return c1;
                                                    })
                                                )
                                            }
                                        />
                                        {FooterOptions(i, c, 16)}
                                    </>
                                );
                            }
                        })}

                {allComponents.length == 0 && !loaderComponents && (
                    <>
                        <AddNewComponent onClick={() => addNewComponente(1)} />
                    </>
                )}
            </Styled.Content.Body>
        </Styled.Content.Cotainer>
    );
};

const AdminFadContents = () => {
    const history = useHistory();
    const { idCurso, idModulo, idConteudo }: any = useParams();
    const [modules, setModules] = useState<FadModulesModel[]>([]);
    const contentRefs = useRef<Record<string, HTMLDivElement | null>>({});

    //loaders
    const [loadingContent, setLoadingContent] = useState<boolean>(true);

    //Menu
    const [openMenu, setOpenMenu] = useState<boolean>(false);

    //Content
    const [openEditContent, setOpenEditContent] = useState<boolean>(false);
    const [formContent, setFormContent] = useState<FadContentsModel | null>();
    //Module
    const [openEditModule, setOpenEditModule] = useState<boolean>(false);
    const [moduleSelected, setModuleSelected] = useState<FadModulesModel | null>(null);
    const [formModule, setFormModule] = useState<FadModulesModel | null>(null);

    //ListContents
    const [contents, setContents] = useState<FadContentsModel[]>([]);
    const [contentSelected, setContentSelected] = useState<FadContentsModel | null>(null);
    const [ordemAdd, setOrdemAdd] = useState<number>(1);
    const [components, setComponents] = useState<ComponentInteractionModel[]>([]);

    const serviceContents = new FadContentsService();
    const serviceModules = new FadModulesService();
    const serviceFadRelations = new FadRelationsTemplateService();
    const serviceComponentInteraction = new ComponentInteractionService();
    const serviceModuleAnexo = new FadModulesAnexoService();

    const serviceFadContent = new FadContentsService();

    //Anexo Módulo
    const [anexo, setAnexo] = useState<FadModulesAnexoModel | null>(null);
    const [loadingAnexo, setLoadingAnexo] = useState<boolean>(true);

    const createUpdateAnexo = async (img: string) => {
        if (moduleSelected) {
            setLoadingAnexo(true);
            let newAnexo = new FadModulesAnexoModel().fromJSON({
                codigo: anexo?.codigo ?? -1,
                codigoFerramentaAutoriaModule: moduleSelected?.codigo,
                filepath: anexo?.filepath ?? '',
                base64: img,
            });

            const { data, status } = await serviceModuleAnexo.create(newAnexo);

            if (status === 201) {
                ToastSuccess('Imagem salva com sucesso!');
                setAnexo(data);
            }
            setLoadingAnexo(false);
        }
    };

    useFetch(async () => {
        setAnexo(null);
        setLoadingAnexo(true);

        if (moduleSelected) {
            const { data, status } = await serviceModuleAnexo.findByModule(moduleSelected.codigo, false);

            if (status === 200) {
                data === '' ? setAnexo(null) : setAnexo(data);
            }
            setLoadingAnexo(false);
        }
    }, [moduleSelected]);

    const handleNewComponent = async (tipo: ComponentInteractionEnum) => {
        if (contentSelected) {
            const component = new ComponentInteractionSimpleModel().fromJSON({
                id: -1,
                conteudo: '',
                conteudoTwo: '',
                cor: '',
                obrigatory: false,
                quantityVisible: 1,
                tipo: tipo,
                ordem: ordemAdd,
                codigoContent: contentSelected.codigo,
            });

            const { data, status } = await serviceComponentInteraction.insertComponentByFAD(contentSelected.codigo, component);

            if (status === 201) {
                ToastSuccess('Componente criado com sucesso!');
                let componentsAttOrdem = components.filter(c => c.codigoContent === contentSelected.codigo && c.ordem >= ordemAdd);

                setComponents((prev: any) => [...prev, data]);

                await handleAttOrdens(componentsAttOrdem);
            }
        }
    };

    const handleNewTemplate = async (codigoTemplate: number) => {
        const template = await serviceFadRelations.getRelationTemplateByCodigo(codigoTemplate);

        if (template.status === 200 && Array.isArray(template.data) && contentSelected) {
            // Ordenando o array com base na ordem
            const sortedRelations = template.data.sort((a: any, b: any) => a.ordem - b.ordem);

            for (let i = 0; i < sortedRelations.length; i++) {
                const relation = sortedRelations[i];

                // Criando o componente
                const component = new ComponentInteractionSimpleModel().fromJSON({
                    id: -1,
                    conteudo: '',
                    conteudoTwo: '',
                    cor: '',
                    obrigatory: false,
                    quantityVisible: 1,
                    tipo: relation.type as ComponentInteractionEnum,
                    ordem: i > 0 ? ordemAdd + i : ordemAdd,
                    codigoContent: contentSelected.codigo,
                });

                // Inserindo o componente
                const { data, status } = await serviceComponentInteraction.insertComponentByFAD(contentSelected.codigo, component);

                if (status === 201 && data) {
                    const componentsAttOrdem = components.filter(c => c.codigoContent === contentSelected.codigo && c.ordem >= ordemAdd);

                    await handleAttOrdens(componentsAttOrdem);

                    setComponents(prev => [...prev, data]);
                }
            }
            ToastSuccess('Template adicionado com sucesso!');
            setOpenMenu(false);
        } else {
            console.error('Erro: template.data não é um array ou contentSelected está ausente.');
        }
    };

    const handleAttOrdens = async (components: ComponentInteractionModel[]) => {
        let newListComponents: ComponentInteractionModel[] = [];

        components.forEach(async c => {
            c.ordem = c.ordem + 1;

            const { data, status } = await serviceComponentInteraction.atualizarOrdem(c);

            if (status === 200) {
                newListComponents.push(data);
            }
        });

        setComponents((prev: ComponentInteractionModel[]) =>
            prev.map(c => {
                const updatedComponent = newListComponents.find(updated => updated.id === c.id);
                return updatedComponent ? updatedComponent : c; // Retorna o componente atualizado ou o original
            })
        );
    };
    useFetch(async () => {
        setComponents([]); // Limpa os componentes inicialmente

        for (const c of contents) {
            const { data, status } = await serviceComponentInteraction.fetchComponentsByFAD(c.codigo, false);

            if (status === 200) {
                setComponents((prev: any) => {
                    const idsExistentes = new Set(prev.map((item: any) => item.id));
                    const novosComponentes = data.filter((item: any) => !idsExistentes.has(item.id));
                    return [...prev, ...novosComponentes];
                });
            }
        }
    }, [contents]);
    useFetch(async () => {
        if (idCurso) {
            const { data, status } = await serviceModules.findByFadCoursesCodigo(idCurso);

            if (status === 200) {
                setModules(data);
                if (data) {
                    const modulo = data.find((d: FadModulesModel) => d.codigo === parseInt(idModulo));

                    if (modulo) {
                        setModuleSelected(modulo);
                        setFormModule(modulo);
                    }
                }
            }
        }
    }, [idCurso, idModulo]);

    useFetch(
        async () => {
            const { data, status } = await serviceContents.findByFadModulesCodigo(moduleSelected?.codigo ?? -1);

            if (status === 200) {
                setContents(data);
            }
        },
        [moduleSelected],
        setLoadingContent
    );

    const handleChange = (value: string) => {
        modules.forEach((m, i) => {
            if (m.codigo === parseInt(value)) {
                setModuleSelected(m);
                setFormModule(m);
                return;
            }
        });
    };

    const newOrdem = (): number => {
        return contents?.at(-1)?.ordem ? contents.at(-1)!.ordem + 1 : 1;
    };

    const handleCreateNewContent = async () => {
        if (moduleSelected) {
            const dataContent = new FadContentsModel().fromJSON({
                codigo: -1,
                title: 'Titulo do conteúdo ' + newOrdem(),
                codigoFerramentaAutoriaModule: moduleSelected?.codigo,
                isPublic: false,
                activeAvaliations: false,
                activeCommentary: false,
                ordem: newOrdem(),
            });

            const { data, status } = await serviceContents.create(dataContent);

            if (status === 201) {
                setContents((prev: any) => [...prev, data]);
            }
        }
    };

    const handleUpdate = async (attPublic?: boolean) => {
        if (moduleSelected && formModule) {
            let form = new FadModulesModel().fromJSON({
                ...moduleSelected,
                isPublic: attPublic ? !moduleSelected.isPublic : moduleSelected.isPublic,
            });

            const { data, status } = await serviceModules.update(moduleSelected.codigo, form);

            if (status === 200) {
                ToastSuccess('Módulo atualizado com sucesso!');

                setModuleSelected(data);

                setModules((prev: FadModulesModel[]) =>
                    prev.map(m => {
                        if (m.codigo === moduleSelected.codigo) {
                            return data;
                        }

                        return m;
                    })
                );
            }

            setOpenEditModule(false);
        }
    };

    const handleUpdateContent = async () => {
        if (formContent) {
            formContent.codigoFerramentaAutoriaModule = idModulo;

            const { data, status } = await serviceContents.update(formContent.codigo, formContent);

            if (status === 200) {
                ToastSuccess('Conteúdo atualizado com sucesso!');
                setContents((prev: any) =>
                    prev.map((c: FadContentsModel) => {
                        if (c.codigo === data.codigo) {
                            return data;
                        }

                        return c;
                    })
                );
            }

            setOpenEditContent(false);
        }
    };

    const removeModuleList = (codigo: number) => {
        setModules((prev: FadModulesModel[]) => prev.filter(m => m.codigo !== codigo));
    };

    const deleteModule = async (module: FadModulesModel) => {
        const { status } = await serviceModules.delete(module.codigo);

        if (status === 204) {
            ToastSuccess('Módulo removido com sucesso!');
            removeModuleList(module.codigo);
            setOpenEditModule(false);
        }
    };

    const handleRemoveContent = (codigo: number) => {
        setContents((prev: FadContentsModel[]) => prev.filter(c => c.codigo !== codigo));
    };

    const deleteContent = async (content: FadContentsModel) => {
        const { status } = await serviceFadContent.delete(content.codigo);

        if (status === 204) {
            ToastSuccess('Conteúdo removido com sucesso!');
            handleRemoveContent(content.codigo);
            setOpenEditContent(false);
        }
    };

    const goToBack = () => {
        history.push(`/admin/ferramenta-autoria/curso/${idCurso}`);
    };

    useEffect(() => {
        // Cria um intervalo para verificar quando a referência está disponível
        const interval = setInterval(() => {
            if (idConteudo && contentRefs.current[idConteudo]) {
                const element = contentRefs.current[idConteudo];

                if (element instanceof HTMLElement) {
                    element.scrollIntoView({ behavior: 'smooth' });
                    clearInterval(interval); // Limpa o intervalo após encontrar a referência
                }
            }
        }, 100);

        return () => clearInterval(interval); // Limpa o intervalo ao desmontar
    }, [idConteudo]);

    return (
        <Styled.Container>
            <Styled.Header>
                <FAD_Button_Outline icon={<IoIosArrowBack />} text="Voltar" onClick={goToBack} />
                <ConfigProvider
                    theme={{
                        token: {
                            colorBgBase: '#FFF',
                            colorBorder: '#FFF',
                            colorText: Colors.Blue,
                            fontSize: 22,
                        },
                    }}
                    select={{
                        style: {},
                    }}
                >
                    <Select
                        style={{ width: 400 }}
                        size="large"
                        value={moduleSelected?.title}
                        onChange={handleChange}
                        options={modules.map((m, i) => ({ label: m.title, value: m.codigo }))}
                    />
                </ConfigProvider>

                <Styled.Buttons>
                    <FAD_Button_Outline icon={<FaPlay />} text="Preview" onClick={() => ''} />
                    <FAD_Button_Primary
                        disabled={anexo === null}
                        icon={moduleSelected?.isPublic ? <HiLockClosed size={22} /> : <BsFillSendFill />}
                        text={moduleSelected?.isPublic ? 'Despublicar' : 'Publicar'}
                        styles={{ backgroundColor: moduleSelected?.isPublic ? Colors.Yellow2 : Colors.Blue }}
                        onClick={() => {
                            handleUpdate(true);
                        }}
                    />
                </Styled.Buttons>
            </Styled.Header>

            {/** Módulo selecionado */}
            <Styled.ContentInfoModule.Container>
                <Styled.Row style={{ alignItems: 'center' }}>
                    {moduleSelected?.isPublic ? (
                        <MdPublic size={22} color={Colors.CloneGreen} />
                    ) : (
                        <HiLockClosed size={22} color={Colors.Grey7} />
                    )}
                    <Styled.LabelInput
                        style={{ fontSize: 18, color: moduleSelected?.isPublic ? Colors.CloneGreen : Colors.Grey7, fontWeight: 600 }}
                    >
                        {moduleSelected?.isPublic ? 'Módulo publicado' : 'Módulo privado'}
                    </Styled.LabelInput>
                </Styled.Row>

                {/* {Adicionar o preview do banner}    */}
                {loadingAnexo ? (
                    <Skeleton.Image style={{ width: '1440px', height: '240px' }} active />
                ) : (
                    <ImageUpload base64={anexo?.base64 ?? ''} handleUpload={a => createUpdateAnexo(a)} componentStyled={{ width: '1440px' }} />
                )}

                <Styled.LabelInput>Nome do módulo</Styled.LabelInput>

                <Styled.RowBettween>
                    <Styled.TitleLarge>{moduleSelected?.title}</Styled.TitleLarge>

                    <MdEditSquare size={28} cursor={'pointer'} color={Colors.Yellow2} onClick={() => setOpenEditModule(true)} />
                </Styled.RowBettween>
            </Styled.ContentInfoModule.Container>

            {loadingContent ? (
                <>
                    <Skeleton active />
                </>
            ) : (
                <>
                    {contents
                        .sort((a, b) => a.ordem - b.ordem)
                        .map((c, i) => (
                            <div key={c.codigo} ref={el => (contentRefs.current[c.codigo] = el)}>
                                <Contents
                                    dados={c}
                                    addNewComponente={ordem => {
                                        setContentSelected(c);
                                        setOrdemAdd(ordem);
                                        setOpenMenu(true);
                                    }}
                                    components={components.sort((a, b) => a.ordem - b.ordem).filter(f => f.codigoContent === c.codigo)}
                                    handleSettings={() => {
                                        setFormContent(c);
                                        setContentSelected(c);
                                        setOpenEditContent(true);
                                    }}
                                    attComponents={setComponents}
                                />
                            </div>
                        ))}

                    <FadAdds onClick={handleCreateNewContent} textPopover="Adicionar um novo conteúdo" />
                </>
            )}

            <AdminFadMenuLateral
                open={openMenu}
                setHidden={() => setOpenMenu(false)}
                handleCreateComponent={tipo => handleNewComponent(tipo)}
                handleAddTemplate={codigoTemplate => handleNewTemplate(codigoTemplate)}
            />

            <ModalFormModule
                form={formModule ?? new FadModulesModel()}
                handleEdit={handleUpdate}
                handleSave={() => ''}
                handleHidden={() => setOpenEditModule(false)}
                setForm={setFormModule}
                open={false}
                openEdit={openEditModule}
                loading={false}
                removeModule={deleteModule}
            />

            <ModalFormContent
                open={false}
                openEdit={openEditContent}
                setForm={setFormContent}
                form={formContent ?? new FadContentsModel()}
                handleEdit={handleUpdateContent}
                handleSave={() => ''}
                handleHidden={() => setOpenEditContent(false)}
                loading={false}
                handleDelete={deleteContent}
            />
        </Styled.Container>
    );
};

export default AdminFadContents;
