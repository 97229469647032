import React from 'react';
import FerramentaAutoriaProvider from './context/FerramentaAutoriaProvider';
import { Route } from 'react-router-dom';
import AdminFerramentaAutoria from './AdminFerramentaAutoria';
import AdminFadCourse from './pages/AdminFadCourse';
import AdminFadClasses from './pages/AdminFadClasses';
import AdminFadContents from './pages/AdminFadContents';

const AdminFadRouter = () => {
    return (
        <FerramentaAutoriaProvider>
            <Route exact path="/admin/ferramenta-autoria" component={() => <AdminFerramentaAutoria />} />
            <Route exact path="/admin/ferramenta-autoria/curso/:idCurso" component={() => <AdminFadCourse />} />
            <Route exact path="/admin/ferramenta-autoria/curso/turmas/:idCurso" component={() => <AdminFadClasses />} />
            <Route
                exact
                path={[
                    '/admin/ferramenta-autoria/curso/turmas/:idCurso/:idModulo/conteudo',
                    '/admin/ferramenta-autoria/curso/turmas/:idCurso/:idModulo/conteudo/:idConteudo',
                ]}
                component={() => <AdminFadContents />}
            />
        </FerramentaAutoriaProvider>
    );
};

export default AdminFadRouter;
