import Loading from 'components/Loading';
import Row from 'components/Row';
import { ToastError, ToastSuccess } from 'components/Toaster';
import { BlueSolidButton, PrimaryButton, RedSolidButton, YellowButton } from 'components/UI/Buttons';
import AdminRecursosCostumizados from 'core/http/service/admin/AdminRecursosCostumizados.service';
import { ConteudoRecursoCustomizado } from 'models/recursos-costumizados/ConteudoRecursoCustomizado';
import { TipoAnexoConteudo } from 'models/recursos-costumizados/TipoAnexoConteudo';
import AdminBreadCrumb from 'pages/admin/components/breadcrumb/AdminBreadCrumb';
import editorConfig from 'pages/diarioBordo/components/textEditor/editorConfig';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { useHistory, useParams } from 'react-router-dom';
import { AdminGestaoPaginasMode } from '../../AdminGestaoPaginasContextWrapper';
import { AdminGestaoPaginasContext } from '../../context/AdminGestaoPaginasContext';
import AdminImageUploader from '../../section-form/imageUploader/AdminImageUploader';
import Styled from './AdminGestaoPaginaConteudoRecurso.styled';
import Select, { SelectOptionType } from 'components/Select';
import { StringHelp } from 'core/util/StringHelp';
import Colors from 'enums/Colors';
import ModalExclusao from '../components/modal/ModalExclusao';
import AllowedGroupsValidator from 'pages/admin/components/allowedGroupsValidator/AllowedGroupsValidator';
import { KcAdminGroups } from 'models/kc/KcUserInfo';
import { ArquivoPrincipalEnum } from 'enums/ArquivoPrincipalEnum';
import DrawerComponentsInteractions from 'pages/gestao_paginas/components/interactions/DrawerComponentsInteractions';
import ComponentInteractionModel from 'models/componentInteraction/ComponentInteraction';
import { ComponentInteractionEnum } from 'enums/ComponentInteractionEnum';
import { TListComponentesInteractions } from 'pages/gestao_paginas/components/interactions/ListComponentesInteractions';
import MenuComponentInteraction from '../components/menu/MenuComponentInteraction';
import { Divider } from 'pages/profile/components/identificacaoImagem/components/ImageSelectMobile.styled';
import { ComponentInteractionService } from 'core/http/service/componetInteraction/ComponentInteraction.service';
import { Button, Collapse, ColorPicker, ConfigProvider, Select as SelectAnt, Space } from 'antd';
import type { CollapseProps } from 'antd';
import ComponentSanfonaModel from 'models/componentInteraction/ComponentSanfona';
import SanfonaInteractionAdmin from 'pages/gestao_paginas/components/interactions/SanfonaInteractionAdmin';
import ComponentSanfonaConteudoModel from 'models/componentInteraction/ComponentSanfonaConteudo';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { CaretRightOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import ComponentQuizModel from 'models/componentInteraction/ComponentQuiz';
import QuizInteractionAdmin from 'pages/gestao_paginas/components/interactions/QuizInteractionAdmin';
import ComponentQuizAlternativasModel from 'models/componentInteraction/ComponentQuizAlternativas';
import Feedback from 'react-bootstrap/esm/Feedback';
import { ComponentQuizService } from 'core/http/service/componetInteraction/ComponentQuiz.service';
import CorrespondenciaInteractionAdmin from 'pages/gestao_paginas/components/interactions/CorrespondenciaInteractionAdmin';
import CorrespondentModel from 'models/componentInteraction/CorrespondentModel';
import CorrespondentItemModel from 'models/componentInteraction/CorrespondentItemModel';
import CarrosselInteractionAdmin from 'pages/gestao_paginas/components/interactions/CarrosselInteractionAdmin';
import ComponentCarrosselInteraction from 'models/componentInteraction/ComponentCarrosselInterarion';
import ComponentItemCarrosselInteraction from 'models/componentInteraction/ComponentItemCarrosselInteraction';
import ComponentAbasModel from 'models/componentInteraction/ComponentAbas';
import ComponentAbasAdmin from 'pages/gestao_paginas/components/interactions/ComponentAbasAdmin';
import ComponentBaralhoModel from 'models/componentInteraction/ComponentBaralho';
import ComponentBaralhoAdmin from 'pages/gestao_paginas/components/interactions/ComponentBaralhoAdmin';
import ComponentReguaAvaliativaModel from 'models/componentInteraction/ComponenteReguaAvaliativa';
import ComponentReguaAvaliativaAdmin from 'pages/gestao_paginas/components/interactions/ComponentReguaAvaliativaAdmin';
import ComponentTimeLine from 'models/componentInteraction/ComponentTimeLine';
import ComponentTimeLineAdmin from 'pages/gestao_paginas/components/interactions/ComponentTimeLineAdmin';
import ComponentQuizPontuacao from 'models/componentInteraction/ComponentQuizPontuacao';
import ComponentQuizPontucaoAdmin from 'pages/gestao_paginas/components/interactions/ComponentQuizPontucaoAdmin';
import ComponentQuizPontuacaoGlobal from 'models/componentInteraction/ComponentQuizPontuacaoGlobal';
import { ComponentCenarioGlobal } from 'models/componentInteraction/ComponentCenarioGlobal';
import ComponentCenarioAdmin from 'pages/gestao_paginas/components/interactions/ComponentCenarioAdmin';
import { ComponentCenario } from 'models/componentInteraction/ComponentCenario';
import { ComponentInteractionAnexo } from 'models/componentInteraction/ComponentInteractionAnexo';
import { ComponentCenarioAlternativa } from 'models/componentInteraction/ComponentCenarioAlternativa';

const StyledCaretRightOutlined = styled(CaretRightOutlined)``;

const { Option } = SelectAnt;
interface IAdminGestaoPaginaConteudoRecurso {
    mode: AdminGestaoPaginasMode;
}

interface IMenuBreadCrumb {
    nomeItem: string;
    idItem: number;
    nomeEtapa: string;
    idEtapa: number;
    idConteudo: number;
}

export const scroolToHeigth = () => {
    var heightPage = document.body.scrollHeight;
    window.scrollTo(0, heightPage);
};

interface IMenuInteractionElement {
    index: number;
    dados: any;
}

const AdminGestaoPaginaConteudoRecurso = ({ mode }: IAdminGestaoPaginaConteudoRecurso) => {
    const params: any = useParams();
    const history = useHistory();

    const [image, setImage] = useState<string>();
    const [pdf, setPdf] = useState<string>();
    const [urlAnexo, setUrlAnexo] = useState<string>('');

    const [codigoConteudo, setCodigoConteudo] = useState<number>(-1);
    const [idEtapa, setIdEtapa] = useState<number>(-1);
    const [idItem, setIdItem] = useState<number>(-1);
    const [nameItem, setNameItem] = useState<string>('');
    const [quantidade, setQuantidade] = useState<number>(-1);
    const [nameContent, setNameContent] = useState<string>('');
    const [texto, setTexto] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [typeModeEdit, setTypeModeEdit] = useState<boolean>(mode === 'NEW' ? false : true);
    const [oculto, setOculto] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const inputElement = useRef<HTMLInputElement>(null);
    const [valuePositionArq, setValuePositionArq] = useState<SelectOptionType>({ value: ArquivoPrincipalEnum.INICIO, label: 'Ínicio' });

    //
    const [loadingButton, setLoadingButton] = useState<boolean>(false);

    //Quiz
    const [quizIndex, setQuizIndex] = useState<number>(0);

    const onChange = (key: string | string[]) => {
        console.log(key);
    };

    //Drawer
    const [openDrawer, setOpenDrawer] = useState(false);
    const [componentInteractions, setComponentsInteractions] = useState<ComponentInteractionModel[]>([]);

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [loading, setloading] = useState<boolean>(false);

    const MAX_CHAR_CONTENT = 200;
    const MAX_CHAR_CONTENT_NAME = 100;
    const MAX_PDF_SIZE_IN_BYTES = 5000000;

    const serviceRecursosCostumizados = new AdminRecursosCostumizados();
    const serviceComponentInteraction = new ComponentInteractionService();
    const serviceComponentQuiz = new ComponentQuizService();

    const handleSetCor = (value: string, index: number) => {
        setComponentsInteractions((prevInteractions: any) =>
            prevInteractions.map((item: ComponentInteractionModel, idx: number) => (idx === index ? { ...item, cor: value } : item))
        );
    };

    const [dadosBreadCrumb, setDadosSetCrumb] = useState<IMenuBreadCrumb>({
        nomeItem: '',
        idItem: -1,
        nomeEtapa: '',
        idEtapa: -1,
        idConteudo: -1,
    });

    const { breadCrumb, setBreadCrumb, currentPage, selectPage, saveOrUpdatePage } = useContext(AdminGestaoPaginasContext.Context);

    const tiposAnexo: SelectOptionType[] = [
        {
            label: 'Imagem',
            value: 1,
        },
        {
            label: 'Áudio',
            value: 2,
        },
        {
            label: 'Vídeo',
            value: 3,
        },
        {
            label: 'PDF',
            value: 4,
        },
        {
            label: 'Nenhum',
            value: 0,
        },
    ];

    const positionArqPrincipal: SelectOptionType[] = [
        {
            label: 'Ínicio',
            value: ArquivoPrincipalEnum.INICIO,
        },
        {
            label: 'Final',
            value: ArquivoPrincipalEnum.FINAL,
        },
    ];

    const goToEtapa = () => {
        const dados = dadosBreadCrumb;
        const valuesURL = `${dados.nomeItem}/${dados.idItem}/${dados.idEtapa}`;

        history.push(`/admin/gestao-paginas/editar-etapa-item/` + valuesURL);
    };

    const breadCrumbInitial = (dados: IMenuBreadCrumb) => {
        const urlEtapa = `${dados?.nomeItem}/${dados?.idItem}/${dados?.idEtapa}`;

        setBreadCrumb([
            { route: 'gestao-paginas', label: 'Gestão de páginas' },
            { route: 'gestao-paginas/editar-recurso/' + currentPage?.codigo, label: 'Editar Recurso' },
            { route: 'gestao-paginas/editar-item-recurso/' + dados?.idItem, label: dados?.nomeItem ?? 'Item' },
            { route: 'gestao-paginas/editar-etapa-item/' + urlEtapa, label: dados?.nomeEtapa ?? 'Etapa' },
            { route: 'gestao-paginas/editar-item-etapa-recurso', label: nameContent ?? 'Conteúdo' },
        ]);
    };

    const [tipoAnexo, setTipoAnexo] = useState(0);

    const service = new AdminRecursosCostumizados();

    const isValidInputs = (): boolean => {
        if (!nameContent || nameContent.trim().length === 0) {
            return false;
        }

        if (!url || url.trim().length === 0) {
            return false;
        }

        return true;
    };

    const scrollToTop = () => {
        document.documentElement.scrollIntoView({
            behavior: 'smooth',
        });
    };

    const fetchConteudo = async (conteudoId: number) => {
        setloading(true);
        const response = await service.buscarConteudo(conteudoId);

        const data = response.data;
        const status = response.status;

        const conteudoData = new ConteudoRecursoCustomizado().fromJSON(data);

        if (status == 200 && conteudoData.anexoBase64) {
            setImage(conteudoData.anexoBase64);
            setTipoAnexo(conteudoData.tipoAnexo?.codigo!);
        }

        if (status == 200 && conteudoData.filePath) {
            setUrlAnexo(conteudoData.filePath!);
            setTipoAnexo(conteudoData.tipoAnexo?.codigo!);
        }

        const positionArq = conteudoData.positionArquivoPrincipal;

        const valuePosition = positionArqPrincipal.find(p => p.value === positionArq);

        if (valuePosition) setValuePositionArq(valuePosition);

        setNameContent(conteudoData.nome);
        setCodigoConteudo(conteudoId);
        setTexto(conteudoData.texto);
        setUrl(conteudoData.url);
        setOculto(conteudoData.oculto);
        setQuantidade(conteudoData.ordem ?? 0);
        setComponentsInteractions(conteudoData.componentes);
        setloading(false);
    };

    const isValidLinkAnexo = (): Boolean => {
        if (tipoAnexo === 2) {
            const soundClound = /https:\/\/w\.soundcloud\.com\/player\/\?url=https%3A\/\/api\.soundcloud\.com\/tracks\/\d+&.+=true?/g;
            const spotify = /^(?:spotify:|https:\/\/[a-z]+\.spotify\.com\/(track\/|\/(.*)\/playlist\/))(.*)$/g;
            const result =
                soundClound.test(urlAnexo.trim()) ||
                spotify.test(urlAnexo.trim()) ||
                urlAnexo.includes('institutoayrtonsenna.org.br/app/uploads/');
            return result;
        }
        if (tipoAnexo === 3) {
            const vimeo = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/g;
            const yt = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/g;
            const result = vimeo.test(urlAnexo.trim()) || yt.test(urlAnexo.trim());
            return result;
        }

        return true;
    };

    const submit = async (returnEtapa: boolean) => {
        if (!isValidInputs()) {
            ToastError('Dados da página inválidos');
            return;
        }

        if (!isValidLinkAnexo()) {
            ToastError('Link inválido');
            return;
        }

        setSubmitting(true);

        const sanitizeUrl = StringHelp.removeAccents(url)
            .replace(/\s/g, '-')
            .replace(/[^a-z0-9-]/gi, '')
            .toLowerCase();

        const currentLink: string = (currentPage?.link ?? '').replace(/[^a-z0-9-]/gi, '');

        let isExistent = false;

        if (currentLink != sanitizeUrl) {
            const { data: value } = await service.verificarUriExistent(sanitizeUrl, codigoConteudo, dadosBreadCrumb.idEtapa, 'conteudo');
            isExistent = value;
        }

        if (isExistent) {
            ToastError('Já existe um conteúdo para a URL informada');
            setSubmitting(false);
            return;
        }

        const conteudo = new ConteudoRecursoCustomizado();
        const tipoAnexoConteudo = new TipoAnexoConteudo();

        if (tipoAnexo === 1) {
            conteudo.anexoBase64 = image;
        } else if (tipoAnexo === 2 || tipoAnexo === 3) {
            conteudo.filePath = urlAnexo;
        } else {
            conteudo.anexoBase64 = pdf;
        }

        tipoAnexoConteudo.codigo = tipoAnexo;
        conteudo.tipoAnexo = tipoAnexoConteudo;
        conteudo.codigoEtapa = dadosBreadCrumb.idEtapa;
        conteudo.nome = nameContent;
        conteudo.texto = texto;
        conteudo.url = sanitizeUrl;
        conteudo.positionArquivoPrincipal = valuePositionArq.value as ArquivoPrincipalEnum;
        conteudo.componentes = componentInteractions;

        if (mode == 'NEW' && codigoConteudo === -1) {
            conteudo.oculto = false;
            conteudo.ordem = quantidade;
            await saveConteudo(conteudo);
        } else {
            await updateConteudo(conteudo);
        }

        setSubmitting(false);
        scrollToTop();

        if (returnEtapa) goToEtapa();
    };

    const saveConteudo = async (conteudo: ConteudoRecursoCustomizado) => {
        if (!conteudo) {
            ToastError('Erro ao salvar!');
            return;
        }

        const { status, data } = await service.novoConteudo(conteudo);
        if (status > 300) {
            ToastError('Houve um erro ao tentar salvar o conteúdo!');
        } else {
            setComponentsInteractions(data.componentes);
            setCodigoConteudo(data.codigo);
            setUrl('/' + data.url);
            setTypeModeEdit(true);
            ToastSuccess('Conteúdo salvo');
        }
    };

    const updateConteudo = async (conteudo: ConteudoRecursoCustomizado) => {
        if (!conteudo) {
            ToastError('Erro ao atualizar!');
            return;
        }

        const { status, data } = await service.atualizarConteudo(conteudo, codigoConteudo);

        if (status > 300) {
            ToastError('Houve um erro ao tentar atualizar o conteúdo!');
        } else {
            setUrl('/' + data.url);
            ToastSuccess('Conteúdo salvo');
        }
    };

    const handleOcultar = async () => {
        const response = await serviceRecursosCostumizados.ocultarConteudo(codigoConteudo);

        if (response.status === 200) {
            ToastSuccess('Conteúdo ocultado.');
            fetchConteudo(codigoConteudo);
        }
    };

    const handleExibir = async () => {
        const response = await serviceRecursosCostumizados.exibirConteudo(codigoConteudo);

        if (response.status === 200) {
            ToastSuccess('Conteúdo visivel.');
            fetchConteudo(codigoConteudo);
        }
    };

    const handleDelete = async () => {
        const response = await serviceRecursosCostumizados.deleteConteudo(codigoConteudo);

        if (response.status === 200) {
            ToastSuccess('Conteúdo excluido com sucesso!');

            history.push(`/admin/gestao-paginas/editar-etapa-item/${nameItem}/${idItem}/${idEtapa}`);
        } else {
            ToastError('Houve algum problema ao tentar excluir o conteúdo.');
        }

        setShowModal(false);
    };

    const fetchInitial = () => {
        const idItem = String(params?.idItem);
        const nomeItem = String(params?.nomeItem);
        const idEtapa = String(params?.idEtapa);
        const nomeEtapa = String(params?.nomeEtapa);
        const idConteudo = String(params?.id);
        const quantidade = String(params?.quantidade);

        const dados: IMenuBreadCrumb = {
            ...dadosBreadCrumb,
            ['idItem']: parseInt(idItem),
            ['nomeItem']: nomeItem,
            ['idEtapa']: parseInt(idEtapa),
            ['nomeEtapa']: nomeEtapa,
        };
        setDadosSetCrumb(dados);
        setIdEtapa(parseInt(idEtapa));
        setIdItem(parseInt(idItem));
        setNameItem(nomeItem);
        setQuantidade(parseInt(quantidade));

        if (mode == 'EDIT') {
            fetchConteudo(parseInt(idConteudo));
        }
    };

    const chooseFile = () => {
        if (inputElement?.current) {
            inputElement.current.onchange = (e: Event) => {
                const target = e.target as HTMLInputElement;

                if (target.files && target.files[0]) {
                    if (target.files[0].size > MAX_PDF_SIZE_IN_BYTES) {
                        ToastError('O arquivo PDF excede o tamanho máximo de 5MB');
                        return;
                    }

                    const reader = new FileReader();
                    reader.onloadend = (ev: any) => {
                        const base64 = btoa(reader.result as string);
                        setPdf(base64);
                    };
                    reader.readAsBinaryString(target.files[0]);
                    setUrlAnexo(target.files[0].name);
                }
            };

            inputElement.current.accept = '.pdf';
            inputElement.current.click();
        }
    };

    const moveItem = async (currentIndex: number, newValue: number) => {
        if (newValue != null && newValue != undefined) {
            const updatedItem = componentInteractions[currentIndex - 1];
            updatedItem.ordem = newValue + 1;

            const response = await serviceComponentInteraction.atualizarOrdem(updatedItem);

            if (response.status === 204) {
                ToastSuccess('Ordens atualizadas!');

                // setComponentsInteractions((prev: any) =>
                //     prev.map((c: ComponentInteractionModel, i: number) => {
                //         if (i === currentIndex - 1) {
                //             return {
                //                 ...c,
                //                 ordem: newValue + 1,
                //             };
                //         }

                //         return c;
                //     })
                // );
                fetchConteudo(codigoConteudo);
            }
        }
    };

    const handleNewComponent = (value: TListComponentesInteractions) => {
        const newComponent = new ComponentInteractionModel().fromJSON({
            tipo: value.tipo,
            id: -1,
            conteudo: '',
            conteudoTwo: '',
            cor: '',
            quantityVisible: 1,
            ordem: componentInteractions.length + 1,
            obrigatory: false,
            sanfona: value.tipo === ComponentInteractionEnum.SANFONA ? [new ComponentSanfonaModel()] : null,
            quiz: value.tipo === ComponentInteractionEnum.QUIZ ? [new ComponentQuizModel()] : null,
            correspondent: value.tipo === ComponentInteractionEnum.CORRESPONDENT ? new CorrespondentModel() : null,
            carrossel: value.tipo === ComponentInteractionEnum.CARROSSEL ? new ComponentCarrosselInteraction() : null,
            abas:
                value.tipo === ComponentInteractionEnum.ABAS
                    ? [
                          new ComponentAbasModel().fromJSON({
                              title: 'Tab 01',
                              description: 'Conteúdo Aba 01',
                          }),
                      ]
                    : null,
            baralho: value.tipo === ComponentInteractionEnum.CARD_BARALHO ? [] : null,
            reguaAvaliativa: value.tipo === ComponentInteractionEnum.REGUA_AVALIATIVA ? new ComponentReguaAvaliativaModel() : null,
            timelines: value.tipo === ComponentInteractionEnum.TIMELINE ? [new ComponentTimeLine()] : null,
            quizPontuacao: value.tipo === ComponentInteractionEnum.QUIZ_PONTUACAO ? new ComponentQuizPontuacaoGlobal() : null,
            anexo: new ComponentInteractionAnexo(),
            cenarios:
                value.tipo === ComponentInteractionEnum.CENARIO
                    ? new ComponentCenarioGlobal().fromJSON({
                          cenarios: [
                              new ComponentCenario().fromJSON({
                                  id: -1,
                                  title: '',
                                  alternativas: [
                                      new ComponentCenarioAlternativa().fromJSON({
                                          id: -1,
                                          text: '',
                                          feedback: '',
                                      }),
                                  ],
                              }),
                          ],
                          anexo: new ComponentInteractionAnexo(),
                      })
                    : null,
                    
        });
        setComponentsInteractions([...componentInteractions, newComponent]);

        insertComponentInteraction(newComponent);
    };

    const atualizarComponentInteraction = (indexComponent: number, component: ComponentInteractionModel) => {
        setComponentsInteractions((prevInteractions: any) =>
            prevInteractions.map((item: any, idx: number) => (idx === indexComponent ? component : item))
        );
    };

    const handleNewQuiz = (indexComponent: number) => {
        const quiz = new ComponentQuizModel();

        let component = componentInteractions[indexComponent];

        component.quiz?.push(quiz);

        atualizarComponentInteraction(indexComponent, component);

        setQuizIndex((component.quiz?.length ?? 1) - 1);
    };

    const deletarQuiz = async (indexComponent: number, indexQuiz: number) => {
        let component = componentInteractions[indexComponent];

        if (component.quiz) {
            let quizItem = component.quiz[indexQuiz];

            const response = await serviceComponentQuiz.deletarQuiz(quizItem.id);

            if (response.status === 204) {
                ToastSuccess('Quiz excluído com sucesso!');

                component.quiz.splice(indexQuiz, 1);

                if (component.quiz.length === 0) return handleRemoveComponent(indexComponent, component);
            }
        }

        atualizarComponentInteraction(indexComponent, component);

        setQuizIndex(indexQuiz > 0 ? (indexQuiz ?? 1) - 1 : 0);
    };

    const atualizarObrigatoryQuiz = (indexComponent: number, obrigatory: boolean) => {
        let component = componentInteractions[indexComponent];

        component.obrigatory = obrigatory;

        atualizarComponentInteraction(indexComponent, component);
    };

    const atualizarMultiplos = (indexComponent: number, indexQuiz: number, multiplos: boolean) => {
        let component = componentInteractions[indexComponent];

        if (component.quiz) {
            component.quiz[indexQuiz].multiplos = multiplos;

            if (!multiplos) {
                const quizItem = component.quiz[indexQuiz];

                if (quizItem && quizItem.alternativas && Array.isArray(quizItem.alternativas)) {
                    quizItem.alternativas.map((alt, index) => {
                        if (alt.correct) {
                            return (alt.correct = false);
                        }
                    });
                }
            }
        }

        atualizarComponentInteraction(indexComponent, component);
    };

    const atualizarPerguntaQuiz = (indexComponent: number, indexQuiz: number, pergunta: string) => {
        let component = componentInteractions[indexComponent];

        if (component.quiz) component.quiz[indexQuiz].pergunta = pergunta;

        atualizarComponentInteraction(indexComponent, component);
    };

    const atualizarFeedbackQuiz = (indexComponent: number, indexQuiz: number, feedback: string) => {
        let component = componentInteractions[indexComponent];

        if (component.quiz) component.quiz[indexQuiz].feedback = feedback;

        atualizarComponentInteraction(indexComponent, component);
    };

    const adicionarNovaAlternativaQuiz = (indexComponent: number, indexQuiz: number) => {
        let component = componentInteractions[indexComponent];

        if (component.quiz) component.quiz[indexQuiz].alternativas?.push(new ComponentQuizAlternativasModel());

        atualizarComponentInteraction(indexComponent, component);
    };

    const removerAlternativaQuiz = async (indexComponent: number, indexQuiz: number, indexAlternativa: number) => {
        let component = componentInteractions[indexComponent];

        if (component && component.quiz) {
            let quizItem = component.quiz[indexQuiz];

            if (quizItem && quizItem.alternativas && Array.isArray(quizItem.alternativas)) {
                if (indexAlternativa >= 0 && indexAlternativa < quizItem.alternativas.length) {
                    let alternativa: ComponentQuizAlternativasModel = quizItem.alternativas[indexAlternativa];

                    const response = await serviceComponentQuiz.deletarAlternativaQuiz(alternativa.id);

                    if (response.status === 204) {
                        ToastSuccess('Alternativa excluída com sucesso!');

                        quizItem.alternativas.splice(indexAlternativa, 1);
                    }
                }
            }
        }

        atualizarComponentInteraction(indexComponent, component);
    };

    const atualizarAlternativaQuiz = (indexComponent: number, indexQuiz: number, indexAlternativa: number, alternativa: string) => {
        let component = componentInteractions[indexComponent];

        if (component && component.quiz) {
            const quizItem = component.quiz[indexQuiz];

            if (quizItem && quizItem.alternativas && Array.isArray(quizItem.alternativas)) {
                if (indexAlternativa >= 0 && indexAlternativa < quizItem.alternativas.length) {
                    quizItem.alternativas[indexAlternativa].alternativa = alternativa;
                }
            }
        }

        atualizarComponentInteraction(indexComponent, component);
    };

    const atualizarCheckAlternativa = (indexComponent: number, indexQuiz: number, indexAlternativa: number, checked: boolean) => {
        let component = componentInteractions[indexComponent];

        if (component && component.quiz) {
            const quizItem = component.quiz[indexQuiz];

            if (quizItem && quizItem.alternativas && Array.isArray(quizItem.alternativas)) {
                if (indexAlternativa >= 0 && indexAlternativa < quizItem.alternativas.length) {
                    if (quizItem.multiplos) {
                        quizItem.alternativas[indexAlternativa].correct = checked;
                    } else {
                        quizItem.alternativas.map((alt, index) => {
                            if (alt.correct) {
                                return (alt.correct = false);
                            }
                        });

                        quizItem.alternativas[indexAlternativa].correct = checked;
                    }
                }
            }
        }

        atualizarComponentInteraction(indexComponent, component);
    };

    const handleNavigateQuiz = (next: boolean, length: number) => {
        if (!next) {
            if (quizIndex > 0) {
                setQuizIndex(quizIndex - 1);
            }
        } else {
            if (quizIndex + 1 < length) {
                setQuizIndex(quizIndex + 1);
            }
        }
    };

    const handleAtualizarCorrespondent = (indexComponent: number, value: string, isPerguta?: boolean) => {
        let component = componentInteractions[indexComponent];

        if (component && component.correspondent) {
            const correspondent = component.correspondent;

            if (isPerguta) {
                correspondent.pergunta = value;
            }

            if (!isPerguta) {
                correspondent.feedback = value;
            }
        }

        atualizarComponentInteraction(indexComponent, component);
    };

    const handleItemCorrespondent = (indexComponent: number, itens: CorrespondentItemModel[]) => {
        let component = componentInteractions[indexComponent];

        if (component && component.correspondent) {
            const correspondent = component.correspondent;

            correspondent.itens = itens;
        }

        atualizarComponentInteraction(indexComponent, component);
    };
    const handleItemCarrossel = (indexComponent: number, itensCarrossel: ComponentItemCarrosselInteraction[]) => {
        let component = componentInteractions[indexComponent];

        if (component && component.carrossel) {
            let carrosselItem = component.carrossel;

            if (carrosselItem) {
                carrosselItem.itens = itensCarrossel;
            }
        }

        atualizarComponentInteraction(indexComponent, component);
    };

    const handleItemCardBaralho = (indexComponent: number, itensBaralho: ComponentBaralhoModel[]) => {
        let component = componentInteractions[indexComponent];

        if (component && component.baralho) {
            component.baralho = itensBaralho;
        }

        atualizarComponentInteraction(indexComponent, component);
    };

    const atualizarQuantityVisible = (indexComponent: number, quantity: number) => {
        let component = componentInteractions[indexComponent];

        component.quantityVisible = quantity;

        atualizarComponentInteraction(indexComponent, component);
    };

    const insertComponentInteraction = async (value: ComponentInteractionModel) => {
        const response = await serviceComponentInteraction.atualizarAndSave(codigoConteudo, value);

        setComponentsInteractions([...componentInteractions, response.data]);
    };

    const atualizationComponent = async (value: ComponentInteractionModel, index: number) => {
        setLoadingButton(true);

        if (value.tipo === ComponentInteractionEnum.TEXT || value.tipo === ComponentInteractionEnum.AUDIO) {
            if (value.conteudo === '' || value.conteudo === '<p><br></p>')
                return ToastError('Você está tentando salvar um componente sem informação!');
        }

        const response = await serviceComponentInteraction.atualizarAndSave(codigoConteudo, value);

        if (response.status === 200) {
            ToastSuccess('Conteúdo atualizado com sucesso!');

            setComponentsInteractions(prevComponentes =>
                prevComponentes.map(
                    (comp, idx) => (idx === index ? response.data : comp) // Substitui o componente no índice especificado
                )
            );
        }
        setLoadingButton(false);
    };

    const handleAbas = (indexComponent: number, abas: ComponentAbasModel[]) => {
        let component = componentInteractions[indexComponent];

        if (component && component.abas) {
            component.abas = abas;
        }

        atualizarComponentInteraction(indexComponent, component);
    };

    const handleRemoveComponent = async (index: number, model: ComponentInteractionModel) => {
        setLoadingButton(true);
        if (model.id != null) {
            const response = await serviceComponentInteraction.deleteById(model.id);

            if (response.status === 204) {
                ToastSuccess('Componente deletado com sucesso!');
            }
        }

        setComponentsInteractions(prevInteractions => prevInteractions.filter((_, idx) => idx !== index));
        setLoadingButton(false);
    };

    const handleAtualizationTitleSanfona = (value: string, idxComponent: number, idxSanfona: number) => {
        // Cria uma nova cópia do estado para evitar mutação direta
        const newComponentInteractions = [...componentInteractions];

        // Acessa o componente específico
        const componentInteraction = newComponentInteractions[idxComponent];

        // Verifica se o componente e a sanfona são válidos
        if (componentInteraction && componentInteraction.sanfona) {
            const sanfona = componentInteraction.sanfona[idxSanfona];

            if (sanfona) {
                // Atualiza o título da sanfona
                sanfona.titulo = value;

                // Atualiza o estado com a nova cópia
                setComponentsInteractions(newComponentInteractions);
            } else {
                console.error(`Sanfona com índice ${idxSanfona} não encontrada.`);
            }
        } else {
            console.error(`Componente com índice ${idxComponent} não encontrado ou sem sanfonas.`);
        }
    };
    const handleAtualizationConteudoSanfona = (
        conteudo: string,
        tipoConteudo: ComponentInteractionEnum,
        idxComponent: number,
        idxSanfona: number,
        idxConteudo: number
    ) => {
        // Cria uma nova cópia do estado para evitar mutação direta
        const newComponentInteractions = [...componentInteractions];

        // Acessa o componente específico
        const componentInteraction = newComponentInteractions[idxComponent];

        // Verifica se o componente e a sanfona são válidos
        if (componentInteraction && componentInteraction.sanfona) {
            const sanfona = componentInteraction.sanfona[idxSanfona];

            if (sanfona && sanfona.componentSanfonaConteudos) {
                sanfona.componentSanfonaConteudos[idxConteudo].conteudo = conteudo;
                sanfona.componentSanfonaConteudos[idxConteudo].tipoConteudo = tipoConteudo;

                // Atualiza o estado com a nova cópia
                setComponentsInteractions(newComponentInteractions);
            } else {
                console.error(`Sanfona com índice ${idxSanfona} não encontrada.`);
            }
        } else {
            console.error(`Componente com índice ${idxComponent} não encontrado ou sem sanfonas.`);
        }
    };

    const handleAddConteudoSanfona = (conteudo: string, tipoConteudo: ComponentInteractionEnum, idxComponent: number, idxSanfona: number) => {
        // Cria uma nova cópia do estado para evitar mutação direta
        const newComponentInteractions = [...componentInteractions];

        // Acessa o componente específico
        const componentInteraction = newComponentInteractions[idxComponent];

        // Verifica se o componente e a sanfona são válidos
        if (componentInteraction && componentInteraction.sanfona) {
            const sanfona = componentInteraction.sanfona[idxSanfona];

            if (sanfona) {
                // Cria um novo conteúdo
                const newConteudo = new ComponentSanfonaConteudoModel(); // Certifique-se de que você tenha um construtor ou método para inicializar isso
                newConteudo.conteudo = conteudo;
                newConteudo.tipoConteudo = tipoConteudo;

                // Adiciona o novo conteúdo à lista de conteúdos da sanfona
                sanfona.componentSanfonaConteudos = [...(sanfona.componentSanfonaConteudos || []), newConteudo];

                // Atualiza o estado com a nova cópia
                setComponentsInteractions(newComponentInteractions);
                console.log(`Novo conteúdo adicionado à sanfona: ${conteudo}`);
            } else {
                console.error(`Sanfona com índice ${idxSanfona} não encontrada.`);
            }
        } else {
            console.error(`Componente com índice ${idxComponent} não encontrado ou sem sanfonas.`);
        }
    };

    const handleAddNewAbaSanfona = (idxComponent: number) => {
        // Cria uma nova cópia do estado para evitar mutação direta
        const newComponentInteractions = [...componentInteractions];

        // Acessa o componente específico
        const componentInteraction = newComponentInteractions[idxComponent];

        if (componentInteraction && Array.isArray(componentInteraction.sanfona)) {
            // Cria uma nova instância de Sanfona
            const newSanfona = new ComponentSanfonaModel();

            // Adiciona a nova sanfona à lista existente
            componentInteraction.sanfona = [...componentInteraction.sanfona, newSanfona];

            // Atualiza o estado
            setComponentsInteractions(newComponentInteractions);
        }
    };

    const updateColor = async (indexInteraction: number, value: string) => {
        setComponentsInteractions((prev: any) =>
            prev.map((c: ComponentInteractionModel, i: number) => (i === indexInteraction ? { ...c, cor: value } : c))
        );
    };

    useEffect(() => {
        fetchInitial();
    }, []);

    useEffect(() => {
        breadCrumbInitial(dadosBreadCrumb);
    }, [dadosBreadCrumb, nameContent]);

    return (
        <>
            <Styled.Container>
                {loading ? (
                    <Loading />
                ) : (
                    <div>
                        <ModalExclusao showModal={showModal} handleHiden={setShowModal} handleExcluxed={handleDelete} />
                        <AdminBreadCrumb crumbs={breadCrumb} />

                        <br />
                        <Styled.Title>{'Dados básicos do Conteúdo'}</Styled.Title>
                        <br />

                        <Styled.FormContainer>
                            <Styled.Divisor width="100%">
                                <Styled.Label>Nome do Conteúdo</Styled.Label>
                                <Styled.Input
                                    value={nameContent}
                                    onChange={(evn: any) => setNameContent(evn.target.value)}
                                    className={'form-control'}
                                    maxLength={MAX_CHAR_CONTENT_NAME}
                                />
                                <Styled.CountLetter danger={nameContent.length >= MAX_CHAR_CONTENT_NAME}>
                                    {nameContent.length}/{MAX_CHAR_CONTENT_NAME}
                                </Styled.CountLetter>
                            </Styled.Divisor>
                            <Styled.Divisor width="100%">
                                <Styled.Label>URL</Styled.Label>
                                <Styled.Input
                                    value={url}
                                    onChange={(evn: any) =>
                                        setUrl('/' + StringHelp.alnum(StringHelp.toSize(evn.target.value.toLowerCase().trim(), 200)))
                                    }
                                    className={'form-control'}
                                    maxLength={MAX_CHAR_CONTENT}
                                />
                                <Styled.CountLetter danger={url.length >= MAX_CHAR_CONTENT}>
                                    {url.length}/{MAX_CHAR_CONTENT}
                                </Styled.CountLetter>
                            </Styled.Divisor>
                        </Styled.FormContainer>

                        <Styled.Label style={{ margin: '10px' }}>Arquivo Principal</Styled.Label>
                        {tiposAnexo.map(tipo => {
                            return (
                                <Form.Check
                                    key={tipo.value}
                                    style={{ cursor: 'pointer', transform: 'translateX(7px)' }}
                                    inline
                                    label={tipo.label}
                                    type="radio"
                                    checked={tipoAnexo == tipo.value}
                                    onClick={() => setTipoAnexo(Number(tipo.value))}
                                />
                            );
                        })}
                        {tipoAnexo > 0 && (
                            <>
                                <Styled.Label style={{ margin: '10px', marginTop: 30 }}>Posição do ( Arquivo Principal )</Styled.Label>
                                <Select itens={positionArqPrincipal} value={valuePositionArq} onSelected={setValuePositionArq} isSmall={true} />
                            </>
                        )}

                        {tipoAnexo === 1 && (
                            <Styled.FormContainer style={{ marginTop: '50px' }}>
                                <AdminImageUploader
                                    onSelect={i => setImage(i)}
                                    setBase64={b => setImage(b)}
                                    size={'sm'}
                                    title={'Arquivo Principal'}
                                    preImageB64={image}
                                    recommendedDimension={{ w: 439, h: 470 }}
                                />
                            </Styled.FormContainer>
                        )}

                        {(tipoAnexo === 3 || tipoAnexo === 2) && (
                            <Styled.FormContainer>
                                <Styled.Divisor width="100%">
                                    <Styled.Label>URL do {tipoAnexo === 2 ? 'áudio' : 'vídeo'}</Styled.Label>
                                    <Form.Control value={urlAnexo} onChange={(evt: any) => setUrlAnexo(evt.target.value)} />
                                </Styled.Divisor>
                            </Styled.FormContainer>
                        )}

                        {tipoAnexo === 4 && (
                            <Styled.FormContainer style={{ marginTop: '50px' }}>
                                <div>
                                    <input type="file" ref={inputElement} style={{ display: 'none' }} />
                                    <Styled.ButtonPDF onClick={chooseFile}>
                                        Selecione um arquivo PDF <p>{urlAnexo}</p>
                                    </Styled.ButtonPDF>
                                </div>
                            </Styled.FormContainer>
                        )}

                        <br />

                        <ReactQuill
                            style={{ marginTop: '20px' }}
                            theme="snow"
                            modules={editorConfig.modules}
                            formats={editorConfig.formats}
                            value={texto}
                            onChange={val => {
                                setTexto(val);
                            }}
                        />

                        <DrawerComponentsInteractions
                            open={openDrawer}
                            onClose={() => setOpenDrawer(false)}
                            onClickNewComponent={v => handleNewComponent(v)}
                        />

                        {/* Componentes Interativos */}

                        {componentInteractions
                            .sort((a, b) => a.ordem - b.ordem)
                            .map((dados, index) => {
                                if (dados.tipo === ComponentInteractionEnum.AUDIO) {
                                    return (
                                        <Styled.ContentComponentInteractions style={{ marginTop: '20px' }} key={index}>
                                            <Styled.Divisor width="100%">
                                                <Styled.Label>URL do áudio</Styled.Label>
                                                <Form.Control
                                                    value={dados.conteudo}
                                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                                        const newContent = evt.target.value;

                                                        // Atualiza o estado sem modificar diretamente o array anterior
                                                        setComponentsInteractions((prevInteractions: any) =>
                                                            prevInteractions.map((item: any, idx: number) =>
                                                                idx === index ? { ...item, conteudo: newContent } : item
                                                            )
                                                        );
                                                    }}
                                                />
                                            </Styled.Divisor>
                                            <Divider />
                                            <MenuComponentInteraction
                                                mode={typeModeEdit}
                                                onClickDelete={() => handleRemoveComponent(index, dados)}
                                                onClickSave={() => atualizationComponent(dados, index)}
                                                length={componentInteractions.length}
                                                handleOrdem={(newOrdem: number) => moveItem(dados.ordem, newOrdem)}
                                                myOrdem={dados.ordem}
                                                loadingButton={loadingButton}
                                            />
                                        </Styled.ContentComponentInteractions>
                                    );
                                }

                                if (dados.tipo === ComponentInteractionEnum.TEXT) {
                                    return (
                                        <Styled.ContentComponentInteractions key={index}>
                                            <ReactQuill
                                                style={{ marginTop: '20px', backgroundColor: dados.cor ?? '' }}
                                                theme="snow"
                                                modules={editorConfig.modules}
                                                formats={editorConfig.formats}
                                                value={dados.conteudo}
                                                onChange={val => {
                                                    // Atualiza o estado sem modificar diretamente o array anterior
                                                    setComponentsInteractions((prevInteractions: any) =>
                                                        prevInteractions.map((item: any, idx: number) =>
                                                            idx === index ? { ...item, conteudo: val } : item
                                                        )
                                                    );
                                                }}
                                            />
                                            <ColorPicker
                                                style={{ maxWidth: 180 }}
                                                defaultValue="#f7f7f7"
                                                size="large"
                                                value={dados.cor}
                                                onChangeComplete={v => updateColor(index, '#' + v.toHex())}
                                                showText
                                            />
                                            <MenuComponentInteraction
                                                mode={typeModeEdit}
                                                onClickDelete={() => handleRemoveComponent(index, dados)}
                                                onClickSave={() => atualizationComponent(dados, index)}
                                                length={componentInteractions.length}
                                                handleOrdem={(newOrdem: number) => moveItem(dados.ordem, newOrdem)}
                                                myOrdem={dados.ordem}
                                                loadingButton={loadingButton}
                                            />
                                            <Divider />
                                        </Styled.ContentComponentInteractions>
                                    );
                                }

                                if (dados.tipo === ComponentInteractionEnum.SANFONA) {
                                    return (
                                        <Styled.ContentComponentInteractions key={index}>
                                            <Divider />
                                            <Styled.Row>
                                                <SelectAnt
                                                    defaultValue={dados.cor}
                                                    style={{ width: 200, height: 46 }}
                                                    onChange={v => handleSetCor(v, index)}
                                                >
                                                    <Option value={Colors.Blue}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <ColorPicker value={Colors.Blue} disabledAlpha disabled />
                                                            <p style={{ marginLeft: 10 }}>{Colors.Blue}</p>
                                                        </div>
                                                    </Option>
                                                    <Option value={Colors.SoftBlue}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <ColorPicker value={Colors.SoftBlue} disabledAlpha disabled />
                                                            <p style={{ marginLeft: 10 }}>{Colors.SoftBlue}</p>
                                                        </div>
                                                    </Option>
                                                    <Option value={Colors.Yellow}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <ColorPicker value={Colors.Yellow} disabledAlpha disabled />
                                                            <p style={{ marginLeft: 10 }}>{Colors.Yellow}</p>
                                                        </div>
                                                    </Option>
                                                </SelectAnt>
                                            </Styled.Row>

                                            <ConfigProvider
                                                collapse={{
                                                    style: {
                                                        backgroundColor: dados.cor ?? '',
                                                    },
                                                }}
                                            >
                                                <Collapse
                                                    size="large"
                                                    items={dados.sanfona?.map((sanfona, idxSanfona) => ({
                                                        key: idxSanfona.toString(),
                                                        label: (
                                                            <Styled.InputSanfona
                                                                key={idxSanfona}
                                                                placeholder="Título da Sanfona"
                                                                value={sanfona.titulo}
                                                                colorSelected={dados.cor ?? ''}
                                                                onChange={e => handleAtualizationTitleSanfona(e.target.value, index, idxSanfona)}
                                                            />
                                                        ),
                                                        children: (
                                                            <SanfonaInteractionAdmin
                                                                key={idxSanfona}
                                                                onChangeValue={(v, tipo, idxConteudo) =>
                                                                    handleAtualizationConteudoSanfona(v, tipo, index, idxSanfona, idxConteudo)
                                                                }
                                                                handleNewConteud={(val: string, tipoConteudo: ComponentInteractionEnum) =>
                                                                    handleAddConteudoSanfona(val, tipoConteudo, index, idxSanfona)
                                                                }
                                                                conteudos={sanfona.componentSanfonaConteudos ?? []}
                                                            />
                                                        ),
                                                    }))}
                                                    onChange={onChange}
                                                    expandIcon={({ isActive }) => (
                                                        <StyledCaretRightOutlined
                                                            style={{
                                                                color:
                                                                    dados.cor === Colors.Blue || dados.cor === Colors.SoftBlue
                                                                        ? Colors.White
                                                                        : Colors.Blue,
                                                            }}
                                                            size={22}
                                                            rotate={isActive ? 90 : 0}
                                                        />
                                                    )}
                                                />
                                            </ConfigProvider>

                                            <Button
                                                onClick={() => handleAddNewAbaSanfona(index)}
                                                style={{ width: 200, backgroundColor: Colors.Yellow2 }}
                                                type="dashed"
                                            >
                                                Adicionar nova aba
                                            </Button>
                                            <MenuComponentInteraction
                                                mode={typeModeEdit}
                                                onClickDelete={() => handleRemoveComponent(index, dados)}
                                                onClickSave={() => atualizationComponent(dados, index)}
                                                length={componentInteractions.length}
                                                handleOrdem={(newOrdem: number) => moveItem(dados.ordem, newOrdem)}
                                                myOrdem={dados.ordem}
                                                loadingButton={loadingButton}
                                            />
                                        </Styled.ContentComponentInteractions>
                                    );
                                }

                                if (dados.tipo === ComponentInteractionEnum.QUIZ) {
                                    return (
                                        <Styled.ContentComponentInteractions style={{ marginTop: '20px' }} key={index}>
                                            <Divider />
                                            {dados.quiz &&
                                                dados.quiz.map((quiz, indexQuiz) => {
                                                    if (indexQuiz === quizIndex)
                                                        return (
                                                            <QuizInteractionAdmin
                                                                key={indexQuiz}
                                                                dados={quiz}
                                                                maxListQuiz={dados.quiz?.length ?? 1}
                                                                myIndex={indexQuiz + 1}
                                                                component={dados}
                                                                handleNewQuiz={() => handleNewQuiz(index)}
                                                                handleNavigateQuiz={v => handleNavigateQuiz(v, dados.quiz?.length ?? 1)}
                                                                atualizarPerguntaQuiz={v => atualizarPerguntaQuiz(index, indexQuiz, v)}
                                                                atualizarFeedbackQuiz={v => atualizarFeedbackQuiz(index, indexQuiz, v)}
                                                                adicionarNovaAlternativaQuiz={() =>
                                                                    adicionarNovaAlternativaQuiz(index, indexQuiz)
                                                                }
                                                                atualizarAlternativaQuiz={(indexAlternativa, value) =>
                                                                    atualizarAlternativaQuiz(index, indexQuiz, indexAlternativa, value)
                                                                }
                                                                atualizarCheckAlternativa={(indexAlternativa, checked) =>
                                                                    atualizarCheckAlternativa(index, indexQuiz, indexAlternativa, checked)
                                                                }
                                                                atualizarObrigatory={v => atualizarObrigatoryQuiz(index, v)}
                                                                atualizarMultiplos={v => atualizarMultiplos(index, indexQuiz, v)}
                                                                removerAlternativaQuiz={v => removerAlternativaQuiz(index, indexQuiz, v)}
                                                                deletarQuiz={() => deletarQuiz(index, indexQuiz)}
                                                            />
                                                        );
                                                })}

                                            <MenuComponentInteraction
                                                mode={typeModeEdit}
                                                onClickDelete={() => handleRemoveComponent(index, dados)}
                                                onClickSave={() => atualizationComponent(dados, index)}
                                                length={componentInteractions.length}
                                                handleOrdem={(newOrdem: number) => moveItem(dados.ordem, newOrdem)}
                                                myOrdem={dados.ordem}
                                                loadingButton={loadingButton}
                                            />
                                        </Styled.ContentComponentInteractions>
                                    );
                                }

                                if (dados.tipo === ComponentInteractionEnum.CORRESPONDENT) {
                                    return (
                                        <Styled.ContentComponentInteractions style={{ marginTop: '20px' }} key={index}>
                                            <Divider />
                                            <CorrespondenciaInteractionAdmin
                                                dadosCorrespondent={dados.correspondent}
                                                handleAtualizarInfos={(value, isPerunta) => {
                                                    handleAtualizarCorrespondent(index, value, isPerunta);
                                                }}
                                                handleItens={(itens: CorrespondentItemModel[]) => handleItemCorrespondent(index, itens)}
                                            />
                                            <MenuComponentInteraction
                                                mode={typeModeEdit}
                                                onClickDelete={() => handleRemoveComponent(index, dados)}
                                                onClickSave={() => atualizationComponent(dados, index)}
                                                length={componentInteractions.length}
                                                handleOrdem={(newOrdem: number) => moveItem(dados.ordem, newOrdem)}
                                                myOrdem={dados.ordem}
                                                loadingButton={loadingButton}
                                            />
                                        </Styled.ContentComponentInteractions>
                                    );
                                }
                                if (dados.tipo === ComponentInteractionEnum.CARROSSEL) {
                                    return (
                                        <Styled.ContentComponentInteractions style={{ marginTop: '20px' }} key={index}>
                                            <Divider />
                                            <CarrosselInteractionAdmin
                                                items={dados.carrossel?.itens ?? []}
                                                handleInfosCarrosel={itens => handleItemCarrossel(index, itens)}
                                            />
                                            <MenuComponentInteraction
                                                mode={typeModeEdit}
                                                onClickDelete={() => handleRemoveComponent(index, dados)}
                                                onClickSave={() => atualizationComponent(dados, index)}
                                                length={componentInteractions.length}
                                                handleOrdem={(newOrdem: number) => moveItem(dados.ordem, newOrdem)}
                                                myOrdem={dados.ordem}
                                                loadingButton={loadingButton}
                                            />
                                        </Styled.ContentComponentInteractions>
                                    );
                                }

                                if (dados.tipo === ComponentInteractionEnum.ABAS) {
                                    return (
                                        <Styled.ContentComponentInteractions style={{ marginTop: '20px' }} key={index}>
                                            <Divider />
                                            <ComponentAbasAdmin dados={dados.abas} handleAtualizationAbas={abas => handleAbas(index, abas)} />
                                            <MenuComponentInteraction
                                                mode={typeModeEdit}
                                                onClickDelete={() => handleRemoveComponent(index, dados)}
                                                onClickSave={() => atualizationComponent(dados, index)}
                                                length={componentInteractions.length}
                                                handleOrdem={(newOrdem: number) => moveItem(dados.ordem, newOrdem)}
                                                myOrdem={dados.ordem}
                                                loadingButton={loadingButton}
                                            />
                                        </Styled.ContentComponentInteractions>
                                    );
                                }

                                if (dados.tipo === ComponentInteractionEnum.CARD_BARALHO) {
                                    return (
                                        <Styled.ContentComponentInteractions style={{ marginTop: '20px' }} key={index}>
                                            <Divider />
                                            <ComponentBaralhoAdmin
                                                componentInteractionCodigo={dados.id}
                                                cardsModels={dados.baralho ?? []}
                                                quantityVisible={dados.quantityVisible}
                                                atualizarQuantityVisible={(n: number) => atualizarQuantityVisible(index, n)}
                                            />
                                            <MenuComponentInteraction
                                                mode={typeModeEdit}
                                                onClickDelete={() => handleRemoveComponent(index, dados)}
                                                onClickSave={() => atualizationComponent(dados, index)}
                                                length={componentInteractions.length}
                                                handleOrdem={(newOrdem: number) => moveItem(dados.ordem, newOrdem)}
                                                myOrdem={dados.ordem}
                                                loadingButton={loadingButton}
                                                ocultarButtonSave
                                            />
                                        </Styled.ContentComponentInteractions>
                                    );
                                }
                                if (dados.tipo === ComponentInteractionEnum.REGUA_AVALIATIVA) {
                                    return (
                                        <Styled.ContentComponentInteractions style={{ marginTop: '20px' }} key={index}>
                                            <Divider />
                                            <ComponentReguaAvaliativaAdmin dados={dados.reguaAvaliativa} codigoInteraction={dados.id} />
                                            <MenuComponentInteraction
                                                mode={typeModeEdit}
                                                onClickDelete={() => handleRemoveComponent(index, dados)}
                                                onClickSave={() => atualizationComponent(dados, index)}
                                                length={componentInteractions.length}
                                                handleOrdem={(newOrdem: number) => moveItem(dados.ordem, newOrdem)}
                                                myOrdem={dados.ordem}
                                                loadingButton={loadingButton}
                                                ocultarButtonSave
                                            />
                                        </Styled.ContentComponentInteractions>
                                    );
                                }
                                if (dados.tipo === ComponentInteractionEnum.TIMELINE) {
                                    return (
                                        <Styled.ContentComponentInteractions style={{ marginTop: '20px' }} key={index}>
                                            <Divider />
                                            <ComponentTimeLineAdmin
                                                dados={dados.timelines}
                                                codigoInteraction={dados.id}
                                                color={dados.cor ?? ''}
                                            />
                                            <MenuComponentInteraction
                                                mode={typeModeEdit}
                                                onClickDelete={() => handleRemoveComponent(index, dados)}
                                                onClickSave={() => atualizationComponent(dados, index)}
                                                length={componentInteractions.length}
                                                handleOrdem={(newOrdem: number) => moveItem(dados.ordem, newOrdem)}
                                                myOrdem={dados.ordem}
                                                loadingButton={loadingButton}
                                                ocultarButtonSave
                                            />
                                        </Styled.ContentComponentInteractions>
                                    );
                                }
                                if (dados.tipo === ComponentInteractionEnum.QUIZ_PONTUACAO) {
                                    return (
                                        <Styled.ContentComponentInteractions style={{ marginTop: '20px' }} key={index}>
                                            <Divider />
                                            <ComponentQuizPontucaoAdmin dados={dados.quizPontuacao} codigoInteraction={dados.id} />
                                            <MenuComponentInteraction
                                                mode={typeModeEdit}
                                                onClickDelete={() => handleRemoveComponent(index, dados)}
                                                onClickSave={() => atualizationComponent(dados, index)}
                                                length={componentInteractions.length}
                                                handleOrdem={(newOrdem: number) => moveItem(dados.ordem, newOrdem)}
                                                myOrdem={dados.ordem}
                                                loadingButton={loadingButton}
                                                ocultarButtonSave
                                            />
                                        </Styled.ContentComponentInteractions>
                                    );
                                }
                                if (dados.tipo === ComponentInteractionEnum.CENARIO) {
                                    return (
                                        <Styled.ContentComponentInteractions style={{ marginTop: '20px' }} key={index}>
                                            <Divider />
                                            <ComponentCenarioAdmin dados={dados.cenarios} codigoInteraction={dados.id} />
                                            <MenuComponentInteraction
                                                mode={typeModeEdit}
                                                onClickDelete={() => handleRemoveComponent(index, dados)}
                                                onClickSave={() => atualizationComponent(dados, index)}
                                                length={componentInteractions.length}
                                                handleOrdem={(newOrdem: number) => moveItem(dados.ordem, newOrdem)}
                                                myOrdem={dados.ordem}
                                                loadingButton={loadingButton}
                                                ocultarButtonSave
                                            />
                                        </Styled.ContentComponentInteractions>
                                    );
                                }
                            })}

                        <Row
                            justify="end"
                            style={{
                                marginTop: 20,
                                justifyContent: 'start',
                                gap: '5rem',
                            }}
                        >
                            <YellowButton style={{ maxWidth: '280px' }} onClick={() => setOpenDrawer(true)}>
                                Adicionar novo componente
                            </YellowButton>

                            <BlueSolidButton disabled={submitting} onClick={() => submit(false)}>
                                {mode == 'NEW' ? (
                                    <>{submitting ? 'Salvando...' : 'Salvar conteúdo'}</>
                                ) : (
                                    <>{submitting ? 'Atualizando...' : 'Atualizar conteúdo'}</>
                                )}
                            </BlueSolidButton>

                            <BlueSolidButton disabled={submitting} onClick={() => submit(true)}>
                                {mode == 'NEW' ? (
                                    <>{submitting ? 'Salvando...' : 'Salvar e voltar'}</>
                                ) : (
                                    <>{submitting ? 'Atualizando...' : 'Atualizar e voltar'}</>
                                )}
                            </BlueSolidButton>
                            {typeModeEdit && (
                                <>
                                    <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                        <Styled.DivAround style={{ justifyContent: 'space-between', width: '200px' }}>
                                            <Row>
                                                <RedSolidButton
                                                    onClick={() => {
                                                        setShowModal(true);
                                                    }}
                                                >
                                                    Deletar
                                                </RedSolidButton>
                                            </Row>
                                        </Styled.DivAround>
                                    </AllowedGroupsValidator>
                                    <Styled.CkeckContainer>
                                        <Row>
                                            <span style={{ marginRight: '10px', fontSize: '16px', fontWeight: 600 }}>Ocultar</span>
                                            <Form>
                                                <Form.Check
                                                    type="switch"
                                                    id={'conteudo' + codigoConteudo}
                                                    checked={oculto}
                                                    onClick={() => {
                                                        !oculto ? handleOcultar() : handleExibir();
                                                    }}
                                                />
                                            </Form>
                                        </Row>
                                    </Styled.CkeckContainer>
                                </>
                            )}
                        </Row>
                    </div>
                )}
            </Styled.Container>
        </>
    );
};

export default AdminGestaoPaginaConteudoRecurso;
