import React from 'react';
import Colors from 'enums/Colors';
import TextArea from 'antd/es/input/TextArea';
import styled from 'styled-components';
import { FAD_Row1 } from 'pages/ferramentaAutoria/AdminFerramentaAutoria.styled';
import { Input, Modal, Switch } from 'antd';
import { FadContentsModel } from 'models/ferramentaAutoria/FadContentsModel';
import { FAD_Button_Outline } from '../FadButtons';

const Styled = {
    Container: styled.div`
        padding: 20px;
        margin-bottom: 50px;
        margin-left: 100px;
        display: flex;
        flex-direction: column;
        background-color: ${Colors.White};
    `,

    ColumnForm: styled.div`
        display: flex;
        flex-direction: column;
        gap: 4px;
    `,

    LabelForm: styled.h3`
        //styleName: Base/Legenda 2;
        font-family: 'Barlow';
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        text-align: left;
        text-decoration-skip-ink: none;
        color: ${Colors.Blue};
    `,
    LabelForm2: styled.h3`
        //styleName: Base/Legenda 2;
        //styleName: Base/Corpo;
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        text-decoration-skip-ink: none;
        color: #666666;
    `,
};

interface IModalFormContent {
    open: boolean;
    loading: boolean;
    handleSave: () => void;
    handleEdit: () => void;
    handleHidden: () => void;
    setForm: (value: any) => void;
    form: FadContentsModel;
    openEdit?: boolean;
    handleDelete: (content: FadContentsModel) => void;
}

const ModalFormContent = ({ open, loading, form, handleSave, handleEdit, handleHidden, setForm, openEdit, handleDelete }: IModalFormContent) => {
    return (
        <Modal
            title={<h1 style={{ fontSize: 26 }}> {openEdit ? 'Editar conteúdo' : 'Criar conteúdo'}</h1>}
            open={open || openEdit}
            onOk={openEdit ? handleEdit : handleSave}
            okText={openEdit ? 'Salvar edição' : 'Criar conteúdo'}
            cancelText={'Cancelar'}
            maskClosable={false}
            confirmLoading={loading}
            okButtonProps={{
                style: {
                    backgroundColor: Colors.Blue,
                },
            }}
            onCancel={handleHidden}
        >
            <Styled.ColumnForm style={{ borderTop: '1px solid #F0F0F0', paddingTop: 22 }}>
                <Styled.LabelForm>Título do conteúdo</Styled.LabelForm>
                <Input
                    showCount
                    value={form.title}
                    maxLength={100}
                    onChange={e => {
                        setForm((prev: any) => ({ ...prev, title: e.target.value }));
                    }}
                    placeholder="Digite o título do conteúdo"
                    size="large"
                />
            </Styled.ColumnForm>
            <FAD_Row1 style={{ marginTop: 10 }}>
                <Switch
                    value={form.isPublic}
                    onChange={c => {
                        setForm((prev: any) => ({ ...prev, isPublic: c }));
                    }}
                />
                <Styled.LabelForm2>Conteúdo público</Styled.LabelForm2>
            </FAD_Row1>
            <FAD_Row1 style={{ marginTop: 10 }}>
                <Switch
                    value={form.activeAvaliations}
                    onChange={c => {
                        setForm((prev: any) => ({ ...prev, activeAvaliations: c }));
                    }}
                />
                <Styled.LabelForm2>Avaliação</Styled.LabelForm2>
            </FAD_Row1>
            <FAD_Row1 style={{ marginTop: 10 }}>
                <Switch
                    value={form.activeCommentary}
                    onChange={c => {
                        setForm((prev: any) => ({ ...prev, activeCommentary: c }));
                    }}
                />
                <Styled.LabelForm2>Comentário</Styled.LabelForm2>
            </FAD_Row1>
            <FAD_Button_Outline
                styles={{
                    backgroundColor: Colors.DeleteRed,
                    color: Colors.White,
                    height: 32,
                    marginTop: 22,
                    width: 200,
                    position: 'relative',
                    top: 42,
                }}
                text="Excluir conteúdo"
                onClick={() => handleDelete(form)}
            />
        </Modal>
    );
};

export default ModalFormContent;
