import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FAD_Button_Outline, FAD_Button_Primary } from '../components/FadButtons';
import { IoIosArrowBack } from 'react-icons/io';
import { FaListAlt, FaPlay } from 'react-icons/fa';
import { BsFillSendFill } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';
import Colors from 'enums/Colors';
import { MdEditSquare, MdPublic } from 'react-icons/md';
import { FadCoursesService } from 'core/http/service/ferramentaAutoria/FadCourses.service';
import { FadCoursesModel } from 'models/ferramentaAutoria/FadCoursesModel';
import TemplateCourse, { TemplateCourseFadEnum } from '../components/courses/TemplateCourse';
import FadAdds from '../components/adds/FadAdds';
import { FadModulesModel } from 'models/ferramentaAutoria/FadModulesModel';
import { FadModulesService } from 'core/http/service/ferramentaAutoria/FadModules.service';
import useFetch from 'hooks/useFetch';
import FadListModules from '../components/modules/FadListModules';
import { Badge, Divider, Popover, Select } from 'antd';
import { FadPagesModel } from 'models/ferramentaAutoria/FadPagesModel';
import { FadPagesService } from 'core/http/service/ferramentaAutoria/FadPages.service';
import { FadRelationsPageService } from 'core/http/service/ferramentaAutoria/FadRelationsPage.service';
import { FadRelationsPageModel } from 'models/ferramentaAutoria/FadRelationsPageModel';
import ModalFormCourse from '../components/modals/ModalFormCourse';
import { FerramentaAutoriaContext } from '../context/FerramentaAutoriaContext';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import { HiLockClosed } from 'react-icons/hi';
import { TbMessage } from 'react-icons/tb';

const Styled = {
    Container: styled.div`
        padding: 20px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        background-color: ${Colors.White};
    `,

    ContentBeet: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,

    Row: styled.div`
        display: flex;
        align-items: center;
        gap: 16px;
    `,

    Column: styled.div`
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 16px;
    `,

    SubTitle: styled.h2`
        font-family: 'Barlow';
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    `,

    TextTitle: styled.h1`
        font-family: 'Barlow';
        font-size: 22px;
        font-weight: 600;
        line-height: 26.4px;
        text-align: left;
        width: 520px; /* Defina a largura desejada */
        white-space: nowrap; /* Impede a quebra de linha */
        overflow: hidden; /* Esconde o texto que excede a largura */
        text-overflow: ellipsis; /* Adiciona '...' ao final do texto */
        color: ${Colors.Blue};
    `,
};

const InitialFormCourse = FadCoursesModel.fromJSON({
    codigo: -1,
    codigoCertificado: -1,
    name: '',
    description: '',
    url: '',
    typeRedirect: TipoRedirecionamento.IGNORAR, // Assuming TipoRedirecionamentoEnum is converted to String
    template: TemplateCourseFadEnum.Lateral,
    sequentialContents: false,
    sequentialSteps: false,
    isPublic: false,
    ordem: 1,
});

const AdminFadCourse = () => {
    const history = useHistory();
    const params: any = useParams();

    //Variaveis
    const [course, setCourse] = useState<FadCoursesModel>(new FadCoursesModel());
    const [modules, setModules] = useState<FadModulesModel[]>([]);
    const [pages, setPages] = useState<FadRelationsPageModel[]>([]);

    const [quantityContents, setQuantityContents] = useState<number>(0);

    //All
    const [listAllPages, setListAllPages] = useState<FadPagesModel[]>([]);

    //services
    const serviceFadCourses = new FadCoursesService();
    const serviceModules = new FadModulesService();
    const servicePages = new FadPagesService();
    const serviceRelationPages = new FadRelationsPageService();

    const [formCourse, setFormCourse] = useState<FadCoursesModel>(InitialFormCourse);
    const { updateCourse, certificados, courseModel } = useContext(FerramentaAutoriaContext.Context);

    const [isModalOpenEditCourse, setModalOpenEditCourse] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleUpdate = async (attPublic: boolean) => {
        setLoading(true);

        if (formCourse) {
            let form = attPublic
                ? new FadCoursesModel().fromJSON({
                      ...course,
                      isPublic: attPublic ? !course.isPublic : course.isPublic,
                  })
                : formCourse;

            const novoForm = form;
            novoForm.url = '/' + novoForm.url;

            updateCourse(novoForm);
        }

        setModalOpenEditCourse(false);
        setLoading(false);
    };

    const fetchCourse = async () => {
        const { data, status } = await serviceFadCourses.findById(params.idCurso);

        if (status === 200) {
            setCourse(data);
        }
    };

    const handleAddQuantity = (quantity: number) => {
        const newValue = quantityContents + quantity;
        setQuantityContents(newValue);
    };

    const handleRelationPage = async (codigoPage: number) => {
        if (course) {
            const datePage = new FadRelationsPageModel().fromJSON({
                codigo: -1,
                codigoFerramentaAutoriaCourse: course.codigo,
                codigoFerramentaAutoriaPage: codigoPage,
            });

            const { data, status } = await serviceRelationPages.create(datePage);

            if (status === 201) {
                setPages((prev: any) => [...prev, data]);
            }
        }
    };

    const handleRemoveRelationPage = async (codigoModel: number) => {
        if (course) {
            const { status } = await serviceRelationPages.deleteModel(codigoModel);

            if (status === 204) {
                setPages(prev => prev.filter(p => p.codigo !== codigoModel));
            }
        }
    };

    // Função para lidar com a alteração no Select
    const handleSelectChange = async (selectedValues: number[]) => {
        const codigosParaAdicionar = selectedValues.filter((n: number) => !pages.some((p: FadRelationsPageModel) => p.codigo === n));

        const codigosParaRemover = pages.filter((p: FadRelationsPageModel) => !selectedValues.includes(p.codigo));

        codigosParaAdicionar.forEach(n => {
            handleRelationPage(n);
        });

        codigosParaRemover.forEach(page => {
            handleRemoveRelationPage(page.codigo);
        });
    };

    const removeModuleList = (codigo: number) => {
        setModules((prev: FadModulesModel[]) => prev.filter(m => m.codigo !== codigo));
    };

    useFetch(async () => {
        if (course) {
            const { data, status } = await serviceModules.findByFadCoursesCodigo(course.codigo);

            if (status === 200) {
                setModules(data);
            }
        }
    }, [course]);

    useFetch(async () => {
        if (course) {
            const { data, status } = await servicePages.findAllFadPages();

            if (status === 200) {
                setListAllPages(data);
            }
        }
    }, [course]);

    useFetch(async () => {
        if (course) {
            const { data, status } = await serviceRelationPages.findByCodigoFadCourses(course.codigo);

            if (status === 200) {
                setPages(data);
            }
        }
    }, [course]);

    const handleNewModules = async () => {
        if (course) {
            const newModules = new FadModulesModel().fromJSON({
                codigo: -1,
                title: 'Nome do novo módulo',
                description: 'Descrição do módulo',
                isPublic: false,
                ordem: modules.length + 1,
                codigoFerramentaAutoriaCourse: course.codigo,
            });

            const { status, data } = await serviceModules.create(newModules);

            if (status === 201) {
                setModules(prevModules => [...prevModules, data]);
            }
        }
    };

    useEffect(() => {
        fetchCourse();
    }, [params]);

    useEffect(() => {
        fetchCourse();
    }, []);

    useEffect(() => {
        if (courseModel) {
            setCourse(courseModel);
        }
    }, [courseModel]);

    const goToBack = () => {
        history.push('/admin/ferramenta-autoria');
    };

    return (
        <Styled.Container>
            {course && (
                <>
                    <Styled.ContentBeet style={{ borderBottom: '1px solid #e3e3e3 ', paddingBottom: 22 }}>
                        <FAD_Button_Outline icon={<IoIosArrowBack />} text="Voltar" onClick={goToBack} />
                        <Styled.Column>
                            <Styled.Row>
                                <Styled.TextTitle>{course.name}</Styled.TextTitle>
                                {/* Abrir o modal de edição ao clicar no botão abaixo */}
                                <MdEditSquare
                                    size={28}
                                    cursor={'pointer'}
                                    color={Colors.Yellow2}
                                    onClick={() => {
                                        setFormCourse(course), setModalOpenEditCourse(true);
                                    }}
                                />
                            </Styled.Row>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '100%', height: 50 }}
                                placeholder="Selecione as páginas que vai exibir esse curso"
                                onChange={handleSelectChange}
                                options={listAllPages.map(l => ({
                                    value: l.codigo,
                                    label: (
                                        <p
                                            style={{
                                                backgroundColor: l.cor,
                                                color: l.cor ? Colors.White : '',
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                            }}
                                        >
                                            {l.url}
                                        </p>
                                    ),
                                }))}
                                value={listAllPages
                                    .filter(p => pages.some(page => page.codigoFerramentaAutoriaPage === p.codigo)) // Filtra os itens com códigos iguais
                                    .map(p => p.codigo)}
                            />
                        </Styled.Column>

                        <Styled.Column>
                            <Styled.Row style={{ justifyContent: 'end', width: '100%' }}>
                                {course.isPublic ? (
                                    <MdPublic size={22} color={Colors.CloneGreen} />
                                ) : (
                                    <HiLockClosed size={22} color={Colors.Grey7} />
                                )}
                                <Styled.SubTitle
                                    style={{ fontSize: 18, color: course.isPublic ? Colors.CloneGreen : Colors.Grey7, fontWeight: 600 }}
                                >
                                    {course.isPublic ? 'Curso publicado' : 'Curso privado'}
                                </Styled.SubTitle>
                            </Styled.Row>
                            <Styled.Row>
                                <FAD_Button_Outline icon={<FaPlay />} text="Preview" onClick={() => ''} />
                                <FAD_Button_Outline
                                    icon={<FaListAlt />}
                                    text="Turmas"
                                    onClick={() => history.push('/admin/ferramenta-autoria/curso/turmas/' + course.codigo)}
                                />
                                {/* <FAD_Button_Outline
                                    icon={
                                        <Badge count={1} overflowCount={3} size="small">
                                            <TbMessage size={18} className="iconButtonFad" color={Colors.Blue} />
                                        </Badge>
                                    }
                                    text="Comentários"
                                    onClick={() => ''}
                                /> */}
                                <FAD_Button_Primary
                                    icon={course.isPublic ? <HiLockClosed size={22} /> : <BsFillSendFill />}
                                    text={course.isPublic ? 'Despublicar' : 'Publicar'}
                                    styles={{ backgroundColor: course.isPublic ? Colors.Yellow2 : Colors.Blue }}
                                    onClick={() => {
                                        handleUpdate(true);
                                    }}
                                />
                            </Styled.Row>
                        </Styled.Column>
                    </Styled.ContentBeet>
                    <Styled.ContentBeet>
                        <TemplateCourse template={course.template} dados={course} handleCourse={setCourse} />
                    </Styled.ContentBeet>
                    <Styled.Row style={{ marginTop: 10 }}>
                        <Styled.SubTitle>{modules.length} Módulo(s)</Styled.SubTitle>
                    </Styled.Row>
                    <Divider style={{ width: '80%', marginBottom: 48 }} />
                    <Styled.Row></Styled.Row>
                    {modules.map((module, index) => (
                        <FadListModules
                            key={index}
                            dados={module}
                            handleQuantity={v => handleAddQuantity(v)}
                            removeModule={c => removeModuleList(c)}
                        />
                    ))}
                        <FadAdds onClick={handleNewModules} textPopover='Adicionar um novo módulo' />
                </>
            )}
            <ModalFormCourse
                open={false}
                openEdit={isModalOpenEditCourse}
                handleSave={() => ''}
                handleEdit={() => handleUpdate(false)}
                loading={loading}
                certificados={certificados}
                form={formCourse}
                setForm={setFormCourse}
                handleHidden={() => setModalOpenEditCourse(false)}
            />
        </Styled.Container>
    );
};

export default AdminFadCourse;
