import { ToastSuccess } from 'components/Toaster';
import { ComponentInteractionService } from 'core/http/service/componetInteraction/ComponentInteraction.service';
import { ComponentInteractionAnexoService } from 'core/http/service/componetInteraction/ComponentInteractionAnexo.service';
import ComponentInteractionModel from 'models/componentInteraction/ComponentInteraction';
import { ComponentInteractionAnexo } from 'models/componentInteraction/ComponentInteractionAnexo';

const serviceComponentInteraction = new ComponentInteractionService();
const serviceComponentInteractionAnexo = new ComponentInteractionAnexoService();

const handleSaveComponent = async (component: ComponentInteractionModel) => {
    const { data, status } = await serviceComponentInteraction.saveComponentByFAD(component);

    if (status === 200) {
        ToastSuccess('Componente salvo com sucesso!');
        return data;
    }
};

const insertAndUpdateAnexoComponentInteraction = async (anexo: ComponentInteractionAnexo, idComponent: number) => {
    const { data, status } = await serviceComponentInteractionAnexo.insertAnexo(anexo, idComponent);

    if (status === 200) {
        ToastSuccess('Anexo salvo com sucesso!');
        return data;
    }
};

const fetchAnexoByComponent = async (idComponent: number, pub: boolean) => {
    const { data, status } = await serviceComponentInteractionAnexo.fetchAnexoByCodigoComponent(idComponent, pub);

    if (status === 200) {
        return data;
    }
};

export default {
    handleSaveComponent,
    insertAndUpdateAnexoComponentInteraction,
    fetchAnexoByComponent
};
