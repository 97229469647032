import { FadModulesAnexoModel } from "models/ferramentaAutoria/FadModulesAnexoModel";
import { GenericService } from "../Generic.service";

export class FadModulesAnexoService extends GenericService {
    readonly URI: string = "/v1/fadModulesAnexo";

    constructor() {
        super();
        this.setUri(this.URI);
    }

    // Método para criar um novo FadModulesAnexo
    async create(model: FadModulesAnexoModel) {
        return await this.post("", model);
    }

    // Método para buscar por código do módulo
    async findByModule(codigoModule: number, isPublic: boolean) {
        return await this.get(`${codigoModule}`, { isPublic });
    }
}