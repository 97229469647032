import React, { useContext, useEffect } from 'react';
import Styled from './CirtuitoPrivateStyled';
import OverviewExclusivo from '../components/OverviewExclusivo';
import MateriaisExclusivos from '../components/MateriasExclusivos';
import ContactContainer from 'pages/home/components/contact/ContactContainer';
import PartnersExclusivo from '../components/PartnersExclusivo';
import { AcessoExclusivoContext } from 'pages/acessoExclusivo/context/AcessoExclusivoContext';
import Loading from 'components/Loading';
import { ListMateriaisCircuitoPrivate } from './ListMateriasCircuitoPrivate';

const CircuitoPrivate = () => {
    const { loading } = useContext(AcessoExclusivoContext.Context);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Styled.Container>
            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <OverviewExclusivo
                        isBlue
                        img={'anexo_storage_files_720895641496235745.jpg_2024-11-26-1732646443603.jpg'}
                        logo="anexo_storage_files_6208071222496577968.svg_2024-11-26-1732646737771.svg"
                        title="CIRCUITO 360"
                        description={`
                        O Instituto Ayrton Senna defende a educação integral, que preconiza o desenvolvimento dos estudantes em suas múltiplas dimensões, respeitando sua diversidade e singularidade. 
                        Essa abordagem integra habilidades cognitivas e competências como socioemocionais, híbridas e volitivas, essenciais para o século 21.
                        <br/>
                        <br/>
                        Nos anos iniciais do Ensino Fundamental, é crucial garantir dois aspectos:
                        <br/>
                        <br/>
                       <li>
                        Alfabetização eficaz nos três primeiros anos;
                       </li>
                       <li>
                        Avanço nos dois últimos anos (4º e 5º anos).
                       </li>
                       <br/>
                       <strong>
                       Para apoiar as secretarias de educação na implementação de políticas públicas que assegurem a alfabetização até o 3º ano e a qualidade do ensino nos anos iniciais, 
                       o Instituto criou a solução educacional Circuito 360.
                       </strong>
                `}
                        arquivo={'anexo_storage_files_3110028966101304179.pdf_2024-11-27-1732735029980.pdf'}
                        nome_arquivo="Circuito_360_Visao_Geral"
                    />
                    {/* <CicloExclusivo
                        title="TRILHA FORMATIVA"
                        description={`
                    A trilha formativa do Diálogos Socioemocionais apresenta os conteúdos da solução educacional de modo que os profissionais 
                    (técnicos da Secretaria de Educação, gestores regionais, coordenadores pedagógicos e docentes) se apropriem dos conteúdos essenciais para a implementação da proposta.
                    Inicie agora a sua trilha formativa!
                    `}
                        img={Banner02}
                        itens={[
                            {
                                title: 'Trilha Formativa Duplo Foco',
                                url: '/recursos/dialogos-socioemocionais/dialogos-socioemocionais-duplo-foco',
                            },
                        ]}
                    /> */}

                    <MateriaisExclusivos
                        title="ACESSE OS MATERIAIS DO CIRCUITO 360"
                        description={`Tenha acesso aos materiais exclusivos do Circuito 360, conheça um pouco mais sobre a solução e comece a traçar os próximos passos rumo à implementação na rede.`}
                        img={'anexo_storage_files_8557788060963491135.jpeg_2024-11-26-1732644557765.jpeg'}
                        items={ListMateriaisCircuitoPrivate}
                    />
                    <PartnersExclusivo
                        title="ALFABETIZAÇÃO INTEGRAL EM APOIO AO COMPROMISSO NACIONAL CRIANÇA ALFABETIZADA"
                        description={`
                            Você sabia que o Instituto Ayrton Senna também disponibiliza na humane uma <strong>trilha formativa sobre Alfabetização,</strong> com <strong>duração de 21hs, 
                            certificado emitido pelo IAS USP Ribeirão Preto </strong>, e que você pode acessa-la gratuitamente?
                            <br /><br />
                            A Trilha aborda conceitos e estratégias de alfabetização, a alfabetização matemática, dificuldades de aprendizagem e práticas de superação,
                            a cultura digital na alfabetização, dentre outros temas. Você também terá acesso a atividades práticas relacionadas aos assuntos apresentados na trilha.
                            <br /><br />
                            Aproveite mais essa oportunidade e aprofunde seus conhecimentos.   
                    `}
                        img={'anexo_storage_files_13062755144318252306.jpg_2024-11-26-1732648981401.jpg'}
                        button={{
                            label: 'Acesse a trilha',
                            url: '/recursos/trilhas/trilha-de-alfabetizacao-aprofundada',
                        }}
                    />
                    <ContactContainer />
                </>
            )}
        </Styled.Container>
    );
};

export default CircuitoPrivate;
