import { FadClassesModel } from 'models/ferramentaAutoria/FadClassesModel';
import { GenericService } from '../Generic.service';

export class FadClassesService extends GenericService {
    readonly URI: string = '/v1/fadClasses';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    async create(model: FadClassesModel) {
        return await this.post('', model);
    }

    async update(codigo: number, model: FadClassesModel) {
        return await this.put(`${codigo}`, model);
    }

    async deleteFadClasses(codigo: number) {
       return await this.delete(codigo);
    }

    async findById(codigo: number) {
        return await this.get(`${codigo}`);
    }

    async findAllFadClasses() {
        return await this.get();
    }

    async findByFadCourses(fadCourses: any) {
        return await this.get('byFadCourses', fadCourses);
    }

    async findByFadCoursesCodigo(codigo: number) {
        return await this.get(`byFadCoursesCodigo/${codigo}`);
    }
    async findByFadCoursesCodigoPrivate(codigo: number) {
        return await this.get(`byFadCoursesCodigo/private/${codigo}`);
    }

    async findByCategoriaPaginaExclusiva(categoriaPaginaExclusiva: any) {
        return await this.get('byCategoriaPaginaExclusiva', categoriaPaginaExclusiva);
    }

    async findByCategoriaPaginaExclusivaCodigo(codigo: number) {
        return await this.get(`byCategoriaPaginaExclusivaCodigo/${codigo}`);
    }

    async findByName(name: string) {
        return await this.get(`byName/${name}`);
    }

    async findByMaxRegistrationDate(maxRegistrationDate: Date) {
        return await this.get('byMaxRegistrationDate', { maxRegistrationDate });
    }

    async findByClosingDate(closingDate: Date) {
        return await this.get('byClosingDate', { closingDate });
    }
}
