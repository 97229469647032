import { jsonProperty, Serializable, SerializationSettings } from "ts-serializable";

interface IFadTemplates {
    codigo: number;
    name: string;
}

export class FadTemplatesModel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(String)
    name: string = "";

    fromJSON(json: IFadTemplates, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.name = json.name;
        return this;
    }
}