import { FadRelationsPageModel } from 'models/ferramentaAutoria/FadRelationsPageModel';
import { GenericService } from '../Generic.service';

export class FadRelationsPageService extends GenericService {
    readonly URI: string = '/v1/fadRelationsPage';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    async create(model: FadRelationsPageModel) {
        return await this.post('', model);
    }

    async update(codigo: number, model: FadRelationsPageModel) {
        return await this.put(`${codigo}`, model);
    }

    async deleteModel(codigo: number) {
        return await this.delete(codigo);
    }

    async findById(codigo: number) {
        return await this.get(`${codigo}`);
    }

    async findAllFadRelationsPage() {
        return await this.get();
    }

    async findByFadPages(fadPages: any) {
        return await this.get('byFadPages', fadPages);
    }

    async findByCodigoFadPages(codigoFadPages: number) {
        return await this.get(`byCodigoFadPages/${codigoFadPages}`);
    }

    async findByFadCourses(fadCourses: any) {
        return await this.get('byFadCourses', fadCourses);
    }

    async findByCodigoFadCourses(codigoFadCourses: number) {
        return await this.get(`byCodigoFadCourses/${codigoFadCourses}`);
    }
}