import { jsonProperty, Serializable, SerializationSettings } from "ts-serializable";

interface IFadRelationsTemplate {
    codigo: number;
    codigoTemplate: number;
    type: string;
    ordem: number;
}

export class FadRelationsTemplateModel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number)
    codigoTemplate: number = -1;

    @jsonProperty(String)
    type: string = "";

    
    @jsonProperty(Number)
    ordem: number = -1;

    fromJSON(json: IFadRelationsTemplate, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.codigoTemplate = json.codigoTemplate;
        this.type = json.type;
        this.ordem = json.ordem;
        return this;
    }
}