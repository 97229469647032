import { FadRelationsTemplateModel } from 'models/ferramentaAutoria/FadRelationsTemplateModel';
import { GenericService } from '../Generic.service';

export class FadRelationsTemplateService extends GenericService {
    readonly URI: string = '/v1/fadRelationsTemplates';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    // Método para criar ou atualizar uma relação de template
    async saveRelationTemplate(relationTemplate: FadRelationsTemplateModel) {
        return await this.post('', relationTemplate);
    }

    async updateRelationTemplate(codigo: number, relationTemplate: FadRelationsTemplateModel){
        return await this.put("" + codigo, relationTemplate);
    }

    // Método para buscar todas as relações de templates
    async getAllRelationTemplates() {
        return await this.get();
    }

    // Método para buscar uma relação de template por código
    async getRelationTemplateByCodigo(codigo: number) {
        return await this.get(`template/${codigo}`);
    }

    // Método para deletar uma relação de template
    async deleteRelationTemplate(codigo: number) {
        return await this.delete(codigo);
    }
}
