import { GetProp, message, Popover, Skeleton, Upload, UploadProps } from 'antd';
import Colors from 'enums/Colors';
import { FadCoursesModel } from 'models/ferramentaAutoria/FadCoursesModel';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import './upload.css';
import ImageUpload from 'components/uploader/ImageUpload';
import { FadCoursesService } from 'core/http/service/ferramentaAutoria/FadCourses.service';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { FadCoursesAnexoService } from 'core/http/service/ferramentaAutoria/FadCoursesAnexo.service';
import { FadCoursesAnexoModel } from 'models/ferramentaAutoria/FadCourseAnexoModel';
import useFetch from 'hooks/useFetch';
import { ToastSuccess } from 'components/Toaster';
import { TextDangerouslySetInnerHtml } from 'pages/recursos/recursos-conteudo/RecursosConteudoPage.styled';

export const enum TemplateCourseFadEnum {
    Banner = 'B',
    Lateral = 'L',
}

const Styled = {
    Container: styled.div<{ template: TemplateCourseFadEnum }>`
        display: flex;
        align-items: ${({ template }) => (template === TemplateCourseFadEnum.Lateral ? 'center' : 'start')};
        justify-content: space-between;
        width: 100%;
        flex-direction: ${({ template }) => (template === TemplateCourseFadEnum.Lateral ? 'row' : 'column-reverse')};
        margin-top: 20px;
        gap: 16px;
    `,

    Column: styled.div`
        display: flex;
        flex-direction: column;
        gap: 16px;
    `,

    Row: styled.div`
        display: flex;
        gap: 16px;
    `,

    Status: styled.div<{ isPublic: boolean }>`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 69px;
        height: 25px;
        padding: 4px 8px 4px 8px;
        gap: 16px;
        border-radius: 16px;
        opacity: 0px;
        background-color: ${({ isPublic }) => (isPublic ? '#1B7F43' : '#99E3FF')};
        color: ${({ isPublic }) => (isPublic ? Colors.White : '')};
        font-family: 'Barlow';
        font-size: 12px;
        font-weight: 600;
        line-height: 16.8px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin-top: 10px;
    `,

    Title: styled.h1`
        font-family: 'Barlow';
        font-size: 36px;
        font-weight: 700;
        line-height: 43.2px;
        text-align: left;
        text-decoration-skip-ink: none;
        color: ${Colors.Blue};
    `,

    Description: styled.p`
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        text-decoration-skip-ink: none;
    `,
};

interface ITemplateCourse {
    template: TemplateCourseFadEnum;
    dados: FadCoursesModel;
    handleCourse: (value: FadCoursesModel) => void;
}

const TemplateCourse = ({ template, dados, handleCourse }: ITemplateCourse) => {
    const [loading, setLoading] = useState(false);
    const [anexo, setAnexo] = useState<FadCoursesAnexoModel | null>(null);
    const [templateModel, setTemplateModel] = useState<TemplateCourseFadEnum>(template);

    const serviceCourse = new FadCoursesService();

    const serviceCourseAnexo = new FadCoursesAnexoService();

    const atualizationTemplate = async () => {
        const newTemplate = templateModel === TemplateCourseFadEnum.Lateral ? TemplateCourseFadEnum.Banner : TemplateCourseFadEnum.Lateral;

        setTemplateModel(newTemplate);

        const newData = new FadCoursesModel().fromJSON({
            ...dados,
            template: newTemplate,
        });

        const { data, status } = await serviceCourse.update(dados.codigo, newData);

        if (status === 200) {
            handleCourse(data);
        }
    };

    const createUpdateAnexo = async (img: string) => {
        let newAnexo = new FadCoursesAnexoModel().fromJSON({
            codigo: anexo?.codigo ?? -1,
            codigoFerramentaAutoriaCourse: anexo?.codigoFerramentaAutoriaCourse ?? dados.codigo,
            filepath: anexo?.filepath ?? '',
            base64: img,
        });

        const { data, status } = await serviceCourseAnexo.createUpdate(newAnexo);

        if (status === 201) {
            ToastSuccess('Imagem salva com sucesso!');
            setAnexo(data);
        }
    };

    useFetch(async () => {
        setLoading(true);
        const { data, status } = await serviceCourseAnexo.findAnexoByCourse(dados.codigo);

        if (status === 200) {
            setAnexo(data);
            setLoading(false);
        }
    }, [dados]);

    useEffect(() => {
        setTemplateModel(template);
    }, [template]);

    return (
        <Styled.Container template={templateModel}>
            <Styled.Column style={{ maxWidth: templateModel === TemplateCourseFadEnum.Banner ? '80%' : '50%' }}>
                <Styled.Row>
                    <Styled.Title>{dados.name}</Styled.Title>
                    <Styled.Status isPublic={dados.isPublic}>{dados.isPublic ? 'Publicado' : 'Privado'}</Styled.Status>
                </Styled.Row>
                <TextDangerouslySetInnerHtml dangerouslySetInnerHTML={{ __html: dados.description }} />
            </Styled.Column>
            <Styled.Row style={{ alignItems: 'end' }}>
                {loading ? (
                    <Skeleton.Image
                        active
                        style={{
                            width: templateModel === TemplateCourseFadEnum.Banner ? '1440px' : '500px',
                            height: '240px',
                        }}
                    />
                ) : (
                    <>
                        <ImageUpload
                            base64={anexo?.base64 ?? ''}
                            handleUpload={a => createUpdateAnexo(a)}
                            componentStyled={{ width: templateModel === TemplateCourseFadEnum.Banner ? '1440px' : '500px' }}
                        />
                    </>
                )}
                <Popover content={'Mudar o tipo do template - Lateral ou Banner'} title="Template" placement="leftTop">
                    {templateModel === TemplateCourseFadEnum.Banner ? (
                        <FaArrowDown size={22} cursor={'pointer'} onClick={atualizationTemplate} />
                    ) : (
                        <FaArrowUp size={22} cursor={'pointer'} onClick={atualizationTemplate} />
                    )}
                </Popover>
            </Styled.Row>
        </Styled.Container>
    );
};
export default TemplateCourse;
