import { Divider, Popover } from 'antd';
import React from 'react';
import { MdAdd } from 'react-icons/md';

interface IFadAdds {
    onClick: () => void;
    componentStyled?: React.CSSProperties;
    textPopover: string;
}

const FadAdds = ({ onClick, componentStyled, textPopover }: IFadAdds) => {
    return (
        <Popover title={textPopover}>
            <div
                style={{
                    position: 'relative',
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                    marginTop: 40,
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        width: '60px ',
                        height: '60px',
                        borderRadius: '50%',
                        backgroundColor: '#003366', // Cor azul
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        top: '-5px',
                        ...componentStyled,
                    }}
                    onClick={onClick}
                >
                    <span style={{ fontSize: '24px', color: '#fff' }}>
                        <MdAdd />
                    </span>
                </div>
                <Divider />
            </div>
        </Popover>
    );
};

export default FadAdds;
