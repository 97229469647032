import { Serializable } from 'ts-serializable';
import { GenericService } from '../Generic.service';
import { FadCoursesAnexoModel } from 'models/ferramentaAutoria/FadCourseAnexoModel';

export class FadCoursesAnexoService extends GenericService {
    readonly URI: string = '/v1/fadCoursesAnexo';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    async createUpdate(model: FadCoursesAnexoModel) {
        return await this.post('', model);
    }

    async findAnexoByCourse(codigo: number) {
        return await this.get('course/' + codigo, { isPublic: false });
    }
}
