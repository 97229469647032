import { FadUserClassesModel } from 'models/ferramentaAutoria/FadUserClassesModel';
import { GenericService } from '../Generic.service';

export class FadUserClassesService extends GenericService {
    readonly URI: string = '/v1/fadUserClasses';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    async create(codigo: number) {
        return await this.post(`${codigo}`, null);
    }

    async update(codigo: number, model: FadUserClassesModel) {
        return await this.put(`${codigo}`, model);
    }

    async deleteModel(codigo: number) {
        return await this.delete(codigo);
    }

    async findById(codigo: number) {
        return await this.get(`${codigo}`);
    }

    async findAllFadUserClasses() {
        return await this.get();
    }

    async findByFadClasses(fadClasses: any) {
        return await this.get('byFadClasses', fadClasses);
    }

    async findByFadClassesCodigo(codigo: number) {
        return await this.get(`byFadClassesCodigo/${codigo}`);
    }

    async findByInscrito(codigoCourse: number) {
        return await this.get(`byInscrito/${codigoCourse}`);
    }

    async findByPerfilUsuarioCodigo(codigo: number) {
        return await this.get(`byPerfilUsuarioCodigo/${codigo}`);
    }

    async findByRegistration(registration: string) {
        return await this.get('byRegistration', { registration });
    }
}
