import { jsonProperty, Serializable, SerializationSettings } from 'ts-serializable';

interface IFadCoursesAnexoModel {
    codigo: number;
    codigoFerramentaAutoriaCourse: number;
    filepath: string;
    base64: string;
}

export class FadCoursesAnexoModel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number)
    codigoFerramentaAutoriaCourse: number = -1;

    @jsonProperty(String)
    filepath: string = '';

    @jsonProperty(String)
    base64: string = '';

    fromJSON(json: IFadCoursesAnexoModel, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.codigoFerramentaAutoriaCourse = json.codigoFerramentaAutoriaCourse;
        this.filepath = json.filepath;
        this.base64 = json.base64;

        return this;
    }
}
