import { FadTemplatesModel } from 'models/ferramentaAutoria/FadTemplatesModel';
import { GenericService } from '../Generic.service';

export class FadTemplatesService extends GenericService {
    readonly URI: string = '/v1/fadTemplates';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    // Método para criar ou atualizar um template
    async saveTemplate(template: FadTemplatesModel) {
        return await this.post('', template);
    }

    async updateTemplate(codigo: number, template: FadTemplatesModel) {
        return await this.put('' + codigo, template);
    }

    // Método para buscar todos os templates
    async getAllTemplates() {
        return await this.get();
    }

    // Método para buscar um template por código
    async getTemplateByCodigo(codigo: number) {
        return await this.get(`${codigo}`);
    }

    // Método para deletar um template
    async deleteTemplate(codigo: number) {
        return await this.delete(codigo);
    }
}