import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { FAD_Button_Outline } from '../components/FadButtons';
import { IoIosArrowBack, IoMdCloseCircle } from 'react-icons/io';
import { useHistory, useParams } from 'react-router-dom';
import { MdAddCircle, MdDeleteForever, MdEdit } from 'react-icons/md';
import { FadCoursesModel } from 'models/ferramentaAutoria/FadCoursesModel';
import { FadCoursesService } from 'core/http/service/ferramentaAutoria/FadCourses.service';
import useFetch from 'hooks/useFetch';
import Colors from 'enums/Colors';
import { DatePicker, Input, Modal, Popconfirm, Select, Switch, Table, TableProps } from 'antd';
import { FerramentaAutoriaContext } from '../context/FerramentaAutoriaContext';
import { FadClassesModel } from 'models/ferramentaAutoria/FadClassesModel';
import moment, { now } from 'moment';
import dayjs from 'dayjs';
import { FadClassesService } from 'core/http/service/ferramentaAutoria/FadClasses.service';
import { ToastError, ToastSuccess } from 'components/Toaster';
import { DataSourceItemType } from 'antd/es/auto-complete';
import { CategoriaPE } from 'pages/gestao_paginas/new-edit-recurso/item-recurso/AdminGestaoPaginaItemRecurso';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        padding: 22px;
        background-color: #fff;
    `,

    Header: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;
    `,

    Title: styled.h1`
        font-size: 22px;
        color: ${Colors.Blue};
        font-weight: 600;
        width: 600px;
        text-align: center;
        line-height: 26px;
    `,

    Form: {
        Container: styled.div`
            display: flex;
            flex-direction: column;
            gap: 16px;
        `,

        Column: styled.div`
            display: flex;
            flex-direction: column;
            gap: 8px;
        `,

        Label: styled.h2`
            color: ${Colors.Blue};
            font-weight: 600;
        `,
    },
};

const { RangePicker } = DatePicker;

const InitialFormClasses = new FadClassesModel().fromJSON({
    codigo: -1,
    name: '',
    categoriaPaginaExclusivaCodigo: null,
    closingDate: null,
    active: true,
    fadCoursesCodigo: -1,
    maxRegistrationDate: null,
});

const AdminFadClasses = () => {
    const history = useHistory();
    const { idCurso }: any = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [formClass, setFormClass] = useState<FadClassesModel>(InitialFormClasses);

    const [isUpdate, setUpdate] = useState<boolean>(false);
    const [idClasse, setIdClasse] = useState<number>(-1);

    const [classes, setClasses] = useState<FadClassesModel[]>([]);

    const handleChange = (dates: any) => {
        setFormClass((prev: any) => ({ ...prev, maxRegistrationDate: dates[0], closingDate: dates[1] }));
    };

    const serviceFadCourses = new FadCoursesService();
    const serviceClasses = new FadClassesService();

    const { categoriasExclusivas } = useContext(FerramentaAutoriaContext.Context);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCreateClasses = async () => {
        const date = formClass;
        date.fadCoursesCodigo = course.codigo;
        const categoriaSelecionada = categoriasExclusivas.find(f => f.nome.includes(date.categoriaPaginaExclusivaCodigo?.toString() ?? ''));

        if (categoriaSelecionada) {
            date.categoriaPaginaExclusivaCodigo = categoriaSelecionada.id;
        }

        const { data, status } = await serviceClasses.create(date);

        if (status === 201) {
            ToastSuccess('Turma criada com sucesso!');
            setClasses((prev: any) => [...prev, data]);
        }
        setIsModalOpen(false);
        setFormClass(InitialFormClasses);
    };

    const handleUpdateClasses = async () => {
        const date = formClass;
        date.fadCoursesCodigo = course.codigo;
        const categoriaSelecionada = categoriasExclusivas.find(f => f.nome.includes(date.categoriaPaginaExclusivaCodigo?.toString() ?? ''));

        if (categoriaSelecionada) {
            date.categoriaPaginaExclusivaCodigo = categoriaSelecionada.id;
        }

        const { data, status } = await serviceClasses.update(idClasse, date);

        if (status === 200) {
            ToastSuccess('Turma atualizada com sucesso!');
            setClasses((prev: any) =>
                prev.map((c: FadClassesModel, i: number) => {
                    if (c.codigo === data.codigo) {
                        return data;
                    }

                    return c;
                })
            );
        }
        setIsModalOpen(false);
        setFormClass(InitialFormClasses);
        setIdClasse(-1);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setFormClass(InitialFormClasses);
        setUpdate(false);
    };

    const [course, setCourse] = useState<FadCoursesModel>(new FadCoursesModel());

    const disabledDate = (current: dayjs.Dayjs | null): boolean => {
        return !!current && current.isBefore(dayjs().startOf('day'));
    };

    useFetch(async () => {
        const { data, status } = await serviceFadCourses.findById(idCurso);

        if (status === 200) {
            setCourse(data);
        }
    }, []);

    useFetch(async () => {
        const { data, status } = await serviceClasses.findByFadCoursesCodigoPrivate(idCurso);

        if (status === 200) {
            setClasses(data);
        }
    }, []);

    const goToBack = () => {
        history.push('/admin/ferramenta-autoria/curso/' + idCurso);
    };

    const columns = [
        // Defina suas colunas aqui
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Código Categoria',
            dataIndex: 'categoriaPaginaExclusivaCodigo',
            key: 'categoriaPaginaExclusivaCodigo',
            render: (categoria: number) => {
                const categoriaEncontrada = categoriasExclusivas.find(f => f.id === categoria);
                return categoriaEncontrada ? categoriaEncontrada.nome : 'Nenhuma categoria'; // Retorna o nome ou null se não encontrar
            },
        },
        {
            title: 'Turma ativa',
            dataIndex: 'active',
            key: 'active',
        },
        {
            title: 'Data Máxima de Registro',
            dataIndex: 'maxRegistrationDate',
            key: 'maxRegistrationDate',
            render: (date: any) => (date ? moment(date).format('DD/MM/YYYY') : 'Nenhuma data informada'), // Formata a data
        },
        {
            title: 'Data de Fechamento',
            dataIndex: 'closingDate',
            key: 'closingDate',
            render: (date: any) => (date ? moment(date).format('DD/MM/YYYY') : 'Nenhuma data informada'), // Formata a data
        },
        {
            title: 'Ações',
            dataIndex: 'acoes',
            key: 'acoes',
        },
    ];

    return (
        <Styled.Container>
            <Styled.Header>
                <FAD_Button_Outline icon={<IoIosArrowBack />} text="Voltar" onClick={goToBack} />
                <Styled.Title>{course.name}</Styled.Title>
                <FAD_Button_Outline icon={<MdAddCircle />} text="Criar nova turma" onClick={showModal} />
            </Styled.Header>
            <Table
                columns={columns}
                dataSource={classes.map(c => {
                    return {
                        name: c.name,
                        active: c.active ? (
                            <IoCheckmarkCircleSharp size={22} color={Colors.LightGreen} />
                        ) : (
                            <IoMdCloseCircle size={22} color={Colors.InfoRed} />
                        ),
                        categoriaPaginaExclusivaCodigo: c.categoriaPaginaExclusivaCodigo,
                        maxRegistrationDate: c.maxRegistrationDate,
                        closingDate: c.closingDate,
                        acoes: (
                            <div style={{ display: 'flex', gap: 10 }}>
                                <MdEdit
                                    size={20}
                                    cursor={'pointer'}
                                    color={Colors.Yellow2}
                                    onClick={() => {
                                        setUpdate(true);
                                        setFormClass(c);
                                        setIdClasse(c.codigo);
                                        showModal();
                                    }}
                                />
                            </div>
                        ), // Aqui você pode adicionar seus botões ou links
                    };
                })}
            />
            <Modal
                title={isUpdate ? 'Atualizar turma' : 'Criar uma nova turma'}
                okButtonProps={{
                    style: {
                        backgroundColor: Colors.Blue,
                    },
                }}
                maskClosable={false}
                okText={isUpdate ? 'Atualizar turma' : 'Criar turma'}
                cancelText="Cancelar"
                open={isModalOpen}
                onOk={() =>
                    formClass.categoriaPaginaExclusivaCodigo != null
                        ? isUpdate
                            ? handleUpdateClasses()
                            : handleCreateClasses()
                        : ToastError('O campo categoria está vazio!!')
                }
                onCancel={handleCancel}
            >
                <Styled.Form.Container>
                    <Styled.Form.Column>
                        <Styled.Form.Label>Nome da turma:</Styled.Form.Label>
                        <Input
                            placeholder="Exemplo: Turma 01"
                            showCount
                            maxLength={80}
                            size="large"
                            value={formClass.name}
                            onChange={e => setFormClass((prev: any) => ({ ...prev, name: e.target.value }))}
                        />
                    </Styled.Form.Column>
                    <Styled.Form.Column>
                        <Styled.Form.Label>Adicione uma categoria exclusiva caso for nescessário:</Styled.Form.Label>
                        <Select
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Categorias exclusivas"
                            size="large"
                            value={formClass.categoriaPaginaExclusivaCodigo}
                            onChange={v => setFormClass((prev: any) => ({ ...prev, categoriaPaginaExclusivaCodigo: v }))}
                            options={categoriasExclusivas.map(c => ({ label: c.nome, value: c.id }))}
                        />
                    </Styled.Form.Column>
                    <Styled.Form.Column>
                        <Styled.Form.Label>Possui uma data limite? (Data limite de inscrição / Data limite de acesso):</Styled.Form.Label>
                        <RangePicker
                            placeholder={['Fim da inscrição', 'Limite de acesso']}
                            format={'DD/MM/YYYY'}
                            size="large"
                            value={[
                                formClass.maxRegistrationDate ? dayjs(formClass.maxRegistrationDate.toString()) : null,
                                formClass.closingDate ? dayjs(formClass.closingDate.toString()) : null,
                            ]}
                            onChange={handleChange} // Chama a função quando as datas mudam
                            disabledDate={disabledDate}
                        />
                    </Styled.Form.Column>
                    <Styled.Form.Column>
                        <Styled.Form.Label>Turma ativa:</Styled.Form.Label>
                        <Switch
                            style={{ width: 60 }}
                            checked={formClass.active}
                            onChange={c => setFormClass((prev: any) => ({ ...prev, active: c }))}
                        />
                    </Styled.Form.Column>
                </Styled.Form.Container>
            </Modal>
        </Styled.Container>
    );
};

export default AdminFadClasses;
