import { jsonProperty, Serializable, SerializationSettings } from "ts-serializable";

interface IFadPages {
    codigo: number;
    url: string;
    cor: string;
}

export class FadPagesModel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(String)
    url: string = '';

    @jsonProperty(String)
    cor: string = '';

    fromJSON(json: IFadPages, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.url = json.url;
        this.cor = json.cor;
        return this;
    }
}