import { FadUserAvaliationsContentsModel } from 'models/ferramentaAutoria/FadAvaliationsContentModel';
import { GenericService } from '../Generic.service';

export class FadUserAvaliationsContentsService extends GenericService {
    readonly URI: string = '/v1/fadUserAvaliationsContents';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    // Método para criar ou atualizar uma avaliação
    async saveOrUpdate(model: FadUserAvaliationsContentsModel) {
        return await this.post('', model);
    }

    // Método para buscar uma avaliação por código
    async getByCodigo(codigo: number) {
        return await this.get(`${codigo}`);
    }

    // Método para deletar uma avaliação
    async deleteModel(codigo: number) {
        return await this.delete(codigo);
    }

    // Método para buscar todas as avaliações
    async getAll() {
        return await this.get();
    }

    async findByCodigoFerramentaAutoriaContent(codigoFerramentaAutoriaContent: number, codigoClasse: number) {
        return await this.get(`ferramenta-autoria-content/${codigoFerramentaAutoriaContent}/${codigoClasse}`);
    }
}
