import { FadCoursesService } from 'core/http/service/ferramentaAutoria/FadCourses.service';
import useFetch from 'hooks/useFetch';
import { FerramentaAutoriaContext } from 'pages/ferramentaAutoria/context/FerramentaAutoriaContext';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import FadCourseTemplatePublic from './components/FadCourseTemplatePublic';
import FadListModulesPublic from './components/FadListModulesPublic';
import { FAD_Button_Outline } from 'pages/ferramentaAutoria/components/FadButtons';
import { MdArrowLeft, MdOutlineArrowLeft } from 'react-icons/md';
import { IoMdArrowBack } from 'react-icons/io';
import { FadLogUserCoursesService } from 'core/http/service/ferramentaAutoria/FadLogUserCourses.service';
import { FadLogUserCoursesModel } from 'models/ferramentaAutoria/FadLogUserCoursesModel';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 100px 60px;

        @media screen and (max-width: 720px){
            padding: 100px 16px;
        }
    `,
};

interface IFadCoursePage {}

const FadCoursePage = ({}: IFadCoursePage) => {
    const { page, curso }: any = useParams();
    const { setCoursePublic, coursePublic, inscrito, courseClose } = useContext(FerramentaAutoriaContext.Context);
    const [initiated, setInitiated] = useState<boolean>(false);
    const history = useHistory();
    //service
    const serviceCourse = new FadCoursesService();

    const getLastModuloCompleted = (indexCurrent: number) => {
        if (courseClose) {
            return false;
        }

        const lastModulo = coursePublic.modulos[indexCurrent - 1];

        if (lastModulo) {
            if (lastModulo.progress) {
                return true;
            } else {
                return false;
            }
        } else {
            if (inscrito) {
                return true;
            } else {
                return false;
            }
        }
    };

    const courseInitiated = () => {
        if (coursePublic.modulos[0]) {
            if (coursePublic.modulos[0].conteudos && coursePublic.modulos[0].conteudos.length > 0) {
                if (coursePublic.modulos[0].conteudos[0].progress) {
                    if (coursePublic.modulos[0].conteudos[0].progress.percentageCompleted >= 0) {
                        setInitiated(true);
                    }
                }
            }
        }
    };

    const goToBack = () => {
        history.push(`/cursos/` + page);
    };

    useEffect(() => {
        courseInitiated();
    }, [coursePublic]);



    useFetch(async () => {
        if (coursePublic.course.url == '/' + curso) {
            return;
        }

        const { data, status } = await serviceCourse.findByUrlPublic(curso);

        if (status === 200) {
            setCoursePublic(data);
        }
    }, [curso]);

    return (
        <Styled.Container>
            <FAD_Button_Outline text="Voltar" icon={<IoMdArrowBack />} onClick={() => goToBack()} styles={{ width: 120 }} />
            <FadCourseTemplatePublic course={coursePublic.course} anexo={coursePublic.anexo} courseInitiated={initiated} />

            {coursePublic.modulos
                ?.sort((a, b) => a.ordem - b.ordem)
                .map((m, i) => (
                    <FadListModulesPublic
                        key={i}
                        modulo={m}
                        isFirstModule={!courseClose && inscrito && i == 0}
                        lastModuloCompleted={getLastModuloCompleted(i)}
                        modulesSequences={coursePublic.course.sequentialSteps}
                        contentsSequences={coursePublic.course.sequentialContents}
                    />
                ))}
        </Styled.Container>
    );
};

export default FadCoursePage;
