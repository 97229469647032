import Colors from 'enums/Colors';
import { FadModulesModel } from 'models/ferramentaAutoria/FadModulesModel';
import React, { useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosArrowDown, IoMdSettings } from 'react-icons/io';
import styled from 'styled-components';
import FadAdds from '../adds/FadAdds';
import { FadContentsModel } from 'models/ferramentaAutoria/FadContentsModel';
import { FadContentsService } from 'core/http/service/ferramentaAutoria/FadContents.service';
import useFetch from 'hooks/useFetch';
import { MdSettings } from 'react-icons/md';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { FAD_Button_Outline } from '../FadButtons';
import ModalFormModule from '../modals/ModalFormModule';
import { FadModulesService } from 'core/http/service/ferramentaAutoria/FadModules.service';
import { ToastError, ToastSuccess } from 'components/Toaster';
import ModalFormContent from '../modals/ModalFormContent';
import { useHistory, useParams } from 'react-router-dom';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 20px;
        border: 1px dotted ${Colors.Grey10};
        position: relative;
        margin-top: 44px;
    `,

    Header: styled.div`
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: space-between;
        background-color: #f0f0f0;
        width: fit-content;
        padding: 10px;
        top: -42px;
        gap: 16px;
        left: 0;
        border-radius: 10px 10px 0px 0px;
    `,

    Metric: styled.div`
        position: relative;
        font-family: 'Barlow';
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    `,

    Description: styled.p`
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    `,

    Row: styled.div`
        display: flex;
        align-items: center;
        gap: 16px;
    `,

    Column: styled.div`
        display: flex;
        flex-direction: column;
        align-items: end;
    `,

    Item: {
        Container: styled.div`
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #fafafa;
            padding: 26px;
        `,

        Title: styled.h1`
            font-family: 'Barlow';
            font-size: 22px;
            font-weight: 600;
            line-height: 25.2px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
        `,
    },
};

interface IItemContent {
    dados: FadContentsModel;
    index: number;
    codigoModule: number;
    handleUpdateOrdem: (isUp: boolean) => void;
    isLastContent: boolean;
    handleRemoveContent: (codigo: number) => void;
}

const ItemContent = ({ dados, index, codigoModule, isLastContent, handleUpdateOrdem, handleRemoveContent }: IItemContent) => {
    const { idCurso }: any = useParams();
    const history = useHistory();
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [content, setContent] = useState<FadContentsModel>(dados);

    const [formContent, setFormContent] = useState<FadContentsModel>(dados);

    const serviceFadContent = new FadContentsService();

    const handleEdit = async () => {
        formContent.codigoFerramentaAutoriaModule = codigoModule;

        const { data, status } = await serviceFadContent.update(dados.codigo, formContent);

        if (status === 200) {
            ToastSuccess('Conteúdo atualizado com sucesso!');
            setOpenEdit(false);
            setContent(data);
        }
    };

    const deleteContent = async (content: FadContentsModel) => {
        const { status } = await serviceFadContent.delete(content.codigo);

        if (status === 204) {
            ToastSuccess("Conteúdo removido com sucesso!")
            handleRemoveContent(content.codigo);
            setOpenEdit(false);
        }
    };

    const goToContent = () => {
        history.push(`/admin/ferramenta-autoria/curso/turmas/${idCurso}/${codigoModule}/conteudo/${content.codigo}`);
    };

    useEffect(() => {
        setContent(dados);
    }, [FadContentsModel]);

    return (
        <Styled.Item.Container>
            <Styled.Row>
                <Styled.Item.Title>{content.title}</Styled.Item.Title>
                <MdSettings size={22} cursor={'pointer'} onClick={() => setOpenEdit(true)} />
            </Styled.Row>
            <Styled.Column style={{ gap: 16 }}>
                <Styled.Row>
                    {index != 0 && <FaArrowUp onClick={() => handleUpdateOrdem(true)} size={22} cursor={'pointer'} />}
                    {!isLastContent && <FaArrowDown onClick={() => handleUpdateOrdem(false)} size={22} cursor={'pointer'} />}
                </Styled.Row>
                <FAD_Button_Outline onClick={goToContent} text="Editar conteúdo" />
            </Styled.Column>
            <ModalFormContent
                open={false}
                openEdit={openEdit}
                setForm={setFormContent}
                form={formContent}
                handleEdit={handleEdit}
                handleSave={() => ''}
                handleHidden={() => setOpenEdit(false)}
                loading={false}
                handleDelete={c => deleteContent(c)}
            />
        </Styled.Item.Container>
    );
};

interface IFadListModules {
    dados: FadModulesModel;
    handleQuantity: (value: number) => void;
    removeModule: (codigo: number) => void;
}

const FadListModules = ({ dados, handleQuantity, removeModule }: IFadListModules) => {
    const [contents, setContents] = useState<FadContentsModel[]>([]);
    const [module, setModule] = useState<FadModulesModel>(dados);

    //Modal
    const [open, setOpen] = useState<boolean>(false);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [formModal, setFormModal] = useState<FadModulesModel>(dados);

    //service
    const serviceContents = new FadContentsService();
    const serviceModules = new FadModulesService();

    const newOrdem = (): number => {
        return contents?.at(-1)?.ordem ? contents.at(-1)!.ordem + 1 : 1;
    };

    const handleCreateNewContent = async () => {
        const dataContent = new FadContentsModel().fromJSON({
            codigo: -1,
            title: 'Titulo do conteúdo',
            codigoFerramentaAutoriaModule: dados.codigo,
            isPublic: false,
            activeAvaliations: false,
            activeCommentary: false,
            ordem: newOrdem(),
        });

        const { data, status } = await serviceContents.create(dataContent);

        if (status === 201) {
            setContents((prev: any) => [...prev, data]);
        }
    };

    const handleUpdate = async () => {
        const { data, status } = await serviceModules.update(dados.codigo, formModal);

        if (status === 200) {
            ToastSuccess('Módulo atualizado com sucesso!');
            setModule(data);
        }

        setOpenEdit(false);
    };

    const deleteModule = async (module: FadModulesModel) => {
        const { status } = await serviceModules.delete(module.codigo);

        if (status === 204) {
            ToastSuccess('Módulo removido com sucesso!');
            removeModule(module.codigo);
        }

        setOpenEdit(false);
    };

    const handleUpdateOrdem = async (index: number, isUp: boolean) => {
        // Verificar se o índice está dentro dos limites
        if ((isUp && index === 0) || (!isUp && index === contents.length - 1)) {
            ToastError('Movimento inválido.');
            return;
        }

        // Fazer uma cópia da lista para manipular
        const updatedContents = [...contents];

        // Determinar os conteúdos a serem trocados
        const content1 = updatedContents[index];
        const content2 = isUp ? updatedContents[index - 1] : updatedContents[index + 1];

        // Trocar as ordens
        const tempOrdem = content1.ordem;
        content1.ordem = content2.ordem;
        content2.ordem = tempOrdem;

        // Adicionar informações adicionais antes do envio
        content1.codigoFerramentaAutoriaModule = module.codigo;
        content2.codigoFerramentaAutoriaModule = module.codigo;

        try {
            // Enviar atualizações ao backend
            const [response1, response2] = await Promise.all([
                serviceContents.updateOrdem(content1.codigo, content1),
                serviceContents.updateOrdem(content2.codigo, content2),
            ]);

            if (response1.status === 200 && response2.status === 200) {
                ToastSuccess('Ordens atualizadas com sucesso.');

                // Reordenar a lista localmente
                updatedContents[index] = content2;
                updatedContents[isUp ? index - 1 : index + 1] = content1;

                // Atualizar o estado
                setContents(updatedContents);
            } else {
                throw new Error('Falha na atualização das ordens.');
            }
        } catch (error) {
            ToastError('Erro ao atualizar as ordens.');
            console.error(error);
        }
    };

    const handleRemoveContent = (codigo: number) => {
        setContents((prev: FadContentsModel[]) => prev.filter(c => c.codigo !== codigo));
    };

    useFetch(async () => {
        const { data, status } = await serviceContents.findByFadModulesCodigo(dados.codigo);

        if (status === 200) {
            setContents(data);
        }

        setModule(dados);
    }, [dados]);

    return (
        <Styled.Container>
            <Styled.Header>
                {module.title} <IoMdSettings size={22} cursor={'pointer'} color={Colors.Grey12} onClick={() => setOpenEdit(true)} />
            </Styled.Header>
            <Styled.Description>{module.description}</Styled.Description>
            <Styled.Metric>{contents.length} Conteúdo(s)</Styled.Metric>
            {contents
                .sort((a, b) => a.ordem - b.ordem)
                .map((content, index) => {
                    return (
                        <ItemContent
                            key={content.codigo}
                            index={index}
                            dados={content}
                            codigoModule={dados.codigo}
                            handleUpdateOrdem={isUp => handleUpdateOrdem(index, isUp)}
                            isLastContent={contents.length - 1 === index}
                            handleRemoveContent={handleRemoveContent}
                        />
                    );
                })}
            <FadAdds
                onClick={handleCreateNewContent}
                componentStyled={{ backgroundColor: Colors.SoftBlue }}
                textPopover="Adicionar um novo conteúdo"
            />

            <ModalFormModule
                open={open}
                handleHidden={() => setOpenEdit(false)}
                form={formModal}
                setForm={setFormModal}
                handleSave={() => ''}
                handleEdit={handleUpdate}
                loading={false}
                openEdit={openEdit}
                removeModule={deleteModule}
            />
        </Styled.Container>
    );
};

export default FadListModules;
